import payments from "../pages/transactionOverview/payments";

export const propertiesInitialState = (data = null) => {
  const { valuation, financial, address } = data || {};

  return {
    // * Property Type data
    building: data?.building || '',

    //Building details data
    propertyName: data?.name || '',
    typeId: data?.summary?.typeId || '',
    recordType: data?.summary?.recordTypeId || '',
    grossSQM: data?.generalInfo?.grossSqm || '',
    placeId: address?.placeId || '',
    description: address?.description || '',
    longitude: address?.longitude || '',
    latitude: address?.latitude || '',
    masterPropertyId: data?.masterPropertyId || '',
    acquisitionPrice: data?.acquisitionAmount || '',
    loanLevel: data?.summary?.loanLevel || '',
    buildingLoanAmount: data?.loanAmount || '0',

    //Units data
    unitGrossSQM: data?.unitGrossSqm?.toString() || '',
    numberOfBedrooms: data?.numberOfBedrooms?.toString() || '',
    numberOfBathrooms: data?.numberOfBathrooms?.toString() || '',
    loanAmount: financial?.loanAmount || '',
    unitAcquisitionAmount: financial?.acquisitionAmount?.toString() || '',
    unitTitle: data?.unitTitle || '',
    unitTypeId: data?.unitTypeId || '',
    numberOfUnits: 1,
    units: [],
    //viewUnit only used for viewing unit details in the dialog
    viewUnit: data?.units || [],

    //description
    propertyDescription: data?.description || '',
  };
};
