import listedProperties from 'assets/propp.png';
import locationIcon from 'assets/LocationIcon.svg';
import homeIcon from 'assets/homeIcon.svg';

export const ListedPropertyCard = (props) => {
  const surfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');
  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  return (
    <div className="flex grid-cols-2 rounded-md bg-white justify-between border border-[#ECECEC] min-w-[200px] sm:min-w-[425px]">
      <div>
        <img
          alt=""
          className="rounded-l-md sm:w-36 w-24 h-full"
          src={props.item.avatar || listedProperties}
        />
      </div>
      <div className="w-3/4 flex grid-cols-2">
        <div className="flex-col w-3/4 pl-1">
          <div className="sm:pt-2 pt-1 sm:pl-3 pl-2">
            <div className="justify-start text-left">
              <p className="font-semibold text-left self-start sm:text-base text-sm">
                {props.item.propertyName}
              </p>
            </div>
          </div>

          <div className="sm:p-2 p-1 flex items-center gap-2">
            <div className="bg-[#ECECEC] rounded-full p-1.5">
              <img alt="" className="sm:w-4 w-3" src={locationIcon} />
            </div>
            <div>
              <div className="sm:text-xs text-[10px] font-medium">{props.item.city}</div>
              <p className="sm:text-xs text-[10px] font-light text-[#8E8E8E]">
                {props.item.address}
              </p>
            </div>
          </div>

          <div>
            <div className="sm:p-2 p-1 flex items-center gap-2">
              <div className="bg-[#ECECEC] rounded-full p-1.5">
                <img alt="" className="sm:w-4 w-3" src={homeIcon} />
              </div>
              <div>
                <div className="sm:text-xs text-[10px] font-medium">{props.item.propertyType}</div>
                <p className="sm:text-xs text-[10px] font-light text-[#8E8E8E]">
                  {props.item.grossSqm + ' ' + surfaceSymbol}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col p-1">
          <div className="sm:text-xs text-[10px] rounded sm:p-2 p-1 bg-[#FFF2F0] text-[#EA3323] mt-2 m-auto">
            {props.item.listingType}
          </div>
          <div className="font-bold text-[#EA3323] whitespace-nowrap sm:text-lg text-sm">
            {' '}
            {currencySymbol + ' ' + props.item.askingPrice}
          </div>
        </div>
      </div>
    </div>
  );
};
