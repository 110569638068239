
export const RegisterSteps = ({ currentStep }) => {

  const steps = [
    {
      stepName: 'Account Info',
      stepIdentifier: 'account_info',
      stepIndex: 1,
    },
    {
      stepName: 'Billing Info',
      stepIdentifier: 'billing_info',
      stepIndex: 2,
    },
    {
      stepName: 'Credit Card Info',
      stepIdentifier: 'credit_card_info',
      stepIndex: 3,
    },
  ];
  return (
    <div className="flex justify-start items-center gap-3 mt-[40px] m:mt-[30px]">
      {
        steps.map((step) => (
          <div key={step.stepIndex}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col justify-center items-center gap-5 z-50">
                <div className={`flex justify-center items-center w-[40px] h-[40px] transition ease-in-out delay-150 p-4 ${currentStep >= step.stepIndex ? 'bg-[#F00] text-white' : 'bg-transparent text-[#F00] border border-[#F00]'} rounded-full`}>
                  {step.stepIndex}
                </div>
              </div>
              {step.stepIndex !== steps.length &&
                <div className={`w-[40px] h-[1px] bg-[#F00]`}></div>
              }
            </div>
          </div>
        ))}
    </div>
  );
};
