import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = (props) => {
  return (
    <DataGrid
      sx={{
        border: 0,
        '& .MuiDataGrid-columnGroupHeader': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          visibility: 'hidden',
        },
        '& .email_cell, .mobile_cell': {
          color: '#8E8E8E',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& > .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '& .MuiDataGrid-footerContainer': {
          borderTop: 'none',
        },

        '& > .MuiDataGrid-main': {
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#F0F3FF',
            '& .MuiDataGrid-cell': {
              color: 'black',
            },
            '&:focus': {
              outline: 'none',
            },
          },
          '& div div div div >.MuiDataGrid-cell': {
            borderBottom: '1px solid #F6F4F4',
          },
        },
        '&.MuiDataGrid-root': {
          padding: '10px',

          '& .MuiDataGrid-columnHeader': {
            fontWeight: '700 !important',
            fontSize: '12px',
            '&:focus': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-row': {
            color: 'black',
            display: 'flex',
            paddingLeft: '2px',
            fontSize: '12px',
            borderRadius: '8px',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },

        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '700',
          color: '#535353',
        },

        '& .MuiDataGrid-selectedRowCount': {
          background: '#F6F4F4',
          padding: '12px',
          borderRadius: '8px',
        },
      }}
      autoHeight
      disableSelectionOnClick
      disableColumnMenu
      {...props}
    />
  );
};
