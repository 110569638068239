import React from 'react';
import { useTranslation } from 'react-i18next';

import visibilityIcon from 'assets/visibiltyIcon.svg';
import EditButtonIcon from 'assets/EditButton.svg';
import DeleteButtonIcon from 'assets/DeleteButton.svg';
import bellIcon from 'assets/bell.svg';

/**
 * A component to display a list of attachments and provide
 * options to edit and delete each attachment.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {Array} props.attachments - A list of attachments.
 * @param {function} props.onEdit - A function to edit an attachment.
 * @param {function} props.onDelete - A function to delete an attachment.
 * @returns {JSX.Element} - The rendered component.
 */

export const AttachmentsList = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* mobile view */}
      <div className="flex flex-col sm:hidden gap-5">
        {props.attachments.pages.map((page) =>
          page.data.attachments.map((attachment, index) => (
            <div>
              <div className="grid grid-cols-2 items-center">
                <div className="flex flex-col gap-2 font-bold text-[#535353] text-xs">
                  {['file_name', 'file_type', 'shared_with_tenant', 'tags', 'actions'].map(
                    (_, index) => (
                      <div key={index}>{t(_)}</div>
                    ),
                  )}
                </div>

                <div className="flex flex-col gap-2">
                  <div className="text-xs col-span-2">{attachment.attachment.fileName}</div>
                  <div className="text-xs">{attachment.attachment.extension}</div>
                  <div className="text-xs">{attachment.shareWithTenant ? t('yes') : t('no')}</div>
                  <div className="text-xs">
                    {attachment.tags.length ? (
                      attachment.tags.map(({ name }) => name).join(', ')
                    ) : (
                      <p>{t('no_tags')}</p>
                    )}
                  </div>

                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={EditButtonIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        props.onEdit(attachment);
                      }}
                    />

                    <img
                      alt=""
                      src={DeleteButtonIcon}
                      className="cursor-pointer"
                      onClick={() => props.onDelete(attachment)}
                    />

                    {props.reminder && (
                      <img
                        alt=""
                        src={bellIcon}
                        className="cursor-pointer"
                        onClick={() => props.OnSetReminder(attachment)}
                      />
                    )}

                    <a href={attachment.attachment?.fileUrl} target="_blank" rel="noreferrer">
                      <img alt="" src={visibilityIcon} />
                    </a>
                  </div>
                </div>
              </div>
              {index !== props.attachments.length - 1 && (
                <div className="mt-2 bg-gray-300 w-full h-[1px]"></div>
              )}
            </div>
          )),
        )}

        {props.children}
      </div>

      {/* desktop view */}
      {props.attachments.pages.every((p) => p.data.attachments.length === 0) ? (
        <div className="text-center font-bold">{t('no_attachments')}</div>
      ) : (
        <div className="hidden sm:flex flex-col gap-6">
          <div className="grid grid-cols-6 font-bold text-[#535353] text-xs">
            {['file_name', 'file_type', 'shared_with_tenant', 'tags', 'actions'].map((_, index) => (
              <div className={`${_ === 'file_name' && 'col-span-2'}`} key={index}>
                {t(_)}
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-6">
            {props.attachments.pages.map((page) =>
              page.data.attachments.map((attachment) => (
                <div className="grid grid-cols-6 rounded-xl" key={attachment.attachmentId}>
                  <div className="text-xs col-span-2">{attachment.attachment.fileName}</div>
                  <div className="text-xs">{attachment.attachment.extension}</div>
                  <div className="text-xs">{attachment.shareWithTenant ? t('yes') : t('no')}</div>
                  <div className="text-xs">
                    {attachment.tags.length ? (
                      attachment.tags.map(({ name }) => name).join(', ')
                    ) : (
                      <p>{t('no_tags')}</p>
                    )}
                  </div>

                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={EditButtonIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        props.onEdit(attachment);
                      }}
                    />

                    <img
                      alt=""
                      src={DeleteButtonIcon}
                      className="cursor-pointer"
                      onClick={() => props.onDelete(attachment)}
                    />

                    {props.reminder && (
                      <img
                        alt=""
                        src={bellIcon}
                        className="cursor-pointer"
                        onClick={() => props.OnSetReminder(attachment)}
                      />
                    )}

                    <a href={attachment.attachment?.fileUrl} target="_blank" rel="noreferrer">
                      <img alt="" src={visibilityIcon} />
                    </a>
                  </div>
                </div>
              )),
            )}

            {props.children}
          </div>
        </div>
      )}
    </>
  );
};
