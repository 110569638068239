import React from 'react';
import styled from 'styled-components';
import { useIsMutating } from 'react-query';
import { Formik, Form, ErrorMessage } from 'formik';

import { isMobile } from 'utils/utils';
import { AttachmentSchema } from 'validationSchemas';

import { StyledDialog, StyledInput, StyledSelect } from 'components';
import { CircularProgress, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const EditAttachmentDialog = ({
  openDialog,
  onClose,
  onEdit,
  tagsDropdown,
  shareAttachmentApps,
  enableShareWithTenant,
  shareWithTenantDropdown,
}) => {
  const isMutating = useIsMutating(); // * used to display loading indicator
  const { t } = useTranslation();
  const { dialogType } = openDialog || {};
  const { attachment, tags } = openDialog?.data || {};

  return (
    <Formik
      enableReinitialize
      validationSchema={AttachmentSchema}
      initialValues={{
        fileName: attachment?.fileName || '',
        extension: attachment?.extension || '',
        tags: tags?.map((tag) => tag.id) || [],
        shareWithTenant: openDialog?.data?.shareWithTenant?.toString() || null,
        sharedToType: [],
      }}
      onSubmit={(values) => {
        const payload = {
          file_name: values.fileName,
          extension: values.extension,
          tags: values.tags,
          share_with_tenant: values.shareWithTenant,
          shared_to_type: values.sharedToType,
        };

        onEdit(payload);
      }}
    >
      {({ handleChange, values, errors, touched }) => (
        <StyledDialog
          fullScreen={isMobile()}
          fullWidth
          paddig="8px"
          open={dialogType === 'editAttachment'}
          onClose={onClose}
        >
          <DialogTitle>
            <div className="text-2xl font-bold">{t('edit_file')}</div>
          </DialogTitle>

          {!tagsDropdown ? (
            <div className="flex items-center justify-center">
              <CircularProgress color="error" />
            </div>
          ) : (
            <Form>
              <DialogContent>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col sm:flex-row gap-5">
                    <div className="flex flex-col gap-1 w-full">
                      <div className="text-sm text-black">{t('file_name')}</div>
                      <StyledInput
                        name="fileName"
                        placeholder={t('file_name')}
                        value={values.fileName}
                        onChange={handleChange}
                        error={touched.fileName && errors.fileName}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="fileName" />
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                      <div className="text-sm text-black">{t('extension')}</div>
                      <StyledInput
                        name="extension"
                        placeholder={t('file_type')}
                        value={values.extension}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-1 w-full">
                    <div className="text-sm text-black">{t('tags')}</div>
                    <StyledSelect
                      multiple
                      height="100%"
                      name="tags"
                      placeholder={t('tags')}
                      value={values.tags}
                      onChange={handleChange}
                      error={touched.tags && errors.tags}
                      itemsList={tagsDropdown}
                    />
                    <ErrorMessage component={CustomErrorMessage} name="tags" />
                  </div>

                  {enableShareWithTenant && (
                    <div className="flex flex-col gap-1">
                      <div className="text-sm text-black">{t('share_with_tenant')}</div>
                      <StyledSelect
                        name="shareWithTenant"
                        placeholder={t('share_with_tenant')}
                        value={values.shareWithTenant}
                        onChange={handleChange}
                        error={touched.shareWithTenant && errors.shareWithTenant}
                        itemsList={shareWithTenantDropdown}
                      />
                    </div>
                  )}
                </div>

                {shareAttachmentApps && (
                  <div className="w-full flex flex-col gap-1 mt-2">
                    <div className="text-sm text-black">{t('share_with_app')}</div>

                    <StyledSelect
                      multiple
                      height="100%"
                      name="sharedToType"
                      placeholder={t('share_with_app')}
                      value={values.sharedToType}
                      onChange={handleChange}
                      error={touched.sharedToType && errors.sharedToType}
                      itemsList={shareAttachmentApps}
                    />
                    <ErrorMessage component={CustomErrorMessage} name="sharedToType" />
                  </div>
                )}

                {values.extension === 'pdf' ? (
                  <div className="font-bold text-center p-2">{t('no_preview_available')}</div>
                ) : (
                  <img
                    className="rounded-lg object-cover h-[200px] w-full m-auto mt-4"
                    src={openDialog?.data?.attachment?.fileUrl}
                  />
                )}
              </DialogContent>

              <DialogActions>
                <div className="grid justify-items-center m-auto w-[90%] sm:w-[60%]">
                  <button
                    className="px-10 py-2 bg-[#EA3323] rounded-lg text-white font-semibold"
                    type="submit"
                  >
                    {!!isMutating ? (
                      <div className="flex items-center justify-center">
                        <CircularProgress color="inherit" size={25} />
                      </div>
                    ) : (
                      t('save')
                    )}
                  </button>
                </div>
              </DialogActions>
            </Form>
          )}
        </StyledDialog>
      )}
    </Formik>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
