import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useIsMutating } from 'react-query';

import { useCustomMutation } from 'mutations';
import { useCustomQuery } from 'queries';
import { paymentInitialState } from 'initialStates';
import { addPayment } from 'apis/payments/payments';
import { paymentsSchema } from 'validationSchemas';
import { isMobile } from 'utils/utils';

import { DatePicker, Notifier, StyledDialog, StyledInput, StyledSelect } from 'components';

import { DialogContent, CircularProgress } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

export const PaymentDialog = ({ apiFunctions, openDialog, onClose }) => {
  const isMutating = useIsMutating();
  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const { data } = openDialog || {};
  const { t } = useTranslation();

  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addPayment') return true;
    else if (openDialog?.dialogType === 'editPayment') return true;
    return false;
  };

  const {
    data: dropdownValues,
    isLoading,
    error,
  } = useCustomQuery(apiFunctions.getPaymentDropdowns.func, apiFunctions.getPaymentDropdowns.key, {
    enabled: isDialogOpen(),
  });

  const {
    mutateAsync: addPaymentMutation,
    data: addPaymentMutationResponse,
    isLoading: addPaymentLoading,
  } = useCustomMutation(apiFunctions.addPayment.func, apiFunctions.addPayment.key);

  const {
    mutateAsync: updatePaymentMutation,
    data: editPaymentMutationResponse,
    isLoading: updatePaymentLoading,
  } = useCustomMutation(apiFunctions.editPayment.func, apiFunctions.editPayment.key);

  return (
    <>
      <Notifier data={addPaymentMutationResponse} />
      <Notifier data={editPaymentMutationResponse} />

      <Formik
        enableReinitialize
        initialValues={paymentInitialState(data)}
        validationSchema={paymentsSchema}
        onSubmit={async (values) => {
          let payload = {
            ...values,
          };

          if (openDialog?.dialogType === 'addPayment') {
            const response = await addPaymentMutation(payload);
            if (response.statusCode === 200) onClose();
          } else if (openDialog?.dialogType === 'editPayment') {
            const updateResponse = await updatePaymentMutation(payload);
            if (updateResponse.statusCode === 200) onClose();
          }
        }}
      >
        {({ values, handleChange, errors, touched, setFieldValue, dirty }) => (
          <StyledDialog
            width="1064px"
            height="725px"
            open={isDialogOpen()}
            fullScreen={isMobile()}
            onClose={onClose}
            isEdited={dirty}
          >
            <DialogContent>
              {isLoading ? (
                <div className="grid place-content-center h-full">
                  <CircularProgress color="error" />
                </div>
              ) : (
                <div className="flex flex-col gap-8 items-center">
                  <div className="flex w-full sm:w-[60%] h-full border border-[#dfdfe87a] rounded-2xl justify-between items-center p-3">
                    <div className="flex gap-2 items-center font-semibold">
                      <div className="flex w-[48px] h-[48px] bg-[#EA3323] rounded-full items-center justify-center">
                        <img src={dialogTitleIcon} />
                      </div>
                      {openDialog?.dialogType === 'addPayment'
                        ? t('add_new_payment')
                        : t('edit_payment')}
                    </div>
                  </div>

                  <Form
                    className={`flex flex-col w-full sm:w-[60%] gap-4 justify-between h-full p-2`}
                  >
                    <div className="flex flex-col sm:flex-row gap-4 justify-center w-full overflow-auto p-1">
                      <div className="flex w-full flex-col gap-5">
                        <div className="w-full">
                          <div>{t('transaction')}</div>
                          <StyledSelect
                            name="transactionId"
                            placeholder={t('transaction')}
                            value={values.transactionId}
                            itemsList={dropdownValues?.data?.transactions}
                            error={touched.transactionId && errors.transactionId}
                            onChange={handleChange}
                            disabled
                          />
                        </div>

                        <div className="w-full">
                          <div>
                            {t('payment_date')}
                            {errors['paymentDate'] && <span className="text-[#EA3323]">*</span>}
                          </div>
                          <DatePicker
                            name="paymentDate"
                            value={values.paymentDate}
                            onChange={(value) => setFieldValue('paymentDate', value)}
                            error={touched.paymentDate && errors.paymentDate}
                          />
                        </div>

                        <div className="w-full">
                          <div>
                            {t('reference')}
                            {errors['referenceId'] && <span className="text-[#EA3323]">*</span>}
                          </div>
                          <StyledInput
                            name="referenceId"
                            placeholder={t('reference')}
                            value={values.referenceId}
                            onChange={handleChange}
                            error={touched.referenceId && errors.referenceId}
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-5">
                        <div className="w-full">
                          <div>
                            {t('contact')}
                            {errors['contactId'] && <span className="text-[#EA3323]">*</span>}
                          </div>
                          <StyledSelect
                            name="contactId"
                            placeholder={t('contact')}
                            value={values.contactId}
                            onChange={handleChange}
                            error={touched.contactId && errors.contactId}
                            itemsList={dropdownValues?.data?.contacts}
                          />
                        </div>

                        <div className="w-full">
                          <div>
                            {t('amount')}
                            {errors['amountFc'] && <span className="text-[#EA3323]">*</span>}
                          </div>
                          <StyledInput
                            name="amountFc"
                            placeholder={t('amount')}
                            value={values.amountFc}
                            onChange={handleChange}
                            error={touched.amountFc && errors.amountFc}
                            prefix={currencySymbol}
                          />
                        </div>

                        <div className="w-full">
                          <div>
                            {t('payment_method')}
                            {errors['paymentMethodId'] && <span className="text-[#EA3323]">*</span>}
                          </div>
                          <StyledSelect
                            name="paymentMethodId"
                            placeholder={t('payment_method')}
                            value={values.paymentMethodId}
                            onChange={handleChange}
                            error={touched.paymentMethodId && errors.paymentMethodId}
                            itemsList={dropdownValues?.data?.paymentMethods}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="self-end flex gap-4">
                      <SubmitButton type="submit">
                        {!!isMutating ? (
                          <div className="flex justify-center items-center">
                            <CircularProgress color="inherit" size={20} />
                          </div>
                        ) : (
                          t('submit')
                        )}
                      </SubmitButton>
                    </div>
                  </Form>
                </div>
              )}
            </DialogContent>
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const SubmitButton = styled.button`
  background: #ea3323;
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  width: 120px;
  padding: 10px;
  font-weight: bold;
`;
