import React from 'react';
import { StyledDialog } from 'components';
import { useMutation, useQuery } from 'react-query';

import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

import alertIcon from 'assets/redAlert.svg';
import alertCross from 'assets/alertCross.svg';
import { exportClosings, getClosingDropdownData } from 'apis/closings/closings';
import { useTranslation } from 'react-i18next';

export const ExportClosingsDialog = ({ openDialog, onClose, selectedClosings }) => {
  const { t } = useTranslation();
  const [exportType, setExportType] = React.useState('');
  const { data, isLoading } = useQuery('closingsDropDownData', getClosingDropdownData, {
    enabled: openDialog?.dialogType === 'export',
  });
  const { mutate, isLoading: exportLoading } = useMutation((payload) => exportClosings(payload));
  const [APIResponse, setAPIResponse] = React.useState({ message: '', code: '' });

  const handleExport = () => {
    let payload = {
      type: exportType,
      closingIds: selectedClosings,
    };
    mutate(payload, {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          onClose();
        } else if (data.statusCode === 403) {
          setAPIResponse({ message: data.message, code: data.statusCode });
        } else {
          setAPIResponse({ message: 'Server Error', code: data.statusCode });
        }
      },
    });
  };
  return (
    <StyledDialog
      fullWidth
      width="326px"
      height="400px"
      open={openDialog?.dialogType === 'export'}
      onClose={onClose}
    >
      {/*<DialogTitle sx={{ fontWeight:700,}}>Your Report</DialogTitle>*/}
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div className="font-bold text-xl">{t('your_report')}</div>
          {APIResponse.message && (
            <div className="flex justify-between items-center bg-[#FFF2F0] text-xs text-[#EA3323] p-3 rounded-lg">
              <div className="flex gap-2">
                <img src={alertIcon} />
                <span>{APIResponse.message}</span>
              </div>
              <img
                className="hover:cursor-pointer"
                src={alertCross}
                onClick={() => setAPIResponse({ message: '', code: '' })}
              />
            </div>
          )}
          <div className="text-xs text-[#EA3323] font-semibold">{t('export')}</div>
          <div className="text-sm text-[#15192080]">{t('receive_report')}</div>
          {isLoading ? (
            <div className="flex items-center justify-center">
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            data?.data.exportTypes.map((selectedExportType, index) => (
              <div key={selectedExportType.id}>
                <FormControlLabel
                  label={selectedExportType.name}
                  labelPlacement="left"
                  control={
                    <Checkbox
                      icon={
                        <div className="w-5 h-5 border border-[3px] border-[#56678942] rounded-full">
                          {' '}
                        </div>
                      }
                      checkedIcon={
                        <div className="flex justify-center items-center w-5 h-5 border border-[3px] border-[#EA3323] rounded-full">
                          <div className="w-2 h-2 bg-[#EA3323] rounded-full"></div>
                        </div>
                      }
                      checked={selectedExportType.name === exportType}
                      onChange={() => setExportType(selectedExportType.name)}
                      disableRipple
                    />
                  }
                />
              </div>
            ))
          )}
        </div>
      </DialogContent>
      <div className="flex justify-around">
        <button
          className="min-w-[136px] min-h-[42px] bg-white rounded-lg border border-[#56678942] text-[#15192080] font-semibold"
          onClick={onClose}
        >
          {t('cancel')}
        </button>
        <button
          className="min-w-[136px] min-h-[42px] bg-[#EA3323] rounded-lg text-white font-semibold"
          onClick={handleExport}
        >
          {exportLoading ? (
            <div className="flex items-center justify-center">
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            t('send_file')
          )}
        </button>
      </div>
    </StyledDialog>
  );
};
