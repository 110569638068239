import { Formik, Form, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCustomMutation } from 'mutations';
import { isMobile } from 'utils/utils';

import { StyledSelect, StyledInput, StyledDialog, Notifier } from 'components';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

export const EditSubscriptionDialog = ({ openDialog, onClose, dropdownData, apiFunctions }) => {
  const { t } = useTranslation();
  const { planId, users, units, billingPeriodId, userGroupPlanId, userGroupId } =
    openDialog?.data || {};

  const {
    mutateAsync: editSubscriptionMutation,
    isLoading: editLoading,
    data: subscriptionData,
  } = useCustomMutation(apiFunctions.updateSubscription.func, apiFunctions.updateSubscription.key);

  return (
    <>
      <Notifier data={subscriptionData} />

      <Formik
        enableReinitialize
        initialValues={{
          userGroupId: userGroupId,
          userGroupPlanId: userGroupPlanId,
          plan: planId,
          paymentFrequency: billingPeriodId,
          units: units,
          users: users,
        }}
        onSubmit={async (values) => {
          let payload = {
            ...values,
          };

          const response = await editSubscriptionMutation(payload);

          if (response.statusCode === 200) onClose();
        }}
      >
        {({ handleChange, values, errors, touched, dirty }) => (
          <StyledDialog
            width="1064px"
            fullScreen={isMobile()}
            open={openDialog?.dialogType === 'editSubscription'}
            onClose={onClose}
            isEdited={dirty}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex items-center w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
                    <img alt="" src={dialogTitleIcon} />
                  </div>
                  <span className="font-semibold">{t('edit_subscription')}</span>
                </div>
              </div>
            </div>

            <Form>
              <DialogContent>
                <div className="grid grid-cols-1 gap-4 w-[90%] sm:w-[60%] m-auto">
                  <div>
                    <div className="text-sm text-black pb-1">{t('plan')}</div>

                    <StyledSelect
                      name="plan"
                      placeholder={t('plan')}
                      value={values.plan}
                      onChange={handleChange}
                      error={touched.plan && errors.plan}
                      itemsList={dropdownData?.plans}
                    />
                  </div>

                  <div>
                    <div className="text-sm text-black pb-1">{t('payment_frequency')}</div>

                    <StyledSelect
                      name="paymentFrequency"
                      placeholder={t('payment_frequency')}
                      value={values.paymentFrequency}
                      onChange={handleChange}
                      error={touched.paymentFrequency && errors.paymentFrequency}
                      itemsList={dropdownData?.paymentFrequencies}
                    />
                  </div>

                  <div>
                    <div className="text-sm text-black pb-1">{t('units')}</div>

                    <StyledInput
                      name="units"
                      placeholder={t('units')}
                      value={values.units}
                      onChange={handleChange}
                      error={touched.units && errors.units}
                    />
                  </div>

                  <div>
                    <div className="text-sm text-black pb-1">{t('users')}</div>

                    <StyledInput
                      name="users"
                      placeholder={t('users')}
                      value={values.users}
                      onChange={handleChange}
                      error={touched.users && errors.users}
                    />
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <div className="grid justify-items-center sm:justify-items-end m-auto w-[90%] sm:w-[60%]">
                  <button
                    className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                    type="submit"
                  >
                    {editLoading ? (
                      <div className="flex justify-center items-center">
                        <CircularProgress color="inherit" size={25} />
                      </div>
                    ) : (
                      t('submit')
                    )}
                  </button>
                </div>
              </DialogActions>
            </Form>
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};
