import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';

import { PaymentAttachments } from './paymentAttachments';
import { PaymentDialog } from './paymentDialog';
import { InvoiceOverviewLoading } from '../invoiceOverviewLoading';
import { AddButton, EditButton, DeleteButton, DeleteDialog, Notifier } from 'components';

import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dateIcon from 'assets/dateIcon.svg';

const TransactionPayments = ({ apiFunctions }) => {
  const { t } = useTranslation();
  const { transactionDetails, transactionId } = useOutletContext();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data, isLoading, error } = useCustomQuery(
    apiFunctions.getPayments.func,
    apiFunctions.getPayments.key,
    transactionId,
  );

  const {
    mutateAsync: deletePaymentMutation,
    data: deletePaymentResponse,
    isLoading: deletePaymentPending,
  } = useCustomMutation(apiFunctions.deletePayment.func, apiFunctions.deletePayment.key);

  const handlePaymentDelete = async () => {
    const response = await deletePaymentMutation([openDialog?.data]);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  return isLoading ? (
    <InvoiceOverviewLoading />
  ) : (
    <div className="flex flex-col gap-6 bg-white rounded-b-2xl p-3">
      <Notifier data={deletePaymentResponse} />

      <div
        onClick={() =>
          setOpenDialog({
            data: { ..._.omit(transactionDetails, ['comments']) },
            dialogType: 'addPayment',
          })
        }
        className="flex items-center gap-2 cursor-pointer"
      >
        <AddButton />
        <span className="font-bold">{t('add_new_payment')}</span>
      </div>

      {isLoading ? (
        <>
          {[1, 2, 3, 4].map((_) => (
            <Skeleton key={_} variant="rectangular" width="100%" height={40} />
          ))}
        </>
      ) : !data?.data?.length ? (
        <div className="flex items-center justify-center font-bold text-[#535353] text-sm p-5 border border-[#C9C9C9] rounded-xl">
          {t('no_payments')}
        </div>
      ) : (
        data.data?.map((payment) => (
          <div key={payment.paymentId}>
            <Accordion sx={{ boxShadow: '0px 0px 1px 0px #000000;' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  {payment.contactName} - {payment.paymentDate} -
                  {`${payment.currency} ${payment.amountDc}`}
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <div className="flex flex-col p-[10px] sm:p-[30px] gap-7">
                  <div className="flex justify-between">
                    <div className="rounded-lg px-3 py-2 bg-[#f0f3ff66] border border-[#2B5DDC] text-[#2B5DDC]">
                      {`${payment.currency} ${payment.amountDc}`}
                    </div>
                    <div className="flex justify-end gap-4">
                      <EditButton
                        onClick={() =>
                          setOpenDialog({
                            data: _.merge(payment, transactionDetails),
                            dialogType: 'editPayment',
                          })
                        }
                      />
                      <DeleteButton
                        onClick={() =>
                          setOpenDialog({ data: payment.paymentId, dialogType: 'delete' })
                        }
                      />
                    </div>
                  </div>

                  <div className="flex flex-col justify-between gap-4">
                    <div className="flex items-center justify-between">
                      <div className="text-[60px]">{payment.contactName}</div>
                    </div>

                    <div className="flex justify-between">
                      <div className="flex flex-col gap-4">
                        <div className="flex gap-2 text-sm">
                          <img src={dateIcon} />
                          <div className="text-[#8E8E8E]">{t('payment_date')}:</div>
                          <div>{payment.paymentDate}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-[1px] bg-[#C9C9C9]"></div>

                  <div className="flex items-center gap-5">
                    {[
                      { name: 'payment_method', value: payment.paymentMethod },
                      { name: 'payment_reference', value: payment.referenceNumber },
                      { name: 'payment_currency', value: payment.currency },
                    ].map((_) => (
                      <div className="flex flex-col w-fit gap-1">
                        <div className="text-[10px] text-[#8E8E8E]">{t(_.name)}</div>
                        <div className="flex justify-center items-center text-xs border border-[#C9C9C9] rounded-lg px-6 py-3">
                          {_.value}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <PaymentAttachments apiFunctions={apiFunctions} paymentId={payment.paymentId} />
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      )}

      <PaymentDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />

      <DeleteDialog
        openDialog={openDialog}
        name="payment"
        onClose={() => setOpenDialog(null)}
        onDelete={handlePaymentDelete}
        isLoading={deletePaymentPending}
      />
    </div>
  );
};

export default TransactionPayments;
