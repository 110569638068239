import {ErrorMessage, Field, useFormikContext} from "formik";
import {CircularProgress} from "@mui/material";
import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {useCustomQuery} from "queries";
import styled from "styled-components";
import {useDebounce} from "hooks/useDebounce";

export const BillingInfo = ({ apiFunctions }) => {

  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');
  const [valueChosen, setValueChosen] = React.useState({ value: '', isChosen: false });
  const debounceSearch = useDebounce(searchValue, 750);

  const { data: AutoCompleteData, isLoading: searchLoading } = useCustomQuery(
    apiFunctions.getAddress.func,
    apiFunctions.getAddress.key,
    debounceSearch,
    {
      enabled: !!searchValue && !valueChosen.isChosen,
    },
  );

  return (
    <div className="sm:w-8/12 w-full flex flex-col">
      <p className="font-semibold text-center text-3xl">{t('billing_information')}</p>

      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col w-full">
          <Field
            name="companyName"
            placeholder={t('company_name')}
            className={`focus:outline-none pl-4 border border-solid ${
              touched.companyName && errors.companyName
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="companyName"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
        <div className="flex flex-col w-full">
          <Field
            name="vatNumber"
            placeholder={t('vat_number')}
            className={`focus:outline-none pl-4 border border-solid ${
              touched.vatNumber && errors.vatNumber
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="vatNumber"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
      </div>

      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col gap-2 w-full">
          {/*<div className="text-sm">*/}
          {/*  {t('location')}{' '}*/}
          {/*  {errors['placeId'] && <span className="text-[#EA3323]">*</span>}*/}
          {/*</div>*/}

          <Field
            className={`focus:outline-none pl-4 border border-solid ${
              touched.placeId && errors.placeId
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
            name="description"
            placeholder={t('location')}
            value={values.description}
            onChange={(e) => {
              setFieldValue('description', e.target.value);
              setSearchValue(e.target.value);
              setValueChosen({ value: '', isChosen: false });
            }}
          />

          <ErrorMessage component={CustomErrorMessage} name="placeId" />

          {searchValue && !valueChosen.isChosen && (
            <div className="bg-white flex flex-col gap-2 max-h-[200px] shadow-lg overflow-y-auto rounded-lg p-1 w-full z-20">
              {searchLoading ? (
                <div className="flex justify-center items-center p-2">
                  <CircularProgress
                    color="primary"
                    sx={{ color: '#EA3323' }}
                    size={25}
                  />
                </div>
              ) : (
                AutoCompleteData?.data?.map((searchItem) => (
                  <div
                    className="flex flex-col gap-1 justify-center rounded-lg py-3 px-4 hover:bg-[#EA3323] hover:text-white hover:cursor-pointer"
                    onClick={() => {
                      setFieldValue('placeId', searchItem.id);
                      setFieldValue('description', searchItem.name);
                      setFieldValue('countryCode', searchItem.countryCode);
                      if(searchItem.zipCode !== null) {
                        setFieldValue('zipCode', searchItem.zipCode);
                      }
                      setSearchValue(searchItem.name);
                      setValueChosen({ value: searchItem.name, isChosen: true });
                    }}
                  >
                    <div className="text-sm font-semibold">{searchItem.name}</div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col w-full">
          <Field
            name="building"
            placeholder={t('building')}
            className={`focus:outline-none pl-4 border border-solid ${
              touched.building && errors.building
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="building"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
      </div>

      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col w-full">
          <Field
            name="floor"
            placeholder={t('floor')}
            className={`focus:outline-none pl-4 border border-solid ${
              touched.floor && errors.floor
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="floor"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
        <div className="flex flex-col w-full">
          <Field
            type="apartmentNumber"
            name="apartmentNumber"
            placeholder={t('apartment_number')}
            className={`focus:outline-none pl-4 border border-solid ${
              touched.apartmentNumber && errors.apartmentNumber
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="apartmentNumber"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
      </div>
      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col w-full">
          <Field
            name="zipCode"
            placeholder={t('zip_code')}
            className={`focus:outline-none pl-4 border border-solid ${
              touched.zipCode && errors.zipCode
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="zipCode"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
      </div>
    </div>
  );
}

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;