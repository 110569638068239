import {
  getClosings,
  getClosing,
  addClosing,
  updateClosing,
  deleteClosing,
  getClosingInsights,
  getClosingDropdownData,
  getClosingAttachment,
  updateClosingAttachment,
  deleteClosingAttachment,
  addClosingAttachment,
} from 'apis/closings/closings';

import {
  storeReminders
} from 'apis/todos/todos'

export const closingsApiFunctions = {
  getAll: { func: getClosings, key: 'closings' },
  get: { func: getClosing, key: 'closingDetails' },
  add: { func: addClosing, key: 'closings' },
  update: { func: updateClosing, key: 'closingDetails' },
  delete: { func: deleteClosing, key: 'closings' },
  getDropdowns: { func: getClosingDropdownData, key: 'closingDropdown' },
  getClosingInsights: { func: getClosingInsights, key: 'closingsInsights' },
  getAttachments: { func: getClosingAttachment, key: 'closingAttachments' },
  addAttachment: { func: addClosingAttachment, key: 'closingAttachments' },
  editAttachment: { func: updateClosingAttachment, key: 'closingAttachments' },
  deleteAttachment: { func: deleteClosingAttachment, key: 'closingAttachments' },
  storeReminders: { func: storeReminders, key: 'reminders' },
};
