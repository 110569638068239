import * as yup from 'yup';

export const invoicesSchema = yup.object({
  invoiceType: yup.string().required('Invoice Type field is required'),
  invoicedTo: yup.string().required('Invoiced To field is required'),
  invoicedToParty: yup.string().required('Invoiced To Party field is required'),
  relatedTo: yup.string().required('Related To field is required'),
  relatedRecord: yup.string().required('Related Record field is required'),
  dueDate: yup.date().typeError('Due Date field is required'),
  coveredDateFrom: yup.date().typeError('Covered Date From field is required'),
  coveredDateTo: yup.date().typeError('Covered Date To field is required'),
  netInvoicedAmount: yup
    .string()
    .typeError('Input must be a number')
    .required('Net Invoiced Amount field is required'),
});
