import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent } from '@mui/material';
import {useTranslation} from "react-i18next";

export const ConfirmDialog = (props) => {
  const { t } = useTranslation();
  return (
    <StyledDialog
      open={props.isConfirmDialogOpen}
      onClose={props.onCancel}
      width="328px"
      height="170px"
    >
      <div className="p-2">
        <DialogContent>
          <div className="font-bold">{t('exit_confirmation')}</div>
        </DialogContent>

        <div className="flex justify-between">
          <div
            className="cursor-pointer w-36 text-center p-3 bg-[#EA3323] rounded-xl text-white font-semibold"
            onClick={props.onConfirm}
          >
            {t('yes')}
          </div>

          <div
            className="cursor-pointer w-36 text-center p-3 bg-white rounded-xl border border-[#56678942] text-[#15192080] font-semibold"
            onClick={props.onCancel}
          >
            {t('cancel')}
          </div>
        </div>
      </div>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 16px;
    padding: 8px 8px 20px;
    width: 328px;
    height: 170px;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
  }
`;
