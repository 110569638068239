import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';
import { mapToAddContactReqBody, mapToEditContactReqBody } from './mapper';
import userEvent from '@testing-library/user-event';

/*
 * |--------------------------------------------------------------------------
 * | Contacts API
 * | getAllContacts - getContact - addContact - updateContact - deleteContact
 * |--------------------------------------------------------------------------
 */

export const getAllContacts = () => {
  return axios.get(`/api/v1/contact-app/contacts/${getUserGroupId()}`);
};

export const getContact = (contactId) =>
  axios.get(`/api/v1/contact-app/contact/show/${contactId}/${getUserGroupId()}`);

export const addContact = (payload) =>
  axios.post(`/api/v1/contact-app/contact/store`, {
    ...mapToAddContactReqBody(payload),
    user_group_id: getUserGroupId(),
  });

export const updateContact = (payload) =>
  axios.post(`/api/v1/contact-app/contact/update`, {
    ...mapToEditContactReqBody(payload),
    user_group_id: getUserGroupId(),
  });

export const deleteContact = (contactId) => {
  /**
   * @request_body
   * * contact_ids: array that includes the contacts ids
   */
  return axios.post(`/api/v1/contact-app/contact/destroy`, {
    contact_ids: contactId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |-----------------------------------------------------------------------------
 * | Contact Attachment API
 * | getContactAttachments - updateContactAttachement - deleteContactAttachement
 * |-----------------------------------------------------------------------------
 */

export const getContactAttachments = (contactId, page) =>
  axios.get(
    `/api/v1/contact-app/contact/attachments/${contactId}/${getUserGroupId()}?page=${page}`,
  );

export const addContactAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachment).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });
  formData.append('contact_id', payload.contact_id);
  formData.append('user_group_id', getUserGroupId());
  return axios.post(`/api/v2/contact-app/contacts/attachments/store`, formData);
};

export const updateContactAttachement = (payload) => {
  return axios.post(`/api/v1/contact-app/contact/attachment/update`, {
    ...payload,
    attachment_type_id: '1',
    user_group_id: getUserGroupId(),
  });
};

export const deleteContactAttachement = (contactAttachementId) => {
  return axios.post(`/api/v1/contact-app/contact/attachment/destroy`, {
    user_group_id: getUserGroupId(),
    contact_attachment_ids: [contactAttachementId],
  });
};

export const getContactRelated = (contactId) =>
  axios.get(`/api/v1/contact-app/contact/related/${contactId}/${getUserGroupId()}`);

export const getContactDropdownValues = () =>
  axios.get(`/api/v1/contact-app/contact/dropdown-values/${getUserGroupId()}`);

/*
 * |-----------------------------------------------------------------------------
 * | Contact Export APIs
 * |
 * |-----------------------------------------------------------------------------
 */

export const exportContacts = (payload) => {
  return axios.post(`/api/v2/contact-app/contacts/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    contact_ids: payload.ids,
  });
};

export const contactTransactions = (contactId) => {
  return axios.get(`/api/v2/contact-app/contacts/transactions/${contactId}/${getUserGroupId()}`);
};

export const getCrmAccounts = () =>
  axios.get(`/api/v2/contact-app/accounting/accounts/crm/${getUserGroupId()}`);
