import * as yup from 'yup';

export const registerSchema = [
  yup.object().shape({
  firstName: yup.string().required('First name field is required'),
  lastName: yup.string().required('Last name field is required'),
  email: yup.string().email('Must be a valid email address').required('Email field is required'),
  mobile: yup.string().nullable().required('Mobile field is required'),
  password: yup
    .string()
    .required('Password field is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .required('Confirm Password field is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  yup.object().shape({
    companyName: yup.string().required('Company name field is required'),
    vatNumber: yup.string().required('Vat number field is required'),
    placeId: yup.string().required('Location field is required'),
    building: yup.string().required('Building field is required'),
    floor: yup.string().required('Floor field is required'),
    apartmentNumber: yup.string().required('Apartment number field is required'),
    zipCode: yup.string().required('Zip code field is required'),
  }),
  yup.object().shape({
    token: yup.string().required('Payment field is required'),
  }),
];
