import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';

import {isMobile, TabName} from 'utils/utils';
import { repairsTableColumns } from 'utils/columns';

import {
  AddButton,
  CardButton,
  DeleteButton,
  DeleteDialog,
  EditButton,
  EmptyList,
  ExportButton,
  StyledDataGrid,
  ListButton,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { RepairDialog } from './RepairDialog';
import { ExportRepairsDialog } from './exportRepairsDialog';

import { CircularProgress, Checkbox, Alert } from '@mui/material';
import searchIcon from 'assets/search.svg';
import locationIcon from 'assets/LocationIcon.svg';

const Repairs = ({ apiFunctions }) => {
  TabName('Repairs');

  const [globalError, setGlobalError] = React.useState(null)
  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedRepairs, setSelectedRepairs] = React.useState([]);
  const [allRepairsSelected, setAllRepairsSelected] = React.useState(false);
  const [repairsList, setRepairsList] = React.useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getAll.func,
    apiFunctions.getAll.key,
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRepairs(data.data.map((item) => item.repairId));
      setAllRepairsSelected(true);
    } else {
      setSelectedRepairs([]);
      setAllRepairsSelected(false);
    }
  };

  const handleRepairDelete = async () => {
    const response = await deleteMutation(selectedRepairs);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setRepairsList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.property.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.title.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.propertyUsage.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.country.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.city.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.category.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.subCategory.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setRepairsList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setRepairsList(data?.data);
    }
  }, [data?.data]);

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if(globalErrorMsg){
      setGlobalError({statusCode: 500, message: globalErrorMsg});
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div className="bg-white rounded-2xl mt-11 pt-2">
      {error && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          There was an error while getting the data
        </Alert>
      )}

      <Notifier data={mutationResponse} />
      <Notifier data={globalError} />

      <div className="flex flex-col lg:flex-row justify-between">
        <div className="hidden lg:flex items-center pl-5">
          <div className="flex h-[40px] items-center justify-center border border-[#C9C9C9] rounded-lg">
            <Checkbox
              icon={
                <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
              }
              checkedIcon={
                <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
              }
              checked={allRepairsSelected}
              onChange={handleSelectAll}
            />
            <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row justify-between gap-4 p-3.5">
          <div className="flex rounded-lg bg-[#F6F4F4]">
            <div className="flex items-center">
              <img className="pl-2" src={searchIcon} />
            </div>
            <input
              className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
              placeholder={t('search_by_repair')}
              value={searchValue}
              onChange={handleFilterChange}
            />
          </div>

          <div className="flex gap-4">
            <AddButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'addRepair' })}
              disabled={isLoading}
            />

            <EditButton
              disabled={selectedRepairs.length > 1 || !selectedRepairs.length}
              onClick={() =>
                navigate(`/repair-overview/${selectedRepairs[0]}/details`, {
                  state: { directEdit: true },
                })
              }
            />

            <DeleteButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
              disabled={!selectedRepairs.length}
            />

            <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
          </div>

          <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
            <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
            <ListButton isActivated={activeTab === 'table'} onClick={() => setActiveTab('table')} />
          </div>
        </div>
      </div>

      {!isLoading && !data?.data?.length ? (
        <EmptyList listName="repairs" />
      ) : activeTab === 'table' ? (
        <StyledDataGrid
          checkboxSelection
          rows={repairsList || []}
          columns={repairsTableColumns(t)}
          getRowId={(row) => row.repairId}
          onSelectionModelChange={(newSelectedRepair) => setSelectedRepairs(newSelectedRepair)}
          selectionModel={selectedRepairs}
          rowsPerPageOptions={[]}
          loading={isLoading}
          pageSize={25}
          rowHeight={70}
          onRowClick={(params) => navigate(`/repair-overview/${params.row.repairId}/details`)}
          components={{
            LoadingOverlay: () => (
              <div className="mt-8 flex justify-center">
                <CircularProgress color="error" />
              </div>
            ),
          }}
        />
      ) : isLoading ? (
        <div className="flex flex-col gap-4 p-3">
          {[1, 2, 3, 4].map((_) => (
            <SkeletonCardLoader />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 overflow-y-auto p-5">
          {data?.data.map((repair) => (
            <div
              className="flex h-[160px] border border-[#ECECEC] rounded-xl hover:cursor-pointer hover:shadow-xl hover:scale-[1.015]"
              onClick={() => navigate(`/repair-overview/${repair.repairId}/details`)}
            >
              <div className="w-full flex flex-col justify-between p-2">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div className="text-[12px] text-[#8E8E8E] font-light">
                      {repair.propertyUsage?.toUpperCase()}
                    </div>
                    <div className="font-bold text-lg">{repair.property}</div>
                  </div>

                  <div
                    className={`text-[10px] rounded p-2 bg-[${repair.statusBackgroundColor}] text-[${repair.statusColor}]`}
                  >
                    {repair.status}
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="text-[10px] text-[#8E8E8E]">{repair.description}</div>
                  <div className="text-xs font-light">
                    {repair.category + ' / ' + repair.subCategory}
                  </div>
                </div>

                <div className="flex gap-2">
                  <div className="bg-[#ECECEC] rounded-full p-1.5">
                    <img className="w-4" src={locationIcon} />
                  </div>
                  <div>
                    <div className="text-[10px] font-medium">{repair.city}</div>
                    <p className="text-[10px] font-light text-[#8E8E8E]">{repair.country}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <RepairDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <DeleteDialog
        openDialog={openDialog}
        name="repair"
        onClose={() => setOpenDialog(null)}
        onDelete={handleRepairDelete}
        isLoading={deleteLoading}
      />

      <ExportRepairsDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        selectedRepairs={selectedRepairs}
      />
    </div>
  );
};

export default Repairs;
