import { InputAdornment, TextField } from '@mui/material';

export const StyledInput = (props) => {
  return (
    <TextField
      sx={{
        '.MuiOutlinedInput-root': {
          borderRadius: '16px',
          boxShadow: props.error && '0px 0px 0px 3px rgba(234,51,35,0.2)',
          '&:hover fieldset': {
            borderColor: !props.error && 'rgba(0, 0, 0, 0.23)',
          },
          '&.Mui-focused fieldset': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            outline: 'none',
          },
        },
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">{props.prefix}</InputAdornment>,
      }}
      type={props.type}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      error={props.error}
      disabled={props.disabled}
      variant="outlined"
      size="medium"
      fullWidth
    />
  );
};
