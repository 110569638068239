import React from 'react';
import { useCustomQuery } from 'queries';
import { TabName } from 'utils/utils';

import {
  MonthlyPerformance,
  Requests,
  ListedProperties,
  YearCashFlow,
  RentYearlyBooking,
  Map,
} from './dashboardComponents';
import {Notifier} from "components";

const Dashboard = ({ apiFunctions }) => {
  TabName('Dashboard');

  const [globalError, setGlobalError] = React.useState(null)

  const { data: dashboardDropdownData } = useCustomQuery(
    apiFunctions.getDashboardDropdown.func,
    apiFunctions.getDashboardDropdown.key,
    null,
  );

  const { selectedYear, months } = dashboardDropdownData?.data || {};

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if(globalErrorMsg){
      setGlobalError({statusCode: 500, message: globalErrorMsg});
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div className="mt-11">
      <Notifier data={globalError}/>

      <div className="flex flex-col lg:flex-row justify-center sm:items-center gap-[20px] h-3/5">
        <MonthlyPerformance months={months} apiFunctions={apiFunctions} />
        <Requests />
      </div>

      <div className="flex flex-col lg:flex-row mt-[40px]">
        <ListedProperties />
        <Map />
      </div>

      <RentYearlyBooking selectedYear={selectedYear} apiFunctions={apiFunctions} />
      <YearCashFlow selectedYear={selectedYear} />
    </div>
  );
};

export default Dashboard;
