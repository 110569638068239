import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { settingsNavItems } from 'utils/constants';
import {TabName} from "utils/utils";
import {Notifier} from "components";

const Settings = () => {
  TabName('Settings');

  const [globalError, setGlobalError] = React.useState(null)
  const { t } = useTranslation();

    React.useEffect(() => {
        let globalErrorMsg = localStorage.getItem('error-message');
        if(globalErrorMsg){
            setGlobalError({statusCode: 500, message: globalErrorMsg});
            localStorage.removeItem('error-message');
        }
    }, []);

  return (
    <div className="mt-6 rounded-xl bg-white">
        <Notifier data={globalError} />

        <div className="flex p-3 gap-2">
        {settingsNavItems.map((item) => (
          <NavLink
            to={`/settings/${item.route}`}
            key={item.name}
            activeClassName="active"
            className={({ isActive }) =>
              isActive
                ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                : 'border border-[#C9C9C9] p-2 rounded-lg'
            }
          >
            {t(item.name)}
          </NavLink>
        ))}
      </div>

      <Outlet />
    </div>
  );
};

export default Settings;
