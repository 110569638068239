import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { numberFormatter } from 'utils/utils';

import Skeleton from '@mui/material/Skeleton';
import closedRentsLogo from 'assets/monthlyPerformanceIcons/closed-rents.svg';
import newOffersLogo from 'assets/monthlyPerformanceIcons/new-offers.svg';
import closedSalesLogo from 'assets/monthlyPerformanceIcons/closed-sales.svg';
import investmentLogo from 'assets/monthlyPerformanceIcons/investment.svg';
import collectedLogo from 'assets/monthlyPerformanceIcons/collected.svg';
import upcomingLogo from 'assets/monthlyPerformanceIcons/upcoming.svg';
import leftArrowIcon from 'assets/leftArrow.svg';

export const MonthlyPerformance = ({ months, apiFunctions }) => {
  const [selectedMonth, setSelectedMonth] = React.useState(null);
  const selectedCurrency = localStorage.getItem('selectedCurrencySymbol');

  const { t } = useTranslation();

  const { data, isLoading } = useCustomQuery(
    apiFunctions.getMonthlyPerformance.func,
    apiFunctions.getMonthlyPerformance.key,
    selectedMonth,
    { enabled: !!selectedMonth },
  );

  React.useEffect(() => {
    // Set the first month as selected if no month is selected yet
    if (months && months.length > 0 && !selectedMonth) {
      const selected = months.find((month) => month.isSelected) || months[0];
      setSelectedMonth(selected);
    }
  }, [months, selectedMonth]);


  const performancesStyles = [
    { title: t('assets_for_rent'), logo: closedSalesLogo, symbol: null, percentage: '' },
    { title: t('occupancy_rate'), logo: closedRentsLogo, symbol: null, percentage: '%' },
    { title: t('monthly_rent'), logo: collectedLogo, symbol: selectedCurrency, percentage: '' },
    { title: t('annual_rent'), logo: investmentLogo, symbol: selectedCurrency, percentage: '' },
    { title: t('monthly_costs'), logo: upcomingLogo, symbol: selectedCurrency, percentage: '' },
    { title: t('new_offers'), logo: newOffersLogo, symbol: null, percentage: '' },
  ];

  const changeMonth = (direction) => {
    let index = months
      .map((e) => {
        return e.id;
      })
      .indexOf(selectedMonth.id);

    if (direction === 'increase') {
      // * if selected month is the last month
      if (selectedMonth.id === months[months.length - 1].id) return;
      return setSelectedMonth(months[index + 1]);
    } else {
      // * if selected month is the first month
      if (selectedMonth.id === months[0].id) return;
      return setSelectedMonth(months[index - 1]);
    }
  };

  return (
    <div className="w-full lg:w-7/12 flex flex-col justify-between">
      <div className="flex justify-between items-center pb-3">
        <div className="font-bold text-left sm:text-xl text-lg">{t('monthly_performance')}</div>
        {selectedMonth && (
          <div className="flex items-center justify-center gap-2 sm:text-lg text-md">
            <span>{selectedMonth?.name}</span>
            <div
              onClick={() => changeMonth('decrease')}
              className={`${selectedMonth.id === months[0].id && 'cursor-default opacity-50'
                } cursor-pointer flex items-center justify-center bg-white rounded-lg w-10 h-10`}
            >
              <img alt="" src={leftArrowIcon} />
            </div>

            <div
              onClick={() => changeMonth('increase')}
              className={`${selectedMonth.id === months[months.length - 1].id && 'cursor-default opacity-50'
                } cursor-pointer flex items-center justify-center bg-white rounded-lg w-10 h-10`}
            >
              <img alt="" className="rotate-[179deg]" src={leftArrowIcon} />
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-3 sm:gap-4 gap-2 pt-2 place-self-start w-full">
        {!selectedMonth || isLoading ? (
          <>
            {[1, 2, 3, 4, 5, 6].map((_) => (
              <Skeleton
                key={_}
                variant="rectangular"
                width="100%"
                height={157}
                sx={{ borderRadius: '16px' }}
              />
            ))}
          </>
        ) : !data?.data?.length ? (
          <div className="col-span-2 lg:col-span-3 text-center">No performance data available for this month.</div>
        ) : (
          data.data.map(
            (performance, index) =>
              index < 6 && (
                <div
                  key={index}
                  className="flex flex-col items-start gap-2 lg:w-full md:w-38 sm:w-full p-6 bg-white rounded-2xl border border-gray-200 border-r-[#0000001a] border-b-[#0000001a] border-l-[#0000001a] shadow-md"
                >
                  <img alt="" src={performancesStyles[index].logo} />

                  <p className="lg:text-2xl md:text-xl sm:text-lg font-bold">
                    {performancesStyles[index].symbol} {numberFormatter(performance.value)} {performancesStyles[index].percentage}
                  </p>

                  <p className="text-[#767676] lg:text-lg md:text-sm sm:text-xs">
                    {t(performancesStyles[index].title)}
                  </p>
                </div>
              ),
          )
        )}
      </div>
    </div>
  );
};
