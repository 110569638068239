import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import emailIcon from 'assets/emailIcon.svg';
import phoneIcon from 'assets/phoneIcon.svg';

export const ContactCard = ({ contact, selectedContacts, onSelect, onUnselect }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      key={contact.contactId}
      className={`cursor-pointer border border-[1px] border-[#C9C9C9] h-[226px] rounded-2xl p-4 ease-in duration-300 hover:shadow-xl hover:scale-[1.015] ${
        selectedContacts.includes(contact.contactId) && 'bg-[#F5F5F5]'
      }`}
    >
      <div className="flex justify-between pb-2.5 border-b-[1px]">
        <div
          className="flex items-center gap-3 w-[90%]"
          onClick={(e) => {
            if (e.target.type === 'checkbox') e.stopPropagation();
            else
              navigate(`/contact-overview/${contact.contactId}/details`, {
                state: { recordType: contact.recordType },
              });
          }}
        >
          <img className="w-[60px] h-[60px] rounded-full object-cover" src={contact.avatar} />
          <div>
            <div className="font-bold text-lg">{contact.name}</div>
            <div className="font-light text-[#EA3323] text-[16px]">{contact.recordType}</div>
          </div>
        </div>

        <div>
          <Checkbox
            icon={<div className="w-4 h-4 bg-[#F6F4F4] border border-[#C9C9C9] rounded"></div>}
            checkedIcon={
              <div className="w-4 h-4 bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border border-[#C9C9C9] rounded"></div>
            }
            checked={!!selectedContacts.includes(contact.contactId)}
            onChange={(e) => {
              if (e.target.checked) onSelect();
              else onUnselect();
            }}
          />
        </div>
      </div>

      <div>
        <div className="flex gap-3 pt-3">
          <img className="bg-[#F6F6F6] rounded-full p-3" src={emailIcon} />

          <div className="flex flex-col gap-2">
            <span className="text-sm font-semibold">{t('email')}</span>
            <span className="font-light text-[#8E8E8E]">{contact.email}</span>
          </div>
        </div>

        <div className="flex gap-3 pt-3">
          <img className="bg-[#F6F6F6] rounded-full p-3" src={phoneIcon} />
          <div className="flex flex-col gap-2">
            <span className="text-sm font-semibold">{t('phone')}</span>
            <span className="font-light text-[#8E8E8E]">{contact.phone || 'N/A'}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
