import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { heathCheck } from 'apis/authentication/authentication';

import { Layout } from 'components';
import { CircularProgress } from '@mui/material';

export const PrivateRoutes = () => {
  const { data: healthCheckData, isLoading } = useQuery('healthCheck', heathCheck);

  return isLoading ? (
    <div className="flex items-center justify-center h-screen m-auto">
      <CircularProgress color="error" />
    </div>
  ) : (
    <Layout>
      {healthCheckData?.data?.isAuthenticated ? <Outlet /> : <Navigate to="/login" />}
    </Layout>
  );
};
