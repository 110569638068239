import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';

import datePickerIcon from 'assets/datepicker.svg';

export const DatePicker = (props) => {
  return (
    <DesktopDatePicker
      PaperProps={{
        sx: {
          '& .MuiPickersDay-root': {
            '&.Mui-selected': {
              backgroundColor: '#EA3323',
            },
          },
        },
      }}
      label=""
      inputFormat="DD/MM/YYYY"
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      error={props.error}
      renderInput={(params) => (
        <TextField
          sx={{
            width: '100%',
            '& .MuiInputBase-root': {
              borderRadius: '16px',
              border: `${props.error && '1px solid #EA3323'}`,
              boxShadow: `${props.error && '0px 0px 0px 3px rgba(234,51,35,0.2)'}`,
            },
          }}
          {...params}
        />
      )}
    />
  );
};
