import React from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { Alert, Skeleton } from '@mui/material';
import {useTranslation} from "react-i18next";

import { transactionOverviewNavitems } from 'utils/constants';
import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';

import { EditButton, DeleteButton, DeleteDialog, Notifier } from 'components';

import greaterIcon from 'assets/greaterIcon.svg';
import {UpdateTransactionDialog} from "../transactions/updateTransactionDialog";

const TransactionOverview = ({ apiFunctions }) => {
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data, isLoading, error } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    transactionId,
  );

  const { data: transactionInvoice, isLoading: transactionInvoiceLoading } = useCustomQuery(
    apiFunctions.showTransactionInvoice.func,
    apiFunctions.showTransactionInvoice.key,
    transactionId,
  );

  const {
    mutateAsync: mutate,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  // const {
  //   mutateAsync: approveInvoice,
  //   isLoading: approvalLoading,
  //   data: approvalMutationResponse,
  // } = useCustomMutation(apiFunctions.approve.func, apiFunctions.approve.key);
  //
  // const {
  //   mutateAsync: disApproveInvoice,
  //   isLoading: disApprovalLoading,
  //   data: disapprovalMutationResponse,
  // } = useCustomMutation(apiFunctions.disapprove.func, apiFunctions.disapprove.key);

  const {
    mutateAsync: sendInvoiceMutation,
    isLoading: sendInvoiceLoading,
    data: sendInvoiceMutationResponse,
  } = useCustomMutation(apiFunctions.sendInvoice.func, apiFunctions.sendInvoice.key);

  const { contactName, referenceNumber } =
    data?.data || {};

  // const handleInvoiceApprove = async () => {
  //   const response = await approveInvoice([invoiceId]);
  // };
  //
  // const handleInvoiceDisApprove = async () => {
  //   const response = await disApproveInvoice([invoiceId]);
  // };

  const handleInvoiceDelete = async () => {
    const response = await mutate([transactionId]);

    if (response.statusCode === 200) {
      setOpenDialog(null);
      navigate('/transactions');
    }
  };
  // const handleSendInvoice = async () => {
  //   const response = await sendInvoiceMutation([invoiceId]);
  // };

  const invoiceContext = {
    transactionDetails: data?.data,
    isLoading: isLoading,
    transactionId: transactionId,
    transactionInvoiceDetails: transactionInvoice?.data,
  };

  return (
    <div className="mt-5">
      {error && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          There was an error while getting the data
        </Alert>
      )}

      <Notifier data={mutationResponse} />
      {/*<Notifier data={approvalMutationResponse} />*/}
      {/*<Notifier data={disapprovalMutationResponse} />*/}
      <Notifier data={sendInvoiceMutationResponse} />

      <div className="flex gap-2 text-[#EA3323] font-medium text-md">
        <NavLink
          className="hover:cursor-pointer"
          to={`/transactions`}
          key="invoiceTitle"
          activeClassName="active"
        >
          {t('transactions')}
        </NavLink>
        <img src={greaterIcon} />
        <div>{referenceNumber}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <div className="text-[#8E8E8E]">{t('reference_number')}:</div>
              <div>{referenceNumber}</div>
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <div className="text-[#8E8E8E]">{t('related_to')}:</div>
              <div>{contactName}</div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-5">
        <div className="flex flex-wrap gap-3 items-center justify-between">
          <div className="flex gap-3 ">
            {transactionOverviewNavitems.map((item) => (
              <NavLink
                to={`/transaction-overview/${transactionId}/${item.route}`}
                key={item.name}
                activeClassName="active"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                    : 'border border-[#C9C9C9] p-2 rounded-lg'
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          {window.location.pathname.includes('/details') && (
            <div className="flex gap-3">
              {/*{data?.data.approvalStatus === 'Pending' && (*/}
              {/*  <div className="flex gap-3">*/}
              {/*    <button*/}
              {/*      className="flex justify-center items-center px-2 h-10 bg-[#F6F4F4] rounded-lg"*/}
              {/*      onClick={handleInvoiceApprove}*/}
              {/*    >*/}
              {/*      {approvalLoading ? (*/}
              {/*        <div className="flex items-center justify-center">*/}
              {/*          <CircularProgress color="inherit" size={20} />*/}
              {/*        </div>*/}
              {/*      ) : (*/}
              {/*        'Approve'*/}
              {/*      )}*/}
              {/*    </button>*/}

              {/*    <button*/}
              {/*      className="flex justify-center items-center px-2 h-10 bg-[#EA3323] text-white rounded-lg"*/}
              {/*      onClick={handleInvoiceDisApprove}*/}
              {/*    >*/}
              {/*      {disApprovalLoading ? (*/}
              {/*        <div className="flex items-center justify-center">*/}
              {/*          <CircularProgress sx={{ color: 'WHITE' }} size={20} />*/}
              {/*        </div>*/}
              {/*      ) : (*/}
              {/*        t('disapprove')*/}
              {/*      )}*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*<button*/}
              {/*  className="flex justify-center text-[#EA3323] font-semibold items-center gap-1 w-fit px-4 h-10 bg-[#FFF2F0] rounded-lg"*/}
              {/*  onClick={handleSendInvoice}*/}
              {/*  disabled={sendInvoiceLoading}*/}
              {/*>*/}
              {/*  {sendInvoiceLoading ? <div className="flex items-center justify-center"><CircularProgress size={20} sx={{ color : '#EA3323'}}/></div> : 'Send'}*/}
              {/*  {!sendInvoiceLoading && <img className="w-3" src={sendIcon} />}*/}
              {/*</button>*/}

              <EditButton
                onClick={() => setOpenDialog({ data: [transactionId], dialogType: 'editTransaction' })}
                disabled={isLoading}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>

      <UpdateTransactionDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />

      <DeleteDialog
        openDialog={openDialog}
        name="transaction"
        onClose={() => setOpenDialog(null)}
        onDelete={handleInvoiceDelete}
        isLoading={deleteLoading}
      />

      <Outlet context={invoiceContext} />
    </div>
  );
};

export default TransactionOverview;
