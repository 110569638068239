import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, useFormikContext } from 'formik';
import { LocusContext } from 'hooks/useLocusContext';
import { StyledSelect } from 'components';
import {useTranslation} from "react-i18next";

export const PropertyType = ({owBuilding, setOwnBuilding}) => {
  const [ ownUnits, setOwnUnits ] = React.useState(0);
  const { t } = useTranslation();
  const { dialogType, dropdownValues } = React.useContext(LocusContext);
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  return (
    <div className="flex flex-col gap-4 w-full sm:w-9/12 p-1">
      {dialogType === 'editProperty' ? (
        <span className="text-3xl font-bold">{t('edit_property')}</span>
      ) : (
        <span className="text-2xl">{t('property_dialog_statement1')}</span>
      )}

      {dialogType !== 'editProperty' && (
        <div className="flex gap-5">
          {[0, 1].map((element) => (
            <PropertyTypeButton
              key={element}
              selected={ownUnits === element}
              onClick={() => setOwnUnits(element)}
            >
              {element ? t('yes') : t('no')}
            </PropertyTypeButton>
          ))}
        </div>
      )}

      {(dialogType !== 'editProperty' && ownUnits ) ? (
        <>
          <span className="text-2xl">{t('property_dialog_statement2')}</span>

          <div className="flex gap-5">
            {[0, 1].map((element) => (
              <PropertyTypeButton
                key={element}
                selected={owBuilding === element}
                onClick={() => setOwnBuilding(element)}
              >
                {element ? t('yes') : t('no')}
              </PropertyTypeButton>
            ))}
          </div>
        </>
      ) : ''}

      {
        (owBuilding || dialogType === 'editProperty') ? (
        <div className="mt-3">
          <div className="mb-2">{t('select_building')}</div>
          <StyledSelect
            name="masterPropertyId"
            placeholder={t('building')}
            value={values.masterPropertyId}
            onChange={handleChange}
            itemsList={dropdownValues.building}
            error={touched.masterPropertyId && errors.masterPropertyId}
            disabled={dialogType === 'editProperty'}
          />
          <ErrorMessage component={ValidationError} name="masterPropertyId" />
        </div>
      ): ''}
    </div>
  );
};

const PropertyTypeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 56px;
  border: ${({ selected }) => (selected ? '1px solid #2B5DDC' : '1px solid #C9C9C9')};
  box-shadow: ${({ selected }) => selected && '0px 0px 0px 3px rgba(43, 93, 220, 0.2)'};
  border-radius: 16px;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
  }
`;

const ValidationError = styled.div`
  color: #ea3323;
`;
