import React from 'react';
import { SideBar, MainHeader, MobileNavigationMenu } from 'components';

export const Layout = ({ children }) => {
  const [mobileMenu, setMobileMenu] = React.useState(false);

  return (
    <div className="flex">
      <div className="hidden lg:block">
        <SideBar />
      </div>

      <div className="grow p-4">
        {mobileMenu && (
          <MobileNavigationMenu
            onMenuToggle={() => setMobileMenu(!mobileMenu)}
            isMenuOpen={mobileMenu}
          />
        )}

        {!mobileMenu && (
          <>
            <MainHeader onMenuToggle={() => setMobileMenu(!mobileMenu)} />
            {children}
          </>
        )}
      </div>
    </div>
  );
};
