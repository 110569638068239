import styled from 'styled-components';
import browseIcon from 'assets/browseIcon.svg';
import { useTranslation } from 'react-i18next';

export const FileUploader = ({ isDragActive, getRootProps, getInputProps }) => {
  const { t } = useTranslation();

  return (
    <FileInputContainer {...getRootProps()}>
      <div className="flex flex-col sm:flex-row gap-2 items-center">
        {!isDragActive && (
          <div
            className={`flex items-center justify-center rounded-full w-[45px] h-[45px] bg-[#2B5DDC]`}
          >
            <img src={browseIcon} />
          </div>
        )}

        <input {...getInputProps()} />
        {isDragActive ? (
          <label>{t('drop_here')} ...</label>
        ) : (
          <label htmlFor="upload-photo">{t('drop_files_here')}</label>
        )}
      </div>

      <button className="w-full sm:w-[20%] h-[44px] bg-[#2B5DDC] text-white rounded-lg">
        {t('browse')}
      </button>
    </FileInputContainer>
  );
};

const FileInputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px;
  top: 50%;
  background: rgba(240, 243, 255, 0.4);
  border: 1px dashed #2b5ddc;
  border-radius: 12px;
  label {
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #8e8e8e;
  }
  input {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 8px;
    label {
      font-size: 14px;
    }
  }
`;
