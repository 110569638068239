import * as yup from 'yup';

export const assetsSchema = yup.object().shape({
  title: yup.string().required('title field is required'),
  category: yup.string().required('category field is required'),
  brand: yup.string().required('brand field is required'),
  model: yup.string().required('model field is required'),
  room: yup.string().required('room field is required'),
  status: yup.string().required('status field is required'),
});
