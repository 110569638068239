import * as yup from 'yup';

export const setNewPasswordSchema = yup.object().shape({
  otp: yup.string().required('OTP field is required'),
  newPassword: yup
    .string()
    .required('Password field is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .required('Confirm Password field is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
