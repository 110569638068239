import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getDashboardMap } from 'apis/dashboard/dashboard';
import { GoogleMap, InfoWindow, Marker, MarkerF, useLoadScript } from '@react-google-maps/api';

import { CircularProgress } from '@mui/material';
import locationIcon from 'assets/LocationIcon.svg';
import homeIcon from 'assets/homeIcon.svg';

export const Map = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery('propertiesMap', getDashboardMap);
  const { t } = useTranslation();
  const [loc, setLoc] = useState({ lat: 50.0, lng: 19.0 });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [longestDistance, setLongestDistance] = useState(0);

  const mapContainerStyle = {
    width: '100%',
    height: '380px',
    borderRadius: '16px',
  };

  const options = {
    zoomControl: true,
    restriction: {
      latLngBounds: {
        north: 85,
        south: -85,
        east: 180,
        west: -180,
      },
    },
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  const libraries = ['places'];
  const mapRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAFfmVePcgng02_8FcRaHZPBIcEYTZvAC4',
    libraries,
  });

  function distance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = degreeToRadian(lat2 - lat1);
    const dLon = degreeToRadian(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreeToRadian(lat1)) *
        Math.cos(degreeToRadian(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function degreeToRadian(deg) {
    return deg * (Math.PI / 180);
  }

  function center(lat1, lon1, lat2, lon2) {
    const centerLat = (lat1 + lat2) / 2;
    const centerLon = (lon1 + lon2) / 2;

    return [centerLat, centerLon];
  }

  function findLongestDistance(coords) {
    if (coords?.length > 1) {
      let longestDistance = 0;
      let coord1, coord2;
      for (let i = 0; i < coords.length; i++) {
        for (let j = i + 1; j < coords.length; j++) {
          const dist = distance(
            coords[i].latitude,
            coords[i].longitude,
            coords[j].latitude,
            coords[j].longitude,
          );
          if (dist > longestDistance) {
            longestDistance = dist;
            coord1 = coords[i];
            coord2 = coords[j];
          }
        }
      }
      const centerCoord = center(
        coord1.latitude,
        coord1.longitude,
        coord2.latitude,
        coord2.longitude,
      );
      setLoc({ lat: centerCoord[0], lng: centerCoord[1] });
      setLongestDistance(longestDistance);
      return { coord1, coord2, centerCoord, longestDistance };
    } else if (coords?.length === 1) {
      setLoc({ lat: coords[0].latitude, lng: coords[0].longitude });
      setLongestDistance(0);
    } else {
      setLoc({ lat: 50.0, lng: 19.0 });
      setLongestDistance(0);
    }
  }

  const handleZoomChange = () => {
    if (longestDistance > 5000) {
      return 3;
    } else if (longestDistance < 2000 && longestDistance > 1000) {
      return 6;
    } else if (longestDistance < 1000) {
      return 7;
    } else if (longestDistance < 500) {
      return 9;
    }
    return 4;
  };

  // run function when map is loaded
  React.useEffect(() => {
    if (!isLoading) {
      findLongestDistance(data?.data);
      if (mapRef.current) {
        mapRef.current.setCenter({ lat: loc.lat, lng: loc.lng });
      }
    }
  }, [data?.data]);

  return (
    <div className="flex flex-col gap-2 pl-2 w-full lg:w-3/5">
      <div className="flex self-start ml-0 sm:ml-2">
        <span className="text-[#8E8E8E] font-bold text-xl mr-0.5">{t('properties')}</span>
      </div>

      {!isLoaded ? (
        <div className="flex bg-white rounded-2xl justify-center items-center h-full">
          <CircularProgress color="primary" sx={{ color: '#ff0000' }} size={25} />
        </div>
      ) : (
        <div className="rounded-2xl">
          <div className="rounded-2xl flex justify-center ml-0 sm:ml-2">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              options={options}
              zoom={handleZoomChange()}
              center={{ lat: loc.lat, lng: loc.lng }}
            >
              {data?.data?.map((marker, index) => (
                <MarkerF
                  key={index}
                  position={{
                    lat: parseFloat(marker.latitude),
                    lng: parseFloat(marker.longitude),
                  }}
                  onClick={() => setSelectedMarker(marker)}
                />
              ))}
              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: parseFloat(selectedMarker.latitude),
                    lng: parseFloat(selectedMarker.longitude),
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div
                    className="flex items-center min-w-[280px] gap-4 hover:cursor-pointer"
                    onClick={() =>
                      navigate(`/property-overview/${selectedMarker.propertyId}/details`)
                    }
                  >
                    <img className="w-28 h-28 rounded-lg" src={selectedMarker.avatar} />

                    <div className="flex flex-col gap-1 w-full">
                      <div className="flex items-center justify-between">
                        <div className="text-xl font-bold">{selectedMarker.name}</div>
                        <div className="text-xs rounded p-2 bg-[#FFF2F0] text-[#EA3323]">
                          {selectedMarker.type}
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                          <div className="bg-[#ECECEC] rounded-full p-1.5">
                            <img className="w-4" src={locationIcon} />
                          </div>
                          <div className="flex flex-col w-[60%]">
                            <span className="font-bold">{selectedMarker.city}</span>
                            <span>{selectedMarker.building + ', ' + selectedMarker.street}</span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2">
                          <div className="bg-[#ECECEC] rounded-full p-1.5">
                            <img className="w-4" src={homeIcon} />
                          </div>
                          <div className="flex flex-col">
                            <span className="font-bold">{selectedMarker.status}</span>
                            <span>{selectedMarker.grossSqm}</span>
                          </div>
                        </div>
                      </div>

                      {selectedMarker.type === 'Building' && selectedMarker?.properties?.length ? (
                        <div className="flex flex-col gap-2 mt-2">
                          <span className="text-center font-bold w-28 py-1 bg-[#ECECEC] rounded-lg w-fit">
                            Properties
                          </span>

                          <div className="max-h-[80px] overflow-y-auto p-2 w-[80%] rounded-lg border border-[#ECECEC]">
                            {selectedMarker.properties.map((unit, index) => (
                              <div className="flex flex-col gap-1 text-[#535353]">
                                <div>{unit.name}</div>
                                {index !== selectedMarker.properties.length - 1 && (
                                  <div className="h-[1px] w-full bg-[#ECECEC]"></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </div>
        </div>
      )}
    </div>
  );
};
