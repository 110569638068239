import chartImage from 'assets/upChart.svg';
import editButtonIcon from 'assets/EditButton.svg';
import DeleteButtonIcon from 'assets/DeleteButton.svg';

export const contactsTableColumns = (t) => [
  {
    field: 'name',
    headerName: t('name'),
    flex: 1,
    FontWeight: 700,
    renderCell: ({ row }) => <div>{row.name}</div>,
  },
  {
    field: 'email',
    headerName: t('email'),
    flex: 1,
    FontWeight: 700,
    cellClassName: 'email_cell',
    renderCell: ({ row }) => <div>{row.email}</div>,
  },
  {
    field: 'recordType',
    headerName: t('record_type'),
    flex: 1,
    FontWeight: 700,
    renderCell: ({ row }) => <div>{row.recordType}</div>,
  },
];

export const propertiesTableColumns = (t) => [
  {
    field: 'propertyName',
    headerName: t('property'),
    flex: 1,
    renderCell: (param) => (
      <div className="flex gap-2 items-center truncate">
        <img alt="" className="w-[39px] h-[39px] rounded-lg" src={param.row.propertyAvatar} />
        {param.row.propertyName}
      </div>
    ),
  },
  { field: 'city', headerName: t('city'), cellClassName: 'city_cell', flex: 1 },
  {
    field: 'address',
    headerName: 'Address',
    cellClassName: 'address_cell',
    flex: 1,
  },
  { field: 'status', headerName: t('status'), flex: 1 },
  { field: 'propertyType', headerName: t('type'), flex: 1 },
  {
    field: 'grossSqm',
    headerName: t('gross_sqm'),
    flex: 1,
    renderCell: (param) => (
      <div>
        {param.row.grossSqm} {localStorage.getItem('SelectedSurfaceSymbol')}
      </div>
    ),
  },
];

export const repairsTableColumns = (t) => [
  { field: 'property', headerName: t('property'), flex: 1 },
  {
    field: 'title',
    headerName: t('title'),
    cellClassName: 'description_cell',
    flex: 1,
  },
  {
    field: 'country',
    headerName: t('country'),
    cellClassName: 'country_cell',
    flex: 1,
  },
  { field: 'city', headerName: t('city'), flex: 1 },
  { field: 'category', headerName: t('category'), flex: 1 },
  { field: 'subCategory', headerName: t('sub_category'), flex: 1 },
  {
    field: 'status',
    headerName: t('status'),
    flex: 1,
    renderCell: (param) => (
      <div
        className={`flex justify-center items-center w-[84px] px-2 py-1 rounded text-[${param.row.statusColor}] bg-[${param.row.statusBackgroundColor}]`}
      >
        {param.row.status}
      </div>
    ),
  },
  { field: 'requestedBy', headerName: t('requested'), flex: 1 },
  {
    field: 'cost',
    headerName: t('cost'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.cost}</div>
    ),
  },
  { field: 'payableBy', headerName: t('paid'), flex: 1 },
];

export const listingsTableColumns = (t) => [
  {
    field: 'propertyName',
    headerName: t('property'),
    flex: 1,
    renderCell: (param) => (
      <div className="flex gap-2 items-center">
        <img alt="" className="w-[39px] h-[39px] rounded-lg" src={param.row.avatar} />
        {param.row.property}
      </div>
    ),
  },
  {
    field: 'listingType',
    headerName: t('listing_type'),
    flex: 1,
    renderCell: (param) => (
      <div
        className={`${param.row.listingType === 'For Rent' ? 'text-[#008E0E]' : 'text-[#EA3323]'}`}
      >
        {param.row.listingType}
      </div>
    ),
  },
  { field: 'city', headerName: t('city'), cellClassName: 'city_cell', flex: 1 },
  { field: 'address', headerName: t('address'), flex: 1 },
  {
    field: 'grossSqm',
    headerName: t('gross_sqm'),
    flex: 1,
    renderCell: (param) => (
      <div>{`${param.row.grossSqm ? param.row.grossSqm : 'N/A'} ${localStorage.getItem(
        'SelectedSurfaceSymbol',
      )}`}</div>
    ),
  },
  {
    field: 'listingStatus',
    headerName: t('status'),
    flex: 1,
    renderCell: (param) => (
      <div
        className={`${param.row.listingStatus === 'Active'
          ? 'text-[#008E0E]'
          : param.row.listingStatus === 'Rented'
            ? ''
            : 'text-[#EA3323]'
          }`}
      >
        {param.row.listingStatus}
      </div>
    ),
  },
  {
    field: 'askingPrice',
    headerName: t('budget'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.askingPrice}</div>
    ),
  },
];

export const leadsTableColumns = (t) => [
  { field: 'contactName', headerName: t('contact'), flex: 1 },
  {
    field: 'contactType',
    headerName: t('contact_type'),
    cellClassName: 'contact_type_cell',
    flex: 1,
  },
  {
    field: 'propertyUsage',
    headerName: t('property_usage'),
    cellClassName: 'property_usage_cell',
    flex: 1,
  },
  { field: 'country', headerName: t('country'), flex: 1 },
  { field: 'city', headerName: t('city'), cellClassName: 'city_cell', flex: 1 },
  {
    field: 'sqmRangeValue',
    headerName: t('sqm_range'),
    flex: 1,
    renderCell: (param) => (
      <div>
        {param.row.sqmRangeValue} {localStorage.getItem('SelectedSurfaceSymbol')}
      </div>
    ),
  },
  {
    field: 'budget',
    headerName: t('budget'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.budget}</div>
    ),
  },
];

export const closingsTableColumns = (t) => [
  { field: 'closingNumber', headerName: t('closing'), flex: 1 },
  {
    field: 'propertyName',
    headerName: t('listing'),
    cellClassName: 'listing_cell',
    flex: 1,
  },
  {
    field: 'propertyAddress',
    headerName: t('property_address'),
    cellClassName: 'property_address_cell',
    flex: 1,
  },
  {
    field: 'grossSqm',
    headerName: t('gross_sqm'),
    flex: 1,
    renderCell: (param) => (
      <div>
        {param.row.grossSqm} {localStorage.getItem('SelectedSurfaceSymbol')}
      </div>
    ),
  },
  { field: 'contactName', headerName: t('tenant'), flex: 1 },
  {
    field: 'closingTypeName',
    headerName: t('closing_type'),
    flex: 1,
    renderCell: (param) => (
      <div
        className={`${param.row.closingTypeName === 'For Rent' ? 'text-[#008E0E]' : 'text-[#EA3323]'
          }`}
      >
        {param.row.closingTypeName}
      </div>
    ),
  },
  { field: 'contractStart', headerName: t('starting_date'), flex: 1 },
  { field: 'contractEnd', headerName: t('ending_date'), flex: 1 },
  {
    field: 'listingAmount',
    headerName: t('closing_amount'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.listingAmount}</div>
    ),
  },
  {
    field: 'invoicedAmount',
    headerName: t('invoiced_amount'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.invoicedAmount}</div>
    ),
  },
];

export const invoicesTableColumns = (t) => [
  { field: 'referenceNumber', headerName: t('transaction_id'), flex: 1 },
  {
    field: 'transactionStatus',
    headerName: t('transaction_status'),
    cellClassName: 'invoice_type_cell',
    flex: 1,
  },
  { field: 'glAccount', headerName: t('gl_account'), flex: 1 },
  {
    field: 'contactName',
    headerName: t('contact_name'),
    cellClassName: 'invoiced_to_cell',
    flex: 1,
  },
  { field: 'dueDate', headerName: t('due_date'), flex: 1 },
  {
    field: 'vatPercentage',
    headerName: t('vat_percentage'),
    flex: 1,
    renderCell: (param) => {
      // Assuming 'N/A' or similar non-numeric string for non-applicable cases.
      if (param.row.vatPercentage === 'N/A') {
        return <div>N/A</div>;
      }

      // Ensure conversion to a number before attempting to multiply.
      const numericValue = Number(param.row.vatPercentage);
      if (!isNaN(numericValue)) {
        // Multiply by 100 to convert to a percentage, and fix to 2 decimal places.
        const percentage = (numericValue * 100).toFixed(2);
        return <div>{percentage}%</div>;
      }

      // Fallback in case of unexpected data type.
      return <div>Invalid</div>;
    },
  }
  ,
  {
    field: 'netAmountDc',
    headerName: t('amount'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.netAmountDc}</div>
    ),
  },
  {
    field: 'totalAmountDc',
    headerName: t('total_amount'),
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.totalAmountDc}</div>
    ),
  },
];

export const yearlyCashFlowColumns = (t) => [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.5,
    headerAlign: 'center',
    renderCell: (index) => <div>{index.api.getRowIndex(index.row.id) + 1}</div>,
  },
  {
    field: 'property',
    headerName: t('property'),
    width: 150,
    flex: 1,
    headerAlign: 'start',
    renderCell: (param) => (
      <div className="flex gap-3 items-center">
        <img className="w-10 h-10 rounded-[4px]" src={param.row.avatar} />
        <a className="font-bold text-sm">{param.row.propertyName}</a>
      </div>
    ),
  },
  {
    field: 'invoicedAmount',
    headerName: t('invoiced_amount'),
    width: 150,
    flex: 1,
    headerAlign: 'start',
    align: 'left',
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.invoicedAmount}</div>
    ),
  },
  {
    field: 'dueAmount',
    headerName: t('due_amount'),
    width: 150,
    flex: 1,
    headerAlign: 'start',
    align: 'left',
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.dueAmount}</div>
    ),
  },
  {
    field: 'upcomingAmount',
    headerName: t('upcoming_amount'),
    width: 150,
    flex: 1,
    headerAlign: 'start',
    align: 'left',
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.upcomingAmount}</div>
    ),
  },
  {
    field: 'chart',
    headerName: t('chart'),
    width: 150,
    flex: 1,
    headerAlign: 'start',
    align: 'left',
    renderCell: (params) => <img src={chartImage} />,
  },
];

//  columns for the table in settings bank integration section (Leads table)
export const accountTransactionsColumns = (cb) => [
  {
    field: 'name',
    headerName: 'Transaction Name',
    flex: 1,
  },
  {
    field: 'iban',
    headerName: 'IBAN',
    flex: 1,
  },
  {
    field: 'transactionAmount',
    headerName: 'Transaction Amount',
    flex: 1,
  },
  {
    field: 'bookingDate',
    headerName: 'Booking Date',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (param) => (
      <img onClick={() => cb('transactionMapping', param.row)} src={editButtonIcon} />
    ),
  },
];

//  columns for the tables in contact overview related section (Leads table)
export const relatedContactLeadsColumns = [
  { field: 'listingType', headerName: 'listing_type', symbol: '' },
  { field: 'budget', headerName: 'budget', symbol: localStorage.getItem('selectedCurrencySymbol') },
  { field: 'usageType', headerName: 'usage_type', symbol: '' },
  {
    field: 'sqmRange',
    headerName: 'sqm_range',
    symbol: localStorage.getItem('SelectedSurfaceSymbol'),
  },
  { field: 'city', headerName: 'city', symbol: '' },
];

//  columns for the tables in contact overview related section (Offers table)
export const relatedContactOffersColumns = [
  { field: 'listing', headerName: 'listings', symbol: '' },
  { field: 'listingType', headerName: 'listing_type', symbol: '' },
  {
    field: 'askingPrice',
    headerName: 'listing_price',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  {
    field: 'offeredAmount',
    headerName: 'offered_amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
];

//  columns for the tables in contact overview related section (Closings table)
export const relatedContactClosingsColumns = [
  { field: 'property', headerName: 'property', symbol: '' },
  { field: 'closingType', headerName: 'closing_type', symbol: '' },
  {
    field: 'totalAmount',
    headerName: 'total_amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  {
    field: 'startDate',
    headerName: 'closing_start_date',
    symbol: '',
  },
  { field: 'endDate', headerName: 'closing_end_date', symbol: '' },
];

//  columns for the tables in contact overview related section (Payments table)
export const relatedContactPaymentsColumns = [
  { field: 'closing', headerName: 'closing', symbol: '' },
  {
    field: 'invoicedAmount',
    headerName: 'invoiced_amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  { field: 'dueDate', headerName: 'due_date', symbol: '' },
  {
    field: 'paidAmount',
    headerName: 'paid_amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
];

//  columns for the tables in property overview related section (Listings table)
export const relatedPropertyListingsColumns = [
  { field: 'listingType', headerName: 'type', symbol: '' },
  {
    field: 'askingPrice',
    headerName: 'asking_price',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  { field: 'status', headerName: 'status', symbol: '' },
];

//  columns for the tables in property overview related section (Repairs table)
export const relatedPropertyRepairColumns = [
  { field: 'title', headerName: 'title', symbol: '' },
  { field: 'category', headerName: 'category', symbol: '' },
  { field: 'property', headerName: 'property', symbol: '' },
  { field: 'requestedBy', headerName: 'reported_by', symbol: '' },
  { field: 'status', headerName: 'status', symbol: '' },
];

//  columns for the tables in property overview related section (Rooms table)
export const relatedPropertyRoomsColumns = [
  { field: 'name', headerName: 'title', symbol: '' },
  {
    field: 'surfaceSqm',
    headerName: 'surface',
    symbol: localStorage.getItem('SelectedSurfaceSymbol'),
  },
  { field: 'roomStatusName', headerName: 'status', symbol: '' },
  { field: 'actions', headerName: 'actions', symbol: '' },
];

// columns for the tables in property overview related table section (Assets table)
export const relatedPropertyAssetsColumns = [
  { field: 'assetName', headerName: 'title' },
  { field: 'assetCategory', headerName: 'category' },
  { field: 'assetBrand', headerName: 'brand' },
  { field: 'assetModel', headerName: 'model' },
  { field: 'assetStatus', headerName: 'status' },
  { field: 'actions', headerName: 'actions' },
];

//  colusmns for the tables in repair overview details page (Materials table)
export const repairMeterialsColumns = (cb) => [
  { flex: 1, field: 'title', headerName: 'Title' },
  { flex: 1, field: 'supplier', headerName: 'Supplier' },
  {
    flex: 1,
    field: 'cost',
    headerName: 'Cost',
    renderCell: (param) => (
      <div>
        {param.row.cost} {localStorage.getItem('selectedCurrencySymbol')}
      </div>
    ),
  },
  { flex: 1, field: 'invoice_id', headerName: 'Invoice' },
  {
    flex: 1,
    field: 'actions',
    headerName: 'Actions',
    renderCell: (param) => (
      <div className="flex gap-4">
        <img onClick={() => cb('editMaterial', param.row)} src={editButtonIcon} alt="" />
        <img onClick={() => cb('delete', param.row)} src={DeleteButtonIcon} alt="" />
      </div>
    ),
  },
];

//  colusmns for the tables in repair overview details page (Checklist table)
export const repairCheckListColumns = [
  { field: 'title', headerName: 'title' },
  { field: 'duration', headerName: 'Dueration (Hours)' },
  { field: 'checkListStatus', headerName: 'status' },
  { field: 'actions', headerName: 'actions' },
];

//  colusmns for the tables in closing overview related page (Invoice table)
export const closingInvoiceColumns = [
  {
    field: 'invoiceAmount',
    headerName: 'Invoiced Amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  { field: 'dueDate', headerName: 'Due Date', symbol: '' },
  { field: 'status', headerName: 'Status', symbol: '' },
  {
    field: 'paidAmount',
    headerName: 'Paid Amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  {
    field: 'cancelledAmount',
    headerName: 'Cancelled Amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  {
    field: 'dueAmount',
    headerName: 'Due Amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
];

//  columns for the tables in closing overview related page (Payments table)
export const closingPaymentsColumns = [
  { field: 'paymentDate', headerName: 'payment_date', symbol: '' },
  { field: 'referenceNumber', headerName: 'reference_number', symbol: '' },
  { field: 'paymentMethod', headerName: 'payment_method', symbol: '' },
  { field: 'amount', headerName: 'amount', symbol: localStorage.getItem('selectedCurrencySymbol') },
];

//  columns for the tables in closing overview related page (Discount transactionLines table)
export const closingDiscountRequestsColumns = [
  { field: 'requestDate', headerName: 'Request Date', symbol: '' },
  { field: 'status', headerName: 'Status', symbol: '' },
  {
    field: 'discountAmount',
    headerName: 'Discount Amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
];

//  columns for the tables in closing overview related page (Repairs table)
export const closingRepairsColumns = [
  { field: 'approvedBy', headerName: 'Approved By', symbol: '' },
  { field: 'category', headerName: 'Category', symbol: '' },
  { field: 'title', headerName: 'Title', symbol: '' },
  { field: 'status', headerName: 'Status', symbol: '' },
];

//  columns for the tables in listings overview related page (Leads table)
export const listingLeadsColumns = [
  { field: 'listingType', headerName: 'listing_type', symbol: '' },
  { field: 'budget', headerName: 'budget', symbol: localStorage.getItem('selectedCurrencySymbol') },
  { field: 'usageType', headerName: 'usage_type', symbol: '' },
  { field: 'sqmRange', headerName: 'sqm_range', symbol: '' },
  { field: 'city', headerName: 'city', symbol: '' },
  { field: 'contact', headerName: 'contact_name', symbol: '' },
];

//  columns for the tables in listings overview related page (Closing table)
export const listingClosingColumns = [
  { field: 'listing', headerName: 'listing', symbol: '' },
  { field: 'listingType', headerName: 'listing_type', symbol: '' },
  { field: 'contact', headerName: 'contact_name', symbol: '' },
  {
    field: 'offeredAmount',
    headerName: 'offered_amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
];

export const potentitalListingColumns = [
  { flex: 1, field: 'propertyName', headerName: 'listing', symbol: '' },
  { flex: 1, field: 'listingType', headerName: 'type', symbol: '' },
  { flex: 1, field: 'city', headerName: 'city', symbol: '' },
  { flex: 1, field: 'address', headerName: 'address', symbol: '' },
  {
    flex: 1,
    field: 'grossSqm',
    headerName: 'sqm',
    symbol: localStorage.getItem('SelectedSurfaceSymbol'),
  },
  {
    flex: 1,
    field: 'price',
    headerName: 'price',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
];

export const propertyAccountingColumns = [
  { field: 'gLAccountCode', headerName: 'GL Account Code', flex: 1 },
  { field: 'name', headerName: 'GL Account Name', flex: 1 },
  { field: 'journalDescription', headerName: 'Journal Description', flex: 1 },
  { field: 'journalCode', headerName: 'Journal Code', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 1 },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    renderCell: (param) => (
      <div>{localStorage.getItem('selectedCurrencySymbol') + ' ' + param.row.amount}</div>
    ),
  },
];

export const ownershipColumns = (cb) => [
  { flex: 1, field: 'contactName', headerName: 'Contact' },
  { flex: 1, field: 'ownershipTypeName', headerName: 'Ownership Type' },
  {
    flex: 1,
    field: 'shares',
    headerName: 'Shares',
    renderCell: (param) => <div>{param.row.shares} %</div>,
  },
  { flex: 1, field: 'roleName', headerName: 'Role' },
  {
    flex: 1,
    field: 'actions',
    headerName: 'Actions',
    renderCell: (param) => (
      <div className="flex gap-4">
        <img onClick={() => cb('editOwnership', param.row)} src={editButtonIcon} alt="" />
        <img onClick={() => cb('delete', param.row)} src={DeleteButtonIcon} alt="" />
      </div>
    ),
  },
];

export const transactionLinesColumns = [
  { field: 'description', headerName: 'description', symbol: '' },
  { field: 'transactionLineType', headerName: 'transaction_line_type', symbol: '' },
  {
    field: 'amountDcFormatted',
    headerName: 'amount',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  { field: 'actions', headerName: 'actions', symbol: '' },
];

export const paymentMethodsColumns = (t, cb) => [
  { field: 'name', headerName: t('name'), flex: 1 },
  { field: 'expirationDate', headerName: t('expiration_date'), flex: 1 },
  {
    field: 'status',
    headerName: t('status'),
    flex: 1,
    renderCell: (param) => (
      <div
        className={`px-6 py-2 w-[100px] rounded-md text-center font-semibold text-[${param.row.statusColor}] bg-[${param.row.statusBackgroundColor}]`}
      >
        {param.row.status}
      </div>
    ),
  },
  { field: 'type', headerName: t('type'), flex: 1 },
  {
    field: 'actions',
    headerName: t('actions'),
    flex: 1.5,
    renderCell: (param) => {
      return (
        <div className="flex gap-4">
          <button
            onClick={() => cb('paymentRoleDialog', { method: 'PRIMARY', ...param.row })}
            className="bg-gray-300 rounded-md p-2"
          >
            Set Primary
          </button>
          <button
            onClick={() => cb('paymentRoleDialog', { method: 'BACKUP', ...param.row })}
            className="bg-gray-300 rounded-md p-2"
          >
            Set Secondary
          </button>
          <img
            src={DeleteButtonIcon}
            onClick={() => cb('delete', param.row.paymentSourceId, 'payment_method')}
          />
        </div>
      );
    },
  },
];

export const subscriptionsColumns = (t, cb) => [
  { field: 'userGroup', headerName: t('user_group'), flex: 1 },
  {
    field: 'plan',
    headerName: t('plan'),
    flex: 1,
    renderCell: (param) => <div className="text-gray-500">{param.row.plan}</div>,
  },
  {
    field: 'billingPeriod',
    headerName: t('billing_period'),
    flex: 1,
    renderCell: (param) => <div className="text-gray-500">{param.row.billingPeriod}</div>,
  },
  {
    field: 'status',
    headerName: t('status'),
    flex: 1,
    renderCell: (param) => {
      switch (param.row.status) {
        case 'active':
          return (
            <div className="text-green-700 font-bold bg-green-100 px-6 py-2 w-[100px] rounded-md">
              {param.row.status}
            </div>
          );
      }
    },
  },
  { field: 'from', headerName: t('from'), flex: 1 },
  { field: 'to', headerName: t('to'), flex: 1 },
  {
    field: 'billableAmount',
    headerName: t('billiable_amount'),
    flex: 1,
    renderCell: (param) => (
      <div>
        {localStorage.getItem('selectedCurrencySymbol')} {param.row.billableAmount}
      </div>
    ),
  },
  { field: 'futureInvoiceDate', headerName: t('future_invoice_date'), flex: 1 },
  { field: 'units', headerName: t('units'), flex: 1 },
  { field: 'users', headerName: t('users'), flex: 1 },
  {
    field: 'actions',
    headerName: t('actions'),
    flex: 1,
    renderCell: (param) => {
      return (
        <div className="flex gap-4">
          <img onClick={() => cb('editSubscription', param.row, '')} src={editButtonIcon} alt="" />
          <img
            onClick={() => cb('delete', param.row, 'subscription')}
            src={DeleteButtonIcon}
            alt=""
          />
        </div>
      );
    },
  },
];

export const userGroupInvitationColumns = (t, cb) => [
  { field: 'userGroupName', headerName: t('user_group_name'), flex: 1 },
  { field: 'invitedBy', headerName: t('invited_by'), flex: 1 },
  { field: 'invitationStatus', headerName: t('invitation_status'), flex: 1 },
  {
    field: 'actions',
    headerName: t('Actions'),
    flex: 1,
    renderCell: (param) => {
      switch (param.row.invitationStatus) {
        case 'pending':
          return (
            <div className="flex flex-1 items-center gap-4 w-[20%]">
              <div
                onClick={() => cb('acceptUserGroupInvite', param.row)}
                className="rounded-lg font-bold text-[#008E0E] bg-[#EEF6F0] py-1.5 px-3"
              >
                Accpet
              </div>
              <div
                onClick={() => cb('rejectUserGroupInvite', param.row)}
                className="rounded-lg font-bold text-[#EA3323] bg-[#FFF2F0] py-1.5 px-3"
              >
                Reject
              </div>
            </div>
          );
        case 'accepted':
          return (
            <div
              onClick={() => cb('leaveUserGroup', param.row)}
              className="rounded-lg font-bold text-[#2B5DDC] bg-[#F0F3FF] py-1.5 px-3"
            >
              Leave
            </div>
          );
        default:
          return null;
      }
    },
  },
];

export const userTableColumns = [
  { field: 'name', headerName: 'contact' },
  { field: 'roleName', headerName: 'role' },
  { field: 'actions', headerName: 'actions' },
];

export const invitationsColumns = [
  { field: 'userGroupName', headerName: 'user_group_name' },
  { field: 'userGroupId', headerName: 'user_group_id' },
  { field: 'invitedUserName', headerName: 'invited_user' },
  { field: 'invitationStatus', headerName: 'invitation_status' },
  { field: 'actions', headerName: 'actions' },
];

export const followUpsColumns = [
  { field: 'date', headerName: 'date' },
  { field: 'todo', headerName: 'todo' },
  { field: 'status', headerName: 'status' },
  { field: 'comments', headerName: 'comments' },
  { field: 'actions', headerName: 'actions' },
];

export const potentialListingsColumns = [
  { flex: 1, field: 'propertyName', headerName: 'listing' },
  { flex: 1, field: 'listingType', headerName: 'type' },
  { flex: 1, field: 'city', headerName: 'city' },
  { flex: 1, field: 'address', headerName: 'address' },
  {
    flex: 1,
    field: 'grossSqm',
    headerName: 'sqm',
    renderCell: (param) => (
      <div>
        {param.row.grossSqm} {localStorage.getItem('SelectedSurfaceSymbol')}
      </div>
    ),
  },
  {
    flex: 1,
    field: 'price',
    headerName: 'price',
    renderCell: (param) => (
      <div>
        {param.row.price} {localStorage.getItem('selectedCurrencySymbol')}
      </div>
    ),
  },
];

export const propertyUnitsColumns = [
  { field: 'unitTitle', headerName: 'unit_title' },
  { field: 'details', headerName: 'details' },
  { field: 'loanAmount', headerName: 'loan_amount' },
  { field: 'duplicates', headerName: 'actions' },
];

export const propertyUnitViewOnly = [
  { field: 'name', headerName: 'name' },
  {
    field: 'acquisitionAmount',
    headerName: 'purchase_price',
    symbol: localStorage.getItem('selectedCurrencySymbol'),
  },
  {
    field: 'grossSqm',
    headerName: 'gross_sqm',
    symbol: localStorage.getItem('selectedSurfaceSymbol'),
  },
];
