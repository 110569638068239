import * as React from 'react';
import { List } from 'react-virtualized';
import { Autocomplete, TextField } from '@mui/material';

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={350}
          width={300}
          style={{ outline: 'none' }}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export const StyledAutoComplete = (props) => {
  const modifiedList = () => {
    return props.itemsList.map((list) => {
      return {
        label: list.name,
        ...list,
      };
    });
  };

  return (
    <Autocomplete
      disablePortal
      disableListWrap
      ListboxComponent={ListboxComponent}
      options={modifiedList()}
      onChange={props.onChange}
      sx={{
        width: '100%',
        '& fieldset': { borderRadius: '16px' },
      }}
      renderInput={(params) => <TextField {...params} placeholder={props.placeholder} />}
    />
  );
};
