export const truncate = (input, length = 30) =>
  input?.length > length ? `${input.substring(0, length)}...` : input;

export const getSectionName = (routeName) => {
  if (routeName.includes('/contact')) return 'Contacts';
  if (routeName.includes('/property') || routeName.includes('/properties')) return 'Properties';
  if (routeName.includes('/repair')) return 'Repairs';
  if (routeName.includes('/listing')) return 'Listings';
  if (routeName.includes('/lead')) return 'Leads';
  if (routeName.includes('/offer')) return 'Offers';
  if (routeName.includes('/closing')) return 'Closings';
  if (routeName.includes('/transaction')) return 'Transactions';
  if (routeName.includes('/payment')) return 'Payments';
  if (routeName.includes('/settings')) return 'Settings';
  if (routeName.includes('/reports')) return 'Reports';
  if (routeName.includes('/blog')) return 'Blog';
};

export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const getAccessToken = () => localStorage.getItem('access-token');

export const getUserGroupId = () => localStorage.getItem('userGroupId');

export const delay = (fn, ms, ...args) => setTimeout(fn, ms, ...args);

export const getCountryRegions = (countries, selectedCountry) =>
  countries?.find((country) => country.id === selectedCountry)?.regions;

export const getCities = (countries, selectedCountry, selectedRegion) => {
  let regions = countries?.find((country) => country.id === selectedCountry)?.regions;
  return regions?.find((region) => region.id === selectedRegion)?.cities;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const numberFormatter = (n) => {
  if (typeof n === 'number') {
    const sign = Math.sign(n); // Get the sign of the number
    n = Math.abs(n); // Treat number as positive
    if (n === 0) return sign * n;
    if (n < 1e3) return sign * n.toFixed(1);
    if (n >= 1e3 && n < 1e6) return sign * +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return sign * +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return sign * +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return sign * +(n / 1e12).toFixed(1) + 'T';
  }
  return n;
};

export const TabName = (newName) => {
  return (document.title = newName + ' .LOCUS');
};
