import * as yup from 'yup';

export const transactionLineSchema = yup.object().shape({
  transactionLineTypeId: yup.string().required('Transaction Line Type field is required'),
  amountDc: yup
    .number()
    .typeError('Input must be a number')
    .required('Amount field is required'),
  transactionLinesDescription: yup.string().required('Description Amount field is required'),
});
