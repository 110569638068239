import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';

import { addressAutoComplete } from 'apis/address/address';
import { LocusContext } from 'hooks/useLocusContext';
import { useDebounce } from 'hooks/useDebounce';

import { StyledInput, StyledSelect } from 'components';
import { CircularProgress, Autocomplete, TextField } from '@mui/material';

export const Address = () => {
  const {
    dialogType,
    dropdownValues,
    selectedSurfaceSymbol,
    selectedCurrencySymbol,
    apiFunctions,
  } = React.useContext(LocusContext);
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  const { t } = useTranslation();
  const [userLocation, setUserLocation] = React.useState(null);
  const [marker, setMarker] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  // const [valueChosen, setValueChosen] = React.useState({ value: '', isChosen: false });

  const debounceSearch = useDebounce(searchValue, 750);

  const {
    data,
    isLoading: searchLoading,
    error,
  } = useQuery(['addressAutoComplete', debounceSearch], () => addressAutoComplete(debounceSearch), {
    enabled: !!searchValue,
  });

  const containerStyle = {
    width: 'auto',
    height: '330px',
    borderRadius: '16px',
    border: `${
      errors['longitude'] && errors['latitude']
        ? '1px solid #EA3323'
        : '1px solid rgba(0, 0, 0, 0.23)'
    }`,
    boxShadow: `${
      errors['longitude'] && errors['latitude'] ? '0px 0px 0px 3px rgba(234,51,35,0.2)' : 'none'
    }`,
    marginTop: '1rem',
  };

  const libraries = ['places'];
  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAFfmVePcgng02_8FcRaHZPBIcEYTZvAC4',
    id: 'google-map-script',
    libraries,
  });

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleBoundsChanged = () => {
    const mapCenter = mapRef.current.getCenter(); //get map center
    // setCenter(mapCenter);
  };

  const panTo = (longitude, latitude) => {
    mapRef.current.panTo({ lat: latitude, lng: longitude });
    mapRef.current.setZoom(12);
  };

  const onMapClick = (e) => {
    setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setFieldValue('latitude', e.latLng.lat());
    setFieldValue('longitude', e.latLng.lng());
  };

  const isError = (value) => {
    if (errors[value]) return <span className="text-[#EA3323]">*</span>;
  };

  React.useEffect(() => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        if (dialogType === 'editProperty')
          setUserLocation({ lat: values.latitude, lng: values.longitude });
        else setUserLocation({ lat: coords.latitude, lng: coords.longitude });
      });

    if (dialogType === 'editProperty') {
      setSearchValue(values.description);
      // setValueChosen({ value: values.description, isChosen: true });
      setMarker({ lat: values.latitude, lng: values.longitude });
    }
  }, []);

  return (
    <div>
      <div className="text-3xl font-bold">{t('address')}</div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-4 p-1">
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-sm pb-1">
              {t('title')} {isError('propertyName')}
            </div>
            <StyledInput
              name="propertyName"
              placeholder={t('title')}
              value={values.propertyName}
              onChange={handleChange}
              error={touched.propertyName && errors.propertyName}
            />
            <ErrorMessage name="propertyName" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('type')} {isError('type')}
            </div>
            <StyledSelect
              name="typeId"
              placeholder={t('type')}
              value={values.typeId}
              onChange={handleChange}
              itemsList={dropdownValues.types}
              error={touched.typeId && errors.typeId}
            />
            <ErrorMessage name="typeId" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('loan_level')} {isError('loanLevel')}
            </div>
            <StyledSelect
              name="loanLevel"
              placeholder={t('loan_level')}
              value={values.loanLevel}
              onChange={handleChange}
              itemsList={dropdownValues.loanLevel}
              error={touched.loanLevel && errors.loanLevel}
              disabled={dialogType === 'editProperty'}
            />
            <ErrorMessage name="loanLevel" component={ValidationError} />
          </div>

          {values.loanLevel === 'building' && (
            <div>
              <div className="text-sm pb-1">
                {t('loan_amount')} {isError('buildingLoanAmount')}
              </div>
              <StyledInput
                name="buildingLoanAmount"
                value={values.buildingLoanAmount}
                onChange={handleChange}
                prefix={selectedCurrencySymbol}
                error={touched.buildingLoanAmount && errors.buildingLoanAmount}
              />
              <ErrorMessage name="buildingLoanAmount" component={ValidationError} />
            </div>
          )}

          <div>
            <div className="text-sm pb-1">
              {t('gross_sqm')} {isError('grossSQM')}
            </div>
            <StyledInput
              name="grossSQM"
              value={values.grossSQM}
              onChange={handleChange}
              prefix={selectedSurfaceSymbol}
              error={touched.grossSQM && errors.grossSQM}
            />
            <ErrorMessage name="grossSQM" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('purchase_price')} {isError('acquisitionPrice')}
            </div>
            <StyledInput
              name="acquisitionPrice"
              value={values.acquisitionPrice}
              onChange={handleChange}
              prefix={selectedCurrencySymbol}
              error={touched.acquisitionPrice && errors.acquisitionPrice}
            />
            <ErrorMessage name="acquisitionPrice" component={ValidationError} />
          </div>
        </div>

        {isLoaded && (
          <div className="flex flex-col gap-1">
            <div className="text-sm">
              {t('location')} {isError('placeId')}
            </div>

            <Autocomplete
              autoComplete
              noOptionsText="No locations"
              loading={searchLoading}
              options={data?.data || []}
              getOptionLabel={(o) => o.name || values.description}
              value={values.description}
              onChange={(e, newValue) => {
                // setFieldValue('residencyAddress.description', newValue.name);
                // setFieldValue('residencyAddress.placeId', newValue.id);

                panTo(newValue.longitude, newValue.latitude);
                setFieldValue('placeId', newValue.id);
                setFieldValue('description', newValue.name);
                setMarker({ lat: newValue.latitude, lng: newValue.longitude });
                setFieldValue('latitude', newValue.latitude);
                setFieldValue('longitude', newValue.longitude);
              }}
              onInputChange={(e, newInputValue) => {
                setSearchValue(newInputValue);
              }}
              sx={{
                width: '100%',
                '.MuiOutlinedInput-root': {
                  borderRadius: 4,
                },
              }}
              renderInput={(params) => (
                <TextField {...params} name="description" placeholder="Enter Location" />
              )}
            />

            <GoogleMap
              mapContainerStyle={containerStyle}
              center={userLocation}
              zoom={15}
              options={options}
              onLoad={onMapLoad}
              onClick={onMapClick}
              onBoundsChanged={handleBoundsChanged}
            >
              <MarkerF position={{ lat: marker.lat, lng: marker.lng }} />
            </GoogleMap>
          </div>
        )}
      </div>
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
