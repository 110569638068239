import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { repairOverviewNavItems } from 'utils/constants';

import { DeleteButton, DeleteDialog, EditButton } from 'components';
import { RepairDialog } from 'pages/repairs/RepairDialog';

import { Skeleton } from '@mui/material';
import greaterIcon from 'assets/greaterIcon.svg';

const RepairOverview = ({ apiFunctions }) => {
  const { t } = useTranslation();
  const { repairId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data, isLoading } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    repairId,
    {
      onSuccess: (data) => {
        if (location.state && location.state.directEdit)
          setOpenDialog({ data: data.data, dialogType: 'editRepair' });
      },
    },
  );

  const { data: dropdownData, isLoading: dropdownLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    repairId,
  );

  const { mutateAsync: deleteMutation, isLoading: deleteLoading } = useCustomMutation(
    apiFunctions.delete.func,
    apiFunctions.delete.key,
  );

  const handleRepairDelete = async () => {
    const response = await deleteMutation([repairId]);

    if (response.statusCode === 200) {
      setOpenDialog(null);
      navigate('/repairs');
    }
  };

  const repairsContext = {
    repairDetails: data?.data,
    isLoading: isLoading,
    dropdownData: dropdownData?.data,
    dropdownLoading: dropdownLoading,
    apiFunctions: apiFunctions,
  };

  const { summary } = data?.data || {};

  return (
    <div className="mt-5">
      <div className="flex gap-2 text-[#EA3323] font-medium text-md">
        <NavLink
          className="hover:cursor-pointer"
          to={`/repairs`}
          key="repairTitle"
          activeClassName="active"
        >
          {t('repairs')}
        </NavLink>
        <img src={greaterIcon} />
        <div>{summary?.property}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton key={_} variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <div className="text-[#8E8E8E]">{t('property')}:</div>
              <div>{summary?.property}</div>
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <div className="text-[#8E8E8E]">{t('category')}:</div>
              <div>{summary?.category}</div>
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <div className="text-[#8E8E8E]">{t('sub_category')}:</div>
              <div>{summary?.subCategory}</div>
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <div className="text-[#8E8E8E]">{t('requested_by')}:</div>
              <div>{summary?.requestedBy}</div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-5">
        <div className="flex flex-row gap-2 sm:items-center items-start justify-between">
          <div className="flex gap-3 ">
            {repairOverviewNavItems.map((item) => (
              <NavLink
                to={`/repair-overview/${repairId}/${item.route}`}
                key={item.name}
                activeClassName="active"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                    : 'border border-[#C9C9C9] p-2 rounded-lg'
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          {window.location.pathname.includes('/details') && (
            <div className="flex gap-3">
              <EditButton
                disabled={isLoading}
                onClick={() => setOpenDialog({ data: data?.data, dialogType: 'editRepair' })}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>

      <DeleteDialog
        openDialog={openDialog}
        name="repair"
        onClose={() => setOpenDialog(null)}
        onDelete={handleRepairDelete}
        isLoading={deleteLoading}
      />

      <RepairDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <Outlet context={repairsContext} />
    </div>
  );
};

export default RepairOverview;
