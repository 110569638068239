import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { LocusContext } from 'hooks/useLocusContext';

import { StyledSelect, StyledInput, StyledAutoComplete } from 'components';

export const CorporateGeneralInfo = () => {
  const { dropdownValues, apiFunctions, dialogType } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  const { data: crmAccounts } = useCustomQuery(
    apiFunctions.getCrmAccounts.func,
    apiFunctions.getCrmAccounts.key,
    {
      enabled: dropdownValues?.activeAccountingIntegration,
    },
  );

  const isError = (value) => {
    if (errors[value]) return <span className="text-[#EA3323]">*</span>;
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="text-3xl font-bold">{t('general_info')}</div>

      <div className="flex flex-col sm:flex-row gap-5 p-1">
        <div className="flex flex-col gap-4 w-full">
          <div>
            <div className="text-sm pb-1">
              {t('name')} {isError('name')}
            </div>
            <StyledInput
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Name"
              error={errors.name && touched.name}
            />
            <ErrorMessage component={ValidationError} name="name" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('invoice_email')} {isError('invoiceEmail')}
            </div>
            <StyledInput
              name="invoiceEmail"
              value={values.invoiceEmail}
              onChange={handleChange}
              placeholder="Invoice Email"
              error={errors.invoiceEmail && touched.invoiceEmail}
            />
            <ErrorMessage component={ValidationError} name="invoiceEmail" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('email')} {isError('email')}
            </div>
            <StyledInput
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Email"
              disabled={dialogType === 'editContact'}
              error={errors.email && touched.email}
            />
            <ErrorMessage component={ValidationError} name="email" />
          </div>

          <div>
            <div className="text-sm pb-1">{t('iban')}</div>
            <StyledInput
              name="iban"
              value={values.iban}
              onChange={handleChange}
              placeholder="IBan"
            />
          </div>

          <div>
            <div className="text-sm pb-1">{t('source')}</div>
            <StyledSelect
              name="source"
              value={values.source}
              onChange={handleChange}
              placeholder="source"
              itemsList={dropdownValues.sources}
              error={errors.source && touched.source}
            />
            <ErrorMessage component={ValidationError} name="source" />
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full">
          <div>
            <div className="text-sm pb-1">
              {t('phone')} {isError('phone')}
            </div>
            <StyledInput
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder="(+90)"
              error={errors.phone && touched.phone}
            />
            <ErrorMessage component={ValidationError} name="phone" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('contact_name')} {isError('contactName')}
            </div>
            <StyledInput
              name="contactName"
              value={values.contactName}
              onChange={handleChange}
              placeholder="Contact Name"
              error={errors.contactName && touched.contactName}
            />
            <ErrorMessage component={ValidationError} name="contactName" />
          </div>

          <div>
            <div className="text-sm pb-1">{t('chamber_commerce_number')}</div>
            <StyledInput
              name="chamberCommerceNumber"
              value={values.chamberCommerceNumber}
              onChange={handleChange}
              placeholder="Chamber of Commerce Number"
            />
          </div>

          <div>
            <div className="text-sm pb-1">{t('vat_number')}</div>
            <StyledInput
              name="vatNumber"
              placeholder="VAT Number"
              value={values.vatNumber}
              onChange={handleChange}
            />
          </div>

          {dropdownValues?.activeAccountingIntegration && crmAccounts && (
            <div>
              <div className="text-sm pb-1">{t('crm_account')}</div>
              <StyledAutoComplete
                name="crmAccount"
                placeholder="Crm Account"
                value={values.crmAccount}
                onChange={(event, newValue) => setFieldValue('crmAccount', newValue.id)}
                itemsList={crmAccounts?.data}
              />
              <ErrorMessage component={ValidationError} name="crmAccount" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
