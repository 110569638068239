import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';
import moment from 'moment';

/*
 * |-----------------------------------------------------------------
 * | Repairs API
 * | getRepairs - getRepair - addRepair - updateRepair - deleteRepair
 * |-----------------------------------------------------------------
 */

export const getRepairs = () => {
  return axios.get(`/api/v1/repair-app/repairs/${getUserGroupId()}`);
};

export const getRepair = (leadId) =>
  axios.get(`/api/v1/repair-app/repair/show/${leadId}/${getUserGroupId()}`);

export const addRepair = (payload) => {
  return axios.post(`/api/v1/repair-app/repair/store`, {
    property_id: payload.property,
    category_id: payload.category,
    sub_category_id: payload.subCategory,
    title: payload.title,
    description: payload.description,
    requested_by: payload.requestedBy,
    requested_date: moment(payload.requestedDate).format('YYYY-MM-DD'),
    status_id: payload.status,
    starting_date: null,
    ending_date: null,
    assigned_to_locus: payload.assignedToLocus == 'fasle' ? 0 : 1,
    payable_by_id: null,
    due_date: null,
    request_status_id: payload.requestStatus,
    user_group_id: getUserGroupId(),
  });
};

export const updateRepair = (payload) => {
  return axios.post(`/api/v1/repair-app/repair/update`, {
    repair_id: payload.repairId,
    category_id: payload.category,
    sub_category_id: payload.subCategory,
    title: payload.title,
    requested_by: payload.requestedBy,
    requested_date: moment(payload.requestedDate).format('YYYY-MM-DD'),
    status_id: payload.status,
    property_id: payload.propertyId,
    user_group_id: getUserGroupId(),
  });
};

export const deleteRepair = (repairId) => {
  return axios.post(`/api/v1/repair-app/repair/destroy`, {
    repair_ids: repairId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |----------------------------------------------
 * | Repair Materials API
 * | addMaterial - updateMaterial - deleteMaterial
 * |----------------------------------------------
 */

export const addMaterial = (payload) => {
  return axios.post(`/api/v1/repair-app/material/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateMaterial = (payload) => {
  return axios.post(`/api/v1/repair-app/material/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteMaterial = (materialId) => {
  return axios.post(`/api/v1/repair-app/material/destroy`, {
    material_id: materialId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |-------------------------------------------------
 * | Repair Checklist API
 * | addCheckList - updateCheckList - deleteCheckList
 * |-------------------------------------------------
 */

export const addCheckList = (payload) => {
  return axios.post(`/api/v1/repair-app/check-list/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateCheckList = (payload) => {
  return axios.post(`/api/v1/repair-app/check-list/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteCheckList = (checkListId) => {
  return axios.post(`/api/v1/repair-app/check-list/destroy`, {
    check_list_id: checkListId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |------------------------------------------------------------------------------------------------
 * | Repair Attachment API
 * | getRepairAttachments - addContactAttachment - updateRepairAttachement - deleteRepairAttachement
 * |------------------------------------------------------------------------------------------------
 */

export const getRepairAttachments = (repairId) =>
  axios.get(`/api/v1/repair-app/repair/attachments/${repairId}/${getUserGroupId()}`);

export const addRepairAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachment).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });

  formData.append('repair_id', payload.repair_id);
  formData.append('user_group_id', getUserGroupId());

  return axios.post(`/api/v2/repair-app/repairs/attachments/store`, formData);
};

export const updateRepairAttachement = (payload) => {
  return axios.post(`/api/v1/repair-app/repair/attachment/update`, {
    ...payload,
    attachment_type_id: '1',
    user_group_id: getUserGroupId(),
  });
};

export const deleteRepairAttachement = (repairAttachmentId) => {
  return axios.post(`/api/v1/repair-app/repair/attachment/destroy`, {
    user_group_id: getUserGroupId(),
    repair_attachment_ids: [repairAttachmentId],
  });
};

// * leads dropdown data API
export const getRepairsDropdownData = () =>
  axios.get(`/api/v1/repair-app/repair/dropdown-values/${getUserGroupId()}`);

/*
 * |---------------------------------------------
 * | Repair Rejection / Approval API
 * | assignToLocus - acceptRepair - rejectRepair
 * |---------------------------------------------
 */

export const assignToLocus = (repairId) =>
  axios.put(`/api/v1/repair-app/repair/assigned-to-locus/${getUserGroupId()}/${repairId}`);

export const acceptRepair = (repairId) =>
  axios.put(`/api/v1/repair-app/repair/accept/${getUserGroupId()}/${repairId}`);

export const rejectRepair = (repairId) =>
  axios.put(`/api/v1/repair-app/repair/reject/${getUserGroupId()}/${repairId}`);

export const exportRepairs = (payload) => {
  return axios.post(`/api/v2/repair-app/repair/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    repair_ids: payload.repairIds,
  });
};
