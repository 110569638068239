import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { isMobile } from 'utils/utils';
import { useCustomMutation } from 'mutations';
import {useCustomQuery, useQueryList} from 'queries';

import {StyledDialog, StyledSelect, StyledInput, Notifier, DatePicker} from 'components';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

const updateTransactionSchema = yup.object().shape({
  transactionDate: yup.string().required('Transaction Date field is required'),
  dueDate: yup.string().required('Due Date field is required'),
  description: yup.string().required('Description field is required'),
});

export const UpdateTransactionDialog = ({ apiFunctions, openDialog, onClose }) => {
  const { t } = useTranslation();
  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const { data: dropdownValues, isLoading } = useQueryList(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
  );
  const { transactionStatuses } = dropdownValues?.data || {};
  const transactionId = openDialog?.data ? openDialog?.data[0] : '';

  const { data: transactionDetailsData, isLoading: transactionDetailsLoading, error } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    transactionId,
    {
      enabled: openDialog?.dialogType === 'editTransaction',
    }
  );

  const { mutateAsync: updateTransactionMutation,
    data: updateTransactionData,
    isLoading: updateTransactionLoading } =
    useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  const { dueDate, transactionDate, description } = transactionDetailsData?.data || {};

  const isDialogOpen = () => {
    return openDialog?.dialogType === 'editTransaction';
  };

  return (
    <>
      <Notifier data={updateTransactionData}/>
      <Formik
        enableReinitialize
        initialValues={{
          description: description || '',
          transactionDate: transactionDate || '',
          dueDate: dueDate || '',
        }}
        validationSchema={updateTransactionSchema}
        onSubmit={async (values, { resetForm }) => {
          let payload = {
            ...values,
            transactionId: transactionId,
          };

          const addTransactionResponse = await updateTransactionMutation(payload);

          if (addTransactionResponse.statusCode === 200) {
            onClose();
            resetForm();
          }
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue, dirty, resetForm }) => (
          <StyledDialog
            width="1064px"
            height="725px"
            fullScreen={isMobile()}
            open={isDialogOpen()}
            onClose={() => {
              resetForm();
              onClose();
            }}
            isEdited={dirty}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex w-[90%] sm:w-[60%] h-full border border-[#dfdfe87a] rounded-2xl justify-between items-center p-3 mt-4 sm:mt-0">
                <div className="flex gap-2 items-center font-semibold">
                  <div className="flex w-[48px] h-[48px] bg-[#EA3323] rounded-full items-center justify-center">
                    <img src={dialogTitleIcon} />
                  </div>
                  {t('edit_transaction')}
                </div>
              </div>

            </div>

            {transactionDetailsLoading ? (
              <div className="flex h-full justify-center items-center">
                <CircularProgress sx={{ color: '#EA3323' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="flex h-full justify-center">
                    <div className="flex flex-col w-full sm:w-[60%] items-center gap-4">
                      <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                        <div className="w-full">
                          <div className="text-sm pb-1">{t('transaction_date')}</div>
                          <DatePicker
                            name="transactionDate"
                            value={values.transactionDate}
                            onChange={(value) => setFieldValue('transactionDate', value)}
                            error={touched.transactionDate && errors.transactionDate}
                          />
                          <ErrorMessage component={ValidationError} name="transactionDate" />
                        </div>

                        <div className="w-full">
                          <div className="text-sm pb-1">{t('description')}</div>
                          <StyledInput
                            name="description"
                            placeholder={t('description')}
                            value={values.description}
                            onChange={handleChange}
                            error={touched.description && errors.description}
                            symbol={currencySymbol}
                          />
                          <ErrorMessage component={ValidationError} name="description" />
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                        <div className="w-full">
                          <div className="text-sm pb-1">{t('due_date')}</div>
                          <DatePicker
                            name="dueDate"
                            value={values.dueDate}
                            onChange={(value) => setFieldValue('dueDate', value)}
                            error={touched.dueDate && errors.dueDate}
                          />
                          <ErrorMessage component={ValidationError} name="dueDate" />
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <div className="w-full flex justify-center">
                    <div className="w-full sm:w-[60%] flex justify-center sm:justify-end">
                      <button
                        className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                        type="submit"
                      >
                        {updateTransactionLoading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          t('update')
                        )}
                      </button>
                    </div>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
