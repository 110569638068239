import React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { resetPassword } from 'apis/authentication/authentication';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { setNewPasswordSchema } from 'validationSchemas';

import { CircularProgress } from '@mui/material';
import bannerImage from 'assets/testLoginbg.png';
import { TabName } from 'utils/utils';

const SetNewPassword = () => {
  TabName('Set-New-Password');

  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, error, mutate, isLoading } = useMutation((payload) => resetPassword(payload));
  const { redirectionMessage, username } = state || {};
  const [OTPExpiredMessage, setOTPExpiredMessage] = React.useState('');

  return (
    <Formik
      initialValues={{
        otp: '',
        newPassword: '',
        confirmPassword: '',
        email: username,
      }}
      validationSchema={setNewPasswordSchema}
      onSubmit={(values) => {
        mutate(values, {
          onSuccess: (data) => {
            if (data.statusCode === 200) {
              navigate('/login');
            } else if (data.statusCode === 201) {
              setOTPExpiredMessage(data.message);
            }
          },
        });
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="flex h-screen">
            <div className="flex-none w-[500px] hidden lg:flex relative">
              <img src={bannerImage} className="absolute inset-0 w-full h-full object-cover" />
            </div>

            <div className="flex flex-col w-full bg-[url('assets/login-background.svg')] bg-cover bg-center p-4">
              <div className="flex items-center self-end mt-4 justify-center text-lg bg-black w-40 h-12 text-white rounded-tr-3xl rounded-bl-3xl">
                <din onClick={() => navigate('/login')}>Back to home</din>
              </div>

              <div className="grid content-center w-[80%] lg:w-6/12 h-full flex flex-col ml-16">
                {redirectionMessage && (
                  <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                    <span className="font-medium"> {redirectionMessage}</span>
                  </div>
                )}

                {!!OTPExpiredMessage.length && (
                  <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                    <span className="font-medium"> {OTPExpiredMessage}</span>
                  </div>
                )}

                <p className="w-fit font-semibold text-4xl mb-6">Set New Password</p>
                <div>
                  <div className="mb-3">
                    <Field
                      name="otp"
                      value={values.otp}
                      placeholder="Enter OTP"
                      className={`pl-4 border border-solid ${
                        touched.otp && errors.otp ? 'border-[#EA3323]' : 'border-[#00000033]'
                      } rounded-lg w-5/6 h-14`}
                    />
                    <ErrorMessage
                      name="otp"
                      render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="newPassword"
                      type="password"
                      placeholder="Set new password"
                      value={values.password}
                      className={`pl-4 border border-solid ${
                        touched.newPassword && errors.newPassword
                          ? 'border-[#EA3323]'
                          : 'border-[#00000033]'
                      } rounded-lg w-5/6 h-14`}
                    />
                    <ErrorMessage
                      name="newPassword"
                      render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm new password"
                      value={values.confirmPassword}
                      className={`pl-4 border border-solid ${
                        touched.confirmPassword && errors.confirmPassword
                          ? 'border-[#EA3323]'
                          : 'border-[#00000033]'
                      } rounded-lg w-5/6 h-14`}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
                    />
                  </div>
                </div>

                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="mt-6 px-4 w-fit h-12 bg-[#FF0000] text-white font-medium align-middle text-center rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer"
                  >
                    {isLoading ? <CircularProgress color="inherit" size={25} /> : 'Set Password'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SetNewPassword;
