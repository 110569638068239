import React from 'react';
import { NavLink, Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { TabName } from 'utils/utils';
import { listingOverviewNavItems } from 'utils/constants';

import { DeleteButton, DeleteDialog, EditButton, Notifier } from 'components';
import { ListingDialog } from 'pages/listings/listingDialog';
import { ClosingDialog } from 'pages/closings/closingDialog';

import { Skeleton } from '@mui/material';
import redHomeIcon from 'assets/redHome.svg';
import greaterIcon from 'assets/greaterIcon.svg';
import redLocationIcon from 'assets/redLocation.svg';
import closingButtonIcon from 'assets/listingButtonIcon.svg';

const ListingOverview = ({ apiFunctions }) => {
  TabName('ListingsOverview');

  const { t } = useTranslation();
  const { listingId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data: listingDetails, isLoading } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    listingId,
    {
      onSuccess: (data) => {
        if (location.state && location.state.directEdit)
          setOpenDialog({ data: data.data, dialogType: 'editListing' });
      },
    },
  );

  const {
    mutateAsync: deleteListingMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleDeleteListing = async () => {
    const response = await deleteListingMutation([listingId]);

    if (response.statusCode === 200) {
      setOpenDialog(null);
      navigate('/listings');
    }
  };

  const { summary, address, generalInfo } = listingDetails?.data || {};

  const listingContext = {
    listingDetails: listingDetails?.data,
    isLoading: isLoading,
    apiFunctions: apiFunctions,
  };

  return (
    <div className="mt-5">
      <Notifier data={mutationResponse} />

      <div className="flex gap-2 text-[#EA3323] font-medium text-md">
        <NavLink
          className="hover:cursor-pointer"
          to={`/listings`}
          key="listingTitle"
          activeClassName="active"
        >
          {t('listings')}
        </NavLink>

        <img src={greaterIcon} />
        <div>{summary?.property}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img src={redHomeIcon} />
              {summary.property}
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl">{summary.listingType}</div>

            <div className="bg-white p-3 font-semibold rounded-3xl text-[#008E0E]">
              {summary.listingStatus}
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img src={redLocationIcon} />
              <div>{address.city + ',' + address.country}</div>
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl">
              {generalInfo.grossSqm + ' SQM'}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-5">
        <div className="flex flex-col sm:flex-row sm:items-center items-start gap-2 justify-between">
          <div className="flex w-[80vw] sm:w-auto overflow-x-auto whitespace-nowrap sm:gap-3 gap-2 text-sm sm:text-md">
            {listingOverviewNavItems.map((item) => (
              <NavLink
                to={`/listing-overview/${listingId}/${item.route}`}
                key={item.name}
                activeClassName="active"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                    : 'border border-[#C9C9C9] p-2 rounded-lg'
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          {window.location.pathname.includes('/details') && (
            <div className="hidden sm:flex gap-3">
              <button
                className="flex gap-1 p-2 items-center justify-center rounded-lg bg-[#FFF2F0] text-[#EA3323]"
                onClick={() =>
                  setOpenDialog({ dialogType: 'addNewClosing', data: listingDetails?.data })
                }
              >
                <img src={closingButtonIcon} />
                {t('closing')}
              </button>

              <EditButton
                onClick={() =>
                  setOpenDialog({ data: listingDetails.data, dialogType: 'editListing' })
                }
                disabled={isLoading}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>

      <DeleteDialog
        openDialog={openDialog}
        name="listing"
        onClose={() => setOpenDialog(null)}
        onDelete={handleDeleteListing}
        isLoading={deleteLoading}
      />

      <ListingDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <ClosingDialog openDialog={openDialog} onClose={() => setOpenDialog(null)} />

      <Outlet context={listingContext} />
    </div>
  );
};

export default ListingOverview;
