import React from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getRepairs, getTodos } from 'apis/dashboard/dashboard';

import { Calendar } from 'components';
import { CircularProgress } from '@mui/material';
import icon from 'assets/todos.svg';
import emptyTodos from 'assets/empty-todos.svg';

export const Requests = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [todos, setTodos] = React.useState(null);
  const { t } = useTranslation();

  const { data: todosData, isLoading: todosLoading, error: todosError } = useQuery(
    ['todos', page],
    () => getTodos(page),
    { enabled: activeTab === 0 },
  );

  const { data: repairs, isLoading: repairsLoading, error: repairsError } = useQuery(
    'repairs',
    getRepairs,
    { enabled: activeTab === 1 },
  );

  const todosNavigation = (type, id) => {
    switch (type) {
      case 'discount-request':
        return `/invoice-overview/${id}/details`;
      case 'follow-ups':
        return `/lead-overview/${id}/details`;
      case 'repair-check-list':
        return `/repair-overview/${id}/details`;
      case 'invoice':
        return `/invoice-overview/${id}/details`;
      case 'closing-tasks':
        return `/closing-overview/${id}/details`;
      case 'property-attachment':
        return `/property-overview/${id}/details`;
      case 'contact-attachment':
        return `/contact-overview/${id}/details`;
      case 'closing-attachment':
        return `/closing-overview/${id}/details`;
      default:
        return '/';
    }
  };

  const todosRef = React.useRef(null);

  React.useEffect(() => {
    if (todos && todosRef.current) {
      const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      });
      const list = todosRef.current;
      const lastItem = list.lastChild;
      if (lastItem) {
        observer.observe(lastItem);
      }

      return () => {
        if (lastItem) {
          observer.unobserve(lastItem);
        }
      };
    }
  }, [todos, todosLoading]);

  function handleObserver(entries) {
    const target = entries[0];
    if (target.isIntersecting && !todosLoading && todos?.pagination?.totalPages > page) {
      setPage(page + 1);
    }
  }

  React.useEffect(() => {
    if (todosData) {
      if (page === 1) {
        setTodos(todosData);
      } else {
        setTodos({ ...todos, data: [...todos.data, ...todosData.data] });
      }
    }
  }, [todosData]);

  return (
    <div className="w-full lg:w-2/5 pl-3 p-4 bg-white rounded-2xl">
      <div className="flex justify-start gap-3">
        {[t('todos'), t('repairs'), t('calendar')].map((_, index) => (
          <div
            key={_}
            className={`cursor-pointer w-fit h-9 flex justify-center items-center px-2 ${activeTab === index ? 'text-white' : 'text-[#8E8E8E]'
              } text-base font-bold h-9 mt-1 ml-1 rounded-lg ${activeTab === index && 'bg-[#EA3323]'
              }`}
            onClick={() => setActiveTab(index)}
          >
            {t(_)}
          </div>
        ))}
      </div>

      <div ref={todosRef} className="overflow-y-auto h-80 mt-4">
        {activeTab === 0 &&
          (todosLoading && !todos ? (
            <div className="flex justify-center items-center h-full">
              <CircularProgress color="primary" sx={{ color: '#ff0000' }} size={25} />
            </div>
          ) : todosError || !todos?.data?.length ? (
            <div className="flex justify-center">
              <img src={emptyTodos} alt="No todos" />
            </div>
          ) : (
            todos?.data.map((item, index) =>
              todos && index === todos?.data?.length - 1 && todosLoading ? (
                <div className="flex justify-center items-center" key={index}>
                  <CircularProgress color="primary" sx={{ color: '#ff0000' }} size={20} />
                </div>
              ) : (
                <NavLink
                  key={index}
                  className="flex gap-4 justify-between items-center rounded p-2 m-1"
                  to={todosNavigation(item.type, item.parentId)}
                >
                  <div className="flex w-[55%] gap-2 items-center ">
                    <div className="flex bg-[#F7ECEC] rounded-lg items-center justify-center p-2">
                      <img src={icon} alt="Todo Icon" />
                    </div>
                    <div className="text-left self-center truncate overflow-x-auto">
                      <div className="text-xs font-bold">{item.title}</div>
                      <div className="text-[#8E8E8E] text-xs font-light">{item.type}</div>
                    </div>
                  </div>
                  <div
                    className={`px-2 py-1 sm:w-[15%] rounded flex justify-center items-center bg-[${item.statusBackgroundColor}] text-[${item.statusColor}] font-normal text-[10px] sm:text-xs`}
                  >
                    {item.status}
                  </div>
                  <div className="flex-auto text-[#8E8E8E] font-medium text-xs">{item.date}</div>
                </NavLink>
              ),
            )
          ))}

        {activeTab === 1 &&
          (repairsLoading ? (
            <div className="flex justify-center items-center h-full">
              <CircularProgress color="primary" sx={{ color: '#ff0000' }} size={25} />
            </div>
          ) : repairsError || !repairs?.data?.length ? (
            <div className="flex justify-center">
              <img src={emptyTodos} alt="No repairs" />
            </div>
          ) : (
            repairs?.data?.map((item, index) => (
              <NavLink
                key={index}
                className="flex gap-14 justify-between rounded p-2 m-1 hover:cursor-pointer"
                to={`/repair-overview/${item.id}/details`}
              >
                <div className="flex w-[55%] gap-2 items-center">
                  <div className="flex bg-[#F7ECEC] rounded-lg self-center items-center justify-center p-2">
                    <img src={icon} alt="Repair Icon" />
                  </div>
                  <div className="text-left self-center truncate overflow-x-auto">
                    <div className="text-xs font-bold">{item.title}</div>
                    <div className="text-[#8E8E8E] text-xs font-light">{item.type}</div>
                  </div>
                </div>
                <div
                  className={`px-2 py-1 rounded flex justify-center items-center self-center font-normal text-xs bg-[${item.statusBackgroundColor}] text-[${item.statusColor}]`}
                >
                  {item.status}
                </div>
                <div className="flex-auto text-[#8E8E8E] font-medium text-xs">{item.date}</div>
              </NavLink>
            ))
          ))}

        {activeTab === 2 && <Calendar />}
      </div>
    </div>
  );
};
