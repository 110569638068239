import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';
import { TabName, isMobile } from 'utils/utils';
import { listingsTableColumns } from 'utils/columns';

import {
  AddButton,
  CardButton,
  DeleteButton,
  DeleteDialog,
  EditButton,
  EmptyList,
  ExportButton,
  ListButton,
  StyledDataGrid,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { ListingDialog } from './listingDialog';
import { ExportDialog } from 'components/dialogs';
import { ListingCard } from './listingCard';

import { CircularProgress, Checkbox, Alert } from '@mui/material';
import searchIcon from 'assets/search.svg';

const Listings = ({ apiFunctions }) => {
  TabName('Listings');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [globalError, setGlobalError] = React.useState(null)
  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedListings, setSelectedListings] = React.useState([]);
  const [allListingsSelected, setAllListingsSelected] = React.useState(false);
  const [listingsList, setListingsList] = React.useState([]);

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getAll.func,
    apiFunctions.getAll.key,
  );

  const {
    mutateAsync: deleteListingMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleListingDelete = async () => {
    const response = await deleteListingMutation(selectedListings);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedListings(data.data.map((item) => item.listingId));
      setAllListingsSelected(true);
    } else {
      setSelectedListings([]);
      setAllListingsSelected(false);
    }
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setListingsList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.propertyName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.listingType.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.city.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.address.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.listingStatus.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setListingsList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setListingsList(data?.data);
    }
  }, [data?.data]);

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if(globalErrorMsg){
      setGlobalError({statusCode: 500, message: globalErrorMsg});
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div className="bg-white rounded-2xl mt-11 pt-2">
      {error && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          There was an error while getting the data
        </Alert>
      )}

      <Notifier data={mutationResponse} />
      <Notifier data={globalError} />

      <div className="flex flex-col lg:flex-row justify-between">
        <div className="hidden lg:flex items-center pl-5">
          <div className="flex h-[40px] items-center justify-center border border-[#C9C9C9] rounded-lg">
            <Checkbox
              icon={
                <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
              }
              checkedIcon={
                <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
              }
              checked={allListingsSelected}
              onChange={handleSelectAll}
            />
            <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row justify-between gap-4 p-3.5">
          <div className="flex rounded-lg bg-[#F6F4F4]">
            <div className="flex items-center">
              <img alt="" className="pl-2" src={searchIcon} />
            </div>

            <input
              className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
              placeholder={t('search_by_listing')}
              value={searchValue}
              onChange={handleFilterChange}
            />
          </div>

          <div className="flex gap-4">
            <AddButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'addListing' })}
              disabled={isLoading}
            />

            <EditButton
              onClick={() =>
                navigate(`/listing-overview/${selectedListings[0]}/details`, {
                  state: { directEdit: true },
                })
              }
              disabled={selectedListings.length > 1 || !selectedListings.length}
            />

            <DeleteButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
              disabled={!selectedListings.length}
            />

            <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
          </div>

          <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
            <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
            <ListButton isActivated={activeTab === 'table'} onClick={() => setActiveTab('table')} />
          </div>
        </div>
      </div>

      {!isLoading && !data?.data?.length ? (
        <EmptyList listName="listings" />
      ) : activeTab === 'table' ? (
        <StyledDataGrid
          checkboxSelection
          rows={listingsList || []}
          columns={listingsTableColumns(t)}
          getRowId={(row) => row.listingId}
          onSelectionModelChange={(newSelectedListing) => setSelectedListings(newSelectedListing)}
          selectionModel={selectedListings}
          rowsPerPageOptions={[]}
          loading={isLoading}
          rowHeight={70}
          pageSize={25}
          onRowClick={(params) => navigate(`/listing-overview/${params.row.listingId}/details`)}
          components={{
            LoadingOverlay: () => (
              <div className="mt-8 flex justify-center">
                <CircularProgress color="error" />
              </div>
            ),
          }}
        />
      ) : isLoading ? (
        <div className="flex flex-col gap-4 p-3">
          {[1, 2, 3, 4].map((_) => (
            <SkeletonCardLoader />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 overflow-y-auto p-5">
          {data?.data.map((listing) => (
            <ListingCard listing={listing} />
          ))}
        </div>
      )}

      <DeleteDialog
        openDialog={openDialog}
        name="listing"
        onClose={() => setOpenDialog(null)}
        onDelete={handleListingDelete}
        isLoading={deleteLoading}
      />

      <ExportDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        selectedElements={selectedListings}
        apiFunctions={apiFunctions}
      />

      <ListingDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />
    </div>
  );
};

export default Listings;
