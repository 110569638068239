import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

export const getAuthenticatedUserById = () =>
  axios.get(`api/v1/setting-app/contact/authenticated-user/show`);

export const getAdminUserGroupById = (userGroupId) =>
  axios.get(`api/v1/setting-app/user-group/admin/show/${userGroupId}`);

export const getUserGroupInvitations = () => axios.get(`api/v1/setting-app/user-group/invitations`);

export const getSettingsDropdownValues = () =>
  axios.get(`api/v1/setting-app/user-group/settings/dropdown-values/${getUserGroupId()}`);

export const addUserGroup = (payload) => {
  return axios.post(`api/v1/setting-app/user-group/settings/user-group/store`, {
    name: payload.userGroupName,
    sqm_unit_id: payload.surfaceUnit,
    currency_id: payload.currency,
    vat: payload.vatApplicable,
    vat_percentage: payload.vatPercentage,
    iban: payload.IBAN,
    bic: payload.BICNumber,
    chamber_commerce_number: payload.chamberOfCommerceNumber,
    legal_entity_name: payload.legalEntityName,
    place_id: payload.placeId,
    description: payload.description,
    building: payload.building,
    floor: payload.floor,
    vat_number: payload.vatNumber,
    zip_code: payload.zipCode,
    invoice_color: payload.color,
    apartment_number: payload.apartmentNumber,
    registration_number: payload.registrationNumber,
    attachments: payload.attachments,
    plan_id: payload.plan,
    payment_frequency: payload.paymentFrequency,
    units: payload.units,
    users: payload.users,
  });
};

export const updateContact = (payload) => {
  return axios.post(`api/v1/setting-app/contact/authenticated-user/update`, {
    contact_id: payload.contactId,
    salutation_id: payload.salutation,
    phone: payload.phone,
    first_name: payload.firstName,
    last_name: payload.lastName,
    country: payload.nationality,
    vat_number: payload.vatNumber,
    contact_address: {
      address_id: payload.residencyAddress.addressId,
      place_id: payload.residencyAddress.placeId,
      description: payload.residencyAddress.description,
      zip_code: payload.residencyAddress.zipCode,
      apartment_number: payload.residencyAddress.apartmentNumber,
      building: payload.residencyAddress.building,
      floor: payload.residencyAddress.floor,
    },
    billing_address: {
      address_id: payload.billingAddress.addressId,
      place_id: payload.billingAddress.placeId,
      description: payload.billingAddress.description,
      zip_code: payload.billingAddress.zipCode,
      apartment_number: payload.billingAddress.apartmentNumber,
      building: payload.billingAddress.building,
      floor: payload.billingAddress.floor,
    },
    user_group_id: payload.userGroupId,
  });
};

export const updateUserGroupPreferences = (payload) => {
  return axios.post(`api/v1/setting-app/user-group/settings/preferences/update`, {
    name: payload.userGroupName,
    sqm_unit_id: payload.surfaceUnit,
    currency_id: payload.currency,
    vat: payload.vatApplicable,
    vat_percentage: payload.vatPercentage,
    iban: payload.IBAN,
    bic: payload.BICNumber,
    chamber_commerce_number: payload.chamberOfCommerceNumber,
    legal_entity_name: payload.legalEntityName,
    place_id: payload.placeId,
    description: payload.description,
    building: payload.building,
    floor: payload.floor,
    vat_number: payload.vatNumber,
    zip_code: payload.zipCode,
    apartment_number: payload.apartmentNumber,
    registration_number: payload.registrationNumber,
    attachments: [],
    invoice_color: payload.color,
    user_group_id: payload.userGroupId,
  });
};

export const sendInvitationToUser = (payload) => {
  return axios.post(`api/v1/setting-app/user-group/invitation/send`, {
    ...payload,
  });
};

export const updateUserRole = (payload) => {
  return axios.post(`api/v1/setting-app/user-group/admin/role/update`, {
    ...payload,
  });
};

export const updatePassword = (payload) => {
  return axios.post(`api/v2/auth-app/auth/user/password/update`, {
    old_password: payload.oldPassword,
    new_password: payload.newPassword,
    confirm_password: payload.confirmPassword,
  });
};

export const acceptInvitation = (invitationId) =>
  axios.put(`api/v1/setting-app/user-group/invitation/accept/${invitationId}`);

export const rejectInvitation = (invitationId) =>
  axios.put(`api/v1/setting-app/user-group/invitation/reject/${invitationId}`);

export const leaveUserGroup = (userGroupId) =>
  axios.delete(`api/v1/setting-app/user-group/assignee/leave/${userGroupId}`);

export const deleteUser = (assigneeId) =>
  axios.delete(
    `api/v1/setting-app/user-group/admin/assignee/remove/${assigneeId}/${getUserGroupId()}`,
  );

export const getUserGroupAttachments = (userGroupId) =>
  axios.get(`/api/v2/setting-app/user-group/attachments/${userGroupId}`);

export const addUserGroupAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachment).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });

  formData.append('user_group_id', payload.userGroup);

  return axios.post(`/api/v2/setting-app/user-group/attachments/store`, formData);
};

export const updateUserGroupAttachment = (payload) => {
  return axios.post(`/api/v2/setting-app/user-group/attachments/update`, {
    ...payload,
  });
};

export const deleteUserGroupAttachment = (payload) => {
  return axios.post(`/api/v2/setting-app/user-group/attachments/destroy`, {
    user_group_id: payload.userGroup,
    user_group_attachment_ids: [payload.userGroupAttachmentId],
  });
};

export const update2FA = (payload) => {
  return axios.post(`api/v2/auth-app/auth/user/2fa/update`, payload);
};

export const cancelUserGroupInvitation = (payload) =>
  axios.post(`api/v2/setting-app/user-group/invitations/cancel`, {
    user_group_id: payload.userGroupId,
    invitation_id: payload.invitationId,
  });

// nordigen apis --------------------------------------------------------------------------------------------------------------
export const getCountries = () => {
  return axios.get(`api/v2/accounting-app-new/bank-integrations/countries/${getUserGroupId()}`);
};

export const getIntegrations = () => {
  return axios.get(`api/v2/accounting-app-new/bank-integrations/${getUserGroupId()}`);
};

export const getTransactions = (accountId) => {
  return axios.get(
    `api/v2/accounting-app-new/bank-integrations/transactions/${accountId}/${getUserGroupId()}`,
  );
};

export const getTransaction = (payload) => {
  return axios.post(`api/v2/accounting-app-new/bank-integrations/transactions/show`, {
    user_group_id: getUserGroupId(),
    account_id: payload.selectedAccount.accountId,
    transaction_id: payload.transactionId,
    transaction_date: payload.bookingDate,
  });
};

export const mapTransactions = (payload) => {
  return axios.post(`api/v2/accounting-app-new/bank-integrations/transactions/map`, {
    user_group_id: getUserGroupId(),
    bank_transaction_id: payload.transactionId,
    bank_transaction_date: payload.bookingDate,
    account_id: payload.selectedAccount.accountId,
    transactions: payload.selectedTransactions,
  });
};

export const getInstitutions = (countryId) =>
  axios.get(
    `api/v2/accounting-app-new/bank-integrations/institutions/${getUserGroupId()}?country=${countryId}`,
  );

// --------------------------------------------------------------------------------------------------

export const listAgreements = () =>
  axios.get(`api/v1/nordigen-app/nordigen/agreements/${getUserGroupId()}`);

export const showAgreement = (agreementId) =>
  axios.get(`api/v1/nordigen-app/nordigen/agreements/${agreementId}/${getUserGroupId()}`);

export const listTransactions = (agreementId) =>
  axios.get(`api/v1/nordigen-app/nordigen/agreements/${agreementId}/${getUserGroupId()}`);

export const listAccountTransactions = (accountId, page) =>
  axios.get(
    `api/v2/accounting-app-new/bank-integrations/transactions/${accountId}/${getUserGroupId()}?page=${page}`,
  );

export const transactionDropdownValues = () =>
  axios.get(`api/v2/nordigen-app/transaction/invoice/map/dropdown-values/${getUserGroupId()}`);

export const transactionUpdate = (payload) => {
  return axios.post(`api/v2/nordigen-app/transaction/invoice/map/update`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const deleteTransaction = (invoiceTransactionId) => {
  return axios.post(`api/v2/nordigen-app/transaction/invoice/map/delete`, {
    user_group_id: getUserGroupId(),
    transaction_id: invoiceTransactionId,
  });
};

export const deleteAgreement = (agreementId) =>
  axios.delete(`api/v1/nordigen-app/nordigen/agreements/${agreementId}/${getUserGroupId()}`);

export const showTransaction = (payload) =>
  axios.post(`api/v2/nordigen-app/transaction/invoices`, {
    user_group_id: getUserGroupId(),
    transaction_id: payload.transactionId,
    date: payload.date,
    account_id: payload.accountId,
  });

export const validateRequisition = (referenceId) =>
  axios.post(`api/v2/accounting-app-new/bank-integrations/status/update`, {
    reference_id: referenceId,
    user_group_id: getUserGroupId(),
  });

export const storeNordigenInstitutions = (institutionId) => {
  return axios.post(`api/v2/accounting-app-new/bank-integrations/store`, {
    institution_id: institutionId,
    user_group_id: getUserGroupId(),
  });
};

export const storePaymentMethod = (tokenId) => {
  return axios.post(`api/v2/setting-app/subscriptions/payment-methods/store`, {
    user_group_id: getUserGroupId(),
    token_id: tokenId,
  });
}

export const deletePaymentMethod = (paymentSourceId) => {
  return axios.post(`api/v2/setting-app/subscriptions/payment-methods/delete`, {
    user_group_id: getUserGroupId(),
    payment_source_id: paymentSourceId,
  });
}
