import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { LocusContext } from 'hooks/useLocusContext';
import { StyledSelect } from 'components';

export const ContactType = ({ onRecordTypeSelect, contactRecordType }) => {
  const { dialogType, dropdownValues } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const { values, handleChange, errors, touched } = useFormikContext();

  return (
    <div className="flex flex-col gap-4 w-full sm:w-9/12 p-1">
      {dialogType === 'editContact' ? (
        <span className="text-3xl font-bold">{t('edit_contact')}</span>
      ) : (
        <span className="text-3xl font-bold">{t('what_contact_type')}</span>
      )}

      {dialogType !== 'editContact' && (
        <div className="flex gap-5">
          {dropdownValues.recordTypes.map((type) => (
            <ContactTypeButton
              selected={contactRecordType.id === type.id}
              onClick={() => onRecordTypeSelect(type)}
            >
              {type.name}
            </ContactTypeButton>
          ))}
        </div>
      )}

      <div className="mt-3">
        <div className="mb-2">{t('who_are_you')}</div>
        <StyledSelect
          name="type"
          placeholder="Types"
          value={values.type}
          onChange={handleChange}
          itemsList={dropdownValues.contactTypes}
          error={touched.type && errors.type}
        />
        <ErrorMessage component={ValidationError} name="type" />
      </div>
    </div>
  );
};

const ContactTypeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 56px;
  border: ${({ selected }) => (selected ? '1px solid #2B5DDC' : '1px solid #C9C9C9')};
  box-shadow: ${({ selected }) => selected && '0px 0px 0px 3px rgba(43, 93, 220, 0.2)'};
  border-radius: 16px;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
  }
`;

const ValidationError = styled.div`
  color: #ea3323;
`;
