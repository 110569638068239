import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { sidebarItems } from 'utils/constants';

import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material';
import locusLogo from 'assets/locus-logo-sidebar.svg';
import sidebIcon from 'assets/sidebar-menu.svg';
import blogsSectionLogo from 'assets/sidebar-icons-new/report-sidebar-new.svg';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
  marginTop: 20,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SideBar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const isAdmin = localStorage.getItem('isAdmin');

  const isActiveLink = (routePath) => {
    return routePath.substr(0, 5) === location.pathname.substr(0, 5);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader sx={{ marginBottom: '28px' }}>
        {open && (
          <Link to="/">
            <img className="h-5 w-24" src={locusLogo} />
          </Link>
        )}
        <img
          className={`cursor-pointer ${!open && 'm-auto'}`}
          src={sidebIcon}
          onClick={() => setOpen(!open)}
        />
      </DrawerHeader>

      <List sx={{ paddingLeft: open ? 1 : 0 }}>
        {sidebarItems.map((item) => (
          <Link key={item.name} to={item.routePath}>
            <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
              <div
                className={`w-52 flex ${
                  isActiveLink(item.routePath) ? 'bg-[#FFF2F0] rounded-2xl' : ''
                } hover:rounded-2xl`}
              >
                <ListItemButton
                  sx={{
                    '&:hover': {
                      borderRadius: '16px',
                    },
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      flex: 0.2,
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={isActiveLink(item.routePath) ? item.activeIcon : item.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(item.name)}
                    sx={{
                      flex: 1,
                      opacity: open ? 1 : 0,
                      color: isActiveLink(item.routePath) ? '#EA3323' : '#8E8E8E',
                    }}
                  />
                </ListItemButton>
              </div>
            </ListItem>
          </Link>
        ))}

        {JSON.parse(isAdmin) && (
          <Link to="blog">
            <ListItem disablePadding sx={{ display: 'block' }}>
              <div
                className={`w-52 flex ${
                  isActiveLink('/blog') ? 'bg-[#FFF2F0] rounded-2xl' : ''
                } hover:rounded-2xl`}
              >
                <ListItemButton
                  sx={{
                    '&:hover': {
                      borderRadius: '16px',
                    },
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      flex: 0.2,
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={isActiveLink('/blog') ? blogsSectionLogo : blogsSectionLogo} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Blog"
                    sx={{
                      flex: 1,
                      opacity: open ? 1 : 0,
                      color: isActiveLink('/blog') ? '#EA3323' : '#8E8E8E',
                    }}
                  />
                </ListItemButton>
              </div>
            </ListItem>
          </Link>
        )}
      </List>
    </Drawer>
  );
};
