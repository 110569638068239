import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

export const getListedProperties = () =>
  axios.get(`/api/v1/dashboard-app/dashboard/listed-properties/${getUserGroupId()}`);
export const getMonthlyPerformance = (month) =>
  axios.get(
    `/api/v1/dashboard-app/dashboard/monthly-performance/${getUserGroupId()}?filter[month]=${
      month.id
    }`,
  );
export const getDashboardMap = () =>
  axios.get(`/api/v1/dashboard-app/dashboard/map/${getUserGroupId()}`);
export const getTodos = (page) =>
  axios.get(`/api/v1/dashboard-app/dashboard/todos/${getUserGroupId()}/?page=${page}`);
export const getRepairs = () =>
  axios.get(`/api/v1/dashboard-app/dashboard/repairs/${getUserGroupId()}`);
export const getChartGrid = (year, page) =>
  axios.get(
    `/api/v1/dashboard-app/dashboard/chart-grid/${getUserGroupId()}?filter[year]=2022&page=${page}`,
  );
export const getYearlyCashFlow = (year, page) =>
  axios.get(
    `/api/v1/dashboard-app/dashboard/cash-flow/${getUserGroupId()}?filter[year]=${year}&page=${page}`,
  );
export const getDashboardDropdown = () =>
  axios.get(`/api/v1/dashboard-app/dashboard/dropdown-values/${getUserGroupId()}`);
export const getMasterProperties = () =>
  axios.get(`/api/v1/dashboard-app/dashboard/master-properties/${getUserGroupId()}`);
export const globalSearch = (searchKey, page) =>
  axios.get(
    `/api/v1/dashboard-app/dashboard/search/${getUserGroupId()}?filter[search]=${searchKey}&page=${page}`,
  );
