import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getListedProperties } from 'apis/dashboard/dashboard';

import { ListedPropertyCard } from 'components';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ListedProperties = () => {
  const { data, isLoading } = useQuery('listedProperties', getListedProperties);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 w-full lg:w-5/12">
      <div className="flex justify-between items-center">
        <span className="font-bold text-xl">{t('listed_properties')}</span>
        <Link to="/listings" className="text-xs text-[#EA3323]">
          {t('view_all')}
        </Link>
      </div>

      <div className="flex flex-col overflow-y-auto h-96 rounded-xl bg-white border-opacity-30 p-4">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <CircularProgress color="primary" sx={{ color: '#ff0000' }} size={25} />
          </div>
        ) : !data?.data?.length ? (
          // Adding enhanced empty data handling here
          <div className="text-center p-4">No listed properties found.</div>
        ) : (
          data.data.map((item) => (
            <div key={item.listingId} className="p-1">
              <Link to={`listing-overview/${item.listingId}/details`}>
                <ListedPropertyCard item={item} />
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
