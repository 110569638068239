import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { verifyOTP } from 'apis/authentication/authentication';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CircularProgress } from '@mui/material';
import { confirmAccountSchema } from 'validationSchemas';
import bannerImage from 'assets/testLoginbg.png';
import { useTranslation } from 'react-i18next';

export const ConfirmAccount = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { redirectionMessage, username } = state || {};
  const [invalidOTP, setInvalidOTP] = React.useState('');
  const { mutate, isLoading } = useMutation((payload) => verifyOTP(payload));

  return (
    <Formik
      initialValues={{ otp: '' }}
      validationSchema={confirmAccountSchema}
      onSubmit={(values) => {
        mutate(
          { code: values.otp, username: username },
          {
            onSuccess: (data) => {
              if (data.statusCode === 200) {
                  if(data.data.isActivePassword)
                      navigate('/login');
                  else
                      navigate('/set-new-password', { state: { username: username } })
              } else if (data.statusCode === 201) {
                setInvalidOTP(data.message);
              }
            },
          },
        );
      }}
    >
      {({ values, errors, touched, handleChange }) => (
        <Form>
          <div className="flex h-screen">
            <div className="flex-none w-[500px] relative">
              <img src={bannerImage} className="absolute inset-0 w-full h-full object-cover" />
            </div>

            <div className="flex flex-col w-full bg-[url('assets/login-background.svg')] bg-cover bg-center p-4">
              <div className="flex items-center self-end mt-4 justify-center text-lg bg-black w-40 h-12 text-white rounded-tr-3xl rounded-bl-3xl">
                <Link to="/">Back to home</Link>
              </div>

              {redirectionMessage && (
                <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                  <span className="font-medium">{redirectionMessage}</span>
                </div>
              )}

              {!!invalidOTP.length && (
                <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                  <span className="font-medium">{invalidOTP}</span>
                </div>
              )}

              <div className="grid content-center w-6/12 h-full flex flex-col ml-16">
                <p className="font-semibold text-3xl">{t('confirm_account')}</p>
                <Field
                  name="otp"
                  value={values.otp}
                  placeholder="Enter OTP"
                  onChange={handleChange}
                  className={`pl-4 mt-4 focus:outline-none border border-solid ${
                    touched.otp && errors.otp ? 'border-[#EA3323]' : 'border-[#00000033]'
                  } rounded-lg w-80 h-14`}
                />
                <ErrorMessage
                  name="otp"
                  render={(msg) => <div className="mt-2 text-[#EA3323]">{msg}</div>}
                />
                <button
                  type="submit"
                  className="w-32 h-12 mt-8 bg-[#FF0000] text-white font-medium align-middle text-center rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer"
                >
                  {isLoading ? <CircularProgress color="inherit" size={25} /> : t('confirm')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
