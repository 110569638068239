import * as yup from 'yup';

export const propertiesBuildingSchema = [
  yup.object().shape({}),
  yup.object().shape({
    typeId: yup.string().required('Type field is required'),
    propertyName: yup.string().required('Property Name field is required'),
    grossSQM: yup.string().required('Gross SQM field is required'),
    placeId: yup.string().required('Location field is required'),
    description: yup.string(),
    acquisitionPrice: yup.string().required('Purchase Price field is required'),
    loanLevel: yup.string().required('Loan Level field is required'),
  }),
  yup.object().shape({
    units: yup.array().required('Units field is required'),
  }),
  yup.object().shape({
    propertyDescription: yup.string(),
  }),
];
