import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { bussinessTypeInitialState, individualTypeInitialState } from 'initialStates';
import { businessContactsSchema, individualContactsSchema } from 'validationSchemas';
import { isMobile } from 'utils/utils';
import { contactDialogSteps } from 'utils/constants';
import { LocusContextProvider } from 'hooks/useLocusContext';

import {
  ContactType,
  IndividualGeneralInfo,
  CorporateGeneralInfo,
  ContactAddress,
  BillingAddress,
  Comments,
  SuccessStep,
} from 'pages/contacts/addContactDialogComponents';
import { StyledDialog, Steps, Notifier } from 'components';
import { CircularProgress, DialogContent } from '@mui/material';
import backArrow from 'assets/backArrow.svg';

export const ContactDialog = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formikFormRef = React.useRef();

  const { apiFunctions } = props;

  const [isSameAddress, setIsSameAddress] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [contactData, setContactData] = React.useState(null);
  const [contactRecordType, setContactRecordType] = React.useState({});

  const isLastStep = currentStep === contactDialogSteps.length - 1;
  const currentValidationSchema =
    contactRecordType?.name === 'Corporate'
      ? businessContactsSchema[currentStep]
      : individualContactsSchema[currentStep];


  React.useEffect(() => {
    if (props.openDialog?.data)
      setContactRecordType({
        name: props.openDialog.data.generalInfo.recordType,
        id: props.openDialog.data.generalInfo.recordTypeId,
      });
  }, [props.openDialog]);

  const isDialogOpen = () => {
    if (props.openDialog?.dialogType === 'addContact') return true;
    else if (props.openDialog?.dialogType === 'editContact') return true;
    return false;
  };

  const handleDecreaseStep = () => {
    if (isSameAddress && currentStep === 4) return setCurrentStep(currentStep - 2);
    return setCurrentStep(currentStep - 1);
  };

  const handleCloseDialog = () => {
    formikFormRef.current?.resetForm();
    setCurrentStep(0);
    props.onClose();
  };

  const { data: contactDropdown, isLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    null,
    { enabled: isDialogOpen() },
  );

  const { mutateAsync: addContactMutation, isLoading: addContactLoading } = useCustomMutation(
    apiFunctions.add.func,
    apiFunctions.add.key,
  );

  const {
    mutateAsync: updateContactMutation,
    data: editContactMutationResponse,
    isLoading: updateContactLoading,
  } = useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  return (
    <>
      <Notifier data={editContactMutationResponse} />

      <Formik
        innerRef={formikFormRef}
        enableReinitialize
        initialValues={
          contactRecordType.name === 'Corporate'
            ? bussinessTypeInitialState(props.openDialog?.data)
            : individualTypeInitialState(props.openDialog?.data)
        }
        validationSchema={currentValidationSchema}
        onSubmit={async (values, { resetForm, setTouched, setSubmitting }) => {
          if (isLastStep) {
            let payload = {
              ...values,
              record_type_id: contactRecordType.id,
              contact_id: props.openDialog?.data?.contactId,
              same_address: isSameAddress,
              billingAddressId: props.openDialog?.data?.billingAddress.addressId,
              contactAddressId: props.openDialog?.data?.contactAddress.addressId,
            };

            if (props.openDialog?.dialogType === 'addContact') {
              const addContactResponse = await addContactMutation(payload);

              if (addContactResponse.statusCode === 200) {
                setContactData(addContactResponse?.data);
                setCurrentStep(5);
                resetForm();
              }
            } else if (props.openDialog?.dialogType === 'editContact') {
              const editContactResponse = await updateContactMutation(payload);

              if (editContactResponse.statusCode === 200) {
                setCurrentStep(0);
                props.onClose();
              }
            }
          } else {
            if (isSameAddress && currentStep === 2) return setCurrentStep(currentStep + 2);
            setTouched({});
            setSubmitting(false);
            return setCurrentStep(currentStep + 1);
          }
        }}
      >
        {({ dirty }) => (
          <StyledDialog
            open={isDialogOpen()}
            onClose={handleCloseDialog}
            isEdited={dirty}
            fullScreen={isMobile()}
            width="1064px"
            height="725px"
          >
            <DialogContent>
              {isLoading ? (
                <div className="grid place-content-center h-full">
                  <CircularProgress color="error" />
                </div>
              ) : (
                <div className="flex gap-8 h-full">
                  {currentStep !== 5 && (
                    <Steps steps={contactDialogSteps} currentStep={currentStep} />
                  )}

                  <Form
                    className={`${currentStep === 5 ? 'w-full' : 'w-full sm:w-[75%]'
                      } flex flex-col gap-4 justify-between h-full p-2`}
                  >
                    <div className="m-auto w-full overflow-auto">
                      <LocusContextProvider
                        dialogType={props.openDialog?.dialogType}
                        dropdownValues={contactDropdown?.data}
                        apiFunctions={apiFunctions}
                      >
                        {currentStep === 0 && (
                          <ContactType
                            contactRecordType={contactRecordType}
                            onRecordTypeSelect={(type) => setContactRecordType(type)}
                          />
                        )}
                        {currentStep === 1 &&
                          (contactRecordType.name === 'Corporate' ? (
                            <CorporateGeneralInfo />
                          ) : (
                            <IndividualGeneralInfo />
                          ))}

                        {currentStep === 2 && (
                          <ContactAddress
                            onCheckBoxToggle={() => setIsSameAddress(!isSameAddress)}
                            isChcked={isSameAddress}
                          />
                        )}

                        {currentStep === 3 && !isSameAddress && <BillingAddress />}
                        {currentStep === 4 && <Comments />}
                        {currentStep === 5 && <SuccessStep contactData={contactData} />}
                      </LocusContextProvider>
                    </div>

                    <div className="self-end flex gap-4">
                      {currentStep !== 0 && currentStep !== 5 && (
                        <div
                          onClick={handleDecreaseStep}
                          className="flex justify-center gap-4 bg-[#F6F4F4] cursor-pointer text-[#8E8E8E] rounded-2xl font-bold p-3 w-32 text-center"
                        >
                          <img alt="" src={backArrow} />
                          {t('back')}
                        </div>
                      )}

                      {addContactLoading || updateContactLoading ? (
                        <StyledButton type="button">
                          <CircularProgress color="inherit" size={20} />
                        </StyledButton>
                      ) : (
                        currentStep !== 5 && (
                          <StyledButton type="submit">
                            {isLastStep ? 'Submit' : t('next')}
                          </StyledButton>
                        )
                      )}

                      {currentStep === 5 && (
                        <StyledButton
                          type="button"
                          onClick={() =>
                            navigate(`/contact-overview/${contactData?.contactId}/details`, {
                              state: { recordType: contactData?.recordType },
                            })
                          }
                        >
                          {t('go_to_details')}
                        </StyledButton>
                      )}
                    </div>
                  </Form>
                </div>
              )}
            </DialogContent>
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const StyledButton = styled.button`
  background: #ea3323;
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  min-width: 120px;
  padding: 10px;
  font-weight: bold;
  &:disabled {
    opacity: 0.5;
  }
`;
