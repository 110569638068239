import React, {useEffect, useRef} from "react";

import Slider from 'react-input-slider';
import {FormControlLabel, Checkbox} from "@mui/material";

import checkedTick from 'assets/checked_tick.svg';
import uncheckedTick from 'assets/unchecked_tick.svg';
import redPolygon from 'assets/polygon.svg';
import {ConfirmSubscriptionDialog} from "./confirmSubscriptionDialog";
import {useCustomMutation} from "mutations";
import {useNavigate} from "react-router-dom";
import {useCustomQuery} from "queries";
const SubscriptionPage = ({ apiFunctions }) => {
  const navigate = useNavigate();

  const [rentals, setRentals] = React.useState({ x: 10 });
  const [users, setUsers] = React.useState({ x: 10 });
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [plan, setPlan] = React.useState('monthly');
  const sliderRef = useRef(null);
  const userSliderRef = useRef(null);
  const [minPlan, setMinPlan] = React.useState(0);
  const [price, setPrice] = React.useState({base: '', plus: ''});

  const { data: dropDownData, isLoading } = useCustomQuery(
    apiFunctions.subscriptionDropdown.func,
    apiFunctions.subscriptionDropdown.key,
  );

  const {
    mutateAsync: subscribeMutation,
    isLoading: subscribeLoading,
    data: subscribeResponse,
  } = useCustomMutation(apiFunctions.subscribeCustomer.func, apiFunctions.subscribeCustomer.key);

  const includes = [
    {
      id: 0,
      name: 'Access to web platform',
      plans: [
        {
          id:0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        },
      ]
    },
    {
      id: 3,
      name: 'Automated messaging',
      plans: [
        {
          id:0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 4,
      name: 'Overview of TODOs',
      plans: [
        {
          id:0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 5,
      name: 'Automatic reminders for insurance checks etc (Attachment Reminders)',
      plans: [
        {
          id:0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        },
      ]
    },
    {
      id: 8,
      name: 'Repair Checklist',
      plans: [
        {
          id: 0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 11,
      name: 'Automated Indexation',
      plans: [
        {
          id: 0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 13,
      name: 'charge late fees for late payments',
      plans: [
        {
          id: 0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 9,
      name: 'Cost management of the repair',
      plans: [
        {
          id: 0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 15,
      name: 'Accounting integration',
      plans: [
        {
          id: 0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 10,
      name: 'Upload relevant documents and proof of work',
      plans: [
        {
          id: 0,
          name: 'Base'
        },
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 1,
      name: 'Access to Landlord App',
      plans: [
        {
          id: 1,
          name: 'Plus'
        },
      ]
    },
    {
      id: 2,
      name: 'Access to the Tenant app',
      plans: [
        {
          id: 1,
          name: 'Plus'
        },
      ]
    },
    {
      id: 6,
      name: 'Repairs reported by tenants',
      plans: [
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 7,
      name: 'Technical issues are automatically distributed to the right person',
      plans: [
        {
          id: 1,
          name: 'Plus'
        }
      ]
    },
    {
      id: 14,
      name: 'Bank account integrations',
      plans: [
        {
          id: 0,
          name: 'Base',
          value: '2'
        },
        {
          id: 1,
          name: 'Plus',
          value: '∞'
        }
      ]
    },
    {
      id: 12,
      name: 'process planner for invoicing',
      plans: [
        {
          id: 2,
          name: 'Enterprise'
        }
      ]
    }
  ]
  const plans = [
    {
      id: 0,
      name: 'Base',
      firstDescription: 'The best plan to manage your apartment The best ',
      secondDescription: 'plan to manage your apartment The best plan to manage your apartment The best plan to manage your apartment ',
      price: 11.00,
    },
    {
      id: 1,
      name: 'Plus',
      firstDescription: 'The best plan to manage your apartment The best ',
      secondDescription: 'plan to manage your apartment The best plan to manage your apartment The best plan to manage your apartment ',
      price: 59.00,
    },
    // {
    //   id: 2,
    //   name: 'Enterprise',
    //   firstDescription: 'The best plan to manage your apartment The best ',
    //   secondDescription: 'plan to manage your apartment The best plan to manage your apartment The best plan to manage your apartment ',
    //   price: 99.00,
    // }
  ]


  const computeLabelStyles = () => {
    if (!sliderRef.current) return {};

    const trackWidth = sliderRef.current.offsetWidth;
    const ratio = (rentals.x) / (500);

    return {
      left: `${ratio * trackWidth}px`
    };
  };

  const computeUserLabelStyles = () => {
    if (!userSliderRef.current) return {};

    const trackWidth = userSliderRef.current.offsetWidth;
    const ratio = (users.x) / (500);

    return {
      left: `${ratio * trackWidth}px`
    };
  };

  useEffect(() => {
    if (rentals.x >= 400) {
      setMinPlan(2);
    } else if (rentals.x >= 200) {
      setMinPlan(1);
    } else {
      setMinPlan(0);
    }
  }, [rentals.x]);

  useEffect(() => {
    if(plan === 'yearly') {
      setPrice({ base: '5', plus: '9'})
    }else {
      setPrice({ base: '7', plus: '12'})
    }
  }, [plan]);

  const handleConfirmPlanSubscription = async () => {
    let payload = {
      plan_id: openDialog.data.id,
      unit_quantity: rentals.x,
      user_quantity: users.x,
      payment_frequency: plan
    }

    const response = await subscribeMutation(payload);
    if (response.statusCode === 200) {
      navigate('/dashboard');
    }
  }

  return (
    <div className="bg-white sm:px-[80px] px-[20px] sm:py-[100px] py-[60px]">
      <div className="w-full flex flex-col items-center justify-center">

        <div className="bg-[#FFF2F0] w-fit border border-[#EA3323] py-[8px] px-[14px] rounded-[4px] text-[#EA3323] text-[12px] font-[400] leading-[16px]">
          Your Property, Your Plan
        </div>
        <h1 className="mt-[8px] sm:mt-[22px] sm:px-[200px] text-black font-bold leading-[60px] text-[36px] sm:text-[50px] text-center">
          Customizable Solutions for Tenants and Renters
        </h1>

        <div className="flex flex-col items-end mt-[15px]">
          <div className="sm:mt-[50px] mt-[30px] flex flex-col sm:flex-row gap-[15px] sm:gap-[30px]">
            <div className="font-bold text-[16px] sm:text-[20px]">How many rentals do you manage?</div>
            <div className="flex items-center gap-[16px] sm:mt-0 mt-[35px]">
              <div className="text-[8E8E8E] text-[14px]">1</div>
              <div ref={sliderRef} className="relative">
                <Slider
                  styles={{
                    track: {
                      backgroundColor: '#FFF2F0'
                    },
                    active: {
                      backgroundColor: '#EA3323'
                    },
                    thumb: {
                      width: 20,
                      height: 20,
                      border: '5px solid #EA3323',
                    },
                    disabled: {
                      opacity: 0.5
                    }
                  }}
                  xmin={1}
                  xmax={500}
                  axis="x"
                  x={rentals.x}
                  onChange={({ x }) => {
                    setRentals(state => ({ ...state, x }))
                  }}
                />
                <div
                  className="mb-[8px] absolute bottom-full flex flex-col justify-center items-center transform -translate-x-1/2 text-white border border-[#F7ECEC] bg-[#EA3323] rounded-[4px] px-[12px] py-1"
                  style={computeLabelStyles()}
                >
                  <img className="absolute top-[31px]" src={redPolygon} alt=""/>
                  <div className="text-[10px] font-bold">{rentals.x}</div>
                  <div className="text-[8px]">rentals</div>
                </div>
              </div>
              <div className="text-[8E8E8E] text-[14px]">+ 500</div>
            </div>
          </div>

          <div className="sm:mt-[50px] mt-[30px] flex flex-col sm:flex-row gap-[15px] sm:gap-[30px]">
            <div className="font-bold text-[16px] sm:text-[20px]">How users do you have?</div>
            <div className="flex items-center gap-[16px] sm:mt-0 mt-[35px]">
              <div className="text-[8E8E8E] text-[14px]">1</div>
              <div ref={userSliderRef} className="relative">
                <Slider
                  styles={{
                    track: {
                      backgroundColor: '#FFF2F0'
                    },
                    active: {
                      backgroundColor: '#EA3323'
                    },
                    thumb: {
                      width: 20,
                      height: 20,
                      border: '5px solid #EA3323',
                    },
                    disabled: {
                      opacity: 0.5
                    }
                  }}
                  xmin={1}
                  xmax={500}
                  axis="x"
                  x={users.x}
                  onChange={({ x }) => {
                    setUsers(state => ({ ...state, x }))
                  }}
                />
                <div
                  className="mb-[8px] absolute bottom-full flex flex-col justify-center items-center transform -translate-x-1/2 text-white border border-[#F7ECEC] bg-[#EA3323] rounded-[4px] px-[12px] py-1"
                  style={computeUserLabelStyles()}
                >
                  <img className="absolute top-[31px]" src={redPolygon} alt=""/>
                  <div className="text-[10px] font-bold">{users.x}</div>
                  <div className="text-[8px]">users</div>
                </div>
              </div>
              <div className="text-[8E8E8E] text-[14px]">+ 500</div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col sm:flex-row gap-[11px] mt-[30px] sm:mt-[100px]">
          <div className="w-full sm:w-[25%] flex sm:block justify-between">
            <div className="hidden sm:block font-semibold text-[32px]">Pick your plans</div>
            {dropDownData?.data?.paymentFrequencies.map((option) => (
              <div key={option.id} className="w-full">
                <FormControlLabel
                  label={option.name}
                  labelPlacement="left"
                  control={
                    <Checkbox
                      icon={
                        <div className="w-5 h-5 border-[3px] border-[#56678942] rounded-full">
                          {' '}
                        </div>
                      }
                      checkedIcon={
                        <div className="flex justify-center items-center w-5 h-5 border-[3px] border-[#EA3323] rounded-full">
                          <div className="w-2 h-2 bg-[#EA3323] rounded-full"></div>
                        </div>
                      }
                      checked={option.id === plan}
                      onChange={() => setPlan(option.id)}
                      disableRipple
                    />
                  }
                />
                {option.id !== 'monthly' && (
                  <span className="inline-flex w-fit items-center font-semibold gap-[8px] px-[9px] py-[5px] rounded-[8px] text-[#EA3323] bg-[#FFF2F0] text-[16px]">
                    Save 30%
                  </span>
                )}
              </div>
            ))}
          </div>

          <div className="w-full sm:w-[75%] flex overflow-x-auto gap-[10px]">
            {
              plans.map((plan_item) => (
                <div key={plan_item.id} className={`flex flex-col flex-auto ${minPlan > plan_item.id && 'opacity-30'} gap-[30px] bg-[#f7ecec30] border-[2px] border-[#ea33234d] p-[15px] sm:p-[40px]`}>
                  <div>
                    <div className="text-[#8E8E8E] text-[14px]">FOR HOSTS</div>
                    <div className="text-[36px] font-bold whitespace-nowrap">{plan_item.name}</div>
                    <div className="text-[#00000099] text-[16px]">{plan_item.firstDescription}</div>
                    <div className="text-[#00000099] text-[16px] mt-[16px]">{plan_item.secondDescription}</div>
                  </div>

                  <div className="text-[28px] font-bold">
                    €{plan_item.id === 0 ? price.base : price.plus}
                    + €5/user
                    <span className="font-normal">{plan}</span>
                  </div>

                  <button
                    key={plan_item.id}
                    className={`whitespace-nowrap px-[30px] py-[15px] text-[16px] font-bold rounded-[16px]  ${minPlan > plan_item.id ? 'bg-[#8E8E8E] text-black' : 'bg-[#EA3323] hover:bg-[#d93021] text-white'}`}
                    onClick={() => setOpenDialog({ data: dropDownData?.data?.plans.find(plan => plan.name === plan_item.name) , dialogType: 'confirmPlan' })}
                  >
                    {`Choose ${plan_item.name}`}
                  </button>
                  <div className="block sm:hidden bg-[#EA3323] w-full h-[1px]"></div>
                  <div className="flex sm:hidden flex-col gap-[24px]">
                    {
                      includes.map((include) => (
                        <div className="flex items-center gap-[12px]">
                          {include.plans.find(include_plan => include_plan.id === plan_item.id) ? <img className="w-[20px] h-[20px]" src={checkedTick} alt=""/> : <img className="w-[20px] h-[20px]" src={uncheckedTick} alt=""/>}
                          <div className="font-[500] text-[14px] whitespace-nowrap">{include.name}</div>
                        </div>
                      ))}
                  </div>

                </div>
              ))
            }
          </div>
        </div>

        <div className="hidden sm:block text-[32px] w-full font-[600] mt-[30px] sm:mt-[80px] mb-[16px] sm:mb-[30px]">
          What's included?
        </div>

        <div className="hidden sm:flex items-start w-full gap-[11px]">
          <div className="w-[25%]">
            {
              includes.map((include) => (
                <div className="font-[500] px-[10px] py-[32px] text-[16px]">{include.name}</div>
              ))
            }
          </div>

          <div className="w-[75%] flex items-center gap-[10px]">
            {
              plans.map((plan) => (
                <div key={plan.id} className={`flex flex-col flex-1 ${minPlan > plan.id && 'opacity-30'} items-center bg-[#f7ecec30] border-[2px] border-[#ea33234d]`}>
                  {includes.map((include) => (
                    include.plans.find(include_plan => include_plan.id === plan.id) ?
                      (include.id === 14 ?
                          <div className="py-[33px] text-[24px] font-semibold text-[#EA3323]">{include.plans.find(include_plan => include_plan.id === plan.id).value}</div> :
                          <img className="py-[33px]" src={checkedTick} alt=""/>
                      ) : <img className="py-[33px]" src={uncheckedTick} alt=""/>
                  ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <ConfirmSubscriptionDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog({ data: null, dialogType: '' })}
        onConfirm={handleConfirmPlanSubscription}

      />
    </div>
  );
};
export default SubscriptionPage;