import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { getUserGroups, updateUserGroup } from 'apis/authentication/authentication';

import { MenuItem, Select, CircularProgress } from '@mui/material';

export const UserGroupSelector = () => {
  const navigate = useNavigate();
  const [selectedUserGroup, setSelectedUserGroup] = React.useState('');

  const { data: userGroupData, isLoading } = useQuery('userGroups', getUserGroups);

  const { mutate: updateUserGroupMutation } = useMutation((payload) => updateUserGroup(payload));

  const handleUserGroupSelection = (e) => {
    updateUserGroupMutation(e.target.value, {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          localStorage.setItem('userGroupId', data.data.selectedUserGroup);
          localStorage.setItem('userGroupId', data.data.selectedUserGroup);
          localStorage.setItem('selectedCurrencySymbol', data.data.selectedCurrencySymbol);
          localStorage.setItem(
            'userGroupName',
            userGroupData?.data.find(
              (userGroup) => userGroup.userGroupId === data.data.selectedUserGroup,
            )?.userGroupName,
          );
          setSelectedUserGroup(data.data.selectedUserGroup);
          navigate(0);
        }
      },
    });
  };

  return (
    <Select
      sx={{
        borderRadius: '8px',
        fontSize: 14,
        width: 'auto',
        height: '44px',
        background: '#fff',
        '&& fieldset': {
          border: '1px solid transparent',
        },
      }}
      disableUnderline
      displayEmpty
      placeholder="User Group"
      value={selectedUserGroup}
      onChange={handleUserGroupSelection}
      renderValue={(value) => {
        if (!value || !value.length) return localStorage.getItem('userGroupName') || "Select a user group"; // Provide a fallback display value
        return userGroupData?.data.find((userGroup) => userGroup.userGroupId === value)?.userGroupName;
      }}

    >
      {isLoading && (
        <div className="flex items-center justify-center">
          <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
        </div>
      )}
      {userGroupData?.data?.map(({ userGroupId, userGroupName }) => (
        <MenuItem key={userGroupId} value={userGroupId}>
          {userGroupName}
        </MenuItem>
      ))}
    </Select>
  );
};
