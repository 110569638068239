import React from 'react';
import { NavLink, Outlet, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';

import { DeleteButton, DeleteDialog, EditButton } from 'components';
import { LeadDialog } from 'pages/leads/leadDialog';

import { Skeleton } from '@mui/material';
import greaterIcon from 'assets/greaterIcon.svg';
import redHomeIcon from 'assets/redHome.svg';
import redLocationIcon from 'assets/redLocation.svg';

const LeadOverview = ({ apiFunctions }) => {
  const navigate = useNavigate();
  const { leadId } = useParams();
  const navItems = [{ name: 'details', route: 'details' }];
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data, isLoading } = useCustomQuery(apiFunctions.get.func, apiFunctions.get.key, leadId);

  const { data: leadsDropdown } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    leadId,
  );

  const { mutateAsync: deleteMutation, isLoading: deleteLoading } = useCustomMutation(
    apiFunctions.delete.func,
    apiFunctions.delete.key,
  );

  const handleLeadDelete = async () => {
    const response = await deleteMutation([leadId]);

    if (response.statusCode === 200) {
      setOpenDialog(null);
      navigate('/leads');
    }
  };

  const { summary, generalInfo } = data?.data || {};

  const leadContext = {
    leadDetails: data?.data,
    isLoading: isLoading,
    dropdownData: leadsDropdown?.data,
    apiFunctions: apiFunctions,
  };

  return (
    <div className="mt-5">
      <div className="flex gap-2 text-[#EA3323] font-medium text-md">
        <NavLink
          className="hover:cursor-pointer"
          to={`/leads`}
          key="leadTitle"
          activeClassName="active"
        >
          {t('leads')}
        </NavLink>
        <img alt="" src={greaterIcon} />
        <div>{summary?.contact}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className=" bg-[#FFF2F0] rounded-t-2xl text-[10px] sm:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="flex items-center gap-1 bg-white p-3 font-semibold rounded-3xl">
              <img alt="home-icon" src={redHomeIcon} />
              <span>{summary.contact}</span>
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl text-[#008E0E]">
              {summary.leadSource}
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img alt="" src={redLocationIcon} />
              <div>{summary?.city}</div>
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl">
              {summary.contactRecordType +
                ' / ' +
                summary.propertyUsage +
                ' / ' +
                generalInfo.sqmRange +
                ' SQM'}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-5">
        <div className="flex items-center justify-between">
          <div className="flex gap-3 ">
            {navItems.map((item) => (
              <NavLink
                to={`/lead-overview/${leadId}/${item.route}`}
                key={item.name}
                activeClassName="active"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                    : 'border border-[#C9C9C9] p-2 rounded-lg'
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          <div className="flex gap-3">
            <EditButton
              onClick={() => setOpenDialog({ data: [leadId], dialogType: 'editLead' })}
              disabled={isLoading}
            />

            <DeleteButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <DeleteDialog
        openDialog={openDialog}
        name="listing"
        onClose={() => setOpenDialog(null)}
        onDelete={handleLeadDelete}
        isLoading={deleteLoading}
      />

      <LeadDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <Outlet context={leadContext} />
    </div>
  );
};

export default LeadOverview;
