import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
let isRefreshing = false;

const axiosInstance = axios.create({ baseURL });
const refreshTokenInstance = axios.create({ baseURL });

const getStringAfterEu = (url) => {
  let urlParts = url.split('/');
  let startIndex = 0;

  if (urlParts[0].startsWith('https://')) {
    startIndex = 2;
  } else if (urlParts[0].startsWith('http://')) {
    startIndex = 1;
  }

  const euIndex = urlParts.indexOf('app.locus.eu');

  if (euIndex !== -1 && euIndex < urlParts.length - 1 && urlParts[euIndex + 1] !== '') {
    return urlParts.slice(euIndex + startIndex + 1).join('/');
  } else {
    return urlParts[urlParts.length - 1];
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    // * if an invalid permission happens redirect to the parent page
    if (response.data.statusCode === 404) {
      let route = response.data.app.split('_')[0];
      if (route === 'property') {
        localStorage.setItem('error-message', response.data.message);
        window.location = `/properties`;
      } else if (route === 'offer') {
        localStorage.setItem('error-message', response.data.message);
        window.location = `/listings`;
      } else if (route === 'payment') {
        localStorage.setItem('error-message', response.data.message);
        window.location = `/transactions`;
      } else if (route === 'api') {
        localStorage.setItem('error-message', response.data.message);
        window.location = `/`;
      } else {
        localStorage.setItem('error-message', response.data.message);
        window.location = `/${route}s`;
      }
    } else if (response.data.statusCode === 402 || response.data.statusCode === 500) {
      localStorage.setItem('error-message', response.data.message);
      window.location = '/';
    } else if (response.data.statusCode === 429) {
      localStorage.clear();
      window.location = '/login';
    } else {
      return response.data;
    }
  },

  async (error) => {
    // * if user is unauthorized redirect to login page
    if (error.response.data.statusCode === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        return refreshTokenInstance
          .post(`/api/v1/auth-app/auth/refresh-token`)
          .then((res) => {
            localStorage.setItem('access-token', res.data.data.accessToken);
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            localStorage.clear();
            //get the current url
            let currentUrl = getStringAfterEu(window.location.href);
            if (currentUrl === 'login' || currentUrl === 'register') window.location = '/login';
            else window.location = '/login?redirect=' + currentUrl;
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
    }
  },
);

axiosInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem('access-token');
  config.headers = Object.assign(
    {
      Authorization: `Bearer ${token}`,
    },
    config.headers,
  );
  return config;
});

refreshTokenInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem('access-token');
  config.headers = Object.assign({ Authorization: `Bearer ${token}` }, config.headers);
  return config;
});

export default axiosInstance;
