import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

/*
 * |-----------------------------------------------------------------------
 * | Offers API
 * | getOffers - getOffer - addOffer - updateOffer - deleteOffer
 * |-----------------------------------------------------------------------
 */

export const getOffers = (sortParams, searchKey, page) => {
  let sort = sortParams.sort === 'desc' ? '-'.concat(sortParams.field) : sortParams.field;
  return axios.get(
    `/api/v1/offer-app/offers/${getUserGroupId()}?sort=${sort}&filter[search]=${searchKey}&page=${page}`,
  );
};

export const getOffer = (offerId) => {
  return axios.get(`/api/v1/offer-app/offer/show/${offerId}/${getUserGroupId()}`);
};

export const addOffer = (payload) => {
  return axios.post(`/api/v1/offer-app/offer/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateOffer = (payload) => {
  return axios.post(`/api/v1/offer-app/offer/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteOffer = (offersIds) => {
  return axios.post(`/api/v1/offer-app/offer/destroy`, {
    offer_ids: offersIds,
    user_group_id: getUserGroupId(),
  });
};

export const updateOfferStatus = (payload) => {
  return axios.post(`/api/v1/offer-app/offer/status/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

// * offer dropdown API
export const getOfferDropdownValues = () =>
  axios.get(`/api/v1/offer-app/offer/dropdown-values/${getUserGroupId()}`);
