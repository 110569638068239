import {ErrorMessage, Field, useFormikContext} from "formik";
import PasswordStrengthBar from "react-password-strength-bar";
import Checkbox from "@mui/material/Checkbox";

import React, {useState} from "react";
import PhoneInput from "react-phone-input-2";
import {useTranslation} from "react-i18next";

export const AccountInfo = ({ data }) => {
  const { values, touched, errors, handleChange } = useFormikContext();

  const [checkedTerms, setCheckedTerms] = useState(false);
  const { t } = useTranslation();
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const geolocationAPI = navigator.geolocation;
  const [countryCode, setCountryCode] = useState(null);
  const [error, setError] = useState(null);

  const getUserCoordinates = () => {
    if (!geolocationAPI) {
      setError('Geolocation API is not available in your browser!');
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setLat(coords.latitude);
          setLong(coords.longitude);
        },
        (error) => {
          setError('Something went wrong getting your position!');
        },
      );
    }
  };

  //function that gets the user country from long and lat
  const getUserCountry = async () => {
    const response = await fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=en`,
    );
    const data = await response.json();
    return data.countryCode;
  };

  //run getUserCoordinates on page load
  React.useEffect(() => {
    getUserCoordinates();
    getUserCountry().then((r) => setCountryCode(r));
  }, []);

  // const handleGoogleAuthResponseOnSuccess = (response) => {
  //   mutate(response, {
  //     onSuccess: (data) => {
  //       navigate({ pathname: '/login' });
  //     },
  //   });
  // };

  const handlGoogleAuthResponseOnFailure = (response) => {
    // TODO: handle google login failure
  };

  return (
    <div className="sm:w-8/12 flex flex-col">
      <p className="font-semibold text-center text-3xl">{t('sign_up')}</p>

      <div className="flex mt-4">
        <p className="text-lg font-light">{t('sign_up_statement')}</p>
        <a
          href="/login"
          className="ml-1 text-lg underline text-[#FF0000] hover:cursor-pointer"
        >
          {t('login')}
        </a>
      </div>

      {data && (
        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          <span className="font-medium"> {data?.message}</span>
        </div>
      )}

      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col w-full">
          <Field
            name="firstName"
            placeholder="First Name"
            className={`focus:outline-none pl-4 border border-solid ${
              touched.firstName && errors.firstName
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="firstName"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
        <div className="flex flex-col w-full">
          <Field
            name="lastName"
            placeholder="Last Name"
            className={`focus:outline-none pl-4 border border-solid ${
              touched.lastName && errors.lastName
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="lastName"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
      </div>

      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col w-full">
          <Field
            name="email"
            placeholder="Email"
            className={`focus:outline-none pl-4 border border-solid ${
              touched.email && errors.email ? 'border-[#EA3323]' : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="email"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
        <PhoneInput
          //add country code state to the phone input country prop
          country={countryCode?.toString().toLowerCase()}
          specialLabel=""
          value={values.mobile}
          onChange={(phoneNumber, country, e) => {
            handleChange(e);
          }}
          inputStyle={{
            width: '100%',
            height: '100%',
            background: '#fff',
            borderRadius: '8px',
          }}
          containerStyle={{
            borderColor: touched.mobile && Boolean(errors.mobile) && '#EA3323',
            height: '48px',
          }}
          inputProps={{ name: 'mobile' }}
        />

      </div>

      <div className="flex sm:flex-row flex-col gap-4 mt-4">
        <div className="flex flex-col w-full">
          <Field
            type="password"
            name="password"
            placeholder="Password"
            className={`focus:outline-none pl-4 border border-solid ${
              touched.password && errors.password
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="password"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
          {Boolean(values.password.length) && (
            <PasswordStrengthBar password={values.password} />
          )}
        </div>
        <div className="flex flex-col w-full">
          <Field
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className={`focus:outline-none pl-4 border border-solid ${
              touched.confirmPassword && errors.confirmPassword
                ? 'border-[#EA3323]'
                : 'border-[#00000033]'
            } rounded-lg h-12`}
          />
          <ErrorMessage
            name="confirmPassword"
            render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
          />
        </div>
      </div>

      {/*<div className="flex items-center mt-3">*/}
      {/*  <Checkbox*/}
      {/*    checked={checkedTerms}*/}
      {/*    onChange={(e) => setCheckedTerms(e.target.checked)}*/}
      {/*  />*/}
      {/*  <div>I agree to the</div>*/}
      {/*  <div className="font-bold hover:cursor-pointer text-[#FF0000] ml-1.5">*/}
      {/*    Terms of use*/}
      {/*  </div>*/}
      {/*  <div className="ml-1.5">and</div>*/}
      {/*  <div className="font-bold hover:cursor-pointer text-[#FF0000] ml-1.5">Policy</div>*/}
      {/*</div>*/}
    </div>
  );
}