import {
  getLeads,
  getLead,
  addLead,
  updateLead,
  deleteLead,
  addPotentialListing,
  addFollowUp,
  updateFollowUp,
  deleteFollowUp,
  exportLeads,
  getLeadsDropdownData,
} from 'apis/leads/leads';
import { addressAutoComplete } from 'apis/address/address';

export const leadsApiFunctions = {
  getAll: { func: getLeads, key: 'leads' },
  get: { func: getLead, key: 'leadDetails' },
  add: { func: addLead, key: 'leads' },
  edit: { func: updateLead, key: 'leadDetails' },
  delete: { func: deleteLead, key: 'leads' },
  addPotentialListing: { func: addPotentialListing, key: 'leadDetails' },
  addFollowUp: { func: addFollowUp, key: 'leadDetails' },
  editFollowUp: { func: updateFollowUp, key: 'leadDetails' },
  deleteFollowUp: { func: deleteFollowUp, key: 'leadDetails' },
  export: { func: exportLeads, key: 'leads' },
  getDropdowns: { func: getLeadsDropdownData, key: 'leadsDropdown' },
  getAddress: { func: addressAutoComplete, key: 'locationAddress' },
};
