import {
  getlistings,
  getListing,
  addListing,
  updateListing,
  deleteListing,
  getListingOffers,
  getListingRelated,
  getListingAttachments,
  addListingAttachment,
  updateListingAttachment,
  deleteListingAttachment,
  getListingDropdownValues,
  exportListings,
} from 'apis/listings/listings';

import {
  addOffer,
  updateOffer,
  deleteOffer,
  updateOfferStatus,
  getOfferDropdownValues,
} from 'apis/offers/offers';

export const listingApiFunctions = {
  getAll: { func: getlistings, key: 'listings' },
  get: { func: getListing, key: 'listingDetails' },
  add: { func: addListing, key: 'listings' },
  edit: { func: updateListing, key: 'listingDetails' },
  delete: { func: deleteListing, key: 'listings' },
  export: { func: exportListings, key: 'listings' },
  getOffers: { func: getListingOffers, key: 'listingOffers' },
  addOffer: { func: addOffer, key: ['listingOffers', 'listingDetails'] },
  editOffer: { func: updateOffer, key: ['listingOffers', 'listingDetails'] },
  deleteOffer: { func: deleteOffer, key: ['listingOffers', 'listingDetails'] },
  updateOfferStatus: { func: updateOfferStatus, key: ['listingOffers', 'listingDetails'] },
  getRelated: { func: getListingRelated, key: 'listingRelated' },
  getAttachments: { func: getListingAttachments, key: 'listingAttachments' },
  addAttachment: { func: addListingAttachment, key: 'listingAttachments' },
  editAttachment: { func: updateListingAttachment, key: 'listingAttachments' },
  deleteAttachment: { func: deleteListingAttachment, key: 'listingAttachments' },
  getDropdowns: { func: getListingDropdownValues, key: 'listingDropdown' },
  getOfferDropdown: { func: getOfferDropdownValues, key: 'offerDropdown' },
};
