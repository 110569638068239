export const profileInitialState = (data = null) => {
  const { billingAddress, contactAddress, summary, generalInfo } = data || {};

  return {
    userGroupId: data?.userGroupId,
    contactId: data?.contactId,
    salutation: generalInfo?.salutationId || '',
    firstName: summary?.firstName || '',
    lastName: summary?.lastName || '',
    nationality: generalInfo?.countryId || '',
    email: summary?.email || '',
    phone: summary?.phone || '',
    // billing address state
    billingAddress: {
      addressId: billingAddress?.addressId,
      building: billingAddress?.building || '',
      floor: billingAddress?.floor || '',
      zipCode: billingAddress?.zipCode || '',
      placeId: billingAddress?.placeId || '',
      description: billingAddress?.description || null,
      apartmentNumber: billingAddress?.apartmentNumber || '',
    },
    // residency address state
    residencyAddress: {
      addressId: contactAddress?.addressId,
      building: contactAddress?.building || '',
      floor: contactAddress?.floor || '',
      zipCode: contactAddress?.zipCode || '',
      placeId: contactAddress?.placeId || '',
      description: contactAddress?.description || null,
      apartmentNumber: contactAddress?.apartmentNumber || '',
    },
  };
};
