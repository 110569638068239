import React from 'react';
import { StyledDialog } from 'components';
import { DialogContent, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const DeleteDialog = ({ openDialog, onClose, onDelete, isLoading, name }) => {
  const { t } = useTranslation();
  return (
    <StyledDialog
      fullWidth
      width="328px"
      open={openDialog?.dialogType === 'delete'}
      onClose={onClose}
    >
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div className="text-xs text-[#EA3323] font-semibold">{t('delete')}</div>
          <div className="text-2xl text-black font-bold">
            {t('are_you_sure')} {t(name)}?
          </div>
        </div>
      </DialogContent>

      <div className="grid justify-items-center m-auto w-[90%] sm:w-[60%]">
        <button
          className="min-w-[136px] min-h-[42px] bg-[#EA3323] rounded-lg text-white font-semibold"
          onClick={onDelete}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            t('confirm')
          )}
        </button>
      </div>
    </StyledDialog>
  );
};
