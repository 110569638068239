import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage, useFormikContext } from 'formik';

import { Alert } from '@mui/material';
import { LocusContext } from 'hooks/useLocusContext';
import {useTranslation} from "react-i18next";

export const Comments = () => {
  const { APIResponse } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="text-3xl font-bold">{t('property_description')}</div>
      {APIResponse.message && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          {APIResponse.message}
        </Alert>
      )}

      <div className="flex flex-col gap-1 p-1">
        <div className="text-sm">{t('description')}</div>
        <Field
          as={StyledTextArea}
          name="propertyDescription"
          placeholder={t('description')}
          rows="6"
          cols="50"
          error={touched.propertyDescription && errors.propertyDescription}
        />
        <ErrorMessage component={ValidationError} name="propertyDescription" />
      </div>
    </div>
  );
};

const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 16px;
  padding: 8px;
  box-shadow: ${({ error }) => error && '0px 0px 0px 3px rgba(234,51,35,0.2)'};
  border: ${({ error }) => (error ? '1px solid #EA3323' : '1px solid #C9C9C9')};
  transition: 0.5s;
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(43, 93, 220, 0.2);
    border: 1px solid #2b5ddc;
    outline: none;
  }
`;

const ValidationError = styled.div`
  color: #ea3323;
`;
