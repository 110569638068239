import * as yup from "yup";

export const transactionSchema = [
  yup.object().shape({
    contactId: yup
      .string()
      .typeError('Contact field is required')
      .required('Contact field is required'),
    transactionDate: yup
      .date()
      .typeError('Transaction Date from field is required')
      .required('Transaction Date from field is required'),
    dueDate: yup
      .string()
      .typeError('Due Date field is required')
      .required('Due Date field is required'),
    applyVat: yup
      .string()
      .typeError('Apply Vat field is required')
      .required('Apply Vat field is required'),
    glAccountId: yup
      .string()
      .typeError('GL Account field is required')
      .required('Gl Account is required'),
    description: yup
      .string()
      .typeError('Description field is required')
      .required('Description field is required'),
    accountingReference: yup
      .string()
      .typeError('Accounting Reference field is required')
  }),
  yup.object().shape({

  }),
  ]
