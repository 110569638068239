import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';

import { useDebounce } from 'hooks/useDebounce';
import { addressAutoComplete } from 'apis/address/address';
import { LocusContext } from 'hooks/useLocusContext';

import { StyledInput } from 'components';
import { CircularProgress } from '@mui/material';

export const BillingAddress = () => {
  const { t } = useTranslation();

  const { dialogType } = React.useContext(LocusContext);
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  const [searchValue, setSearchValue] = React.useState('');
  const [valueChosen, setValueChosen] = React.useState({ value: '', isChosen: false });
  const debounceSearch = useDebounce(searchValue, 750);

  const { data, isLoading: searchLoading } = useQuery(
    ['addressAutoComplete', debounceSearch],
    () => addressAutoComplete(debounceSearch),
    {
      enabled: !!searchValue && !valueChosen.isChosen,
    },
  );

  const isError = (value) => {
    if (errors.billingAddress?.[value]) return <span className="text-[#EA3323]">*</span>;
  };

  React.useEffect(() => {
    if (dialogType === 'editContact') {
      setSearchValue(values.billingAddress.description);
      setValueChosen({ value: values.billingAddress.description, isChosen: true });
    }
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-3xl font-bold">{t('contact_billing_address')}</div>
      <div className="text-2xl font-medium">{t('billing_address')}</div>

      <div className="mt-3 p-1">
        <div className="text-sm pb-1">
          {t('location')} {isError('placeId')}
        </div>
        <StyledInput
          name="billingAddress.placeId"
          placeholder={t('location')}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            //checks if the value is being deleted
            if (valueChosen.value.length > e.target.value.length) {
              setValueChosen({ value: '', isChosen: false });
              setSearchValue(e.target.value);
            }
          }}
          error={touched.billingAddress?.placeId && errors.billingAddress?.placeId}
        />
        <ErrorMessage name="billingAddress.placeId" component={ValidationError} />

        {searchValue && !valueChosen.isChosen && (
          <div className="bg-white flex flex-col gap-2 max-h-[200px] shadow-lg overflow-y-auto rounded-lg p-1 w-full z-10">
            {searchLoading ? (
              <div className="flex justify-center items-center p-2">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
              </div>
            ) : (
              data?.data?.map((searchItem) => (
                <div
                  className="flex flex-col gap-1 justify-center rounded-lg py-3 px-4 hover:bg-[#EA3323] hover:text-white hover:cursor-pointer"
                  onClick={() => {
                    setFieldValue('billingAddress.placeId', searchItem.id);
                    setFieldValue('billingAddress.description', searchItem.name);
                    setSearchValue(searchItem.name);
                    setValueChosen({ value: searchItem.name, isChosen: true });
                  }}
                >
                  <div className="text-sm font-semibold">{searchItem.name}</div>
                </div>
              ))
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="w-full">
          <div className="text-sm pb-1">
            {t('apartment_number')} {isError('apartmentNumber')}
          </div>
          <StyledInput
            name="billingAddress.apartmentNumber"
            value={values.billingAddress.apartmentNumber}
            onChange={handleChange}
            placeholder="Apartment Number"
            error={
              touched.billingAddress?.apartmentNumber && errors.billingAddress?.apartmentNumber
            }
          />
          <ErrorMessage name="billingAddress.apartmentNumber" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">
            {t('floor')} {isError('floor')}
          </div>
          <StyledInput
            name="billingAddress.floor"
            value={values.billingAddress.floor}
            onChange={handleChange}
            placeholder="Floor"
            error={touched.billingAddress?.floor && errors.billingAddress?.floor}
          />
          <ErrorMessage name="billingAddress.floor" component={ValidationError} />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="w-full">
          <div className="text-sm pb-1">
            {t('zip_code')} {isError('zip_code')}
          </div>

          <StyledInput
            name="billingAddress.zipCode"
            value={values.billingAddress.zipCode}
            onChange={handleChange}
            placeholder={t('zip_code')}
            error={touched.billingAddress?.zipCode && errors.billingAddress?.zipCode}
          />
          <ErrorMessage name="billingAddress.zipCode" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">
            {t('building')} {isError('building')}
          </div>

          <StyledInput
            name="billingAddress.building"
            value={values.billingAddress.building}
            onChange={handleChange}
            placeholder="Building"
            error={touched.billingAddress?.building && errors.billingAddress?.building}
          />
          <ErrorMessage name="billingAddress.building" component={ValidationError} />
        </div>
      </div>
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
