import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

export const register = (payload) => {
  /**
   * @request_body
   * * user_type_id: (1) => landlord, (2) => tenant
   * * registration_method_id: (1) => email, (2) => mobile, (4) => social media
   * * social_media_type: used if registration method is social media.
   * * contact_record_type_id: (1) => corporate, (2) => individual
   * * contact_type_id: (1) => tenant, (2) => lead
   * ? contact_legal_type_id:
   * ? record_type_id:
   * * social_token: used when registering with social media
   * * social_account_id: used when registering with social media, (google) => googleId
   * * social_driver: () => google | linkedIn
   */

  //  * LOCUS registration data
  const { firstName, lastName, email, mobile, password, confirmPassword } = payload || {};
  const { companyName, vatNumber, placeId, description, floor, building, apartmentNumber, zipCode, countryCode } = payload || {};
  const { token } = payload || {};

  // * Social registration data (Google)

  return axios.post(`/api/v1/auth-app/auth/user/register`, {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    password_confirmation: confirmPassword,
    phone: mobile,
    company: companyName,
    vat_number: vatNumber,
    place_id: placeId,
    place_description: description,
    floor: floor,
    building: building,
    zip_code: zipCode,
    country_code: countryCode,
    apartment_number: apartmentNumber,
    payment_id: token,
    reference_id: 'aaaaaa',
  });
};

export const login = (payload) => {
  /**
   * @request_body
   * * registration_method_id: (1) => email, (2) => mobile, (4) => social media (google, linkedin)
   * * user_type_id: (1) => landlord, (2) => tenant
   */

  const { email, password } = payload || {};

  return axios.post(`/api/v1/auth-app/auth/user/login`, {
    email: email,
    password: password,
    mobile: '',
    registration_method_id: payload.googleId ? '4' : '1',
    user_type_id: '1',
    social_token: payload.googleId && payload.tokenObj.access_token,
    social_driver: 'google',
  });
};

export const logout = () => axios.post(`/api/v1/auth-app/auth/user/logout`);

export const forgotPassword = (userEmail) => {
  /**
   * @request_body
   * * username: user's email
   * * registration_method_id: (1) => email, (2) => mobile, (4) => social media
   * * validation_purpose_id: (1) => verify account, (2) => forgot password
   * * user_type_id: (1) => landlord, (2) => tenant
   */
  return axios.post(`/api/v1/auth-app/auth/otp/generate`, {
    username: userEmail,
    registration_method_id: '1',
    validation_purpose_id: '2',
    user_type_id: '1',
  });
};

export const resetPassword = (payload) => {
  /**
   * @request_body
   * * code: OTP code
   * * username: user's email
   * * validation_purpose_id: (1) => verify account, (2) => forgot password
   * * registration_method_id: (1) => email, (2) => mobile, (4) => social media
   * * user_type_id: (1) => landlord, (2) => tenant
   */
  return axios.post(`/api/v1/auth-app/auth/password/reset`, {
    code: payload.otp,
    username: payload.email,
    password: payload.newPassword,
    password_confirmation: payload.confirmPassword,
    validation_purpose_id: '2',
    registration_method_id: '1',
    user_type_id: '1',
  });
};

export const verifyOTP = (payload) => {
  return axios.post(`/api/v1/auth-app/auth/otp/verify`, {
    code: payload.code,
    registration_method_id: '1',
    validation_purpose_id: '1',
    user_type_id: '1',
    username: payload.username,
  });
};

export const verify2FA = (payload) => {
  return axios.post(`/api/v2/auth-app/auth/2fa/verify`, payload);
};

export const getProfile = (userTypeId) => {
  return axios.get(`/api/v1/auth-app/auth/user/profile/${userTypeId}`);
};

export const getUserGroups = () => axios.get(`/api/v1/auth-app/auth/user/user-groups`);

export const updateUserGroup = (payload) => {
  return axios.put(`/api/v1/auth-app/auth/user/update-selected-user-group/${payload}`, {
    user_group_id: payload,
  });
};

export const refreshToken = () => {
  return axios.post(`/api/v1/auth-app/auth/refresh-token`);
};

export const getSelectedUserGroup = () =>
  axios.get(`/api/v2/auth-app/auth/user/selected-user-group`);

export const heathCheck = () => axios.get(`/api/v2/auth-app/auth/account/health-check`);

export const localeDropDown = () => axios.get(`/api/v2/auth-app/auth/user/locale`);

export const updateLocale = (localeId) => {
  return axios.post(`/api/v2/auth-app/auth/user/update/locale`, {
    locale_id: localeId,
  });
};
export const addressAutoCompleteAuth = (searchKey) =>
  axios.get(
    `/api/v2/auth-app/auth/address/google-maps/auto-complete?input=${searchKey}`,
  );

export const loginSubscription = (payload) =>
  axios.post(`/api/v2/auth-app/auth/user/customer-subscription/create`,payload);

export const subscriptionDropdown = () =>
  axios.get(`/api/v2/auth-app/auth/subscriptions/dropdown-values`);
