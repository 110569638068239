import * as yup from 'yup';

export const glAccountSchema = yup.object().shape({
  title: yup.string().required('title field is required'),
  code: yup.string().required('code field is required'),
  type: yup.string().required('type field is required'),
  subType: yup.string().required('sub type field is required'),
  enableVAT: yup.string().required('vat field is required'),
  VATPercentage: yup.string().required('vat percentage field is required'),
  balanceSide: yup.string().required('balance side field is required'),
  description: yup.string().required('description field is required'),
});
