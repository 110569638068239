import React from 'react';
import { NavLink, useParams, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { TabName } from 'utils/utils';
import { propertyOvervoewNavItems } from 'utils/constants';

import { PropertyDialog } from 'pages/properties/propertyDialog';
import { ClosingDialog } from 'pages/closings/closingDialog';
import { ListingDialog } from 'pages/listings/listingDialog';
import { NotifyTenantDialog } from './notifyTenantDialog';
import { EditUnitPropertyDialog } from 'pages/properties/editUnitPropertyDialog';

import { DeleteButton, EditButton, Notifier } from 'components';
import { DeleteDialog } from 'components/dialogs';
import { Skeleton } from '@mui/material';
import listingButtonIcon from 'assets/listingButtonIcon.svg';
import greaterIcon from 'assets/greaterIcon.svg';
import redLocationIcon from 'assets/redLocation.svg';
import notify from 'assets/notifyy.svg';

const PropertyOverview = ({ apiFunctions }) => {
  TabName('Properties - Overview');

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { propertyId } = useParams();
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data, isLoading } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    propertyId,
    {
      onSuccess: (data) => {
        if (location.state && location.state.directEdit)
          setOpenDialog({ data: data.data, dialogType: 'editProperty' });
      },
    },
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handlePropertyDelete = async () => {
    const response = await deleteMutation([propertyId]);

    if (response.statusCode === 200) {
      setOpenDialog(null);
      navigate('/properties');
    }
  };

  const { summary, address } = data?.data || {};

  const propertyContext = {
    isLoading: isLoading,
    propertyDetails: data?.data,
    apiFunctions: apiFunctions,
  };

  return (
    <div className="mt-5">
      <Notifier data={mutationResponse} />

      <div className="flex gap-2 text-[#EA3323] font-medium sm:text-md text-sm">
        <NavLink
          className="hover:cursor-pointer"
          to={`/properties`}
          key="PropertyTitle"
          activeClassName="active"
        >
          {t('properties')}
        </NavLink>

        <img alt="" src={greaterIcon} />
        <div>{summary?.property}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-row flex-wrap sm:flex-nowrap justify-between gap-2 h-full sm:h-[85px] bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] items-center mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="bg-white p-3 font-semibold rounded-3xl">{summary.property}</div>

            <div className="bg-white p-3 font-semibold rounded-3xl">{summary.type}</div>

            <div className="bg-white p-3 font-semibold rounded-3xl text-[#008E0E]">
              {summary.status}
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img alt="" src={redLocationIcon} />
              <div>{address.description}</div>
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl">
              {summary.recordType + ' / ' + summary.grossSqm + ' SQM'}
            </div>
          </div>

          <button
            onClick={() =>
              setOpenDialog({ data: { propertyId: propertyId }, dialogType: 'notifyTenant' })
            }
            className="flex gap-1 p-2 items-center justify-center sm:text-sm text-xs font-semibold rounded-lg bg-white text-[#EA3323]"
          >
            <img alt="" src={notify} />
            Tenant
          </button>
        </div>
      )}

      <div className="bg-white p-3">
        <div className="flex flex-col sm:flex-row sm:items-center items-start gap-2 justify-between">
          <div className="flex w-[80vw] sm:w-auto overflow-x-auto whitespace-nowrap sm:gap-3 gap-2 text-sm sm:text-md">
            {propertyOvervoewNavItems.map((item) => (
              <NavLink
                to={`/property-overview/${propertyId}/${item.route}`}
                key={item.name}
                activeClassName="active"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg font-[500]'
                    : 'border border-[#C9C9C9] p-2 rounded-lg font-[500] text-[#5A5A5A]'
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          <div className="flex gap-3">
            {summary?.recordType !== 'Building' && (
              <div className="flex gap-3">
                <button
                  onClick={() => setOpenDialog({ data: null, dialogType: 'addListing' })}
                  className="flex gap-1 p-2 items-center justify-center rounded-lg bg-[#FFF2F0] text-[#EA3323]"
                >
                  <img alt="" src={listingButtonIcon} />
                  {t('listing')}
                </button>

                <button
                  onClick={() =>
                    setOpenDialog({
                      data: { propertyDetails: data?.data },
                      dialogType: 'addNewPropertyClosing',
                    })
                  }
                  className="flex gap-1 p-2 items-center justify-center rounded-lg bg-[#FFF2F0] text-[#EA3323]"
                >
                  <img alt="" src={listingButtonIcon} />
                  {t('closing')}
                </button>
              </div>
            )}

            {window.location.pathname.includes('/details') && (
              <div className="flex gap-3">
                <EditButton
                  onClick={() =>
                    setOpenDialog({
                      data: data?.data,
                      dialogType:
                        data?.data?.summary?.recordTypeId === '1'
                          ? 'editUnitProperty'
                          : 'editProperty',
                    })
                  }
                  disabled={isLoading}
                />

                <DeleteButton
                  onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                  disabled={isLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteDialog
        openDialog={openDialog}
        name="property"
        onClose={() => setOpenDialog(null)}
        onDelete={handlePropertyDelete}
        isLoading={deleteLoading}
      />

      <ListingDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />
      <PropertyDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />

      <EditUnitPropertyDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />

      <ClosingDialog openDialog={openDialog} onClose={() => setOpenDialog(null)} />

      <NotifyTenantDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <Outlet context={propertyContext} />
    </div>
  );
};

export default PropertyOverview;
