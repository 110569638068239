import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getChartGrid } from 'apis/dashboard/dashboard';

import CircularProgress from '@mui/material/CircularProgress';
import leftArrowIcon from 'assets/leftArrow.svg';

export const RentYearlyBooking = ({ selectedYear, apiFunctions }) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const { t } = useTranslation();

  const [page, setPage] = React.useState(1);
  const [year, setYear] = React.useState('');

  const { data, isLoading } = useQuery(['yearlyBooking', year, page], () =>
    getChartGrid(year, page),
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    if (selectedYear) setYear(selectedYear);
  }, [selectedYear]);

  const generateRandomColor = () => {
    const randomColors = ['#C0B9DD', '#97B9EE', '#F58F8F', '#C4F1BE', '#75C9C8'];
    const randomColor = randomColors[Math.floor(Math.random() * randomColors.length)];
    return randomColor.toString();
  };

  const getMonthPlacement = (startMonth) => {
    let index = months
      .map((e) => {
        return e;
      })
      .indexOf(startMonth);
    let placementValue = index * 150;
    return placementValue.toString();
  };

  const changePage = (direction) => {
    if (direction === 'increase') {
      // * if selected month is the last month
      if (page !== data?.pagination.totalPages) {
        return setPage(page + 1);
      }
    } else {
      // * if selected month is the first month
      if (page !== 1) {
        return setPage(page - 1);
      }
    }
  };
  // Added: Handling loading state
  if (isLoading) {
    return <div className="flex justify-center items-center"><CircularProgress /></div>;
  }

  // Added: Handling empty data
  if (!data?.data?.length) {
    return <div className="text-center">No bookings available for the selected year.</div>;
  }

  return (

    <div className="mt-8">
      <div className="font-bold text-xl">{t('rent_yearly_booking')}</div>

      <div className="grid grid-cols-1 gap-4 lg:hidden bg-white rounded-t-2xl p-4 mt-4 overflow-scroll h-[500px] shadow-lg">
        {data?.data.map((closing) => (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="font-bold">{closing.propertyName}</div>
              <p
                onClick={() => navigate(`/closing-overview/${closing.closingId}/details`)}
                className="text-[#ff0000]"
              >
                Go to property
              </p>
            </div>

            <RentDurationBox randomBackground={generateRandomColor()} key={closing.closingId}>
              <div className="font-bold">{closing.duration}</div>
              <div>
                {closing.contractStart} | {closing.contractEnd}
              </div>
            </RentDurationBox>
          </div>
        ))}
      </div>

      <div className="hidden lg:flex items-center bg-white rounded-t-2xl p-4 mt-4 shadow-lg">
        {isLoading ? (
          <div className="flex items-center m-auto justify-center p-8">
            <CircularProgress color="error" size={25} />
          </div>
        ) : (
          <>
            <div className="flex gap-2 flex-col">
              <div className="h-16"></div>
              {data.data.map((property, index) => (
                <div className="w-32 h-16 font-bold" key={index}>
                  {property.propertyName}
                </div>
              ))}
            </div>

            <div className="flex flex-col w-full whitespace-nowrap overflow-scroll">
              <div>
                {months.map((month) => (
                  <MonthCell key={month}>{month}</MonthCell>
                ))}
              </div>

              {data.data.map((closing) => (
                <div
                  className="relative hover:cursor-pointer"
                  key={closing.closingId}
                  style={{ lineHeight: 0 }}
                  onClick={() => navigate(`/closing-overview/${closing.closingId}/details`)}
                >
                  <RentDuration
                    left={getMonthPlacement(closing.startMonth)}
                    width={closing.width}
                    marginLeft={closing.marginLeft}
                    randomBackground={generateRandomColor()}
                  >
                    <div className="font-bold">{closing.duration}</div>
                    <div>
                      {closing.contractStart} | {closing.contractEnd}
                    </div>
                  </RentDuration>

                  <div className="">
                    {months.map((month) => (
                      <BookingCell key={month} closing={closing} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className="flex items-center justify-end gap-2 bg-white rounded-b-2xl p-4">
        <div
          onClick={() => changePage('decrease')}
          className={`${page === 1 && 'cursor-default opacity-40'
            } cursor-pointer flex items-center justify-center bg-[#f6f4f4] rounded-lg w-10 h-10`}
        >
          <img src={leftArrowIcon} />
        </div>
        <div
          onClick={() => changePage('increase')}
          className={`${page === data?.pagination.totalPages && 'cursor-default opacity-40'
            } cursor-pointer flex items-center justify-center bg-[#f6f4f4] rounded-lg w-10 h-10`}
        >
          <img className="rotate-[179deg]" src={leftArrowIcon} />
        </div>
      </div>
    </div>
  );
};

const MonthCell = styled.div`
  width: 100%;
  max-width: 150px;
  height: 48px;
  display: inline-block;
`;

const BookingCell = styled.div`
  background: #fff;
  width: 100%;
  max-width: 150px;
  height: 70px;
  display: inline-block;
  border: 1px dashed #e4e4e4;
  border-left-style: solid;
  border-right-style: solid;
  &:first-child {
    border-left: none;
  }
`;

const RentDuration = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 4px;
  align-items: center;
  width: ${({ width }) => width + 'px'};
  position: absolute;
  left: ${({ left }) => left + 'px'};
  margin: auto;
  margin-left: ${({ marginLeft }) => marginLeft + 'px'};
  top: 0;
  bottom: 0;
  border-radius: 12px;
  height: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  background: ${({ randomBackground }) => randomBackground};
`;

const RentDurationBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ randomBackground }) => randomBackground};
  padding: 12px;
  border-radius: 12px;
`;
