import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { loginSchema } from 'validationSchemas';
import { login } from 'apis/authentication/authentication';

import { CircularProgress } from '@mui/material';
import bannerImage from 'assets/testLoginbg.png';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const { data, mutate, isLoading } = useMutation((payload) => login(payload));

  const setUserAuthData = (data) => {
    const {
      selectedUserGroupId,
      selectedUserGroupName,
      token,
      contact,
      SelectedSurfaceSymbol,
      selectedCurrencySymbol,
      selectedCurrencyName,
      isAdmin,
    } = data.data;

    localStorage.setItem('selectedLanguage', data.data.locale);
    localStorage.setItem('access-token', token.accessToken);
    localStorage.setItem('userGroupId', selectedUserGroupId);
    localStorage.setItem('userGroupName', selectedUserGroupName);
    localStorage.setItem('contactName', contact.fullName);
    localStorage.setItem('SelectedSurfaceSymbol', SelectedSurfaceSymbol);
    localStorage.setItem('selectedCurrencySymbol', selectedCurrencySymbol);
    localStorage.setItem('selectedCurrencyName', selectedCurrencyName);
    localStorage.setItem('isAdmin', isAdmin);
  };

  const handleAuthError = (statusCode, isAccountVerified, isActivePassword, message, values) => {
    if (statusCode === 403 || statusCode === 404) return;
    else if (!isAccountVerified)
      navigate('/confirm-account', {
        state: { redirectionMessage: message, username: values.email },
      });
    else if (!isActivePassword)
      navigate('/set-new-password', {
        state: { redirectionMessage: message, username: values.email },
      });
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={loginSchema}
      onSubmit={(values) => {
        mutate(values, {
          onSuccess: (data) => {
            const { message, statusCode } = data;
            const { isAccountVerified, isActivePassword, token, twoFaEnabled } = data?.data || {};

            if (statusCode === 200) {
              setUserAuthData(data);
              //check if query string is present in url
              if (redirect) {
                if (redirect === 'login' || redirect === 'register') navigate('/');
                else navigate('/' + redirect);
              } else navigate('/');
            } else if (statusCode === 202) {
              if (!isAccountVerified) {
                //navigate to the confirmation of account through otp
                navigate('/confirm-account', {
                  state: { redirectionMessage: message, username: values.email },
                });
              }
              else if (!isActivePassword) {
                //navigate to 2fa
                navigate('/forgot-password');
              }
              else if (twoFaEnabled) {
                //navigate to 2fa
                navigate('/otp', {
                  state: {
                    redirectionMessage: message,
                    sessionId: data?.data.sessionId,
                  },
                });
              }
            } else if (statusCode === 206) {
              //use the temporary token to use in subscription page
              setUserAuthData(data);
              navigate('/subscription');
            }
            else
              handleAuthError(statusCode, isAccountVerified, isActivePassword, message, values);
          },
        });
      }}
    >
      {({ values, errors, touched, handleChange }) => (
        <Form>
          <div className="flex h-screen">
            <div className="flex-none w-[35%] hidden lg:block relative">
              <img src={bannerImage} className="absolute inset-0 w-full h-full object-cover" />
            </div>

            <div className="flex flex-col w-full lg:w-[65%] bg-[url('assets/login-background.svg')] bg-cover bg-center p-4">
              <div className="flex items-center self-end mt-4 justify-center text-lg bg-black w-40 p-3 text-white rounded-tr-3xl rounded-bl-3xl">
                <a href="">Back to home</a>
              </div>

              <div className="w-full sm:w-6/12 flex flex-col my-auto mr-auto">
                <p className="font-semibold text-4xl mb-6">Sign in</p>
                <div className="flex mt-3">
                  <p className="text-lg mb-10">You don't have an account yet,</p>
                  <div onClick={() => navigate('/register')} className="ml-1 text-lg w-fit underline underline-offset-2 text-[#FF0000] hover:cursor-pointer">
                    register here
                  </div>
                </div>

                {data?.message && (
                  <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                    <span className="font-medium">{data?.message}</span>
                  </div>
                )}

                <Field
                  name="email"
                  placeholder="Email Address"
                  className={`focus:outline-none pl-4 border border-solid w-full ${touched.email && errors.email ? 'border-[#EA3323]' : 'border-[#00000033]'
                    } rounded-lg w-full h-14 mb-3`}
                  value={values.email}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="email"
                  render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
                />

                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={`focus:outline-none pl-4 border border-solid ${touched.password && errors.password ? 'border-[#EA3323]' : 'border-[#00000033]'
                    } rounded-lg w-full h-14 mt-3`}
                  value={values.password}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' || e.key === 'Delete') {
                      e.currentTarget.value = '';
                    }
                  }}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
                />

                <div className="mt-6 flex items-center justify-between">
                  <button
                    type="submit"
                    className="w-24 h-12 bg-[#FF0000] text-white rounded-tr-3xl rounded-bl-3xl cursor-pointer flex items-center justify-center font-medium"
                  >
                    {isLoading ? <CircularProgress color="inherit" size={25} /> : 'Sign in'}
                  </button>
                  <a className="text-[#FF0000] underline" href="/forgot-password">
                    Forgot Password
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
