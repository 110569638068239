import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

/*
 * |--------------------------------------------------------
 * | Leads API
 * | getLeads - getLead - addLead - updateLead - deleteLead
 * |--------------------------------------------------------
 */

export const getLeads = () => {
  return axios.get(`/api/v1/lead-app/leads/${getUserGroupId()}`);
};

export const getLead = (leadId) =>
  axios.get(`/api/v1/lead-app/lead/show/${leadId}/${getUserGroupId()}`);

export const addLead = (payload) => {
  return axios.post(`/api/v1/lead-app/lead/store`, {
    contact_id: payload.contact,
    contact_method_id: payload.contactMethod,
    lead_source_id: payload.leadSource,
    listing_type_id: payload.listingType,
    place_description: payload.description,
    place_id: payload.placeId,
    property_usage_id: payload.propertyUsage,
    sqm_range_id: payload.sqmRange,
    budget: payload.budget,
    potential_listings: payload.potential_listings,
    user_group_id: getUserGroupId(),
  });
};

export const updateLead = (payload) => {
  return axios.post(`/api/v1/lead-app/lead/update`, {
    lead_id: payload.leadId,
    contact_id: payload.contact,
    lead_source_id: payload.leadSource,
    creation_date: payload.creationDate,
    contact_method_id: payload.contactMethod,
    listing_type_id: payload.listingType,
    place_id: payload.placeId,
    place_description: payload.description,
    property_usage_id: payload.propertyUsage,
    property_type_id: payload.propertyTypeId,
    sqm_range_id: payload.sqmRange,
    budget: payload.budget,
    comments: payload.comments,
    user_group_id: getUserGroupId(),
  });
};

export const deleteLead = (leadIds) => {
  return axios.post(`/api/v1/lead-app/lead/destroy`, {
    lead_ids: leadIds,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |------------------------
 * | Potential Listings API
 * | addPotentialListing
 * |------------------------
 */

export const addPotentialListing = (payload) => {
  return axios.post(`/api/v1/lead-app/potential-listing/save`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |-----------------------------------------------------------------------
 * | FollowUp API
 * | addPotentialListing - updatePotentialListing - deletePotentialListing
 * |-----------------------------------------------------------------------
 */

export const addFollowUp = (payload) => {
  return axios.post(`/api/v1/lead-app/follow-up/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateFollowUp = (payload) => {
  return axios.post(`/api/v1/lead-app/follow-up/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteFollowUp = (followUpId) => {
  return axios.post(`/api/v1/lead-app/follow-up/destroy`, {
    follow_up_id: followUpId,
    user_group_id: getUserGroupId(),
  });
};

// * leads dropdown data API
export const getLeadsDropdownData = () =>
  axios.get(`/api/v1/lead-app/lead/dropdown-values/${getUserGroupId()}`);

export const exportLeads = (payload) => {
  return axios.post(`/api/v2/lead-app/lead/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    lead_ids: payload.ids,
  });
};
