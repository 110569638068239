import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

/*
 * |--------------------------------------------------------------------------
 *
 * | Accounting Integration API
 *
 * |--------------------------------------------------------------------------
 */

export const accountingIntegrationDropdown = () =>
  axios.get(`/api/v2/accounting-app-new/accounting-integrations/dropdown/${getUserGroupId()}`);

export const storeAccountingIntegration = (payload) =>
  axios.post(`/api/v2/accounting-app-new/accounting-integrations/store`, {
    integration_id: payload.integrationId,
    country_id: payload.country,
    client_id: payload.clientId,
    client_secret: payload.clientSecret,
    code: payload.code.replace(/%21/, '!'),
    user_group_id: getUserGroupId(),
  });

export const storeAccountingUserGroupIntegration = (payload) =>
  axios.post(`/api/v2/accounting-app-new/accounting-integrations/user-group/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });

export const getAccountingTransactions = () =>
  axios.get(`/api/v2/accounting-app-new/accounting-integrations/transactions/${getUserGroupId()}`);

export const getUserGroupAccountingIntegrations = () =>
  axios.get(`/api/v2/accounting-app-new/accounting-integrations/integrations/${getUserGroupId()}`);
