import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import phoneIcon from 'assets/phoneContactIcon.svg';

export const SuccessStep = ({ contactData }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full text-center p-2">
      <div className="font-bold text-2xl">{t('contact_successfully_created')} 🎉</div>

      <ContactCardContainer>
        <span className="mt-4 text-4xl font-bold">{contactData.fullName}</span>
        <span className="mt-2 text-sm uppercase">{contactData.contactType}</span>

        <div className="flex gap-4 justify-center mt-2">
          <div className="flex gap-2">
            <img alt="" src={phoneIcon} />
            <span>{contactData.email}</span>
          </div>
          <div className="flex gap-2">
            <img alt="" src={phoneIcon} />
            <span>{contactData.mobile}</span>
          </div>
        </div>

        <div className="h-1 mt-5 w-[370px] m-auto border-b border-[#C9C9C9]"></div>

        <div className="flex justify-between mt-6 m-auto w-11/12">
          <div className="flex flex-col">
            <span className="text-[#8E8E8E] text-sm">{t('contact_record_type')}</span>
            <span>{contactData.recordType}</span>
          </div>

          {contactData.recordType === 'Corporate' ? (
            <div className="flex flex-col">
              <span className="text-[#8E8E8E] text-sm">{t('contact_name')}</span>
              <span>{contactData.fullName}</span>
            </div>
          ) : (
            <div className="flex flex-col">
              <span className="text-[#8E8E8E] text-sm">{t('nationality')}</span>
              <span>{contactData.nationality}</span>
            </div>
          )}
          <div className="flex flex-col">
            <span className="text-[#8E8E8E] text-sm">{t('source')}</span>
            <span>{contactData.source}</span>
          </div>
        </div>
      </ContactCardContainer>
    </div>
  );
};

const ContactCardContainer = styled.div`
  width: 554px;
  padding: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 16px auto;
  border: 1px solid #fff;
  border-radius: 22px;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.2),
    0px 2px 10px rgba(0, 0, 0, 0.1);
`;
