import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';
import {isMobile, TabName} from 'utils/utils';
import { propertiesTableColumns } from 'utils/columns';

import {
  AddButton,
  CardButton,
  DeleteButton,
  EditButton,
  EmptyList,
  ExportButton,
  ListButton,
  StyledDataGrid,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { DeleteDialog, ExportDialog } from 'components/dialogs';
import { PropertyCard } from './propertyCard';
import { PropertyDialog } from './propertyDialog';

import { Alert, Checkbox, CircularProgress } from '@mui/material';
import searchIcon from 'assets/search.svg';

const Properties = ({ apiFunctions }) => {
  TabName('Properties');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const recordTypes = [
    { value: 'building', name: t('building') },
    { value: 'unit', name: t('unit') },
  ];

  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [activeRecordTab, setActiveRecordTab] = React.useState(recordTypes[0].value);
  const [selectedProperties, setSelectedProperties] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [allPropertiesSelected, setAllPropertiesSelected] = React.useState(false);
  const [propertiesList, setPropertiesList] = React.useState([]);

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getAll.func,
    apiFunctions.getAll.key,
    activeRecordTab,
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedProperties(data.data.map((item) => item.propertyId));
      setAllPropertiesSelected(true);
    } else {
      setSelectedProperties([]);
      setAllPropertiesSelected(false);
    }
  };

  const handlePropertyDelete = async () => {
    const response = await deleteMutation(selectedProperties);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setPropertiesList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.propertyName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.city.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.address.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.status.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.propertyType.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setPropertiesList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setPropertiesList(data?.data);
    }
  }, [data?.data]);

  return (
    <div className="mt-11">
      {error && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          There was an error while getting the data
        </Alert>
      )}

      <Notifier data={mutationResponse} />

      <div className="bg-white rounded-2xl pt-2">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="hidden lg:flex items-center gap-4 pl-5">
            <div className="flex h-[40px] items-center justify-center border border-[#C9C9C9] rounded-lg">
              <Checkbox
                icon={
                  <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
                }
                checkedIcon={
                  <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
                }
                checked={allPropertiesSelected}
                onChange={handleSelectAll}
              />
              <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
            </div>

            <div className="flex h-[40px] rounded-lg p-1 justify-center items-center bg-[#F6F4F4]">
              {recordTypes.map((type) => (
                <div
                  className={`flex justify-center items-center w-fit h-fit hover:cursor-pointer text-xs py-2 px-4 ${
                    activeRecordTab === type.value
                      ? 'bg-white font-medium'
                      : 'bg-[#F6F4F4] text-[#8E8E8E]'
                  } rounded-lg`}
                  onClick={() => setActiveRecordTab(type.value)}
                >
                  {type.name}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col-reverse sm:flex-row justify-between gap-4 p-3.5">
            <div className="flex rounded-lg bg-[#F6F4F4]">
              <div className="flex items-center">
                <img alt="" className="pl-2" src={searchIcon} />
              </div>

              <input
                className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
                placeholder={t('search_by_property')}
                value={searchValue}
                onChange={handleFilterChange}
              />
            </div>

            <div className="flex gap-4">
              <AddButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'addProperty' })}
                disabled={isLoading}
              />

              <EditButton
                onClick={() =>
                  navigate(`/property-overview/${selectedProperties[0]}/details`, {
                    state: { directEdit: true },
                  })
                }
                disabled={selectedProperties.length > 1 || !selectedProperties.length}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={!selectedProperties.length}
              />

              <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
            </div>

            <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
              <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
              <ListButton
                isActivated={activeTab === 'table'}
                onClick={() => setActiveTab('table')}
              />
            </div>
          </div>
        </div>

        {!isLoading && !data?.data?.length ? (
          <EmptyList listName="properties" />
        ) : activeTab === 'table' ? (
          <StyledDataGrid
            checkboxSelection
            rows={propertiesList || []}
            columns={propertiesTableColumns(t)}
            getRowId={(row) => row.propertyId}
            onSelectionModelChange={(newSelectedContact) =>
              setSelectedProperties(newSelectedContact)
            }
            selectionModel={selectedProperties}
            rowsPerPageOptions={[]}
            loading={isLoading}
            pageSize={25}
            rowHeight={70}
            onRowClick={(params) => navigate(`/property-overview/${params.row.propertyId}/details`)}
            components={{
              LoadingOverlay: () => (
                <div className="mt-8 flex justify-center">
                  <CircularProgress color="error" />
                </div>
              ),
            }}
          />
        ) : isLoading ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 p-3">
            {[1, 2, 3, 4].map((_) => (
              <SkeletonCardLoader />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 overflow-y-auto p-3">
            {data?.data.map((property) => (
              <PropertyCard
                property={property}
                selectedProperties={selectedProperties}
                onSelect={() => setSelectedProperties([...selectedProperties, property.propertyId])}
                onUnselect={() =>
                  setSelectedProperties(
                    selectedProperties.filter(
                      (selectedProperty) => property.propertyId !== selectedProperty,
                    ),
                  )
                }
              />
            ))}
          </div>
        )}

        <PropertyDialog
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
          apiFunctions={apiFunctions}
        />

        <DeleteDialog
          openDialog={openDialog}
          name="property"
          onClose={() => setOpenDialog(null)}
          onDelete={handlePropertyDelete}
          isLoading={deleteLoading}
        />

        <ExportDialog
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
          selectedProperties={selectedProperties}
          apiFunctions={apiFunctions}
        />
      </div>
    </div>
  );
};

export default Properties;
