import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { getReportsDropdown, getReports } from 'apis/reports/reports';

import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import disabledFilterIcon from 'assets/disabledFilter.svg';
import filterIcon from 'assets/filter.svg';
import ExportButtonIcon from 'assets/ExportButton.svg';
import disabledExportIcon from 'assets/disabledExport.svg';
import {TabName, truncate} from 'utils/utils';

const Reports = () => {
  TabName('Reports');

  const navigate = useNavigate();
  const [reportType, setReportType] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const { data, isFetching } = useQuery('reportDropdown', getReportsDropdown);
  const { mutate, isLoading } = useMutation((payload) => getReports(payload));

  const handleReportGenerate = () => {
    mutate(reportType, {
      onSuccess: (data) => {
        const { headers, report } = data?.data || {};
        setColumns(
          headers.map(({ field, headerName }) => {
            return { field: field, headerName: headerName, flex: 1 };
          }),
        );
        setRows(report);
      },
    });
  };

  return (
    <div className="bg-white rounded-2xl mt-11 pt-2">
      <div className="flex gap-3 justify-end p-5">
        <Select
          sx={{
            borderRadius: '8px',
            fontSize: 14,
            width: 'fit-content',
            height: '40px',
          }}
          name="listingType"
          value={reportType || ''}
          placeholder="Report Type"
          onChange={(e) => setReportType(e.target.value)}
          disableUnderline
          displayEmpty
          renderValue={(value) => {
            if (!value) return 'Report Type';
            if (data?.data?.reports?.find((item) => String(item.name) === value))
              return truncate(
                data?.data?.reports?.find((item) => String(item.name) === value)?.name,
              );
            return 'Report Type'.concat(': Select a valid option');
          }}
        >
          {isFetching && (
            <div className="flex items-center justify-center">
              <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
            </div>
          )}
          {data?.data?.reports?.map((list) => (
            <MenuItem disabled={list.disabled} key={list.id} value={list.name}>
              {list.name}
            </MenuItem>
          ))}
        </Select>

        <button
          className="flex justify-center items-center py-1 px-3 h-10 rounded-lg bg-[#EA3323] text-white font-bold hover:cursor-pointer disabled:text-[#C9C9C9] disabled:bg-[#F6F4F4]"
          disabled={!reportType}
          onClick={handleReportGenerate}
        >
          {isLoading ? <CircularProgress color="inherit" size={25} /> : 'Generate'}
        </button>

        <button
          className="flex justify-center items-center w-10 h-10 py-1 px-3 rounded-lg bg-[#F6F4F4] font-bold hover:cursor-pointer"
          disabled={!reportType}
        >
          <img src={!reportType ? disabledFilterIcon : filterIcon} />
        </button>

        <button
          className="flex justify-center gap-1 items-center h-10 py-1 px-3 text-[#2B5DDC] rounded-lg bg-[#F9FAFF] disabled:bg-[#F6F4F4] disabled:text-[#C9C9C9] font-bold hover:cursor-pointer"
          disabled={!reportType}
        >
          <img src={!reportType ? disabledExportIcon : ExportButtonIcon} />
          <span>Export</span>
        </button>
      </div>

      {!!rows?.length && (
        <DataGrid
          sx={{
            padding: '2rem 2rem 2rem 2rem',
            border: 0,
            '& .MuiDataGrid-row': {
              color: '#535353',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 700,
              color: '#535353',
            },
            '& .MuiDataGrid-columnGroupHeader': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              visibility: 'hidden',
            },
            '& .email_cell, .mobile_cell': {
              color: '#8E8E8E',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& > .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
            '& .MuiDataGrid-cell:nth-child(2)': {
              color: '#8E8E8E',
            },
            '& .MuiDataGrid-cell:nth-child(3)': {
              color: '#8E8E8E',
            },
            '& .MuiDataGrid-cell:nth-child(4)': {
              color: '#8E8E8E',
            },
            '& .MuiDataGrid-cell:nth-child(8)': {
              color: '#8E8E8E',
            },

            '& .city_cell, .property_usage_cell, .contact_type_cell': {
              color: '#8E8E8E',
            },

            '& > .MuiDataGrid-main': {
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#F0F3FF',
                '& .MuiDataGrid-cell': {
                  color: 'black',
                },
                '&:focus': {
                  outline: 'none',
                },
              },
              '& div div div div >.MuiDataGrid-cell': {
                borderBottom: '1px solid #F6F4F4',
              },
            },
            '&.MuiDataGrid-root': {
              padding: '10px',

              '& .MuiDataGrid-columnHeader': {
                fontWeight: '700 !important',
                fontSize: '12px',
                '&:focus': {
                  outline: 'none',
                },
              },
              '& .MuiDataGrid-row': {
                color: 'black',
                display: 'flex',
                paddingLeft: '2px',
                fontSize: '12px',
                borderRadius: '8px',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            },
            '& .MuiDataGrid-selectedRowCount': {
              background: '#F6F4F4',
              padding: '12px',
              borderRadius: '8px',
            },
          }}
          autoHeight
          pagination
          paginationMode="server"
          sortingMode="server"
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          hideFooter
          rows={rows || []}
          columns={columns}
          getRowId={(row) => row.id}
          loading={isLoading}
          onRowClick={({ row }) => navigate(`/${row.appName}-overview/${row.id}/details`)}
          components={{
            LoadingOverlay: () => (
              <div className="mt-8 flex justify-center">
                <CircularProgress color="error" />
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};

export default Reports;
