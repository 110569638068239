import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getYearlyCashFlow } from 'apis/dashboard/dashboard';
import { yearlyCashFlowColumns } from 'utils/columns';

import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';
import chartImage from 'assets/upChart.svg';

export const YearCashFlow = ({ selectedYear }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [year, setYear] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const { data, isLoading } = useQuery(
    ['yearlyCashFlow', year, page],
    () => getYearlyCashFlow(year, page),
    { enabled: !!year },
  );

  React.useEffect(() => {
    if (selectedYear) setYear(selectedYear);
  }, [selectedYear]);

  React.useEffect(() => {
    if (data?.data?.length) setTotalRecords(data?.pagination.totalRecords);
  }, [data]);
  // Added: Handling loading state
  if (isLoading) {
    return <div className="flex justify-center items-center"><CircularProgress /></div>;
  }

  // Added: Handling empty data
  if (!data?.data?.length) {
    return <div className="text-center">No cash flow data available for the selected year.</div>;
  }
  return (
    <div className="mt-8">
      <div className="font-bold text-xl">{t('yearly_cashflow')}</div>

      <div className="block lg:hidden bg-white rounded-[16px] mt-[15px]">
        {isLoading ? (
          <div className="flex justify-center">
            <CircularProgress color="error" size={25} />
          </div>
        ) : (
          !!data?.data?.length &&
          data?.data.map((proprety) => (
            <div className="p-[20px]">
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <img alt="" className="w-[30px] h-[30px]" src={proprety.avatar} />
                  <div className="text-[14px] font-bold">{proprety.propertyName}</div>
                </div>
                <img alt="" src={chartImage} />
              </div>

              <div className="grid grid-cols-2 gap-[15px] mt-[15px]">
                <div>
                  <div className="text-[#535353]">{t('invoiced_amount')}</div>
                  <div>{proprety.invoicedAmount}</div>
                </div>

                <div>
                  <div className="text-[#535353]">{t('collected amount')}</div>
                  <div>{proprety.paidAmount}</div>
                </div>

                <div>
                  <div className="text-[#535353]">{t('due amount')}</div>
                  <div>{proprety.dueAmount}</div>
                </div>

                <div>
                  <div className="text-[#535353]">{t('upcoming amount')}</div>
                  <div>{proprety.upcomingAmount}</div>
                </div>
              </div>
            </div>
            // }
          ))
        )}
      </div>

      <div className="hidden lg:block w-full">
        <StyledTable
          pagination
          autoHeight
          paginationMode="server"
          disableSelectionOnClick
          disableColumnMenu
          rows={data?.data || []}
          columns={yearlyCashFlowColumns(t)}
          getRowId={(row) => row.id}
          rowCount={totalRecords}
          onPageChange={(newPage) => setPage(newPage + 1)}
          page={page - 1}
          rowsPerPageOptions={[]}
          loading={isLoading}
          pageSize={6}
          onRowClick={(params) => navigate(`/closing-overview/${params.row.id}/details`)}
          components={{
            LoadingOverlay: () => (
              <div className="mt-8 flex items-center justify-center">
                <CircularProgress color="error" />
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

const StyledTable = styled(DataGrid)`
  &>.MuiDataGrid-main {
    &>.MuiDataGrid-columnHeaders {
      border-bottom: none;
    }
    & div > .MuiDataGrid-cell {
    border-bottom: none;
  },
  },

  &.MuiDataGrid-root {
    padding: 10px;
    border: none;
    background-color: white;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    margin-top: 12px;
  
    & .MuiDataGrid-columnHeader {
      color: #535353;
      font-size: 12px;
      &:focus {
        outline: none;
      }
    }

    & .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
      display: none;
    }
    
    & .MuiDataGrid-columnHeader:nth-child(1) {
      color: #535353;
      font-size: 12px;
    }

    & .MuiDataGrid-row {
      color: #535353;
      display: flex;
      padding-left: 2px;
      font-size: 12px;
    }
    & .MuiDataGrid-footerContainer {
      
      border-top: none;
    }
    
    & .MuiDataGrid-cell:nth-child(1) {
      place-content: center;
      font-weight: bold;
      color: black;
      border-bottom: none;
      &:focus {
        outline: none;
      }
    }

    & .MuiDataGrid-cell:nth-child(2) {
      font-weight: bold;
      color: black;
    }
  }

  & .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }
`;
