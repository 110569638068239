import {
  getInvoices,
  getTransaction,
  addTransaction,
  updateTransaction,
  deleteTransaction,
  getAttachments,
  addAttachment,
  updateAttachment,
  deleteAttachment,
  getTransactionDropdownData,
  exportInvoices,
  addTransactionLine,
  editTransactionLine,
  deleteTransactionLine,
  sendInvoice,
  generateInvoicePdf,
  transactionInvoiceShow,
  getTransactionPayments,
  showTransactionInvoice,
  getPaymentDropdownData,
  addPayment,
  updatePayment,
  deletePayment
} from 'apis/transactions/transactions';

export const transactionApiFunctions = {
  getAll: { func: getInvoices, key: 'transactions' },
  get: { func: getTransaction, key: 'transactionDetails' },
  add: { func: addTransaction, key: 'transactions' },
  edit: { func: updateTransaction, key: 'transactionDetails' },
  delete: { func: deleteTransaction, key: 'transactions' },
  getDropdowns: { func: getTransactionDropdownData, key: 'transactionDropdown' },
  getAttachments: { func: getAttachments, key: 'transactionAttachments' },
  addAttachment: { func: addAttachment, key: 'transactionAttachments' },
  editAttachment: { func: updateAttachment, key: 'transactionAttachments' },
  deleteAttachment: { func: deleteAttachment, key: 'transactionAttachments' },
  getPayments: { func: getTransactionPayments, key: 'payments' },
  addPayment: { func: addPayment, key: ['payments', 'transactionDetails'] },
  editPayment: { func: updatePayment, key: ['payments', 'transactionDetails'] },
  deletePayment: { func: deletePayment, key: ['payments', 'transactionDetails'] },
  getPaymentDropdowns: { func: getPaymentDropdownData, key: 'paymentDropdown' },
  export: { func: exportInvoices, key: '' },
  addTransactionLine: { func: addTransactionLine, key: 'transactionDetails' },
  editTransactionLine: { func: editTransactionLine, key: 'transactionDetails' },
  deleteTransactionLine: { func: deleteTransactionLine, key: 'transactionDetails' },
  sendInvoice: { func: sendInvoice, key: '' },
  generatePdf: { func: generateInvoicePdf, key: 'transactionInvoiceDetails' },
  transactionInvoiceShow: { func: transactionInvoiceShow, key: 'transactionInvoiceDetails' },
  showTransactionInvoice: { func: showTransactionInvoice, key: 'transactionInvoiceDetails' },
};
