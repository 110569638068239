import React from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { deleteClosing, getClosing, getClosingDropdownData } from 'apis/closings/closings';
import { closingOverviewNavItems } from 'utils/constants';

import { DeleteButton, DeleteDialog, EditButton } from 'components';
import { EditClosingDialog } from 'pages/closings/editClosingDialog';

import { Snackbar, SnackbarContent, Skeleton } from '@mui/material';
import greaterIcon from 'assets/greaterIcon.svg';
import redHomeIcon from 'assets/redHome.svg';
import redLocationIcon from 'assets/redLocation.svg';
import closingButtonIcon from 'assets/listingButtonIcon.svg';
import withApiFunctions from "HOC/withApiFunctions";
import {NewTransactionDialog} from "../transactions/newTransactionDialog";
import {transactionApiFunctions} from "apiManager";

const ClosingOverview = () => {
  const navigate = useNavigate();
  const { closingId } = useParams();
  const queryClient = useQueryClient();
  const isMutating = useIsMutating(); // * used to display loading indicator
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [snackBarColor, setSnackBarColor] = React.useState({ bg: '', color: '' });
  const [APIResponse, setAPIResponse] = React.useState({ message: '', code: '' });

  const EnhancedTransactionDialog = withApiFunctions(NewTransactionDialog, transactionApiFunctions);

  const { data, isLoading } = useQuery(['closingDetails', closingId], () => getClosing(closingId));
  const { data: dropDownValues, isLoading: dropdownLoading } = useQuery(
    'closingDropdown',
    getClosingDropdownData,
  );
  const { mutate } = useMutation((payload) => deleteClosing(payload));

  const handleClosingDelete = () => {
    mutate([closingId], {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          setOpenDialog(null);
          queryClient.invalidateQueries('closings');
          navigate('/closings');
          setAPIResponse({ message: data.message, code: data.statusCode });
          setSnackBarColor({ bg: '#EEF6F0', color: '#008E0E' });
        } else if (data.statusCode === 403) {
          setAPIResponse({ message: data.message, code: data.statusCode });
          setSnackBarColor({ bg: '#FFF2F0', color: '#EA3323' });
        } else {
          setAPIResponse({ message: 'Server Error', code: data.statusCode });
          setSnackBarColor({ bg: '#FFF2F0', color: '#EA3323' });
        }
      },
    });
  };

  const closingContext = {
    closingDetails: data?.data,
    isLoading: isLoading,
    closingDropdown: dropDownValues,
    dropdownLoading: dropdownLoading,
  };

  return (
    <div className="mt-5">
      <Snackbar
        open={APIResponse.message}
        onClose={() => setAPIResponse({ message: '', code: '' })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={APIResponse.message}
        autoHideDuration={3000}
      >
        <SnackbarContent
          style={{
            backgroundColor: snackBarColor.bg,
            color: snackBarColor.color,
            borderRadius: '12px',
          }}
          message={<span id="client-snackbar">{APIResponse.message}</span>}
        />
      </Snackbar>

      <div className="flex gap-2 text-[#EA3323] font-medium text-md">
        <NavLink
          className="hover:cursor-pointer"
          to={`/closings`}
          key="closingTitle"
          activeClassName="active"
        >
          {t('closings')}
        </NavLink>
        <img src={greaterIcon} />
        <div>{data?.data.closingId}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img src={redHomeIcon} />
              {data.data.listing.propertyName}
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl">
              {data.data.closingTypeName}
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl text-[#008E0E]">
              {data.data.listing.listingStatus}
            </div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img src={redLocationIcon} />
              <div>
                {data.data.listing.province}
              </div>
            </div>

            <div className="bg-white p-3 font-semibold rounded-3xl">
              {data.data.listing.netSqm + ' SQM'}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-5">
        <div className="flex items-center justify-between">
          <div className="flex gap-3">
            {closingOverviewNavItems.map((item) => (
              <NavLink
                to={`/closing-overview/${closingId}/${item.route}`}
                key={item.name}
                activeClassName="active"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                    : 'border border-[#C9C9C9] p-2 rounded-lg'
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          {window.location.pathname.includes('/details') && (
            <div className="hidden sm:flex gap-3">
              <button
                className="flex gap-1 p-2 items-center justify-center rounded-lg bg-[#FFF2F0] text-[#EA3323]"
                onClick={() => setOpenDialog({ dialogType: 'addNewInvoice', data: '' })}
              >
                <img src={closingButtonIcon} />
                {t('transaction')}
              </button>

              <EditButton
                disabled={isLoading}
                onClick={() => setOpenDialog({ data: [closingId], dialogType: 'editClosing' })}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>

      <DeleteDialog
        openDialog={openDialog}
        name="closing"
        onClose={() => setOpenDialog(null)}
        onDelete={handleClosingDelete}
        isLoading={!!isMutating}
      />

      <EditClosingDialog
        openDialog={openDialog}
        data={dropDownValues}
        isLoading={dropdownLoading}
        onClose={() => setOpenDialog(null)}
      />

      <EnhancedTransactionDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />

      <Outlet context={closingContext} />
    </div>
  );
};

export default ClosingOverview;
