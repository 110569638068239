import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SkeletonTableLoading } from './skeletonTableLoading';
import EditButtonIcon from 'assets/EditButton.svg';
import DeleteButtonIcon from 'assets/DeleteButton.svg';
import DuplicateButtonIcon from 'assets/duplicate.svg';

export const Table = ({
  columns,
  rows,
  linked,
  isLoading,
  onEdit,
  onDelete,
  onDuplicate,
  name,
  minimizeHeight,
  deleteButtonHidden = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/*  for responsive view */}
      {isLoading ? (
        <SkeletonTableLoading />
      ) : (
        <div className="flex flex-col gap-4 sm:hidden">
          {rows?.map((row) => (
            <div className="flex flex-col gap-2 border border-[#C9C9C9] rounded-[20px] p-4">
              {columns.map((col) => (
                <div className="grid grid-cols-2 items-center">
                  <div>{t(col.headerName)}: </div>
                  {col.field === 'actions' ? (
                    <div className="flex items-center gap-3">
                      <img alt="" onClick={() => onEdit(row)} src={EditButtonIcon} />
                      <img alt="" onClick={() => onDelete(row)} src={DeleteButtonIcon} />
                    </div>
                  ) : col.field === 'duplicates' ? (
                    <div className="flex items-center gap-3">
                      <img alt="" onClick={() => onEdit(row)} src={EditButtonIcon} />
                      <img alt="" onClick={() => onDelete(row)} src={DeleteButtonIcon} />
                      <img alt="" onClick={() => onDuplicate(row)} src={DuplicateButtonIcon} />
                    </div>
                  ) : (
                    <TableData type={row[col.field]}>{row[col.field]}</TableData>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {/*  for desktop view */}
      <div className="hidden sm:flex flex-col gap-4 border border-[#C9C9C9] rounded-[20px] p-6">
        {isLoading ? (
          <SkeletonTableLoading />
        ) : rows.length !== 0 ? (
          <div className="flex flex-col gap-5">
            <TableHeader>
              {columns.map((col) => (
                <div key={col.headerName}>{t(col.headerName)}</div>
              ))}
            </TableHeader>

            <div
              className={`flex flex-col ${minimizeHeight && 'max-h-[100px]'} overflow-y-auto gap-4`}
            >
              {rows.map((row, index) => (
                <>
                  {linked ? (
                    <NavLink
                      className="flex items-center rounded-[20px] text-sm"
                      to={`/${linked}-overview/${row[linked + 'Id']}/details`}
                    >
                      {columns.map((col) => (
                        <TableData linked={linked} field={col.field} type={row[col.field]}>
                          {col.symbol + ' ' + row[col.field]}
                        </TableData>
                      ))}
                    </NavLink>
                  ) : (
                    <div className="flex items-center justify-between rounded-[20px] text-sm">
                      {columns.map((col) =>
                        col.field === 'actions' ? (
                          <div className="flex flex-1 items-center gap-4 w-[20%]">
                            <img
                              alt=""
                              onClick={() => onEdit(row)}
                              src={EditButtonIcon}
                              style={{ cursor: 'pointer' }}
                            />
                            {!deleteButtonHidden && (
                              <img
                                alt=""
                                onClick={() => onDelete(row)}
                                src={DeleteButtonIcon}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </div>
                        ) : col.field === 'duplicates' ? (
                          <div className="flex flex-1 items-center gap-4 w-[20%]">
                            <img
                              alt=""
                              onClick={() => onEdit(row)}
                              src={EditButtonIcon}
                              style={{ cursor: 'pointer' }}
                            />
                            <img
                              alt=""
                              onClick={() => onDelete(row)}
                              src={DeleteButtonIcon}
                              style={{ cursor: 'pointer' }}
                            />
                            <img
                              alt=""
                              onClick={() => onDuplicate(row)}
                              src={DuplicateButtonIcon}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        ) : (
                          <TableData type={row[col.field]}>{row[col.field]}</TableData>
                        ),
                      )}
                    </div>
                  )}
                  {index !== rows.length - 1 && <div className="w-full h-[1px] bg-[#F6F4F4]"></div>}
                </>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center font-bold text-[#535353] text-sm">
            {name ? t(`no_${name}`) : t(`no_${linked}s`)}
          </div>
        )}
      </div>
    </>
  );
};

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  color: #535353;
  font-weight: 700;
  font-size: 12px;
  div {
    flex: 1;
  }
`;

const TableData = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: ${({ field }) => {
    return field === 'status' ? 500 : 400;
  }};
  font-size: 14px;
  line-height: 15px;
  color: ${({ type, linked, field }) => {
    switch (type) {
      case 'For Rent':
        return '#008E0E';
      case 'For Sale':
        return '#2B5DDC';
    }
    switch (linked) {
      case 'listing':
        return field === 'status' ? (type === 'Active' ? '#008E0E' : '#EA3323') : '';
      case 'repair':
        return field === 'status'
          ? type === 'accepted'
            ? '#008E0E'
            : type === 'pending'
            ? '#DBAC06'
            : '#EA3323'
          : '';
    }
  }};
`;
