import { useNavigate } from 'react-router-dom';
import locationIcon from 'assets/LocationIcon.svg';
import homeIcon from 'assets/homeIcon.svg';

export const ListingCard = ({ listing }) => {
  const navigate = useNavigate();
  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const surfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');

  return (
    <div
      className="cursor-pointer border border-[1px] border-[#C9C9C9] rounded-2xl p-2 ease-in duration-300 hover:shadow-xl hover:scale-[1.015]"
      onClick={() => navigate(`/listing-overview/${listing.listingId}/details`)}
    >
      <img className="w-[34%] flex-shrink-0 object-cover rounded-l-lg" src={listing.avatar} />

      <div className="flex flex-col w-full justify-between p-2">
        <div className="flex justify-between items-center">
          <div>
            <div className="text-[10px] text-[#8E8E8E] font-light">
              {listing.usageType?.toUpperCase()}
            </div>
            <div className="font-bold">{listing.propertyName}</div>
          </div>
          <div
            className={`flex flex-shrink-0 items-center text-[10px] rounded-[4px] py-1.5 px-2 ${
              listing.listingType === 'For Rent'
                ? 'bg-[#F4FFE7] text-[#008E0E]'
                : 'bg-[#FFF2F0] text-[#EA3323]'
            }`}
          >
            {listing.listingType}
          </div>
        </div>

        <div className="font-bold">{listing.property}</div>

        <div className="flex items-center gap-2 mt-2">
          <div className="bg-[#ECECEC] rounded-full p-1.5">
            <img className="w-4" src={locationIcon} />
          </div>

          <div>
            <div className="text-[10px] font-medium">{listing.city}</div>
          </div>
        </div>

        <div className="flex justify-between gap-4">
          <div className="flex items-center gap-4">
            <div className="bg-[#ECECEC] rounded-full p-1.5">
              <img className="w-4" src={homeIcon} />
            </div>

            <div>
              <div className="text-[10px] font-medium">
                {listing.grossSqm + ' ' + surfaceSymbol}
              </div>
            </div>
          </div>

          <div className="font-bold text-[#5A5A5A] whitespace-nowrap">
            {currencySymbol + ' ' + listing.askingPrice}
          </div>
        </div>
      </div>
    </div>
  );
};
