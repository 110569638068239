import * as yup from 'yup';

export const PreferenceSchema = yup.object().shape({
  userGroupName: yup.string().required('Email field is required'),
  currency: yup.string().required('Currency field is required'),
  vatApplicable: yup.string().required('Vat Applicable field is required'),
  vatPercentage: yup.string().required('VAT Percentage field is required'),
  surfaceUnit: yup.string().required('Surface Unit field is required'),
  vatNumber: yup.string().required('VAT Number field is required'),
});
