import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { isMobile } from 'utils/utils';

import { StyledSelect, StyledInput, StyledDialog, Notifier } from 'components';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

import {useCustomQuery} from "queries";
import {useCustomMutation} from "mutations";

const unitPropertySchema =
  yup.object().shape({
    grossSQM: yup.string().required('Gross Sqm is Required'),
    numberOfBedrooms: yup.string().required('Number of Bedrooms is Required'),
    numberOfBathrooms: yup.string().required('Number of Bathrooms is Required'),
    loanAmount: yup.string().required('Loan Amount is Required'),
    acquisitionAmount: yup.string().required('Acquisition Amount Required'),
    title: yup.string().required('Title is Required'),
    typeId: yup.string().required('Type is Required'),
  });

export const EditUnitPropertyDialog = ({ openDialog, onClose, apiFunctions }) => {

  const { t } = useTranslation();
  const selectedCurrencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const selectedSurfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');

  const { propertyId } = openDialog?.data || '';

  const isDialogOpen = () => {
    return (openDialog?.dialogType === 'editUnitProperty');
  };

  const { data: dropdownValues, isLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    {
      enabled: isDialogOpen()
    }
  );

  const {
    mutateAsync: editPropertyMutation,
    isLoading: editLoading,
    data: editPropertyResponse,
  } = useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  return (
    <>
      <Notifier data={editPropertyResponse} />

      <Formik
        enableReinitialize
        validationSchema={unitPropertySchema}
        initialValues={{
          buildingId: openDialog?.data?.buildingId || '',
          grossSQM: openDialog?.data?.generalInfo?.grossSqm || '',
          numberOfBedrooms: openDialog?.data?.numberOfBedrooms || '',
          numberOfBathrooms: openDialog?.data?.numberOfBathrooms || '',
          loanAmount: openDialog?.data?.loanAmount || '',
          acquisitionAmount: openDialog?.data?.acquisitionAmount || '',
          title: openDialog?.data?.name || '',
          typeId: openDialog?.data?.summary?.typeId || '',
        }}
        onSubmit={async (values, { resetForm }) => {
          let payload = {
            propertyId: propertyId,
            recordTypeId: openDialog?.data?.summary?.recordTypeId,
            ...values,
          };
          const editResponse = await editPropertyMutation(payload);
          if (editResponse.statusCode === 200) onClose();
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue, dirty, resetForm }) => (
          <StyledDialog
            fullWidth
            width="1064px"
            height="725px"
            padding="30px"
            fullScreen={isMobile()}
            open={isDialogOpen()}
            onClose={() => {
              resetForm();
              onClose();
            }}
            isEdited={dirty}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex items-center w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
                    <img alt="" src={dialogTitleIcon} />
                  </div>
                  <span className="font-semibold">
                    {t('edit_property')}
                  </span>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="flex h-full justify-center items-center">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="flex flex-col sm:flex-row h-full justify-center">
                    <div className="flex flex-col gap-5 p-1">
                      <div className="w-full">
                        <div className="text-sm pb-1">{t('building')}</div>
                        <StyledSelect
                          name="buildingId"
                          placeholder={t('building')}
                          value={values.buildingId}
                          onChange={handleChange}
                          itemsList={dropdownValues?.data?.building}
                          error={touched.buildingId && errors.buildingId}
                          disabled
                        />
                        <ErrorMessage name="buildingId" component={ValidationError} />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('title')}</div>
                        <StyledInput
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          placeholder={t('title')}
                          error={touched.title && errors.title}
                        />
                        <ErrorMessage name="title" component={ValidationError} />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('number_of_bathrooms')}</div>
                        <StyledInput
                          name="numberOfBathrooms"
                          value={values.numberOfBathrooms}
                          onChange={handleChange}
                          placeholder={t('number_of_bathrooms')}
                          error={touched.numberOfBathrooms && errors.numberOfBathrooms}
                        />
                        <ErrorMessage name="numberOfBathrooms" component={ValidationError} />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('number_of_bedrooms')}</div>
                        <StyledInput
                          name="numberOfBedrooms"
                          value={values.numberOfBedrooms}
                          onChange={handleChange}
                          placeholder={t('number_of_bedrooms')}
                          error={touched.numberOfBedrooms && errors.numberOfBedrooms}
                        />
                        <ErrorMessage name="numberOfBedrooms" component={ValidationError} />
                      </div>
                    </div>
                    <div className="flex flex-col gap-5 p-1">
                      <div className="w-full">
                        <div className="text-sm pb-1">{t('sqm')}</div>
                        <StyledInput
                          name="grossSQM"
                          value={values.grossSQM}
                          onChange={handleChange}
                          prefix={selectedSurfaceSymbol}
                          error={touched.grossSQM && errors.grossSQM}
                        />
                        <ErrorMessage name="grossSQM" component={ValidationError} />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('type')}</div>
                        <StyledSelect
                          name="typeId"
                          placeholder={t('type')}
                          value={values.typeId}
                          onChange={handleChange}
                          itemsList={dropdownValues?.data?.types}
                          error={touched.typeId && errors.typeId}
                        />
                        <ErrorMessage name="typeId" component={ValidationError} />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('purchase_amount')}</div>
                        <StyledInput
                          name="acquisitionAmount"
                          value={values.acquisitionAmount}
                          onChange={handleChange}
                          prefix={selectedCurrencySymbol}
                          error={touched.acquisitionAmount && errors.acquisitionAmount}
                        />
                        <ErrorMessage name="acquisitionAmount" component={ValidationError} />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('loan_amount')}</div>
                        <StyledInput
                          name="loanAmount"
                          value={values.loanAmount}
                          onChange={handleChange}
                          prefix={selectedCurrencySymbol}
                          error={touched.loanAmount && errors.loanAmount}
                        />
                        <ErrorMessage name="loanAmount" component={ValidationError} />
                      </div>
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <div className="grid justify-items-center sm:justify-items-end m-auto w-[90%] sm:w-[60%]">
                    <button
                      className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                      type="submit"
                    >
                      {editLoading ? (
                        <div className="flex justify-center items-center">
                          <CircularProgress color="inherit" size={25} />
                        </div>
                      ) : t('save')
                      }
                    </button>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
