import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';

export const Notifier = ({ data }) => {
  const [open, setIsOpen] = React.useState(false);
  const [snackBarColor, setSnackBarColor] = React.useState({ backgroundColor: '', color: '' });

  const getStatus = () => {
    switch (data?.statusCode) {
      case 200:
        setSnackBarColor({ backgroundColor: '#EEF6F0', color: '#008E0E' });
        break;
      case 403:
        setSnackBarColor({ backgroundColor: '#FFF2F0', color: '#EA3323' });
        break;
      default:
        setSnackBarColor({ backgroundColor: '#FFF2F0', color: '#EA3323' });
    }
  };

  React.useEffect(() => {
    if (data) setIsOpen(true);
    getStatus();
  }, [data]);

  return (
    <Snackbar
      open={open}
      onClose={() => {
        setIsOpen(false);
      }}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <SnackbarContent
        style={{
          ...snackBarColor,
          borderRadius: '12px',
        }}
        message={<span id="client-snackbar">{data?.message}</span>}
      />
    </Snackbar>
  );
};
