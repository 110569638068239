import * as yup from 'yup';

export const leadSchema = yup.object().shape({
  contactRecordType: yup.string().required('contact record field is required'),
  listingType: yup.string().required('liting type field is required'),
  contact: yup.string().required('contact field is required'),
  placeId: yup.string().required('Location field is required'),
  description: yup.string().required('Description field is required'),
  leadSource: yup.string().required('lead source field is required'),
  propertyUsage: yup.string().required('property usage field is required'),
  sqmRange: yup.string().required('sqm range field is required'),
  contactMethod: yup.string().required('contact method field is required'),
  budget: yup.string().typeError('Input must be a number').required('budget field is required'),
});
