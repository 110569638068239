import {
  getAllContacts,
  getContact,
  addContact,
  updateContact,
  deleteContact,
  getContactRelated,
  getContactAttachments,
  addContactAttachment,
  updateContactAttachement,
  deleteContactAttachement,
  contactTransactions,
  exportContacts,
  getCrmAccounts,
  getContactDropdownValues,
} from 'apis/contacts/contacts';

export const contactApiFunctions = {
  getAll: { func: getAllContacts, key: 'contacts' },
  get: { func: getContact, key: 'contactDetails' },
  add: { func: addContact, key: 'contacts' },
  edit: { func: updateContact, key: 'contactDetails' },
  delete: { func: deleteContact, key: 'contacts' },
  getDropdowns: { func: getContactDropdownValues, key: 'contactDropdown' },
  getAttachments: { func: getContactAttachments, key: 'contactAttachments' },
  addAttachment: { func: addContactAttachment, key: 'contactAttachments' },
  editAttachment: { func: updateContactAttachement, key: 'contactAttachments' },
  deleteAttachment: { func: deleteContactAttachement, key: 'contactAttachments' },
  getRelated: { func: getContactRelated, key: 'contactRelated' },
  export: { func: exportContacts, key: '' },
  getFinancials: { func: contactTransactions, key: 'contactFinancials' },
  getCrmAccounts: { func: getCrmAccounts, key: 'crmAccounts' },
};
