import * as yup from 'yup';

export const ReminderSchema = yup.object().shape({
  title: yup.string().required('Title field is required'),
  description: yup.string().required('Description field is required'),
  dueDate: yup
    .date()
    .typeError('Due Date field is required')
    .required('Due Date field is required'),
});
