import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Checkbox } from '@mui/material';

import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';
import { invoicesTableColumns } from 'utils/columns';
import { isMobile, TabName } from 'utils/utils';

import {
  AddButton,
  EditButton,
  DeleteButton,
  CardButton,
  ListButton,
  DeleteDialog,
  ExportButton,
  EmptyList,
  StyledDataGrid,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { ExportDialog } from 'components/dialogs';
import { NewTransactionDialog } from './newTransactionDialog';
import { UpdateTransactionDialog } from './updateTransactionDialog';

import searchIcon from 'assets/search.svg';
import dateIcon from 'assets/dateGreyIcon.svg';

const Transactions = ({ apiFunctions }) => {
  TabName('Transactions');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const [globalError, setGlobalError] = React.useState(null)
  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedTransactions, setSelectedTransactions] = React.useState([]);
  const [allInvoicesSelected, setAllInvoicesSelected] = React.useState(false);
  const [transactionsList, setTransactionsList] = React.useState([]);

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getAll.func,
    apiFunctions.getAll.key,
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedTransactions(data?.data.map((item) => item.invoiceId));
      setAllInvoicesSelected(true);
    } else {
      setSelectedTransactions([]);
      setAllInvoicesSelected(false);
    }
  };

  const handleTransactionDelete = async () => {
    const response = await deleteMutation(selectedTransactions);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setTransactionsList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.transactionStatus.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.glAccount.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.contactName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.dueDate.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setTransactionsList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setTransactionsList(data?.data);
    }
  }, [data?.data]);

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if (globalErrorMsg) {
      setGlobalError({ statusCode: 500, message: globalErrorMsg });
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div className="bg-white rounded-2xl mt-11 p-4">
      <div className="flex justify-between mb-4">
        <Notifier data={mutationResponse} />
        <Notifier data={globalError} />

        <div className="hidden lg:flex items-center pl-5">
          <div className="flex h-[40px] items-center justify-center border border-[#C9C9C9] rounded-lg">
            <Checkbox
              icon={
                <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
              }
              checkedIcon={
                <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
              }
              checked={allInvoicesSelected}
              onChange={handleSelectAll}
            />
            <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row justify-between w-full lg:w-auto gap-4 p-3">
          <div className="flex rounded-lg bg-[#F6F4F4]">
            <div className="flex items-center">
              <img className="pl-2" src={searchIcon} />
            </div>
            <input
              className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
              placeholder={t('search_by_invoice')}
              value={searchValue}
              onChange={handleFilterChange}
            />
          </div>

          <div className="flex gap-4">
            <AddButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'addNewInvoice' })}
              disabled={isLoading}
            />

            <EditButton
              onClick={() =>
                setOpenDialog({ data: selectedTransactions, dialogType: 'editTransaction' })
              }
              disabled={selectedTransactions.length > 1 || !selectedTransactions.length}
            />

            <DeleteButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
              disabled={!selectedTransactions.length}
            />

            <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
          </div>

          <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
            <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
            <ListButton isActivated={activeTab === 'table'} onClick={() => setActiveTab('table')} />
          </div>
        </div>
      </div>

      {!isLoading && !data?.data?.length ? (
        <EmptyList listName="transactions" />
      ) : activeTab === 'table' ? (
        <StyledDataGrid
          checkboxSelection
          rows={transactionsList || []}
          columns={invoicesTableColumns(t)}
          getRowId={(row) => row.transactionId}
          onSelectionModelChange={(newSelectedInvoice) =>
            setSelectedTransactions(newSelectedInvoice)
          }
          selectionModel={selectedTransactions}
          rowsPerPageOptions={[]}
          loading={isLoading}
          pageSize={25}
          rowHeight={70}
          onRowClick={(params) =>
            navigate(`/transaction-overview/${params.row.transactionId}/details`)
          }
          components={{
            LoadingOverlay: () => (
              <div className="mt-8 flex justify-center">
                <CircularProgress color="error" />
              </div>
            ),
          }}
        />
      ) : isLoading ? (
        <div className="flex flex-col gap-4 p-3">
          {[1, 2, 3, 4].map((_) => (
            <SkeletonCardLoader />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 overflow-y-auto p-5">
          {data?.data.map((transaction) => (
            <div
              className="flex flex-col justify-between h-[220px] border border-[#ECECEC] rounded-2xl hover:cursor-pointer  hover:shadow-xl hover:scale-[1.015] p-2"
              onClick={() => navigate(`/transaction-overview/${transaction.transactionId}/details`)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-[10px] text-[#8E8E8E]">{transaction.referenceNumber}</div>
                  <div className="font-bold">{transaction.glAccount}</div>
                </div>

                <div
                  className={`flex items-center justify-center rounded text-xs px-3 py-2 h-fit text-[${transaction.statusColor}] bg-[${transaction.statusBackgroundColor}]`}
                >
                  {transaction.status}
                </div>
              </div>

              <div className="flex gap-1.5 text-xs text-[#8E8E8E] font-light">
                <div className="text-black">{transaction.contactName}</div>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-1 w-[24%]">
                  <div className="text-[9px] text-[#8E8E8E] font-light">{t('net_amount')}</div>
                  <div className="flex justify-center items-center text-xs rounded-lg bg-[#F6F4F4] py-2 px-4">
                    {currencySymbol} {transaction.netAmountDc}
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-[24%]">
                  <div className="text-[9px] text-[#8E8E8E] font-light">{t('vat_percentage') * 100}</div>
                  <div className="flex justify-center items-center text-xs rounded-lg bg-[#F6F4F4] py-2 px-4">
                    {transaction.vatPercentage} %
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-[24%]">
                  <div className="text-[9px] text-[#8E8E8E] font-light">{t('total_amount')}</div>
                  <div className="flex justify-center items-center text-xs rounded-lg bg-[#F6F4F4] py-2 px-4">
                    {currencySymbol} {transaction.totalAmountDc}
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex gap-1">
                  <div className="flex justify-center items-center bg-[#ECECEC] rounded-full p-2">
                    <img src={dateIcon} />
                  </div>
                  <div>
                    <div className="text-xs font-semibold">{t('due_date')}</div>
                    <div className="text-xs text-[#8E8E8E] font-light">{transaction.dueDate}</div>
                  </div>
                </div>

                <div className="font-bold text-[#5A5A5A] text-lg">
                  {currencySymbol} {transaction.totalAmountFc}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <NewTransactionDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />
      <UpdateTransactionDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
      />
      <DeleteDialog
        openDialog={openDialog}
        name="transaction"
        onClose={() => setOpenDialog(null)}
        onDelete={handleTransactionDelete}
        isLoading={deleteLoading}
      />

      <ExportDialog
        apiFunctions={apiFunctions}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        selectedElements={selectedTransactions}
      />
    </div>
  );
};

export default Transactions;
