import React from 'react';
import styled from 'styled-components';

import { CircularProgress, Tooltip } from '@mui/material';
import AddButtonIcon from 'assets/AddButton.svg';
import EditButtonIcon from 'assets/EditButton.svg';
import DeleteButtonIcon from 'assets/DeleteButton.svg';
import ExportButtonIcon from 'assets/ExportButton.svg';
import InfoButtonIcon from 'assets/infoIcon.svg';
import CardButtonIcon from 'assets/CardIcon.svg';
import CardInactiveButtonIcon from 'assets/CardInactiveIcon.svg';
import ListInactiveButtonIcon from 'assets/ListInactiveIcon.svg';
import ListButtonIcon from 'assets/ListIcon.svg';
import backArrow from 'assets/backArrow.svg';
import { useTranslation } from 'react-i18next';

export const AddButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('add')} disableHoverListener={disabled}>
      <button
        className="flex justify-center items-center w-10 h-10 bg-[#FFF2F0] rounded-lg disabled:bg-white"
        onClick={onClick}
        disabled={disabled}
      >
        <img src={AddButtonIcon} />
      </button>
    </Tooltip>
  );
};

export const EditButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('edit')} disableHoverListener={disabled}>
      <button
        className="flex justify-center items-center w-10 h-10 bg-[#F6F4F4] rounded-lg disabled:bg-white focus:outline-none"
        onClick={onClick}
        disabled={disabled}
      >
        <img src={EditButtonIcon} alt="" />
      </button>
    </Tooltip>
  );
};

export const DeleteButton = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('delete')} disableHoverListener={props.disabled}>
      <button
        className="flex justify-center items-center w-10 h-10 bg-[#F6F4F4] rounded-lg disabled:bg-white focus:outline-none"
        {...props}
      >
        <img src={DeleteButtonIcon} alt="" />
      </button>
    </Tooltip>
  );
};

export const ExportButton = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title="Export">
      <button
        className="flex justify-center items-center w-10 h-10 bg-[#F6F4F4] rounded-lg disabled:bg-white"
        {...props}
      >
        <img src={ExportButtonIcon} alt="" />
      </button>
    </Tooltip>
  );
};

export const InfoButton = (props) => (
  <button
    className="flex justify-center items-center w-10 h-10 bg-[#F6F4F4] rounded-lg disabled:bg-white"
    {...props}
  >
    <img src={InfoButtonIcon} alt="" />
  </button>
);

export const ListButton = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('list_view')}>
      <button
        className={`flex justify-center items-center w-11 h-8 ${
          props.isActivated ? 'bg-white' : 'bg-[#F6F4F4]'
        } rounded-lg`}
        {...props}
      >
        <img src={props.isActivated ? ListButtonIcon : ListInactiveButtonIcon} alt="" />
      </button>
    </Tooltip>
  );
};

export const CardButton = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('card_view')}>
      <button
        className={`flex justify-center items-center w-11 h-8 ${
          props.isActivated ? 'bg-white' : 'bg-[#F6F4F4]'
        } rounded-lg`}
        {...props}
      >
        <img src={props.isActivated ? CardButtonIcon : CardInactiveButtonIcon} alt="" />
      </button>
    </Tooltip>
  );
};

export const StyledButton = (props) => (
  <Button {...props}>
    {!props.isLoading && props.children}
    {props.isLoading && <CircularProgress color="inherit" size={25} />}
  </Button>
);

export const DialogBackButton = (props) => (
  <button
    className="flex justify-center items-center gap-4 w-[120px] h-[50px] bg-[#F6F4F4] rounded-2xl text-[#8E8E8E]"
    onClick={props.onClick}
  >
    <img className="w-[16px] h-full" src={backArrow} />
    Back
  </button>
);

const Button = styled.button`
  border: none;
  outline: none;
  display: flex;
  flex: 0 0 auto;
  user-select: none;
  letter-spacing: 0;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  height: 45px;
  background: aliceblue;
  box-shadow: ${({ variant }) => variant === 'secondary' && '0px 3px 6px #9A9A9A54'};
  border-radius: 25px;
  width: ${({ width }) => width + 'px'};
  color: red};
&:hover {
  cursor: pointer;
}
&[disabled] {
  background: peachpuff;
  cursor: default;
}
`;

const HoverMessage = styled.div`
  position: fixed;
  z-index: 9999;
  top: 180px;
  right: 270px;
  transform: translateX(0.5rem);
  white-space: nowrap;
  background-color: #fff;
  padding: 0.25rem;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;
