import {
  getProperties,
  getProperty,
  addProperty,
  updateProperty,
  deleteProperty,
  getPropertyRelated,
  getPropertyAttachment,
  addPropertyAttachment,
  updatePropertyAttachment,
  deletePropertyAttachment,
  getPropertyPulicImages,
  addPropertyOwnership,
  updatePropertyOwnership,
  deletePropertyOwnership,
  addPropertyAmenity,
  addRoom,
  updateRoom,
  deleteRoom,
  addAsset,
  updateAsset,
  deleteAsset,
  getPropertyDropdownValues,
  getPropertyInsights,
  exportProperties,
  notifyTenantDropdownValues,
  sendNotification,
  getPropertyCommunications,
  googleMapsAutoComplete,
  getAllGLAccounts,
  addGLAccount,
  updateGLAccount,
  deleteGLAccount,
  getSubTypes,
  addSubType,
  updateSubType,
  deleteSubType,
  getGlAccountsDropdown,
  linkGlAccountsToProperty,
  getLinkedGlAccounts,
} from 'apis/properties/properties';
import { storeReminders } from 'apis/todos/todos';

export const propertyApiFunctions = {
  getAll: { func: getProperties, key: 'properties' },
  get: { func: getProperty, key: 'propertyDetails' },
  add: { func: addProperty, key: 'properties' },
  edit: { func: updateProperty, key: 'propertyDetails' },
  delete: { func: deleteProperty, key: 'properties' },
  getInights: { func: getPropertyInsights, key: 'propertyInsights' },
  getRelated: { func: getPropertyRelated, key: 'propertyRelated' },
  addOwnership: { func: addPropertyOwnership, key: 'propertyDetails' },
  editOwnership: { func: updatePropertyOwnership, key: 'propertyDetails' },
  deleteOwnership: { func: deletePropertyOwnership, key: 'propertyDetails' },
  addAmenity: { func: addPropertyAmenity, key: 'propertyDetails' },
  addRoom: { func: addRoom, key: 'propertyRelated' },
  editRoom: { func: updateRoom, key: 'propertyRelated' },
  deleteRoom: { func: deleteRoom, key: 'propertyRelated' },
  addAsset: { func: addAsset, key: 'propertyRelated' },
  editAsset: { func: updateAsset, key: 'propertyRelated' },
  deleteAsset: { func: deleteAsset, key: 'propertyRelated' },
  getAttachments: { func: getPropertyAttachment, key: 'propertyAttachments' },
  addAttachment: {
    func: addPropertyAttachment,
    key: ['propertyAttachments', 'propertyPublicImage'],
  },
  editAttachment: { func: updatePropertyAttachment, key: 'propertyAttachments' },
  deleteAttachment: { func: deletePropertyAttachment, key: 'propertyAttachments' },
  getPublicImages: { func: getPropertyPulicImages, key: 'propertyPublicImage' },

  setRemainder: { func: storeReminders, key: '' },
  getCommunications: { func: getPropertyCommunications, key: '' },
  export: { func: exportProperties, key: '' },
  sendNotification: { func: sendNotification, key: 'propertyDetails' },
  googleMapsAutoComplete: { func: googleMapsAutoComplete, key: 'googleAutoComplete' },

  //  dropdown api's
  getDropdowns: { func: getPropertyDropdownValues, key: 'propertyDropdown' },
  tenantDropdown: { func: notifyTenantDropdownValues, key: 'tenantDropdown' },
  getGlAccountsDropdown: { func: getGlAccountsDropdown, key: 'glAccountDropdown' },

  //  GL account apis'
  getAllGLAccounts: { func: getAllGLAccounts, key: 'glAccounts' },
  addGLAccount: { func: addGLAccount, key: 'glAccounts' },
  editGLAccount: { func: updateGLAccount, key: 'glAccounts' },
  deleteGLAccount: { func: deleteGLAccount, key: 'glAccount' },
  getSubTypes: { func: getSubTypes, key: 'glSubTypes' },
  addSubType: { func: addSubType, key: ['glSubTypes', 'glAccountDropdown'] },
  editSubType: { func: updateSubType, key: ['glSubTypes', 'glAccountDropdown'] },
  deleteSubType: { func: deleteSubType, key: ['glSubTypes', 'glAccountDropdown'] },


  linkGlAccountsToProperty: { func: linkGlAccountsToProperty, key: 'linkGlAccountsToProperty' },
  getLinkedGlAccounts: { func: getLinkedGlAccounts, key: 'getLinkedGlAccounts' }
};
