import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDebounce } from 'hooks/useDebounce';
import { globalSearch } from 'apis/dashboard/dashboard';

import { CircularProgress } from '@mui/material';
import searchIcon from 'assets/search.svg';
import crossIcon from 'assets/blackCrossIcon.svg';
import { useTranslation } from 'react-i18next';

export const GlobalSearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debounceSearch = useDebounce(searchValue, 750);

  const {
    data,
    isLoading: searchLoading,
    error,
  } = useQuery(['globalSearch', debounceSearch, page], () => globalSearch(debounceSearch, page), {
    enabled: !!searchValue,
  });

  const handleSearchNavigation = (type, id) => {
    if (type === 'offer') navigate(`/listing-overview/${id}/offers`);
    else if (type === 'payment') navigate(`/invoice-overview/${id}/payments`);
    else navigate(`/${type}-overview/${id}/details`);
  };

  return (
    <div className="flex rounded-lg items-center bg-white w-full sm:w-64">
      <img className="pl-2" src={searchIcon} />

      <div>
        <input
          className="rounded-lg p-2 outline-none"
          placeholder={t('search_placeholder')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        {searchValue && (
          <div className="bg-white flex flex-col gap-2 max-h-[200px] overflow-y-auto rounded-lg p-4 w-64 absolute top-12 right-[168px] z-10">
            <div className="flex justify-between items-center">
              <div className="text-[#8E8E8E] font-bold">Search '{searchValue}'</div>
              <img
                className="hover:cursor-pointer"
                src={crossIcon}
                onClick={() => setSearchValue('')}
              />
            </div>

            {searchLoading ? (
              <div className="flex justify-center items-center">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
              </div>
            ) : !data.data.length ? (
              <div className="mt-2 flex justify-center text-[#8E8E8E] font-semibold items-center">
                No results found!
              </div>
            ) : (
              data.data.map((searchItem) => (
                <div
                  className="flex flex-col gap-1 justify-center rounded-lg px-3 py-1 hover:bg-[#C9C9C9] hover:cursor-pointer"
                  onClick={() => handleSearchNavigation(searchItem.type, searchItem.id)}
                >
                  <div className="text-sm font-semibold">{searchItem.name}</div>
                  <div className="flex gap-2 text-xs text-[#8E8E8E]">
                    <div>{searchItem.type},</div>
                    <div>{searchItem.description}</div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};
