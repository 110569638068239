export const transactionInitialState = () => {
  return {
    contactId: '',
    glAccountId: '',
    description: '',
    transactionDate: null,
    dueDate: null,
    invoiceId: '',
    totalAmountFc: null,
    netAmountFc: null,
    transactionStatusId: null,
    accountingReference: null,
    applyVat: '',
    //transaction Line Initial States
    transactionLinesDescription: '',
    amountFc: '',
    transactionLineTypeId: '',
  };
};
