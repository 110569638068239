import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

export const getReports = (reportName) => {
  //split the words by space of reportName
  const reportNameArray = reportName.split(' ');
  //display the first word of reportNameArray
  const reportNameFirstWord = reportNameArray[0];

  return axios.get(
    `/api/v1/report-app/reports/${getUserGroupId()}/?report_name=${reportNameFirstWord}%20Report`,
  );
};
export const getReportsDropdown = () =>
  axios.get(`/api/v1/report-app/report/dropdown-values/${getUserGroupId()}`);
