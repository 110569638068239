import axios from '../axiosInstance';
import moment from 'moment';
import { getUserGroupId } from 'utils/utils';

/*
 * |-----------------------------------------------------------------------
 * | Closings API
 * | getClosings - getClosing - addClosing - updateClosing - deleteClosing
 * |-----------------------------------------------------------------------
 */

export const getClosings = () => {
  return axios.get(`/api/v1/closing-app/closings/${getUserGroupId()}`);
};

export const getClosing = (closingId) =>
  axios.get(`api/v1/closing-app/closing/show/${closingId}/${getUserGroupId()}`);

export const addClosing = (payload) => {
  return axios.post(`api/v1/closing-app/closing/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateClosing = (payload) => {
  return axios.post(`api/v1/closing-app/closing/update`, {
    closing_id: payload.closingId,
    listing_id: payload.listing,
    closing_type_id: payload.closingTypeId,
    progress_id: payload.status,
    contact_id: payload.tenantId,
    contract_start: moment(payload.effectiveFrom).format('YYYY-MM-DD'),
    contract_end: moment(payload.effectiveTo).format('YYYY-MM-DD'),
    exit_date: moment(payload.exitDate).format('YYYY-MM-DD'),
    currency_id: payload.currency,
    vat_applicable: payload.vat,
    offered_amount: payload.offeredAmount,
    deal_amount: payload.dealAmount,
    paid_amount: payload.paidAmount,
    cancelled_amount: payload.cancelledAmount,
    due_amount: payload.dueAmount,
    comments: payload.comments,
    closing_type_category_id: payload.closingTypeCategory,
    accounting_reference: payload.glAccount,
    due_payment_fee: payload.duePaymentFee,
    due_payment_fee_days: payload.duePaymentFeeDays,
    user_group_id: getUserGroupId(),
  });
};

export const deleteClosing = (closingIds) => {
  return axios.post(`api/v1/closing-app/closing/destroy`, {
    closing_ids: closingIds,
    user_group_id: getUserGroupId(),
  });
};

// * Closing Related API
export const getClosingRelated = (closingId) =>
  axios.get(`api/v1/closing-app/closing/related/${closingId}/${getUserGroupId()}`);

/*
 * |------------------------------------------------------------------------------------------------
 * | Closing Attahcment API
 * | getClosingAttachment - addClosingAttachment - updateClosingAttachment - deleteClosingAttachment
 * |------------------------------------------------------------------------------------------------
 */

export const getClosingAttachment = (closingId) => {
  return axios.get(`api/v1/closing-app/closing/attachment/${closingId}/${getUserGroupId()}`);
};

export const addClosingAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachments).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });
  formData.append('closing_id', payload.closing_id);
  formData.append('user_group_id', getUserGroupId());
  return axios.post(`api/v2/closing-app/closings/attachments/store`, formData);
};

export const updateClosingAttachment = (payload) => {
  return axios.post(`api/v1/closing-app/closing/attachment/update`, {
    ...payload,
    attachment_type_id: '1',
    user_group_id: getUserGroupId(),
  });
};

export const deleteClosingAttachment = (closingAttachmentId) => {
  return axios.post(`api/v1/closing-app/closing/attachment/destroy`, {
    closing_attachment_ids: [closingAttachmentId],
    user_group_id: getUserGroupId(),
  });
};

/*
 * |-----------------------------------------------------
 * | Closing Tasks API
 * | addClosingTask - editClosingTask - deleteClosingTask
 * |-----------------------------------------------------
 */

export const addClosingTask = (payload) => {
  return axios.post(`api/v1/closing-app/task/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const editClosingTask = (payload) => {
  return axios.post(`api/v1/closing-app/task/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteClosingTask = (taskId) => {
  return axios.post(`api/v1/closing-app/task/destroy`, {
    task_id: taskId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |-------------------------------------------------------------------------------
 * | Closing Payment Range API
 * | addClosingPaymentRange - editClosingPaymentRange - deleteClosingPaymentRange
 * |-------------------------------------------------------------------------------
 */

export const addClosingPaymentRange = (payload) => {
  return axios.post(`api/v2/closing-app/payment-range/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const editClosingPaymentRange = (payload) => {
  return axios.post(`api/v1/closing-app/payment-range/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteClosingPaymentRange = (paymentRangeId) => {
  return axios.post(`api/v1/closing-app/payment-range/destroy`, {
    payment_range_id: paymentRangeId,
    user_group_id: getUserGroupId(),
  });
};

// * closing dropdown API
export const getClosingDropdownData = () =>
  axios.get(`api/v1/closing-app/closing/dropdown-values/${getUserGroupId()}`);

// * export closings
export const exportClosings = (payload) => {
  return axios.post(`api/v2/closing-app/closing/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    closing_ids: payload.closingIds,
  });
};

export const getClosingSharedAttachments = (closingId) => {
  return axios.get(
    `/api/v2/closing-app/closing/shared-attachments/${closingId}/${getUserGroupId()}`,
  );
};

export const getClosingInsights = (closingId) => {
  return axios.get(`/api/v2/closing-app/closings/metrics/${closingId}/${getUserGroupId()}`);
};

export const getGlAccounts = () =>
  axios.get(`/api/v2/closing-app/accounting/accounts/gl/${getUserGroupId()}`);
