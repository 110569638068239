import {
  addressAutoCompleteAuth,
  loginSubscription,
  subscriptionDropdown,
} from 'apis/authentication/authentication';

export const AuthApiFunctions = {
  getAddress: { func: addressAutoCompleteAuth, key: 'authAddress' },
  subscribeCustomer: { func: loginSubscription, key: 'subscription' },
  subscriptionDropdown: { func: subscriptionDropdown, key: 'subscriptionDropdown' },
};
