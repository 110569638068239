import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { leadInitialState } from 'initialStates';
import { leadSchema } from 'validationSchemas';
import { useDebounce } from 'hooks/useDebounce';
import { isMobile } from 'utils/utils';

import { StyledSelect, StyledInput, StyledDialog, Notifier } from 'components';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
} from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

/**
 * * if add new lead dialog is opened in contact related:
 * * contact record type & contact values must be retrieved and disabled
 * * dont redirect to lead overview page when adding a new lead in contact related
 * * invalidate contact related queries on successful API call
 * * ----------------------------------------------------------------------------
 * * if add new lead dialog is opened in listing related:
 * * listing type must retrived and disabled
 * * dont redirect to lead overview page when adding a new lead in listing related
 * * invalidate listing related queries on successful API call
 * * ----------------------------------------------------------------------------
 * ! disable contactRecordType & contact if contactId is present
 * ! disable listingType field if listingTypeId is present
 * ! addNewleadDialog is used in these components: contact related | listing related | leads list | lead overview
 */

export const LeadDialog = ({ openDialog, onClose, apiFunctions }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  /**
   * if lead dialog is opened from contact related, get contactDetails & listing details
   * via router context & pass it to lead initial state
   */
  const { contactDetails, listingDetails } = useOutletContext() || {};

  const leadId = openDialog?.data ? openDialog?.data[0] : '';
  const selectedCurrencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const [searchValue, setSearchValue] = React.useState('');
  const debounceSearch = useDebounce(searchValue, 750);

  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addNewLead') return true;
    else if (openDialog?.dialogType === 'editLead') return true;
    return false;
  };

  const { data: leadDetails, isLoading } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    leadId,
    {
      enabled: isDialogOpen(),
    },
  );

  const { data: dropdownData, isLoading: dropdownDataLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    null,
    {
      enabled: isDialogOpen(),
    },
  );

  const { data: autoCompleteData, isLoading: searchLoading } = useCustomQuery(
    apiFunctions.getAddress.func,
    apiFunctions.getAddress.key,
    debounceSearch,
    { enabled: !!searchValue },
  );

  const {
    mutateAsync: addMutation,
    isLoading: addLoading,
    data: addMutationResponse,
  } = useCustomMutation(apiFunctions.add.func, apiFunctions.add.key);

  const {
    mutateAsync: editMutation,
    isLoading: editLoading,
    data: editMutationResponse,
  } = useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  const {
    contactMethods,
    contactRecordTypes,
    contacts,
    leadSource,
    listingType,
    propertyUsage,
    sqmRange,
  } = dropdownData?.data || {};

  return (
    <>
      <Notifier data={addMutationResponse} />
      <Notifier data={editMutationResponse} />

      <Formik
        enableReinitialize
        validationSchema={leadSchema}
        initialValues={leadInitialState(leadDetails?.data || contactDetails || listingDetails)}
        onSubmit={async (values, { resetForm }) => {
          let payload = {
            leadId: leadId,
            potential_listings: listingDetails?.listingId ? [listingDetails?.listingId] : null,
            ...values,
          };

          if (openDialog?.dialogType === 'addNewLead') {
            const addLeadResponse = await addMutation(payload);

            if (addLeadResponse.statusCode === 200) {
              resetForm();
              onClose();
              navigate(`/lead-overview/${addLeadResponse.data.leadId}/details`);
            }
          } else {
            const editLeadResponse = await editMutation(payload);
            if (editLeadResponse.statusCode === 200) onClose();
          }
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue, dirty, resetForm }) => (
          <StyledDialog
            fullWidth
            width="1064px"
            fullScreen={isMobile()}
            open={isDialogOpen()}
            isEdited={dirty}
            onClose={() => {
              resetForm();
              onClose();
            }}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex items-center w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
                    <img alt="" src={dialogTitleIcon} />
                  </div>
                  <span className="font-semibold">
                    {openDialog?.dialogType === 'addNewLead' ? t('add_new_lead') : t('edit_lead')}
                  </span>
                </div>
              </div>
            </div>

            {isLoading && dropdownDataLoading ? (
              <div className="flex h-full justify-center items-center">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] md:w-[60%] m-auto items-center gap-4">
                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('contact_record_type')}{' '}
                        {errors['contactRecordType'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="contactRecordType"
                        placeholder={t('contact_record_type')}
                        value={values.contactRecordType}
                        onChange={handleChange}
                        error={touched.contactRecordType && errors.contactRecordType}
                        itemsList={contactRecordTypes}
                        disabled={contactDetails}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="contactRecordType" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('listing_type')}{' '}
                        {errors['listingType'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="listingType"
                        placeholder={t('listing_type')}
                        value={values.listingType}
                        onChange={handleChange}
                        error={touched.listingType && errors.listingType}
                        itemsList={listingType}
                        disabled={listingDetails}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="listingType" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('contact')}{' '}
                        {errors['contact'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="contact"
                        placeholder={t('contact')}
                        value={values.contact}
                        onChange={handleChange}
                        error={touched.contact && errors.contact}
                        itemsList={contacts?.filter(
                          (contact) => contact.contactRecordTypeId === values.contactRecordType,
                        )}
                        disabled={contactDetails}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="contact" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm">
                        {t('location')}{' '}
                        {errors['placeId'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <Autocomplete
                        autoComplete
                        noOptionsText="No locations"
                        loading={searchLoading}
                        options={autoCompleteData?.data || []}
                        getOptionLabel={(o) => o.name || values.description}
                        value={values.description}
                        onChange={(e, newValue) => {
                          setFieldValue('description', newValue.name);
                          setFieldValue('placeId', newValue.id);
                        }}
                        onInputChange={(e, newInputValue) => {
                          setSearchValue(newInputValue);
                        }}
                        sx={{
                          width: '100%',
                          '.MuiOutlinedInput-root': {
                            borderRadius: 4,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="description" placeholder="Enter Location" />
                        )}
                      />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('lead_source')}{' '}
                        {errors['leadSource'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="leadSource"
                        placeholder={t('lead_source')}
                        value={values.leadSource}
                        onChange={handleChange}
                        error={touched.leadSource && errors.leadSource}
                        itemsList={leadSource}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="leadSource" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('property_usage')}{' '}
                        {errors['propertyUsage'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="propertyUsage"
                        placeholder={t('property_usage')}
                        value={values.propertyUsage}
                        onChange={handleChange}
                        error={touched.propertyUsage && errors.propertyUsage}
                        itemsList={propertyUsage}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="propertyUsage" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('sqm_range')}{' '}
                        {errors['sqmRange'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="sqmRange"
                        placeholder={t('sqm_range')}
                        value={values.sqmRange}
                        onChange={handleChange}
                        error={touched.sqmRange && errors.sqmRange}
                        itemsList={sqmRange}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="sqmRange" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('contact_method')}{' '}
                        {errors['contactMethod'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="contactMethod"
                        placeholder={t('contact_method')}
                        value={values.contactMethod}
                        onChange={handleChange}
                        error={touched.contactMethod && errors.contactMethod}
                        itemsList={contactMethods}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="contactMethod" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('budget')}{' '}
                        {errors['budget'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="budget"
                        placeholder={t('budget')}
                        prefix={selectedCurrencySymbol}
                        value={values.budget}
                        onChange={handleChange}
                        error={touched.budget && errors.budget}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="budget" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('comments')}{' '}
                        {errors['comments'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="comments"
                        placeholder={t('comments')}
                        value={values.comments}
                        onChange={handleChange}
                        error={touched.comments && errors.comments}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="comments" />
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <div className="grid justify-items-center sm:justify-items-end m-auto w-[90%] sm:w-[60%]">
                    <button
                      className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                      type="submit"
                    >
                      {addLoading || editLoading ? (
                        <div className="flex justify-center items-center">
                          <CircularProgress color="inherit" size={25} />
                        </div>
                      ) : openDialog?.dialogType === 'addNewLead' ? (
                        t('add')
                      ) : (
                        t('edit')
                      )}
                    </button>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
