import * as yup from 'yup';

export const listingSchema = yup.object({
  listingType: yup.string().required('Listing Type field is required'),
  amount: yup.number().required('Amount field is required').typeError('Amount must be a number'),
  availableFrom: yup.date().typeError('Available From field is required'),
  availableTo: yup.date().min(yup.ref('availableFrom')).typeError('Available To field is required'),
  property: yup.string().required('Property field is required'),
  listingTypeCategory: yup.string().required('Listing Type Category field is required'),
});
