import React from 'react';
import { useIsMutating } from 'react-query';

import { StyledDialog } from 'components';
import { DialogContent, DialogActions, CircularProgress } from '@mui/material';

export const DeleteAttachmentDialog = ({ openDialog, onClose, onDelete }) => {
  const { attachment } = openDialog?.data || {};
  const isMutating = useIsMutating();

  return (
    <StyledDialog fullWidth open={openDialog?.dialogType === 'deleteAttachment'} onClose={onClose}>
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div className="text-2xl font-bold">Delete File</div>
          <div className="text-sm text-[#15192080]">Do you want to delete this file?</div>
          <img className="object-cover h-[200px] w-full rounded-lg" src={attachment?.fileUrl} />
        </div>
      </DialogContent>

      <DialogActions>
        <div className="grid justify-items-center m-auto w-[90%] sm:w-[60%]">
          <button
            className="min-w-[136px] min-h-[42px] bg-[#EA3323] text-white rounded-lg font-semibold"
            onClick={onDelete}
          >
            {!!isMutating ? (
              <div className="flex items-center justify-center">
                <CircularProgress color="inherit" size={25} />
              </div>
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </DialogActions>
    </StyledDialog>
  );
};
