import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';

import { listingSchema } from 'validationSchemas';
import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { isMobile } from 'utils/utils';

import { StyledSelect, StyledDialog, StyledInput, DatePicker, Notifier } from 'components';
import { CircularProgress, DialogContent, DialogActions } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

/**
 * if Listing dialog was opened from property related page:
 * property field must retrieved & disabled
 * propertyId can be retrived either from openDialog or useParams() propertyId
 * -----------------------------------------------------------------------------
 * Listing dialog is used in two components:
 * property related
 * listings list
 */

export const ListingDialog = ({ openDialog, onClose, apiFunctions }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addListing') return true;
    else if (openDialog?.dialogType === 'editListing') return true;
    return false;
  };

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const { propertyId = '' } = useParams(); // set default value in case useParams() returns null

  const listingId = openDialog?.data || '';

  const { data: dropdownDownData, isLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    {
      enabled: isDialogOpen(),
    },
  );

  const {
    mutateAsync: addListingMutation,
    isLoading: addListingLoading,
    data: addListingMutationResponse,
  } = useCustomMutation(apiFunctions.add.func, apiFunctions.add.key);

  const {
    mutateAsync: editListingMutation,
    isLoading: editListingLoading,
    data: editListingMutationResponse,
  } = useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  const { generalInfo, summary } = openDialog?.data || {};
  const { listingTypes, properties, listingTypeCategories } = dropdownDownData?.data || {};

  return (
    <>
      <Notifier data={addListingMutationResponse} />
      <Notifier data={editListingMutationResponse} />

      <Formik
        enableReinitialize
        initialValues={{
          listingType: summary?.listingTypeId || '',
          amount: generalInfo?.askingPrice || '',
          availableFrom: generalInfo?.availableFrom || null,
          availableTo: generalInfo?.availableTo || null,
          property: generalInfo?.propertyId || '',
          listingTypeCategory: generalInfo?.listingTypeCategoryId || '',
        }}
        validationSchema={listingSchema}
        onSubmit={async (values, { resetForm }) => {
          let payload = {
            listing_id: listingId,
            listing_type_id: values.listingType,
            available_from: moment(values.availableFrom).format('YYYY-MM-DD'),
            available_to: moment(values.availableTo).format('YYYY-MM-DD'),
            asking_price: values.amount,
            property_id: values.property,
            listing_type_category_id: values.listingTypeCategory,
          };

          if (openDialog?.dialogType === 'addListing') {
            const addListingResponse = await addListingMutation(payload);
            if (addListingResponse.statusCode === 200) {
              onClose();
              resetForm();
              navigate(`/listing-overview/${openDialog.data.listingId}/details`);
            }
          } else {
            const editListingResponse = await editListingMutation(payload);
            if (editListingResponse.statusCode === 200) onClose();
          }
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue, dirty, resetForm }) => (
          <StyledDialog
            fullScreen={isMobile()}
            width="1064px"
            open={isDialogOpen()}
            onClose={() => {
              resetForm();
              onClose();
            }}
            isEdited={dirty}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex items-center w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
                    <img alt="" src={dialogTitleIcon} />
                  </div>
                  <span className="font-semibold">
                    {openDialog?.dialogType === 'addListing'
                      ? t('add_new_listing')
                      : t('edit_listing')}
                  </span>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center mt-2">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] md:w-[60%] m-auto items-center gap-4">
                    <div>
                      <div className="text-sm pb-1">
                        {t('Listing Type')}
                        {errors['listingType'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="listingType"
                        placeholder={t('Listing Type')}
                        value={values.listingType}
                        onChange={handleChange}
                        itemsList={listingTypes}
                        error={touched.listingType && errors.listingType}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="listingType" />
                    </div>

                    <div>
                      <div className="text-sm pb-1">
                        {t('Property')}{' '}
                        {errors['property'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="property"
                        placeholder={t('Property')}
                        value={values.property}
                        onChange={handleChange}
                        itemsList={properties}
                        disabled={propertyId}
                        error={touched.property && errors.property}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="property" />
                    </div>

                    <div>
                      <div className="text-sm pb-1">
                        {t('Available from')}{' '}
                        {errors['availableFrom'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <DatePicker
                        name="availableFrom"
                        value={values.availableFrom}
                        onChange={(value) => setFieldValue('availableFrom', value)}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="availableFrom" />
                    </div>

                    <div>
                      <div className="text-sm pb-1">
                        {t('Available To')}{' '}
                        {errors['availableTo'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <DatePicker
                        name="availableTo"
                        value={values.availableTo}
                        onChange={(value) => setFieldValue('availableTo', value)}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="availableTo" />
                    </div>

                    <div>
                      <div className="text-sm pb-1">
                        {t('Amount')}{' '}
                        {errors['amount'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="amount"
                        placeholder={t('Amount')}
                        value={values.amount}
                        onChange={handleChange}
                        error={touched.amount && errors.amount}
                        prefix={currencySymbol}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="amount" />
                    </div>

                    <div>
                      <div className="text-sm pb-1">
                        {t('listing_type_category')}{' '}
                        {errors['listingTypeCategory'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="listingTypeCategory"
                        placeholder={t('listing_type_category')}
                        value={values.listingTypeCategory}
                        onChange={handleChange}
                        itemsList={listingTypeCategories}
                        error={touched.listingTypeCategory && errors.listingTypeCategory}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="listingTypeCategory" />
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <div className="flex justify-center gap-4 w-full pt-4">
                    <div className="flex justify-end w-[60%]">
                      <button
                        className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                        type="submit"
                      >
                        {addListingLoading || editListingLoading ? (
                          <div className="flex justify-center items-center">
                            <CircularProgress color="inherit" size={25} />
                          </div>
                        ) : openDialog?.dialogType === 'addListing' ? (
                          t('add')
                        ) : (
                          t('edit')
                        )}
                      </button>
                    </div>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
