import { useQuery, useQueryClient } from 'react-query';
import { showNotification } from '../apis/notifications/notifications';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const NotificationDetails = ({ notification, notificationId }) => {
  const [expanded, setExpanded] = React.useState(false);

  const queryClient = useQueryClient();

  const { data: notificationDetails, isLoading: notificationDetailsLoading } = useQuery(
    ['notificationDetails', notificationId],
    () => showNotification(notificationId),
    { enabled: expanded },
  );

  return (
    <div className="w-[70%]">
      <Accordion
        sx={{ border: '1px solid #f0f0f5', borderRadius: '20px' }}
        onChange={() => {
          setExpanded(true);
          queryClient.invalidateQueries('notificationsCount');
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex w-full justify-between items-center text-[#212121]">
            <div>{notification?.title}</div>
            <div
              className={`py-2 px-3 bg-[${notification?.backgroundColor}] text-[${notification?.textColor}] text-xs rounded-lg`}
            >
              {notification?.isRead ? 'Read' : 'Unread'}
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {notificationDetailsLoading ? (
            <div className="flex justify-center items-center">
              <CircularProgress size={20} sx={{ color: '#EA3323' }} />
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <div className="flex justify-between items-center">
                <div className={`py-2 text-sm rounded`}>{notificationDetails?.data?.date}</div>
              </div>

              <div className="text-[30px]">{notificationDetails?.data?.description}</div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
