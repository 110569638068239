import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import { sidebarItems } from 'utils/constants';
import { getNotificationsCount } from 'apis/notifications/notifications';
import { logout } from 'apis/authentication/authentication';

import { UserGroupSelector, GlobalSearch } from 'components';

import CloseIcon from '@mui/icons-material/Close';
import { Skeleton, Menu, MenuItem } from '@mui/material';
import userIcon from 'assets/usericon.svg';
import questionIcon from 'assets/questionMark.svg';
import notification from 'assets/notification.svg';

export const MobileNavigationMenu = ({ isMenuOpen, onMenuToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userActions, setUserActions] = React.useState(null);

  const { data: notificationCount, isLoading: notificationLoading } = useQuery(
    'notificationsCount',
    getNotificationsCount,
  );

  const { mutate: logoutMutation } = useMutation(() => logout());

  const handleNavigateToSettingsPage = () => {
    onMenuToggle();
    navigate('/settings/profile');
  };

  const isActiveLink = (routePath) => {
    return routePath.substr(0, 5) === location.pathname.substr(0, 5);
  };

  const handleLogout = () => {
    onMenuToggle();
    logoutMutation(null, {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          localStorage.clear();
          setUserActions(null);
          navigate('/login');
        }
      },
    });
  };

  return (
    <div
      className={`${
        isMenuOpen ? 'menu-open h-screen' : 'menu-closed h-0'
      } overflow-scroll ease-in duration-500 bg-[#E5E5E5]`}
    >
      <div className="flex items-center justify-between">
        <div className="text-4xl">Welcome Username!</div>
        <CloseIcon onClick={onMenuToggle} sx={{ color: '#5A5A5A' }} />
      </div>

      <div className="flex flex-col sm:flex-row justify-between mt-4 gap-4">
        <GlobalSearch />

        <div className="flex justify-between gap-2">
          <UserGroupSelector />

          <div className="flex gap-2">
            <div className="flex relative items-center justify-center cursor-pointer bg-white rounded-lg w-10 p-2 z-0">
              {notificationLoading ? (
                <Skeleton
                  className="z-10 absolute top-[-10%] right-[-20%]"
                  variant="circular"
                  width={20}
                  height={20}
                />
              ) : (
                !!notificationCount.data.count && (
                  <div className="z-10 flex justify-center items-center text-xs font-bold text-white w-5 h-5 absolute top-[-10%] right-[-20%] rounded-full bg-[#EA3323]">
                    {notificationCount.data.count}
                  </div>
                )
              )}
              <img src={notification} className="w-10" />
            </div>

            <img className="cursor-pointer bg-white rounded-lg w-10 p-2" src={questionIcon} />

            <img
              className="cursor-pointer bg-white rounded-lg w-10 p-2"
              src={userIcon}
              onClick={(e) => setUserActions(e.currentTarget)}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 mt-8">
        {sidebarItems.map((item) => (
          <Link onClick={onMenuToggle} key={item.name} to={item.routePath}>
            <div
              className={`flex gap-4 p-4 rounded-lg ${
                isActiveLink(item.routePath) ? 'bg-[#FFF2F0]' : 'bg-white'
              }`}
            >
              <img src={isActiveLink(item.routePath) ? item.activeIcon : item.icon} />
              <div className={`${isActiveLink(item.routePath) ? 'text-[#EA3323]' : 'text-[#111]'}`}>
                {item.name}
              </div>
            </div>
          </Link>
        ))}
      </div>

      <Menu
        anchorEl={userActions}
        keepMounted
        open={Boolean(userActions)}
        onClick={() => setUserActions(null)}
      >
        <MenuItem onClick={handleNavigateToSettingsPage}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
