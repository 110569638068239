import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage } from 'formik';

import { useCustomMutation } from 'mutations';
import { glAccountSchema } from 'validationSchemas';
import { glAccountInitialState } from 'initialStates';
import { isMobile } from 'utils/utils';

import { StyledSelect, StyledInput, StyledDialog, Notifier } from 'components';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

export const GLAccountDialog = ({
  openDialog,
  onClose,
  apiFunctions,
  dropdownData,
  dropdownLoading,
  propertyId,
}) => {
  const { t } = useTranslation();

  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addNewLedger') return true;
    else if (openDialog?.dialogType === 'editLedger') return true;
    return false;
  };

  const {
    mutateAsync: addMutation,
    data: addMutationResponse,
    isLoading: addLoading,
  } = useCustomMutation(apiFunctions.addGLAccount.func, apiFunctions.addGLAccount.key);

  const {
    mutateAsync: editMutation,
    data: editMutationResponse,
    isLoading: editLoading,
  } = useCustomMutation(apiFunctions.editGLAccount.func, apiFunctions.editGLAccount.key);

  const { types, subTypes, referenceTypes, enableVat, balanceSide, closings, repairs } =
    dropdownData?.data || {};

  const getReferenceRecordDropdown = (referenceType) => {
    if (referenceType === 'CLOSING') return closings;
    return repairs;
  };

  const isDisabled = () => {
    if (openDialog?.dialogType === 'editLedger') return true;
    return false;
  };

  return (
    <>
      <Notifier data={addMutationResponse} />
      <Notifier data={editMutationResponse} />

      <Formik
        enableReinitialize
        initialValues={glAccountInitialState(openDialog?.data)}
        validationSchema={glAccountSchema}
        onSubmit={async (values, { resetForm }) => {
          let payload = {
            parent_reference_id: propertyId,
            parent_reference_type: 'PROPERTY',
            ...values,
          };

          if (openDialog?.dialogType === 'addNewLedger') {
            const addLeadgerResponse = await addMutation(payload);

            if (addLeadgerResponse.statusCode === 200) {
              resetForm();
              onClose();
            }
          } else if (openDialog?.dialogType === 'editLedger') {
            const editLeadgerResponse = await editMutation(payload);

            if (editLeadgerResponse.statusCode === 200) {
              onClose();
            }
          }
        }}
      >
        {({ handleChange, values, errors, touched, dirty, resetForm }) => (
          <StyledDialog
            fullWidth
            width="1064px"
            fullScreen={isMobile()}
            open={isDialogOpen()}
            onClose={() => {
              resetForm();
              onClose();
            }}
            isEdited={dirty}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex items-center w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
                    <img alt="" src={dialogTitleIcon} />
                  </div>

                  <span className="font-semibold">
                    {openDialog?.dialogType === 'addNewLedger'
                      ? t('add_new_ledger')
                      : t('edit_ledger')}
                  </span>
                </div>
              </div>
            </div>

            {dropdownLoading ? (
              <div className="flex h-full justify-center items-center">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] sm:w-[60%] items-center m-auto gap-4">
                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('title')} {errors['title'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="title"
                        value={values.title}
                        placeholder={t('title')}
                        onChange={handleChange}
                        error={touched.title && errors.title}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="title" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('code')} {errors['code'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="code"
                        value={values.code}
                        placeholder={t('code')}
                        onChange={handleChange}
                        error={touched.code && errors.code}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="code" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('type')} {errors['type'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="type"
                        value={values.type}
                        placeholder={t('type')}
                        onChange={handleChange}
                        error={touched.type && errors.type}
                        itemsList={types}
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="type" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('sub_type')}{' '}
                        {errors['subType'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="subType"
                        value={values.subType}
                        placeholder={t('sub_type')}
                        onChange={handleChange}
                        error={touched.subType && errors.subType}
                        itemsList={
                          values.type ? subTypes.filter((t) => t.type === values.type) : subTypes
                        }
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="subType" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('reference_type')}{' '}
                        {errors['referneceType'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="referenceType"
                        value={values.referenceType}
                        placeholder={t('reference_type')}
                        onChange={handleChange}
                        error={touched.referenceType && errors.referenceType}
                        itemsList={referenceTypes}
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="referenceType" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('reference_record')}{' '}
                        {errors['referneceRecord'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="referenceRecord"
                        value={values.referenceRecord}
                        placeholder={t('reference_record')}
                        onChange={handleChange}
                        error={touched.referenceRecord && errors.referenceRecord}
                        itemsList={getReferenceRecordDropdown(values.referenceType)}
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="referneceRecord" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('enable_VAT')}{' '}
                        {errors['enableVAT'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="enableVAT"
                        value={values.enableVAT}
                        placeholder={t('enable_VAT')}
                        onChange={handleChange}
                        error={touched.enableVAT && errors.enableVAT}
                        itemsList={enableVat}
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="enableVAT" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('VAT_percentage')}{' '}
                        {errors['vatPercentage'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="VATPercentage"
                        value={values.VATPercentage}
                        placeholder={t('VAT_percentage')}
                        onChange={handleChange}
                        prefix={'%'}
                        error={touched.VATPercentage && errors.VATPercentage}
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="VATPercentage" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('balance_side')}{' '}
                        {errors['balanceSide'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledSelect
                        name="balanceSide"
                        value={values.balanceSide}
                        placeholder={t('balance_side')}
                        onChange={handleChange}
                        error={touched.balanceSide && errors.balanceSide}
                        itemsList={balanceSide}
                        disabled={isDisabled()}
                      />

                      <ErrorMessage component={CustomErrorMessage} name="balanceSide" />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <div className="text-sm text-black">
                        {t('description')}{' '}
                        {errors['description'] && <span className="text-[#EA3323]">*</span>}
                      </div>

                      <StyledInput
                        name="description"
                        value={values.description}
                        placeholder={t('description')}
                        onChange={handleChange}
                        error={touched.description && errors.description}
                      />
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <div className="grid justify-items-center sm:justify-items-end m-auto w-[90%] sm:w-[60%]">
                    <button
                      className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                      type="submit"
                    >
                      {addLoading || editLoading ? (
                        <div className="flex justify-center items-center">
                          <CircularProgress color="inherit" size={25} />
                        </div>
                      ) : openDialog?.dialogType === 'addNewLedger' ? (
                        t('add')
                      ) : (
                        t('edit')
                      )}
                    </button>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
