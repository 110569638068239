import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { LocusContext } from 'hooks/useLocusContext';

import browseIcon from 'assets/browseIcon.svg';
import visibilityIcon from 'assets/visibiltyIcon.svg';
import DeleteButtonIcon from 'assets/DeleteButton.svg';

export const Comments = () => {
  const { dialogType } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();

  React.useEffect(() => {
    if (acceptedFiles.length) {
      setUploadedFiles(acceptedFiles);
      var filereader = new FileReader();
      filereader.readAsDataURL(acceptedFiles[0]);
      filereader.onload = function (evt) {
        var base64 = evt.target.result;
        setFieldValue('attachments', [base64]);
        return base64;
      };
    }
  }, [acceptedFiles]);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-3xl font-bold">{t('comments')}</div>
      {/* {APIResponse.message && <Alert sx={{borderRadius: '16px'}} severity="error">{APIResponse.message}</Alert>} */}

      <div className="flex flex-col gap-1">
        <div className="text-sm">{t('comments')}</div>
        <Field as={StyledTextArea} name="comments" placeholder="Comments" rows="6" cols="50" />
      </div>

      {dialogType !== 'editContact' && (
        <>
          <div className="font-2xl font-bold">{t('uplaod_contact_avatar')}</div>

          <AttachmentsContainer>
            <FileInputContainer {...getRootProps()}>
              <div className="flex justify-between">
                <div className="flex gap-4 items-center">
                  <input {...getInputProps()} />
                  {!isDragActive && (
                    <div className="flex justify-between">
                      <div
                        className={`flex items-center justify-center rounded-full w-11 h-11 bg-[#EA3323]`}
                      >
                        <img alt="" src={browseIcon} />
                      </div>
                    </div>
                  )}

                  {isDragActive ? (
                    <label>{t('drop_here')}</label>
                  ) : (
                    <label className="w-60 text-[#8E8E8E]">{t('drop_files_here')}</label>
                  )}
                </div>

                <div className="flex items-center p-4`">
                  <div className="flex justify-center items-center cursor-pointer w-[139px] h-[44px] bg-[#F6F4F4] rounded-2xl">
                    {t('browse')}
                  </div>
                </div>
              </div>
            </FileInputContainer>

            <div className="bg-[#F6F4F4] h-[1px] mt-6"></div>

            {!!uploadedFiles.length && (
              <div className="mt-6 flex justify-between w-11/12 p-2">
                {['File Name', 'File Type', 'Actions'].map((_) => (
                  <div key={_} className="text-[#535353] text-[14px]">
                    {_}
                  </div>
                ))}
              </div>
            )}

            <div className="flex flex-col gap-4 w-11/12 p-2">
              {uploadedFiles.map((file) => (
                <div key={file.name} className="flex justify-between">
                  <div>{file.name}</div>
                  <div>{file.type}</div>
                  <div className="flex gap-4">
                    <img
                      alt=""
                      onClick={() =>
                        setUploadedFiles(
                          uploadedFiles.filter((selectedFile) => selectedFile.name !== file.name),
                        )
                      }
                      className="cursor-pointer w-[18px]"
                      src={DeleteButtonIcon}
                    />

                    <img alt="" className="cursor-pointer w-[18px]" src={visibilityIcon} />
                  </div>
                </div>
              ))}
            </div>
          </AttachmentsContainer>
        </>
      )}
    </div>
  );
};

const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 16px;
  padding: 8px;
  box-shadow: ${({ error }) => error && '0px 0px 0px 3px rgba(234,51,35,0.2)'};
  border: ${({ error }) => (error ? '1px solid #EA3323' : '1px solid #C9C9C9')};
  transition: 0.5s;
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(43, 93, 220, 0.2);
    border: 1px solid #2b5ddc;
    outline: none;
  }
`;

const FileInputContainer = styled.div``;

const AttachmentsContainer = styled.div`
  box-shadow: 0px 2px 4px rgba(2, 4, 74, 0.04);
  border-radius: 16px;
  border: 1px solid #c9c9c9;
  padding: 10px;
`;
