import {
  getAuthenticatedUserById,
  updateContact,
  getAdminUserGroupById,
  getUserGroupInvitations,
  getSettingsDropdownValues,
  addUserGroup,
  updateUserGroupPreferences,
  sendInvitationToUser,
  updateUserRole,
  updatePassword,
  acceptInvitation,
  rejectInvitation,
  leaveUserGroup,
  deleteUser,
  getUserGroupAttachments,
  addUserGroupAttachment,
  updateUserGroupAttachment,
  deleteUserGroupAttachment,
  update2FA,
  cancelUserGroupInvitation,
  getInstitutions,
  getCountries,
  storeNordigenInstitutions,
  getIntegrations,
  getTransactions,
  getTransaction,
  mapTransactions,
  validateRequisition,
  storePaymentMethod,
  deletePaymentMethod,
} from 'apis/settings/settings';
import {
  getCustomerSubscriptions,
  getUserGroupSubscriptionDetails,
  getCustomerDetails,
  getUserGroupDropdowns,
  updateSubscription,
  updatePaymentMethod,
} from 'apis/subscriptions/subscriptions';
import { addressAutoComplete } from 'apis/address/address';
import { localeDropDown, updateLocale } from 'apis/authentication/authentication';

export const settingsApiFunctions = {
  // profile apis
  getUser: { func: getAuthenticatedUserById, key: 'userProfile' },
  updateContact: { func: updateContact, key: 'userProfile' },
  updateLocale: { func: updateLocale, key: '' },
  updatePassword: { func: updatePassword, key: '' },
  update2FA: { func: update2FA, key: 'userProfile' },
  getUserGroupInvitations: { func: getUserGroupInvitations, key: 'userGroupInvitation' },
  acceptInvitation: { func: acceptInvitation, key: 'userGroupInvitation' },
  rejectInvitation: { func: rejectInvitation, key: 'userGroupInvitation' },

  //  chargebee apis
  getCustomerSubscriptions: { func: getCustomerSubscriptions, key: 'subscriptions' },
  getUserGroupSubscriptionDetails: {
    func: getUserGroupSubscriptionDetails,
    key: 'userGroupSubscriptions',
  },
  getCustomerDetails: { func: getCustomerDetails, key: 'customerDetails' },
  getUserGroupDropdowns: { func: getUserGroupDropdowns, key: 'userGroupsDropdowns' },
  updateSubscription: { func: updateSubscription, key: 'subscriptions' },
  upddatePaymentMethod: { func: updatePaymentMethod, key: 'customerDetails' },
  storePaymentMethod: { func: storePaymentMethod, key: 'customerDetails' },
  deletePaymentMethod: { func: deletePaymentMethod, key: 'customerDetails' },

  // user group apis
  getAdminUserGroupById: { func: getAdminUserGroupById, key: 'adminUserGroupInvitation' },
  leaveUserGroup: { func: leaveUserGroup, key: 'adminUserGroupInvitation' },
  deleteUser: { func: deleteUser, key: 'adminUserGroupInvitation' },
  addUserGroup: { func: addUserGroup, key: 'adminUserGroupInvitation' },
  updateUserGroupPreferences: { func: updateUserGroupPreferences, key: 'adminUserGroupInvitation' },
  getUserGroupAttachments: { func: getUserGroupAttachments, key: 'userGroupAttachments' },
  addUserGroupAttachment: { func: addUserGroupAttachment, key: 'userGroupAttachments' },
  updateUserGroupAttachment: { func: updateUserGroupAttachment, key: 'userGroupAttachments' },
  deleteUserGroupAttachment: { func: deleteUserGroupAttachment, key: 'userGroupAttachments' },
  sendInvitationToUser: { func: sendInvitationToUser, key: 'adminUserGroupInvitation' },
  updateUserRole: { func: updateUserRole, key: 'adminUserGroupInvitation' },

  // bank integrations apis
  getCountries: { func: getCountries, key: 'countries' },
  getIntegrations: { func: getIntegrations, key: 'integrations' },
  getTransactions: { func: getTransactions, key: 'transactions' },
  getTransaction: { func: getTransaction, key: 'transaction' },
  mapTransactions: { func: mapTransactions, key: '' },
  getInstitutions: { func: getInstitutions, key: 'institutions' },
  storeNordigenInstitutions: { func: storeNordigenInstitutions, key: '' },
  validateRequisition: { func: validateRequisition, key: '' },

  // dropdown apis
  settingsDropdown: { func: getSettingsDropdownValues, key: 'settingsDropdown' },
  localeDropDown: { func: localeDropDown, key: 'localeDropdown' },

  addressAutoComplete: { func: addressAutoComplete, key: 'addressAutoComplete' },
};
