import { useQueryClient, useMutation } from 'react-query';

export const useCustomMutation = (mutateFunction, queryKey = '') => {
  const queryClient = useQueryClient();

  return useMutation((payload) => mutateFunction(payload), {
    onSuccess: () => {
      if (Array.isArray(queryKey)) {
        for (let i = 0; i < queryKey.length; i++) {
          queryClient.invalidateQueries({ queryKey: [queryKey[i]] });
        }
      } else {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      }
    },
  });
};
