import React from 'react';

const withApiFunctions = (WrappedComponent, apiFunctions) => {
  const WithApiFunctions = (props) => {
    return <WrappedComponent {...props} apiFunctions={apiFunctions} />;
  };

  return WithApiFunctions;
};

export default withApiFunctions;
