import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';
import { TabName, isMobile } from 'utils/utils';
import { contactsTableColumns } from 'utils/columns';

import {
  AddButton,
  CardButton,
  DeleteButton,
  EditButton,
  EmptyList,
  ExportButton,
  ListButton,
  StyledDataGrid,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { ExportDialog, ContactDialog, DeleteDialog } from 'components/dialogs';
import { ContactCard } from './contactCard';

import { Alert, Checkbox, CircularProgress } from '@mui/material';
import searchIcon from 'assets/search.svg';

const Contacts = ({ apiFunctions }) => {
  TabName('Contacts');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [globalError, setGlobalError] = React.useState(null)
  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [allContactsSelected, setAllContactsSelected] = React.useState(false);
  const [contactsList, setContactsList] = React.useState([]);

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getAll.func,
    apiFunctions.getAll.key,
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleDeleteContact = async () => {
    const response = await deleteMutation(selectedContacts);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedContacts(data.data.map((item) => item.contactId));
      setAllContactsSelected(true);
    } else {
      setSelectedContacts([]);
      setAllContactsSelected(false);
    }
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setContactsList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.name.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.email.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.recordType.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setContactsList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setContactsList(data?.data);
    }
  }, [data?.data]);

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if(globalErrorMsg){
      setGlobalError({statusCode: 500, message: globalErrorMsg});
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div className="mt-11">
      {error && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          There was an error while getting the data
        </Alert>
      )}

      <Notifier data={mutationResponse} />
        <Notifier data={globalError} />

      <div className="bg-white rounded-2xl mt-6 pt-2">
        <div className="flex justify-between">
          <div className="hidden lg:flex items-center pl-5">
            <div className="flex items-center justify-center border border-[#C9C9C9] rounded-lg">
              <Checkbox
                icon={
                  <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
                }
                checkedIcon={
                  <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
                }
                checked={allContactsSelected}
                onChange={handleSelectAll}
              />
              <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
            </div>
          </div>

          <div className="flex flex-col-reverse sm:flex-row justify-between w-full lg:w-auto gap-4 p-3">
            <div className="flex rounded-lg bg-[#F6F4F4]">
              <div className="flex items-center">
                <img alt="" className="pl-2" src={searchIcon} />
              </div>

              <input
                className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
                placeholder={t('search_by_contact')}
                value={searchValue}
                onChange={handleFilterChange}
              />
            </div>

            <div className="flex gap-4">
              <AddButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'addContact' })}
                disabled={isLoading}
              />

              <EditButton
                onClick={() =>
                  navigate(`/contact-overview/${selectedContacts[0]}/details`, {
                    state: { directEdit: true },
                  })
                }
                disabled={selectedContacts.length > 1 || !selectedContacts.length}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={!selectedContacts.length}
              />

              <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
            </div>

            <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
              <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
              <ListButton
                isActivated={activeTab === 'table'}
                onClick={() => setActiveTab('table')}
              />
            </div>
          </div>
        </div>

        {activeTab === 'card' ? (
          isLoading ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 p-3">
              {[1, 2, 3, 4].map((_) => (
                <SkeletonCardLoader />
              ))}
            </div>
          ) : !data?.data?.length ? (
            <EmptyList listName="contacts" />
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 overflow-y-auto p-3">
              {data.data.map((contact) => (
                <ContactCard
                  key={contact.contactId}
                  contact={contact}
                  selectedContacts={selectedContacts}
                  onSelect={() => setSelectedContacts([...selectedContacts, contact.contactId])}
                  onUnselect={() =>
                    setSelectedContacts(
                      selectedContacts.filter(
                        (selectedContact) => contact.contactId !== selectedContact,
                      ),
                    )
                  }
                />
              ))}
            </div>
          )
        ) : !isLoading && !data?.data?.length ? (
          <EmptyList listName="contacts" />
        ) : (
          <StyledDataGrid
            checkboxSelection
            rows={contactsList || []}
            columns={contactsTableColumns(t)}
            getRowId={(row) => row.contactId}
            onSelectionModelChange={(newSelectedContact) => setSelectedContacts(newSelectedContact)}
            selectionModel={selectedContacts}
            rowsPerPageOptions={[]}
            loading={isLoading}
            pageSize={25}
            onRowClick={(params) =>
              navigate(`/contact-overview/${params.row.contactId}/details`, {
                state: { recordType: params.row.record_type },
              })
            }
            components={{
              LoadingOverlay: () => (
                <div className="mt-8 flex justify-center">
                  <CircularProgress color="error" />
                </div>
              ),
            }}
          />
        )}

        <ExportDialog
          apiFunctions={apiFunctions}
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
          selectedElements={selectedContacts}
        />

        <DeleteDialog
          openDialog={openDialog}
          name="contact"
          onClose={() => setOpenDialog(null)}
          onDelete={handleDeleteContact}
          isLoading={deleteLoading}
        />

        <ContactDialog
          apiFunctions={apiFunctions}
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
        />
      </div>
    </div>
  );
};

export default Contacts;
