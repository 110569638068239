import React, {useEffect} from 'react';
import styled from 'styled-components';
import { ErrorMessage, Form, Formik } from 'formik';
import { useIsMutating } from 'react-query';
import { CircularProgress, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LocusContextProvider } from 'hooks/useLocusContext';
import {newTransactionDialogSteps} from 'utils/constants';
import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';
import {transactionInitialState} from "initialStates";

import {ConfirmDialog, Notifier, Steps, StyledDialog, StyledInput, StyledSelect} from 'components';
import {transactionSchema} from "validationSchemas";
import { TransactionDetails } from './newTransactionDialogComponents/transactionDetails';
import {isMobile} from "utils/utils";

import backArrow from 'assets/backArrow.svg';
import listingButtonIcon from 'assets/listingButtonIcon.svg';
import removeIcon from 'assets/removeIcon.svg';

export const NewTransactionDialog = ({ openDialog, onClose, apiFunctions, fromClosing, fromClosingContact }) => {
  const { t } = useTranslation();

  const isDialogOpen = () => {
    return openDialog?.dialogType === 'addNewInvoice';
  };

  const columns = [
    { field: 'description', headerName: 'description' },
    { field: 'transaction_line_type_id', headerName: 'transaction_line_type' },
    { field: 'amount', headerName: 'amount' },
    { field: 'remove', headerName: '' },
  ];

  const isMutating = useIsMutating();
  const formikFormRef = React.useRef();

  const [transactionLinesArray, setTransactionLinesArray] = React.useState([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [exchangeRate, setExchangeRate] = React.useState(false);

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
  );

  const { mutateAsync: addTransactionMutation,
    data: addTransactionResponse ,
    isLoading: addTransactionLoading } =
    useCustomMutation(apiFunctions.add.func, apiFunctions.add.key);
  const handleAddTransactionLines = (value) => {
    const newTransactionLinesArray = [...transactionLinesArray, value];
    setTransactionLinesArray(newTransactionLinesArray);
  };

  const isAbleToSubmit = () => {
    return transactionLinesArray?.length;
  }
  const handleRemoveTransactionLines = (index) => {
    const newTransactionLinesArray = transactionLinesArray.filter((item, i) => i !== index);
    setTransactionLinesArray(newTransactionLinesArray);
  };

  const currentValidationSchema = transactionSchema[currentStep]
  const isLastStep = currentStep === newTransactionDialogSteps.length - 1;

  const handleDecreaseStep = () => {
    return setCurrentStep(currentStep - 1);
  };

  const handleCloseDialog = () => {
    formikFormRef.current?.resetForm();
    setCurrentStep(0);
    onClose();
  };

  const renderTableCell = (col, row, index) => {
    switch (col.field) {
      case 'remove':
        return (
          <div className="flex flex-1 items-center gap-4 w-[10%] sm:w-[20%]">
            <img
              className="hover:cursor-pointer"
              src={removeIcon}
              onClick={() => handleRemoveTransactionLines(index)}
            />
          </div>
        );
      case 'amount':
        return <TableData type={row[col.field]}>{currencySymbol + row[col.field]}</TableData>;
      case 'transaction_line_type_id':
        return (
          <TableData type={row[col.field]}>
            {data?.data.transactionLineTypes.find((item) => item.id === row[col.field])?.name || ''}
          </TableData>
        );
      default:
        return <TableData type={row[col.field]}>{row[col.field]}</TableData>;
    }
  };

  return (
    <>
      <Notifier data={addTransactionResponse}/>
      <StyledDialog
        width="1064px"
        height="725px"
        padding="5px"
        fullScreen={isMobile()}
        open={isDialogOpen()}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          {isLoading ? (
            <div className="grid place-content-center h-full">
              <CircularProgress color="error" />
            </div>
          ) : (
            <div className="flex gap-8 h-full">
              <Steps steps={newTransactionDialogSteps} currentStep={currentStep} />

              <Formik
                innerRef={formikFormRef}
                enableReinitialize
                initialValues={transactionInitialState()}
                validationSchema={currentValidationSchema}
                onSubmit={async (values, { resetForm }) => {
                  if (isLastStep) {
                    let payload = {
                      ...values,
                      transactionLines: transactionLinesArray,
                    };

                    const addTransactionResponse = await addTransactionMutation(payload);

                    if (addTransactionResponse.statusCode === 200) {
                      onClose();
                      resetForm();
                    }
                  } else return setCurrentStep(currentStep + 1);
                }}
              >
                {({ values, handleChange, errors, touched, setFieldValue, dirty }) => (
                  <Form
                    className={`${
                      currentStep === 5 ? 'w-full' : 'w-full sm:w-[75%]'
                    } flex flex-col gap-4 justify-between h-full p-2`}
                  >
                    <div className="m-auto w-full overflow-auto">
                      <LocusContextProvider
                        dialogType={openDialog?.dialogType}
                        dropdownValues={data?.data}
                        // editData={editData?.data}
                        data={openDialog?.data}
                        transactionLinesArray={transactionLinesArray}
                      >
                        {currentStep === 0 &&
                          <TransactionDetails
                            exchangeRate={exchangeRate}
                            setExchangeRate={(bool) => setExchangeRate(bool)}
                          />
                        }
                        {currentStep === 1 && (
                          <div className="flex flex-col gap-2 p-1">
                            <div className="text-2xl sm:text-3xl font-bold">{t('transaction_lines')}</div>

                            <div className="flex flex-col sm:flex-row gap-5">
                              <div className="w-full">
                                <div className="text-sm pb-1">
                                  {t('transaction_line_type')}{' '}
                                  {errors['transactionLineTypeId'] && (
                                    <span className="text-[#EA3323]">*</span>
                                  )}
                                </div>
                                <StyledSelect
                                  name="transactionLineTypeId"
                                  placeholder={t('transaction_line_type')}
                                  value={values.transactionLineTypeId}
                                  onChange={handleChange}
                                  error={
                                    touched.transactionLineTypeId && errors.transactionLineTypeId
                                  }
                                  itemsList={data?.data.transactionLineTypes}
                                />
                                <ErrorMessage
                                  component={ValidationError}
                                  name="transactionLineTypeId"
                                />
                              </div>

                              <div className="w-full">
                                <div className="text-sm pb-1">
                                  {t('amount')}{' '}
                                  {errors['amount'] && <span className="text-[#EA3323]">*</span>}
                                </div>
                                <StyledInput
                                  name="amount"
                                  placeholder={t('amount')}
                                  value={values.amount}
                                  onChange={handleChange}
                                  error={touched.amount && errors.amount}
                                  prefix={currencySymbol}
                                />
                                <ErrorMessage component={ValidationError} name="amount" />
                              </div>
                            </div>

                            <div className="flex flex-col sm:flex-row gap-5">
                              <div className="flex flex-col w-full">
                                <div className="text-sm pb-1">
                                  {t('description')}{' '}
                                  {errors['transactionLinesDescription'] && (
                                    <span className="text-[#EA3323]">*</span>
                                  )}
                                </div>
                                <StyledInput
                                  name="transactionLinesDescription"
                                  placeholder={t('description')}
                                  value={values.transactionLinesDescription}
                                  onChange={handleChange}
                                  error={touched.transactionLinesDescription && errors.transactionLinesDescription}
                                />
                                <ErrorMessage
                                  component={ValidationError}
                                  name="transactionLinesDescription"
                                />
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <button
                                disabled={(!values.amount || !values.transactionLinesDescription || !values.transactionLineTypeId)}
                                type="button"
                                className="flex gap-1 p-2 items-center justify-center rounded-lg bg-[#FFF2F0] text-[#EA3323] disabled:opacity-50"
                                onClick={() => {
                                  handleAddTransactionLines({
                                    amount: values.amount,
                                    description: values.transactionLinesDescription,
                                    transaction_line_type_id: values.transactionLineTypeId,
                                  })
                                  setFieldValue('amount', '');
                                  setFieldValue('transactionLinesDescription', '');
                                  setFieldValue('transactionLineTypeId', '');
                                }}
                              >
                                <img src={listingButtonIcon} />
                                {t('add_transaction_line')}
                              </button>
                            </div>

                            <div className="flex flex-col gap-4 border border-[#C9C9C9] rounded-[20px] p-6 mt-6">
                              {transactionLinesArray?.length ? (
                                <div className="flex flex-col gap-5">
                                  <TableHeader>
                                    {columns?.map((col) => (
                                      col.headerName === 'amount_dc' ?
                                        (exchangeRate ? <div className="" key={col.headerName}>{t(col.headerName)}</div> : '')
                                        :
                                        <div className="" key={col.headerName}>{t(col.headerName)}</div>
                                    ))}
                                  </TableHeader>

                                  <div className="flex max-h-[100px] overflow-y-auto flex-col gap-4">
                                    {transactionLinesArray?.map((row, index) => (
                                      <div>
                                        <div className="flex items-center rounded-[20px] text-sm">
                                          {columns?.map((col) => renderTableCell(col, row, index))}
                                        </div>
                                        {index !== transactionLinesArray?.length - 1 && (
                                          <div className="w-full h-[1px] bg-[#F6F4F4] mt-1"></div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <div className="flex items-center justify-center font-bold text-[#535353] text-sm">
                                  {t(`no_transaction_lines`)}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </LocusContextProvider>
                    </div>

                    <div className="self-end flex gap-4">
                      {currentStep !== 0 && currentStep !== 5 && (
                        <div
                          onClick={() => handleDecreaseStep()}
                          className="flex justify-center gap-4 bg-[#F6F4F4] cursor-pointer text-[#8E8E8E] rounded-2xl font-bold p-3 w-32 text-center"
                        >
                          <img src={backArrow} />
                          {t('back')}
                        </div>
                      )}

                      {currentStep !== 5 && (
                        <StyledButton type="submit" disabled={isLastStep && !isAbleToSubmit()}>
                          {!!isMutating ? (
                            <div className="flex justify-center items-center">
                              <CircularProgress color="inherit" size={20} />
                            </div>
                          ) : isLastStep ? (
                            t('submit')
                          ) : (
                            t('next')
                          )}
                        </StyledButton>
                      )}
                    </div>
                    <ConfirmDialog
                      open={openConfirmDialog}
                      onClose={() => setOpenConfirmDialog(false)}
                      onParentDialogClose={onClose}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </DialogContent>
      </StyledDialog>
    </>
  );
};

const StyledButton = styled.button`
  background: #ea3323;
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  min-width: 120px;
  padding: 10px;
  font-weight: bold;
`;

const ValidationError = styled.div`
  color: #ea3323;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  color: #535353;
  font-weight: 700;
  font-size: 12px;
  div {
    flex: 1;
  }
  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const TableData = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ type }) => {
    switch (type) {
      case 'For Rent':
        return '#008E0E';
      case 'For Sale':
        return '#2B5DDC';
    }
  }};
`;
