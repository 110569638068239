import React from 'react';
import { useTranslation } from 'react-i18next';

export const EmptyList = ({ listName }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-[#C9C9C9] rounded-[20px] p-6 font-bold text-center text-[#535353] text-sm">
      {t(`no_${listName}`)}
    </div>
  );
};
