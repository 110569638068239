import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  getClosing,
  updateClosing,
} from 'apis/closings/closings';

import { StyledSelect, StyledDialog, StyledInput, DatePicker, ConfirmDialog } from 'components';
import { CircularProgress, DialogContent, DialogActions } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';
import closeIcon from 'assets/blackCrossIcon.svg';
import alertIcon from 'assets/redAlert.svg';
import alertCross from 'assets/alertCross.svg';
import { useTranslation } from 'react-i18next';

const newClosingSchema = yup.object().shape({
  listing: yup
    .string()
    .typeError('listing field is required')
    .required('listing field is required'),
  tenant: yup.string().typeError('tenant field is required').required('tenant field is required'),
  closingType: yup
    .string()
    .typeError('closing type field is required')
    .required('closing type field is required'),
  offeredAmount: yup
    .string()
    .typeError('offered amount field is required')
    .required('offered amount field is required'),
  closingAmount: yup
    .string()
    .typeError('closing amount field is required')
    .required('closing amount field is required'),
  effectiveFrom: yup
    .date()
    .typeError('effective from field is required')
    .required('effective from field is required'),
  effectiveTo: yup
    .date()
    .typeError('effective from field is required')
    .min(yup.ref('effectiveFrom'))
    .nullable(),
  status: yup.string().typeError('status field is required').required('status field is required'),
  closingTypeCategory: yup
    .string()
    .typeError('closing type category field is required')
    .required('closing type category field is required'),
  duePaymentFee: yup.number().required('Due Payment Fee field is required'),
  duePaymentFeeDays: yup.number().required('Due Payment Fee Days field is required'),
});

/**
 * * if add new closing dialog is opened in listing related:
 * * listing field value must be retrieved & disabled
 * * ------------------------------------------------------
 *
 * ! dont redirect to closing overview in case this dialog was opened from listing related
 * ! disable listing field if listingId is present
 */

export const EditClosingDialog = ({ openDialog, onClose, data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const closingId = openDialog?.data ? openDialog?.data[0] : '';
  // const { data, isLoading } = useQuery('closingDropdownData', getClosingDropdownData)
  const { data: editData, isLoading: editDataLoading } = useQuery(
    ['closingDetails', closingId],
    () => getClosing(closingId),
    { enabled: openDialog?.dialogType === 'editClosing' },
  );

  const { mutate: editClosingMutation, isLoading: editClosingLoading } = useMutation((payload) =>
    updateClosing(payload),
  );

  const [APIResponse, setAPIResponse] = React.useState({ message: '', code: '' });
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const { listings, closingTypes, contacts, progress, closingTypeCategories, vatApplicable } =
    data?.data || {};
  const { listingId, listingTypeId } = openDialog?.data || {};
  const {
    listingId: editListingId,
    closingTypeId,
    contractEnd,
    contractStart,
    progressId,
    duePaymentFee,
    accountingReference,
    duePaymentFeeDays,
  } = editData?.data || {};
  const { offeredAmount, dealAmount, vatApplicableBool } = editData?.data?.financial || {};

  // const { data: glAccounts, isLoading } = useQuery('getGlAccounts', getGlAccounts, {
  //   enabled: activeAccountingIntegration,
  // });

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const isDialogOpen = () => {
    return openDialog?.dialogType === 'editClosing';
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        listing: listingId || editListingId || '',
        tenant: editData?.data?.contact.contactId || '',
        closingType: listingTypeId || closingTypeId || '',
        offeredAmount: offeredAmount || '',
        closingAmount: dealAmount || '',
        effectiveFrom: contractStart || null,
        effectiveTo: contractEnd || null,
        status: progressId || '',
        closingTypeCategory: closingTypeId || '',
        vat: vatApplicableBool?.toString() || '',
        glAccount: accountingReference || '',
        duePaymentFeeDays: duePaymentFeeDays || '',
        duePaymentFee: duePaymentFee || '',
      }}
      validationSchema={newClosingSchema}
      onSubmit={(values, { resetForm }) => {
        let payload = {
          closingId: closingId,
          listing: values.listing,
          tenantId: values.tenant,
          closingType: values.closingType,
          offeredAmount: values.offeredAmount,
          dealAmount: values.closingAmount,
          progress: values.status,
          status: values.status,
          effectiveFrom: values.effectiveFrom,
          effectiveTo: values.effectiveTo,
          vat: values.vat,
          closingTypeCategory: values.closingTypeCategory,
          glAccount: values.glAccount || '',
          duePaymentFee: values.duePaymentFee,
          duePaymentFeeDays: values.duePaymentFeeDays,
        };
        editClosingMutation(payload, {
          onSuccess: (data) => {
            if (data.statusCode === 200) {
              queryClient.invalidateQueries('closings');
              queryClient.invalidateQueries('closingDetails');
              resetForm();
              onClose();
            } else if (data.statusCode === 403) {
              setAPIResponse({ message: data.message, code: data.statusCode });
            } else setAPIResponse({ message: 'Server Error', code: data.statusCode });
          },
        });
      }}
    >
      {({ handleChange, values, errors, touched, setFieldValue, dirty }) => (
        <StyledDialog
          fullWidth
          width="1064px"
          height="725px"
          padding="30px"
          open={isDialogOpen()}
          onClose={onClose}
        >
          <div className="flex flex-col gap-2 w-full h-fit justify-center items-center mt-2">
            <div className="flex w-full sm:w-[60%] h-full border border-[#dfdfe87a] rounded-2xl justify-between items-center p-3">
              <div className="flex gap-2 items-center font-semibold">
                <div className="flex w-[48px] h-[48px] bg-[#EA3323] rounded-full items-center justify-center">
                  <img src={dialogTitleIcon} />
                </div>
                {t('edit_closing')}
              </div>
              <div className="flex h-[40px] items-center border-l border-[#dfdfe87a] pl-3">
                <button
                  className="flex justify-center items-center gap-4 w-[103px] h-full bg-[#F6F4F4] rounded-lg text-[#8E8E8E]"
                  onClick={() => {
                    if (!dirty) onClose();
                    else setOpenConfirmDialog(true);
                  }}
                  disabled={editClosingLoading}
                >
                  <img src={closeIcon} />
                  {t('cancel')}
                </button>
              </div>
            </div>

            {APIResponse.message && (
              <div className="flex justify-between w-full sm:w-[60%] items-center bg-[#FFF2F0] text-[#EA3323] p-3 rounded-lg">
                <div className="flex gap-2">
                  <img src={alertIcon} />
                  <span>{APIResponse.message}</span>
                </div>
                <img
                  className="hover:cursor-pointer"
                  src={alertCross}
                  onClick={() => setAPIResponse({ message: '', code: '' })}
                />
              </div>
            )}
          </div>

          {editDataLoading ? (
            <div className="flex h-full justify-center items-center">
              <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
            </div>
          ) : (
            <Form>
              <DialogContent>
                <div className="flex h-full justify-center">
                  <div className="flex flex-col w-full sm:w-[60%] items-center gap-4">
                    <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">{t('listing')}</div>
                        <StyledSelect
                          name="listing"
                          placeholder="Listing"
                          value={values.listing}
                          onChange={handleChange}
                          error={touched.listing && errors.listing}
                          itemsList={listings}
                          disabled={listingId || editListingId}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="listing" />
                      </div>

                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">{t('tenant')}</div>
                        <StyledSelect
                          name="tenant"
                          placeholder="Tenant"
                          value={values.tenant}
                          onChange={handleChange}
                          error={touched.tenant && errors.tenant}
                          itemsList={contacts}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="tenant" />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">{t('closing_type')}</div>
                        <StyledSelect
                          name="closingType"
                          placeholder="Closing Type"
                          value={values.closingType}
                          onChange={handleChange}
                          error={touched.closingType && errors.closingType}
                          itemsList={closingTypes}
                          disabled={listingTypeId}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="closingType" />
                      </div>

                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">{t('offered_amount')}</div>
                        <StyledInput
                          name="offeredAmount"
                          placeholder="Offered Amount"
                          value={values.offeredAmount}
                          onChange={handleChange}
                          error={touched.offeredAmount && errors.offeredAmount}
                          prefix={currencySymbol}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="offeredAmount" />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">{t('effective_from')}</div>
                        <DatePicker
                          name="effectiveFrom"
                          value={values.effectiveFrom}
                          onChange={(value) => setFieldValue('effectiveFrom', value)}
                          error={touched.effectiveFrom && errors.effectiveFrom}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="effectiveFrom" />
                      </div>

                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">{t('effective_to')}</div>
                        <DatePicker
                          name="effectiveTo"
                          label="Effective To"
                          value={values.effectiveTo}
                          onChange={(value) => setFieldValue('effectiveTo', value)}
                          error={touched.effectiveTo && errors.effectiveTo}
                          disabled={values.closingType === '2'}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="effectiveTo" />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                      <div className="flex flex-col gap-2 w-full">
                        <div className="text-sm text-black">{t('closing_amount')}</div>
                        <StyledInput
                          name="closingAmount"
                          placeholder={t('closing_amount')}
                          value={values.closingAmount}
                          onChange={handleChange}
                          error={touched.closingAmount && errors.closingAmount}
                          prefix={currencySymbol}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="closingAmount" />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('status')}</div>
                        <StyledSelect
                          name="status"
                          placeholder={t('status')}
                          width="100%"
                          value={values.status}
                          onChange={handleChange}
                          error={touched.status && errors.status}
                          itemsList={progress}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="listing" />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                      <div className="w-full">
                        <div className="text-sm pb-1">{t('closing_type_category')}</div>
                        <StyledSelect
                          name="closingTypeCategory"
                          placeholder={t('closing_type_category')}
                          width="100%"
                          value={values.closingTypeCategory}
                          onChange={handleChange}
                          error={touched.closingTypeCategory && errors.closingTypeCategory}
                          itemsList={closingTypeCategories}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="closingTypeCategory" />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('vat')}</div>
                        <StyledSelect
                          name="vat"
                          placeholder={t('vat')}
                          width="100%"
                          value={values.vat}
                          onChange={handleChange}
                          error={touched.vat && errors.vat}
                          itemsList={vatApplicable}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="vat" />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row w-full justify-between gap-3">
                      <div className="w-full">
                        <div className="text-sm pb-1">{t('due_payment_fee')}</div>
                        <StyledInput
                          name="duePaymentFee"
                          placeholder={t('due_payment_fee')}
                          width="100%"
                          value={values.duePaymentFee}
                          onChange={handleChange}
                          error={touched.duePaymentFee && errors.duePaymentFee}
                          prefix={currencySymbol}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="duePaymentFee" />
                      </div>

                      <div className="w-full">
                        <div className="text-sm pb-1">{t('due_payment_fee_days')}</div>
                        <StyledInput
                          name="duePaymentFeeDays"
                          placeholder={t('due_payment_fee_days')}
                          width="100%"
                          value={values.duePaymentFeeDays}
                          onChange={handleChange}
                          error={touched.duePaymentFeeDays && errors.duePaymentFeeDays}
                        />
                        <ErrorMessage component={CustomErrorMessage} name="duePaymentFeeDays" />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <div className="flex justify-center gap-4 w-full pt-4">
                  <div className="flex justify-end w-[60%]">
                    <button
                      className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                      type="submit"
                    >
                      {editClosingLoading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        t('Save')
                      )}
                    </button>
                  </div>
                </div>
              </DialogActions>
              <ConfirmDialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                onParentDialogClose={onClose}
              />
            </Form>
          )}
        </StyledDialog>
      )}
    </Formik>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
