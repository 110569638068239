import * as yup from 'yup';

export const updateProfileSchema = [
  yup.object({
    salutation: yup.string().required('Salutation Type field is required'),
    nationality: yup.string().required('Nationality field is required'),
  }),
  yup.object({
    residencyAddress: yup.object().shape({
      placeId: yup.string().required('Location field is required'),
      zipCode: yup.string().required('Zip Code field is required'),
      apartmentNumber: yup.string().required('Apartment Number field is required'),
      floor: yup.string(),
      building: yup.string(),
    }),
  }),
  yup.object({
    billingAddress: yup.object().shape({
      placeId: yup.string().required('Location field is required'),
      zipCode: yup.string().required('Zip Code field is required'),
      apartmentNumber: yup.string().required('Apartment Number field is required'),
      floor: yup.string(),
      building: yup.string(),
    }),
  }),
];
