import * as yup from 'yup';

export const repairsScheme = yup.object({
  property: yup
    .string()
    .typeError('Property field is required')
    .required('Property field is required'),
  category: yup
    .string()
    .typeError('Category field is required')
    .required('Category field is required'),
  subCategory: yup
    .string()
    .typeError('Sub-Category field is required')
    .required('Sub-Category field is required'),
  title: yup.string().typeError('Title field is required').required('Title field is required'),
  requestedBy: yup
    .string()
    .typeError('Requested by field is required')
    .required('Requested by field is required'),
  requestedDate: yup
    .date()
    .typeError('Requested date field is required')
    .required('Requested date field is required'),
  status: yup.string().typeError('Status field is required').required('Status field is required'),
});
