import React from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

export const SuccessStep = ({ propertyData }) => {
  const { propertyName, typeName, grossSqm, statusName } =
    propertyData || {};

  const { t } = useTranslation();
  const surfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');
  return (
    <div className="w-full h-full text-center p-2">
      <div className="font-bold text-2xl">{t('property_created')} 🎉</div>

      <PropertyCardContainer>
        <span className="mt-4 text-4xl font-bold">{propertyName}</span>
        <span className="mt-2 text-sm uppercase">{typeName}</span>

        <div className="flex gap-4 justify-center mt-2">
          <span></span>
        </div>

        <div className="h-1 mt-5 w-[370px] m-auto border-b border-[#C9C9C9]"></div>

        <div className="flex justify-between mt-6 m-auto w-11/12">
          <div className="flex flex-col">
            <span className="text-[#8E8E8E] text-sm">{t('type')}</span>
            <span>{typeName}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-[#8E8E8E] text-sm">{t('gross_sqm')}</span>
            <span>{grossSqm}{surfaceSymbol}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-[#8E8E8E] text-sm">{t('status')}</span>
            <span>{statusName}</span>
          </div>
        </div>
      </PropertyCardContainer>
    </div>
  );
};

const PropertyCardContainer = styled.div`
  width: 554px;
  padding: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 16px auto;
  border: 1px solid #fff;
  border-radius: 22px;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.2),
    0px 2px 10px rgba(0, 0, 0, 0.1);
`;
