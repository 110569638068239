export const offerInitialState = (data = null) => {
  const { generalInfo } = data || {};

  return {
    contact: data?.contactId || '',
    listing: data?.listingId || '',
    listingPrice: generalInfo?.askingPrice || data?.askingPrice ||'',
    offeredAmount: data?.offeredAmount || '',
    comments: data?.comments || '',
  };
};
