import * as yup from 'yup';

export const propertiesUnitSchema = [
  yup.object().shape({
    masterPropertyId: yup.string().required('Building field is required'),
  }),
  yup.object().shape({

  }),
  yup.object().shape({
    units: yup.array().required('Units field is required'),
  }),
  yup.object().shape({
    propertyDescription: yup.string().required('Comments field is required'),
  }),
];
