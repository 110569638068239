import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export const SkeletonTableLoading = () => {
  return (
    <div className="w-full">
      {[1, 2].map((_) => (
        <Skeleton variant="text" height={48} />
      ))}
    </div>
  );
};
