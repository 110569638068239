import React from 'react';
import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';

import { StyledDialog, Notifier } from 'components';
import { DialogContent, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';

export const ExportDialog = ({ openDialog, onClose, selectedElements, apiFunctions }) => {
  const [exportType, setExportType] = React.useState('');

  const { data, isLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    null,
    {
      enabled: openDialog?.dialogType === 'export',
    },
  );

  const {
    mutateAsync: exportMutation,
    data: exportResponse,
    isLoading: exportLoading,
  } = useCustomMutation(apiFunctions.export.func);

  const handleExport = async () => {
    let payload = {
      type: exportType,
      ids: selectedElements,
    };

    const response = await exportMutation(payload);
    if (response.statusCode === 200) onClose();
  };

  return (
    <>
      <Notifier data={exportResponse} />

      <StyledDialog
        fullWidth
        width="326px"
        height="400px"
        open={openDialog?.dialogType === 'export'}
        onClose={onClose}
      >
        <DialogContent>
          <div className="flex flex-col gap-4">
            <div className="font-bold text-xl">Your Report</div>

            <div className="text-xs text-[#EA3323] font-semibold">Export Dialog</div>
            <div className="text-sm text-[#15192080]">
              You will shortly receive the report by email
            </div>

            {isLoading ? (
              <div className="flex items-center justify-center">
                <CircularProgress color="inherit" size={25} />
              </div>
            ) : (
              data?.data.exportTypes.map((selectedExportType, index) => (
                <div key={selectedExportType.id}>
                  <FormControlLabel
                    label={selectedExportType.name}
                    labelPlacement="left"
                    control={
                      <Checkbox
                        icon={
                          <div className="w-5 h-5 border border-[3px] border-[#56678942] rounded-full">
                            {' '}
                          </div>
                        }
                        checkedIcon={
                          <div className="flex justify-center items-center w-5 h-5 border border-[3px] border-[#EA3323] rounded-full">
                            <div className="w-2 h-2 bg-[#EA3323] rounded-full"></div>
                          </div>
                        }
                        checked={selectedExportType.name === exportType}
                        onChange={() => setExportType(selectedExportType.name)}
                        disableRipple
                      />
                    }
                  />
                </div>
              ))
            )}
          </div>
        </DialogContent>

        <button
          className="w-fit px-4 py-2 m-auto bg-[#EA3323] rounded-lg text-white font-semibold"
          onClick={handleExport}
        >
          {exportLoading ? (
            <div className="flex items-center justify-center">
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            'Send File'
          )}
        </button>
      </StyledDialog>
    </>
  );
};
