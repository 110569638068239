import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, useFormikContext } from 'formik';
import {useTranslation} from "react-i18next";

import { LocusContext } from 'hooks/useLocusContext';
import { StyledInput, StyledSelect, Table} from 'components';
import { propertyUnitsColumns, propertyUnitViewOnly } from "utils/columns";
import {Warning} from "@mui/icons-material";

export const SQMUnit = () => {
  const { selectedSurfaceSymbol, selectedCurrencySymbol, dropdownValues, dialogType } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
  const [ units, setUnits ] = React.useState([]);
  const [ editUnit, setEditUnit] = React.useState({edit: false, data: null});
  const [ viewUnits, setViewUnits ] = React.useState([]);
  const [ disableLoanNow, setDisableLoanNow ] = React.useState(true);

  const isError = (value) => {
    if (errors[value]) return <span className="text-[#EA3323]">*</span>;
  };

  const isFilled = () => {
    return (
      values.unitTitle &&
      values.numberOfBathrooms &&
      values.numberOfBedrooms &&
      values.unitGrossSQM &&
      values.unitTypeId &&
      values.unitAcquisitionAmount &&
      values.loanAmount &&
      values.numberOfUnits
    );
  }

  const handleAddUnit = () => {
    if (!isFilled()) return;
    if (values.numberOfUnits > 1) {
      // loop through the number of units and add them to the array
      for (let i = 0; i < values.numberOfUnits; i++) {
        let id = Math.floor(100000 + Math.random() * 900000);
        setUnits(prevUnits => [
          ...prevUnits,
          {
            id: id,
            property_name: values.unitTitle + ' ' + (i + 1),
            number_of_bathrooms: values.numberOfBathrooms,
            number_of_bedrooms: values.numberOfBedrooms,
            gross_sqm: values.unitGrossSQM,
            type_id: values.unitTypeId,
            acquisition_amount: values.unitAcquisitionAmount,
            loan_amount: values.loanAmount,
          }
        ]);

        setViewUnits(prevViewUnits => [
          ...prevViewUnits,
          {
            id: id,
            unitTitle: values.unitTitle + ' ' + (i + 1),
            details:
              dropdownValues.types.find(type => type.id === values.unitTypeId)?.name
              + ', ' + values.unitGrossSQM + ' ' + selectedSurfaceSymbol
              + ', ' + values.unitAcquisitionAmount + ' ' + selectedCurrencySymbol
              + ', ' + values.numberOfBedrooms + ' ' + t('bedrooms')
              + ', ' + values.numberOfBathrooms + ' ' + t('bathrooms'),
            loanAmount: selectedCurrencySymbol + " " + values.loanAmount,
          }
        ]);
      }
    } else {
      let id = Math.floor(100000 + Math.random() * 900000);
      // add to the existing array
      setUnits(prevUnits => [
        ...prevUnits,
        {
          id: id,
          property_name: values.unitTitle,
          number_of_bathrooms: values.numberOfBathrooms,
          number_of_bedrooms: values.numberOfBedrooms,
          gross_sqm: values.unitGrossSQM,
          type_id: values.unitTypeId,
          acquisition_amount: values.unitAcquisitionAmount,
          loan_amount: values.loanAmount,
        }
      ]);

      setViewUnits(prevViewUnits => [
        ...prevViewUnits,
        {
          id: id,
          unitTitle: values.unitTitle,
          details:
            dropdownValues.types.find(type => type.id === values.unitTypeId)?.name
            + ' , ' + values.unitGrossSQM + ' ' + selectedSurfaceSymbol
            + ' , ' + values.unitAcquisitionAmount + ' ' + selectedCurrencySymbol
            + ' , ' + values.numberOfBedrooms + ' ' + t('bedrooms')
            + ' , ' + values.numberOfBathrooms + ' ' + t('bathrooms'),
          loanAmount: selectedCurrencySymbol + " " + values.loanAmount,
        }
      ]);
    }

    setFieldValue('units', units);
    // reset the values
    setFieldValue('unitTitle', '');
    setFieldValue('numberOfBathrooms', '');
    setFieldValue('numberOfBedrooms', '');
    setFieldValue('unitGrossSQM', '');
    setFieldValue('unitTypeId', '');
    setFieldValue('unitAcquisitionAmount', '');
    setFieldValue('loanAmount', '0');
    setFieldValue('numberOfUnits', 1);
  }

  const handleDuplicateUnit = (row) => {
    //get the unit unit that has unit title same as the row from the units array
    const unit = units.find((unit) => unit.property_name === row.unitTitle);

    let id = Math.floor(100000 + Math.random() * 900000);
    //add this unit again to the units array but having an id that is different from the original one
    setUnits(prevUnits => [
      ...prevUnits,
      {
        id: id,
        property_name: unit.property_name + '1',
        number_of_bathrooms: unit.number_of_bathrooms,
        number_of_bedrooms: unit.number_of_bedrooms,
        gross_sqm: unit.gross_sqm,
        type_id: unit.type_id,
        acquisition_amount: unit.acquisition_amount,
        loan_amount: unit.loan_amount,
      }
    ]);
    //add this unit again to the viewUnits array in the same format
    setViewUnits(prevViewUnits => [
      ...prevViewUnits,
      {
        id: id,
        unitTitle: unit.property_name + '1',
        details:
          dropdownValues.types.find(type => type.id === unit.type_id)?.name
          + ' , ' + unit.gross_sqm + ' ' + selectedSurfaceSymbol
          + ' , ' + unit.acquisition_amount + ' ' + selectedCurrencySymbol
          + ' , ' + unit.number_of_bedrooms + ' ' + t('bedrooms')
          + ' , ' + unit.number_of_bathrooms + ' ' + t('bathrooms'),
        loanAmount: selectedCurrencySymbol + " " + values.loanAmount,
      }
    ]);

    setFieldValue('units', units);
  }

  const handleEditUnit = (row) => {
    //get the unit that has unit title same as the row from the units array
    const unit = units.find((unit) => unit.property_name === row.unitTitle);
    //set the feild values to the unit values
    setFieldValue('unitTitle', unit.property_name);
    setFieldValue('numberOfBathrooms', unit.number_of_bathrooms);
    setFieldValue('numberOfBedrooms', unit.number_of_bedrooms);
    setFieldValue('unitGrossSQM', unit.gross_sqm);
    setFieldValue('unitTypeId', unit.type_id);
    setFieldValue('unitAcquisitionAmount', unit.acquisition_amount);
    setFieldValue('loanAmount', unit.loan_amount);
    setFieldValue('numberOfUnits', 1);
    setEditUnit({edit: true, data: unit.id});
  }

  const confirmEdit = () => {
    //edit the unit in the units array that has the same id as the one in editUnit.data
    const newUnits = units.map((unit) => {
      if (unit.id === editUnit.data) {
        return {
          id: unit.id,
          property_name: values.unitTitle,
          number_of_bathrooms: values.numberOfBathrooms,
          number_of_bedrooms: values.numberOfBedrooms,
          gross_sqm: values.unitGrossSQM,
          type_id: values.unitTypeId,
          acquisition_amount: values.unitAcquisitionAmount,
          loan_amount: values.loanAmount,
        }
      }
      return unit;
    });
    //edit the unit in the viewUnits array that has the same id as the one in editUnit.data
    const newViewUnits = viewUnits.map((unit) => {
      if (unit.id === editUnit.data) {
        return {
          id: unit.id,
          unitTitle: values.unitTitle,
          details:
            dropdownValues.types.find(type => type.id === values.unitTypeId)?.name
            + ' , ' + values.unitGrossSQM + ' ' + selectedSurfaceSymbol
            + ' , ' + values.unitAcquisitionAmount + ' ' + selectedCurrencySymbol
            + ' , ' + values.numberOfBedrooms + ' ' + t('bedrooms')
            + ' , ' + values.numberOfBathrooms + ' ' + t('bathrooms'),
          loanAmount: selectedCurrencySymbol + " " + values.loanAmount,
        }
      }
      return unit;
    });
    setUnits(newUnits);
    setViewUnits(newViewUnits);
    setEditUnit({edit: false, data: null});

    setFieldValue('units', units);
    // reset the values
    setFieldValue('unitTitle', '');
    setFieldValue('numberOfBathrooms', '');
    setFieldValue('numberOfBedrooms', '');
    setFieldValue('unitGrossSQM', '');
    setFieldValue('unitTypeId', '');
    setFieldValue('unitAcquisitionAmount', '');
    setFieldValue('loanAmount', '');
    setFieldValue('numberOfUnits', '');
  }

  React.useEffect(() => {
    setFieldValue('units', units);
  }, [units]);

  React.useEffect(() => {
    if(values.loanLevel === 'building'){
      setFieldValue('loanAmount', '0');
      setDisableLoanNow(true);
    }
  }, []);

  React.useEffect(() => {
    if((values.numberOfUnits > 1) && (values.loanLevel === 'unit')){
      setDisableLoanNow(true);
      setFieldValue('loanAmount', '0');
    }
    else if(values.loanLevel === 'building'){
      setDisableLoanNow(true);
    }
    else
      setDisableLoanNow(false);
  }, [values.numberOfUnits]);

  const handleRemoveUnit = (row) => {
    const newUnits = units.filter((unit) => unit.id !== row.id);
    const newViewUnits = viewUnits.filter((unit) => unit.id !== row.id);
    setUnits(newUnits);
    setViewUnits(newViewUnits);
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="text-3xl font-bold">{t('units')}</div>

      <div className="flex flex-col sm:flex-row gap-5 p-1">
        <div className="w-full">
          <div className="text-sm pb-1">{t('unit_title')} {isError('unitTitle')}</div>
          <StyledInput
            name="unitTitle"
            value={values.unitTitle}
            onChange={handleChange}
            placeholder={t('unit_title')}
            error={touched.unitTitle && errors.unitTitle}
          />
          <ErrorMessage name="unitTitle" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">{t('number_of_bathrooms')} {isError('numberOfBathrooms')}</div>
          <StyledInput
            name="numberOfBathrooms"
            value={values.numberOfBathrooms}
            onChange={handleChange}
            placeholder={t('number_of_bathrooms')}
            error={touched.numberOfBathrooms && errors.numberOfBathrooms}
          />
          <ErrorMessage name="numberOfBathrooms" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">{t('number_of_bedrooms')} {isError('numberOfBedrooms')}</div>
          <StyledInput
            name="numberOfBedrooms"
            value={values.numberOfBedrooms}
            onChange={handleChange}
            placeholder={t('number_of_bedrooms')}
            error={touched.numberOfBedrooms && errors.numberOfBedrooms}
          />
          <ErrorMessage name="numberOfBedrooms" component={ValidationError} />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-5 p-1">
        <div className="w-full">
          <div className="text-sm pb-1">{t('sqm')} {isError('unitGrossSQM')}</div>
          <StyledInput
            name="unitGrossSQM"
            value={values.unitGrossSQM}
            onChange={handleChange}
            prefix={selectedSurfaceSymbol}
            error={touched.unitGrossSQM && errors.unitGrossSQM}
          />
          <ErrorMessage name="unitGrossSQM" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">{t('type')} {isError('typeId')}</div>
          <StyledSelect
            name="unitTypeId"
            placeholder={t('type')}
            value={values.unitTypeId}
            onChange={handleChange}
            itemsList={dropdownValues.types}
            error={touched.unitTypeId && errors.unitTypeId}
          />
          <ErrorMessage name="unitTypeId" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">{t('purchase_amount')} {isError('unitAcquisitionAmount')}</div>
          <StyledInput
            name="unitAcquisitionAmount"
            value={values.unitAcquisitionAmount}
            onChange={handleChange}
            prefix={selectedCurrencySymbol}
            error={touched.unitAcquisitionAmount && errors.unitAcquisitionAmount}
          />
          <ErrorMessage name="unitAcquisitionAmount" component={ValidationError} />
        </div>

      </div>
      <div className="flex flex-col sm:flex-row gap-5 items-end p-1">
        <div className="w-full">
          <div className="text-sm pb-1">{t('loan_amount')} {isError('loanAmount')}</div>
          <StyledInput
            name="loanAmount"
            value={values.loanAmount}
            onChange={handleChange}
            prefix={selectedCurrencySymbol}
            error={touched.loanAmount && errors.loanAmount}
            disabled={disableLoanNow}
          />
          <ErrorMessage name="loanAmount" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">{t('number_of_units')} {isError('numberOfUnits')}</div>
          <StyledInput
            name="numberOfUnits"
            value={values.numberOfUnits}
            onChange={handleChange}
            placeholder={t('number_of_units')}
            error={touched.numberOfUnits && errors.numberOfUnits}
            disabled={editUnit.edit}
          />
          <ErrorMessage name="numberOfUnits" component={ValidationError} />
        </div>

        <StyledButton onClick={editUnit.edit ? confirmEdit : handleAddUnit} disabled={!isFilled()}>
          {editUnit.edit ? t('save') : t('add')}
        </StyledButton>
      </div>

      <div className="flex gap-2 items-center p-2 text-sm text-[#22537f] rounded-2xl border bg-[#d9e7f2e0] border-[#22537f]">
        <Warning style={{color: '#22537f'}}/>
        {values.loanLevel === 'building' ? t('building_loan_hint') : t('unit_loan_hint')}
      </div>
      <Table
        columns={propertyUnitsColumns}
        rows={viewUnits}
        onEdit={(row) => handleEditUnit(row)}
        onDelete={(row) => handleRemoveUnit(row)}
        onDuplicate={(row) => handleDuplicateUnit(row)}
        name={'units'}
        minimizeHeight
      />

      {
        dialogType === 'editProperty' ? (
          <Table
            columns={propertyUnitViewOnly}
            rows={values.viewUnit}
            onEdit={() => {}}
            onDelete={() => {}}
            onDuplicate={() => {}}
            name={'units'}
            minimizeHeight
          />
        ) : ''
      }
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;

const StyledButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #ea3323;
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  min-width: 120px;
  padding: 10px;
  font-weight: bold;
  height: 56px;
  opacity: ${props => props.disabled ? '0.5' : '1'};
`;