import {
  getRepairs,
  getRepair,
  addRepair,
  updateRepair,
  deleteRepair,
  addMaterial,
  updateMaterial,
  deleteMaterial,
  addCheckList,
  updateCheckList,
  deleteCheckList,
  getRepairAttachments,
  addRepairAttachment,
  updateRepairAttachement,
  deleteRepairAttachement,
  getRepairsDropdownData,
  assignToLocus,
  acceptRepair,
  rejectRepair,
  exportRepairs,
} from 'apis/repairs/repairs';

export const repairsApiFunctions = {
  getAll: { func: getRepairs, key: 'repairs' },
  get: { func: getRepair, key: 'repairDetails' },
  add: { func: addRepair, key: 'repairs' },
  edit: { func: updateRepair, key: 'repairDetails' },
  delete: { func: deleteRepair, key: 'repairs' },
  accept: { func: acceptRepair, key: 'repairDetails' },
  reject: { func: rejectRepair, key: 'repairDetails' },
  getAttachments: { func: getRepairAttachments, key: 'repairAttachments' },
  addAttachment: { func: addRepairAttachment, key: 'repairAttachments' },
  editAttachment: { func: updateRepairAttachement, key: 'repairAttachments' },
  deleteAttachment: { func: deleteRepairAttachement, key: 'repairAttachments' },
  addMaterial: { func: addMaterial, key: ['materials', 'repairDetails'] },
  editMaterial: { func: updateMaterial, key: ['materials', 'repairDetails'] },
  deleteMaterial: { func: deleteMaterial, key: ['materials', 'repairDetails'] },
  addCheckList: { func: addCheckList, key: ['checklists', 'repairDetails'] },
  editCheckList: { func: updateCheckList, key: ['checklists', 'repairDetails'] },
  deleteCheckList: { func: deleteCheckList, key: ['checklists', 'repairDetails'] },
  getDropdowns: { func: getRepairsDropdownData, key: 'repairsDropdown' },
};
