import * as yup from 'yup';

export const businessContactsSchema = [
  yup.object().shape({
    type: yup.string().required('Type field is required'),
  }),
  yup.object().shape({
    name: yup.string().required('Name field is required'),
    type: yup.string().required('Type field is required'),
    phone: yup.string().required('Phone field is required'),
    email: yup.string().email('Must be a valid email address').required('Email field is required'),
    invoiceEmail: yup
      .string()
      .email('Must be a valid email address')
      .required('Invoice Email field is required'),
    chamberCommerceNumber: yup.string(),
    contactName: yup.string().required('Contact name field is required'),
    iban: yup.string(),
    source: yup.string().required('Source field is required'),
    crmAccount: yup.string().notRequired(),
    vatNumber: yup.string(),
  }),
  yup.object().shape({
    contactAddress: yup.object().shape({
      placeId: yup.string().required('Location field is required'),
      building: yup.string().required('Building field is required'),
      floor: yup.string().required('Floor field is required'),
      zipCode: yup.string().required('Zip Code field is required'),
      apartmentNumber: yup.string().required('Apartment Number field is required'),
    }),
  }),
  yup.object().shape({
    billingAddress: yup.object().shape({
      placeId: yup.string().required('Location field is required'),
      building: yup.string(),
      floor: yup.string(),
      zipCode: yup.string().required('Zip Code field is required'),
      apartmentNumber: yup.string().required('Apartment Number field is required'),
    }),
  }),
  yup.object().shape({
    comments: yup.string().nullable().notRequired(),
  }),
];

export const individualContactsSchema = [
  yup.object().shape({
    type: yup.string().required('Type field is required'),
  }),
  yup.object().shape({
    salutation: yup.string().required('Salutation type field is required'),
    firstName: yup.string().required('First Name field is required'),
    lastName: yup.string().required('Last Name field is required'),
    phone: yup.string().required('Phone field is required'),
    email: yup.string().email('Must be a valid email address').required('Email field is required'),
    invoiceEmail: yup
      .string()
      .email('Must be a valid email address')
      .required('Invoice Email field is required'),
    iban: yup.string(),
    nationality: yup.string().required('Nationality field is required'),
    source: yup.string().required('Source field is required'),
    crmAccount: yup.string().notRequired(),
    passport: yup.string().notRequired(),
    type: yup.string().required('Type field is required'),
  }),
  yup.object().shape({
    contactAddress: yup.object().shape({
      placeId: yup.string().required('Location field is required'),
      building: yup.string().required('Building field is required'),
      floor: yup.string().required('Floor field is required'),
      zipCode: yup.string().required('Zip Code field is required'),
      apartmentNumber: yup.string().required('Apartment Number field is required'),
    }),
  }),
  yup.object().shape({
    billingAddress: yup.object().shape({
      placeId: yup.string().required('Location field is required'),
      building: yup.string().required('Building field is required'),
      floor: yup.string().required('Floor field is required'),
      zipCode: yup.string().required('Zip Code field is required'),
      apartmentNumber: yup.string().required('Apartment Number field is required'),
    }),
  }),
  yup.object().shape({
    comments: yup.string().notRequired(),
  }),
];
