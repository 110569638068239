import { useNavigate } from 'react-router-dom';

import locationIcon from 'assets/LocationIcon.svg';
import homeIcon from 'assets/homeIcon.svg';

export const LeadCard = ({ lead }) => {
  const navigate = useNavigate();
  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const surfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');

  return (
    <div
      className="flex items-center gap-2 h-[160px] border border-[1px] border-[#C9C9C9] rounded-2xl hover:cursor-pointer ease-in duration-300 p-4 hover:shadow-xl hover:scale-[1.015]"
      onClick={() => navigate(`/lead-overview/${lead.leadId}/details`)}
    >
      <img className="w-[60px] h-[60px] rounded-full object-cover" src={lead.avatar} />

      <div className="flex flex-col w-full justify-between p-2">
        <div className="truncate">
          <span className="text-[10px] text-[#8E8E8E] font-light">
            {lead.propertyUsage?.toUpperCase()}
          </span>
          <div className="font-bold truncate">{lead.contactName}</div>
        </div>

        <div className="text-xs font-light">{lead.contactType}</div>

        <div className="flex items-center gap-1 mt-2">
          <div className="bg-[#ECECEC] rounded-full p-1.5">
            <img className="w-4" src={locationIcon} />
          </div>
          <div>
            <div className="text-[10px] font-medium">{lead.city}</div>
          </div>
        </div>

        <div className="flex justify-between mt-2">
          <div className="flex items-center gap-1">
            <div className="bg-[#ECECEC] rounded-full p-1.5">
              <img className="w-4" src={homeIcon} />
            </div>
            <div>
              <div className="text-[10px] font-medium">
                {lead.sqmRangeValue + ' ' + surfaceSymbol}
              </div>
            </div>
          </div>
          <div className="font-bold text-[#5A5A5A] whitespace-nowrap">
            {currencySymbol + ' ' + lead.budget}
          </div>
        </div>
      </div>
    </div>
  );
};
