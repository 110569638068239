import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {CardComponent, CardCVV, CardExpiry, CardNumber} from "@chargebee/chargebee-js-react-wrapper";
import {useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import tickIcon from "assets/checked_tick.svg";

export const CreditCardInfo = () => {
  const cardRef = useRef(null);

  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvvRef = useRef(null);

  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [state, setState] = useState({
    intent: "",
    additionalData: {},
    token: "",
    error: "",
    loading: false,
    firstName: "",
    options: {
      // Custom classes, styles, locale, placeholders, and fonts
      // Define them here as in your class component
      style: {
        // Styles for default field state
        base: {
          color: '#333',
          fontWeight: '500',
          fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':focus': {
            color: '#424770',
          },

          '::placeholder': {
            color: 'transparent',
          },

          ':focus::placeholder': {
            color: '#7b808c',
          },

          ':-webkit-autofill': {
            webkitTextColor: '#333',
          }
        },

        // Styles for invalid field state
        invalid: {
          color: '#e41029',

          ':focus': {
            color: '#e44d5f',
          },
          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      },
      // locale
      locale: 'en',
      // Custom placeholders
      placeholder: {
        number: "4111 1111 1111 1111",
        expiry: "MM / YY",
        cvv: "CVV"
      },
      // Custom fonts
      fonts: [
        'https://fonts.googleapis.com/css?family=Roboto:300,500,600'
      ]
    }
  });

  const [cardValues, setCardValues] = useState({
    number: '',
    expiry: '',
    cvv: ''
  });

  const tokenize = () => {
    setState(prevState => ({ ...prevState, loading: true }));


    cardRef.current.tokenize()
      .then((data) => {
        console.log("Tokenization successful:", data);
        setState(prevState => ({ ...prevState, loading: false, token: data.token, error: "" }));
      })
      .catch((error) => {
        console.error("Tokenization error:", error);
        setState(prevState => ({ ...prevState, loading: false, token: "", error: "Problem while tokenizing " }));
      });
  }
  const handleChange = (event) => {

    const { name, value, type, checked } = event.target;
    setState(prevState => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  }

  const { style, classes, locale, placeholder, fonts } = state.options;

  const checkCardValues = () => {
    const number = cardNumberRef.current?.element?.value || '';
    const expiry = cardExpiryRef.current?.element?.value || '';
    const cvv = cardCvvRef.current?.element?.value || '';

    setCardValues({ number, expiry, cvv });
  };

  useEffect(() => {
    const interval = setInterval(checkCardValues, 500); // Check every 500ms
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (state.token) {
      setFieldValue('token', state.token);
    }
  }, [state.token]);

  return (
    <div className="w-full sm:w-[55%] flex flex-col">
      <p className="font-semibold text-center text-3xl mb-5">{t('credit_card_information')}</p>
      <div className="p-6 rounded-2xl glass_card">
        <div className="ex1-field">
          <input name="firstName" className={ state.firstName ? "ex1-input val" : "ex1-input"} type="text" value={state.firstName} onChange={handleChange} />
          <label className="ex1-label">Name on Card</label><i className="ex1-bar"></i>
        </div>
        <CardComponent
          ref={cardRef}
          className=""
          styles={style}
          classes={classes}
          locale={locale}
          placeholder={placeholder}
          fonts={fonts}
          onKeyPress={(event) => console.log(event)}
        >
          <div className="ex1-field">
            {/* Card number component */}
            <CardNumber ref={cardNumberRef} className={`ex1-input val ${cardValues.number && 'val'}`} />
            <label className="ex1-label"> Card number</label><i className="ex1-bar"></i>
          </div>

          <div className="ex1-fields">
            <div className="ex1-field">
              {/* Card expiry component */}
              <CardExpiry ref={cardExpiryRef} className={`ex1-input val ${cardValues.expiry && 'val'}`}/>
              <label className="ex1-label">Expiry</label><i className="ex1-bar"></i>
            </div>

            <div className="ex1-field">
              {/* Card cvv component */}
              <CardCVV ref={cardCvvRef} className={`ex1-input val ${cardValues.cvv && 'val'}`}/>
              <label className="ex1-label">CVV</label><i className="ex1-bar"></i>
            </div>

          </div>
        </CardComponent>

        {!state.token && <button type="submit" className={state.loading ? "submit ex1-button" : "ex1-button"} onClick={tokenize}>{t('save')}</button>}
        {state.error && <div className="error" role="alert">{state.error}</div>}
        {state.token && <div className="flex gap-4"><img src={tickIcon} alt=""/> <div className="font-semibold text-[#FF0000]">Card Details Saved</div></div>}
      </div>
    </div>
  );
}

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;