import { useQuery, useInfiniteQuery } from 'react-query';

export const useQueryList = (queryFunc, ...params) => {
  return useQuery(params, queryFunc);
};

export const useCustomQuery = (queryFunc, ...params) => {
  const [key, id, queryOptions = {}] = params;

  return useQuery([key, id], () => queryFunc(id), queryOptions);
};

export const usePaginatedQuery = (queryFunc, key, ...params) => {
  const [id] = params;
  return useInfiniteQuery([key, id], ({ pageParam = 1 }) => queryFunc(...params, pageParam), {
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.pageNumber < lastPage.pagination.totalPages) {
        return lastPage.pagination.pageNumber + 1;
      }
      return undefined;
    },
  });
};
