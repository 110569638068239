import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { forgotPassword } from 'apis/authentication/authentication';
import { CircularProgress } from '@mui/material';
import bannerImage from 'assets/testLoginbg.png';

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().required('email field is required'),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation((payload) => forgotPassword(payload));

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={forgotPasswordSchema}
      onSubmit={(values) => {
        mutate(values, {
          onSuccess: (data) => {
            if (data.statusCode === 200) {
              localStorage.setItem('username', values.email);
              navigate('/set-new-password', {
                state: { username: values.email },
              });
            } else if (data.statusCode === 404) {
              alert('there was an error');
            }
          },
        });
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="flex h-screen">
            <div className="flex-none w-[500px] hidden lg:flex relative">
              <img src={bannerImage} className="absolute inset-0 w-full h-full object-cover" />
            </div>

            <div className="flex flex-col w-full bg-[url('assets/login-background.svg')] bg-cover bg-center p-4">
              <div className="flex items-center self-end mt-4 justify-center text-lg bg-black w-40 h-12 text-white rounded-tr-3xl rounded-bl-3xl">
                <a href="/login">Back to home</a>
              </div>

              <div className="grid content-center flex flex-col w-full sm:w-7/12 h-full justify-start">
                <p className="font-semibold text-4xl mb-3">Forgot Password</p>
                <p className="font-medium text-lg mb-7 text-[#FF0000]">
                  Please enter your email address to reset your password
                </p>

                <div>
                  <Field
                    name="email"
                    value={values.email}
                    placeholder="Email"
                    className={`pl-4 border border-solid ${
                      touched.email && errors.email ? 'border-[#EA3323]' : 'border-[#00000033]'
                    } rounded-lg w-full h-14 focus:outline-none mb-3`}
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => <div className="text-[#EA3323]">{msg}</div>}
                  />
                </div>

                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="mt-6 w-24 h-12 bg-[#FF0000] text-white font-medium align-middle text-center rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer"
                  >
                    {isLoading ? <CircularProgress color="inherit" size={25} /> : 'RESET'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
