//  * individual contact iniial state
export const individualTypeInitialState = (data = null) => {
  return {
    salutation: data?.generalInfo.salutationId || '',
    firstName: data?.summary.firstName || '',
    lastName: data?.summary.lastName || '',
    mobile: data?.summary.mobile || '',
    phone: data?.summary.phone || '',
    email: data?.summary.email || '',
    invoiceEmail: data?.generalInfo.invoiceEmail || '',
    nationality: data?.generalInfo.countryId || '',
    passport: data?.generalInfo.passport || '',
    type: data?.generalInfo.contactTypeId || '',
    iban: data?.generalInfo.iban || '',
    source: data?.generalInfo.sourceId || '',
    crmAccount: data?.generalInfo.accountingReference || '',
    comments: data?.generalInfo.comments || '',
    address: data?.generalInfo.address || '',
    billingAddress: {
      building: data?.billingAddress.building || '',
      floor: data?.billingAddress.floor || '',
      zipCode: data?.billingAddress.zipCode || '',
      placeId: data?.billingAddress.placeId || '',
      description: data?.billingAddress.description || '',
      apartmentNumber: data?.billingAddress.apartmentNumber || '',
    },
    contactAddress: {
      building: data?.contactAddress.building || '',
      floor: data?.contactAddress.floor || '',
      zipCode: data?.contactAddress.zipCode || '',
      placeId: data?.contactAddress.placeId || '',
      description: data?.contactAddress.description || '',
      apartmentNumber: data?.contactAddress.apartmentNumber || '',
    },
    attachments: [],
  };
};

//  * corporate contact initial state
export const bussinessTypeInitialState = (data = null) => {
  return {
    name: data?.summary.name || '',
    phone: data?.summary.phone || '',
    email: data?.summary.email || '',
    type: data?.generalInfo.contactTypeId || '',
    chamberCommerceNumber: data?.generalInfo.chamberOfCommerceNumber || '',
    invoiceEmail: data?.generalInfo.invoiceEmail || '',
    contactName: data?.generalInfo.referencePersonName || '',
    vatNumber: data?.generalInfo.vatNumber || '',
    source: data?.generalInfo.sourceId || '',
    iban: data?.generalInfo.iban || '',
    crmAccount: data?.generalInfo.accountingReference || '',
    comments: data?.generalInfo.comments || '',
    address: data?.generalInfo.address || '',
    billingAddress: {
      building: data?.billingAddress.building || '',
      floor: data?.billingAddress.floor || '',
      zipCode: data?.billingAddress.zipCode || '',
      placeId: data?.billingAddress.placeId || '',
      description: data?.billingAddress.description || '',
      apartmentNumber: data?.billingAddress.apartmentNumber || '',
    },
    contactAddress: {
      building: data?.contactAddress.building || '',
      floor: data?.contactAddress.floor || '',
      zipCode: data?.contactAddress.zipCode || '',
      placeId: data?.contactAddress.placeId || '',
      description: data?.contactAddress.description || '',
      apartmentNumber: data?.contactAddress.apartmentNumber || '',
    },
    attachments: [],
  };
};
