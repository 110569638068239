import * as yup from 'yup';

export const closingsSchema = yup.object({
  listing: yup.string().required('listing feild is required'),
  propertyType: yup.string().required('Property Type feild is required'),
  propertyUsage: yup.string().required('property Usage feild is required'),
  condition: yup.string().required('condition feild is required'),
  furnished: yup.string().required('furnished feild is required'),
  tenantType: yup.string().required('tenantType feild is required'),
  tenant: yup.string().required('tenant feild is required'),
  contactStart: yup.string().required('contact Start feild is required'),
  contactEnd: yup.string().required('contact End feild is required'),
  exitDate: yup.string().required('exit Date feild is required'),
  currency: yup.string().required('currency feild is required'),
  vatApplicable: yup.string().required('vat Applicable required feild is required'),
  vatPercentage: yup.string().required('paid Amount feild is required'),
  guaranteeType: yup.string().required('guarantee Type feild is required'),
  listingAmount: yup.string().required('listing Amount feild is required'),
  offeredAmount: yup.string().required('offered Amount feild is required'),
  dealAmount: yup.string().required('deal Amount feild is required'),
  guaranteeAmount: yup.string().required('guarantee Amount feild is required'),
  invoicedAmount: yup.string().required('invoiced Amount feild is required'),
  paidAmount: yup.string().required('paid Amount feild is required'),
  cancelledAmount: yup.string().required('cancelled Amount feild is required'),
  dueAmount: yup.string().required('due Amount feild is required'),
  comments: yup.string().required('comments feild is required'),
});
