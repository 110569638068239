import React, {useEffect} from 'react';
import { LocusContext } from 'hooks/useLocusContext';
import { ErrorMessage, useFormikContext } from 'formik';
import { DatePicker, StyledInput, StyledSelect } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const TransactionDetails = ({ exchangeRate, setExchangeRate }) => {
  const { dropdownValues, data, dialogType } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const [currencyField, setCurrencyField] = React.useState('');
  const { contacts, currencies, transactionStatuses, glAccounts } = dropdownValues || {};

  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const applyVatArray = [
    { id: '1', name: 'Yes' },
    { id: '0', name: 'No' },
  ]
  const isError = (value) => {
    if (errors[value]) return <span className="text-[#EA3323]">*</span>;
  };

  useEffect(() => {
    //search for the currency id in the currencies array
    const currency = currencies?.find((currency) => currency.id === currencyField)?.symbol;
    //get the currency symbol from the local storage
    const userGroupCurrency = localStorage.getItem('selectedCurrencySymbol');
    if (currency !== userGroupCurrency)
      setExchangeRate(true);
    else setExchangeRate(false);
  }, [currencyField])

  useEffect(() => {
    setExchangeRate(false);
  }, [dialogType])

  return (
    <div className="flex flex-col gap-2">
      <div className="text-2xl sm:text-3xl font-bold">{t('transaction_details')}</div>

      <div className="flex flex-col sm:flex-row gap-5">
        <div className="flex flex-col gap-4 w-full p-2">
          <div>
            <div className="text-sm pb-1">
              {t('contact')} {isError('contactId')}
            </div>
            <StyledSelect
              name="contactId"
              placeholder={t('contact')}
              width="100%"
              value={values.contactId}
              onChange={handleChange}
              error={touched.contactId && errors.contactId}
              itemsList={contacts}
            />
            <ErrorMessage component={ValidationError} name="contactId" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('transaction_date')} {isError('transactionDate')}
            </div>
            <DatePicker
              name="transactionDate"
              value={values.transactionDate}
              onChange={(value) => setFieldValue('transactionDate', value)}
              error={touched.transactionDate && errors.transactionDate}
            />
            <ErrorMessage component={ValidationError} name="transactionDate" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('gl_account')} {isError('glAccountId')}
            </div>
            <StyledSelect
              name="glAccountId"
              placeholder={t('gl_account')}
              width="100%"
              value={values.glAccountId}
              onChange={handleChange}
              error={touched.glAccountId && errors.glAccountId}
              itemsList={glAccounts}
            />
            <ErrorMessage component={ValidationError} name="glAccountId" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('apply_vat')} {isError('applyVat')}
            </div>
            <StyledSelect
              name="applyVat"
              placeholder={t('apply_vat')}
              value={values.applyVat}
              onChange={handleChange}
              error={touched.applyVat && errors.applyVat}
              itemsList={applyVatArray}
            />
            <ErrorMessage component={ValidationError} name="applyVat" />
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full p-2">
          <div>
            <div className="text-sm pb-1">
              {t('due_date')} {isError('dueDate')}
            </div>
            <DatePicker
              name="dueDate"
              value={values.dueDate}
              onChange={(value) => setFieldValue('dueDate', value)}
              error={touched.dueDate && errors.dueDate}
            />
            <ErrorMessage component={ValidationError} name="dueDate" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('accounting_reference')} {isError('accountingReference')}
            </div>
            <StyledInput
              name="accountingReference"
              placeholder={t('accounting_reference')}
              value={values.accountingReference}
              onChange={handleChange}
              error={touched.accountingReference && errors.accountingReference}
            />
            <ErrorMessage component={ValidationError} name="accountingReference" />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('description')} {isError('description')}
            </div>
            <StyledInput
              name="description"
              placeholder={t('description')}
              value={values.description}
              onChange={handleChange}
              error={touched.description && errors.description}
              symbol={currencySymbol}
            />
            <ErrorMessage component={ValidationError} name="description" />
          </div>
        </div>
      </div>
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
