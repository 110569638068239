import styled from 'styled-components';

export const SectionContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  width: ${({ fullwidth }) => fullwidth && '100%'};
  height: ${({ height }) => (height ? height + 'px' : 'auto')};
`;
