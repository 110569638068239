import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { isMobile } from 'utils/utils';
import { propertyDialogSteps } from 'utils/constants';
import { propertiesInitialState } from 'initialStates';
import { propertiesBuildingSchema, propertiesUnitSchema } from 'validationSchemas';
import { addProperty } from 'apis/properties/properties';
import { LocusContextProvider } from 'hooks/useLocusContext';

import { Address, Comments, SuccessStep, PropertyType, SQMUnit } from './propertyDialogComponents';
import { StyledDialog, Steps, Notifier } from 'components';

import { CircularProgress, DialogContent } from '@mui/material';
import backArrow from 'assets/backArrow.svg';

export const PropertyDialog = ({ openDialog, onClose, apiFunctions }) => {
  const { t } = useTranslation();
  const formikFormRef = React.useRef();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [propertyData, setPropertyData] = React.useState(null);
  const [APIResponse, setAPIResponse] = React.useState({ message: '', code: '' });
  const [ownBuilding, setOwnBuilding] = React.useState(0);

  const selectedSurfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');
  const selectedCurrencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const isLastStep = currentStep === propertyDialogSteps.length - 1;

  const currentValidationSchema = ownBuilding
    ? propertiesUnitSchema[currentStep]
    : propertiesBuildingSchema[currentStep];
  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addProperty') return true;
    else if (openDialog?.dialogType === 'editProperty') return true;
    return false;
  };

  const handleSetNewStep = (direction) => {
    if (direction === 'increase') setCurrentStep(currentStep + 1);
    else {
      if (currentStep === 2 && ownBuilding) setCurrentStep(currentStep - 2);
      else setCurrentStep(currentStep - 1);
    }
  };

  const handleCloseDialog = () => {
    formikFormRef.current?.resetForm();
    setCurrentStep(0);
    setOwnBuilding(0);
    onClose();
  };

  const { data, isLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    {
      enabled: isDialogOpen(),
    },
  );

  const {
    mutateAsync: addPropertyMutation,
    isLoading: addPropertyLoading,
    data: addPropertyResponse,
  } = useCustomMutation(apiFunctions.add.func, apiFunctions.add.key);

  const {
    mutateAsync: updatePropertyMutation,
    isLoading: updatePropertyLoading,
    data: updatePropertyResponse,
  } = useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  React.useEffect(() => {
    if (openDialog?.dialogType === 'editProperty') {
      setOwnBuilding(openDialog?.data?.recordTypeName);
    }
  }, [openDialog]);

  return (
    <>
      <Notifier data={addPropertyResponse} />
      <Notifier data={updatePropertyResponse} />
      <Formik
        innerRef={formikFormRef}
        enableReinitialize
        initialValues={propertiesInitialState(openDialog?.data)}
        validationSchema={currentValidationSchema}
        onSubmit={async (values, { resetForm, setTouched, setSubmitting }) => {
          if (isLastStep) {
            let payload = {
              ...values,
              propertyId: openDialog?.data?.propertyId,
            };

            if (openDialog?.dialogType === 'addProperty') {
              const addResponse = await addPropertyMutation(payload);
              if (addResponse.statusCode === 200) {
                resetForm();
                setPropertyData(addResponse.data);
                setCurrentStep(4);
              }
            } else if (openDialog?.dialogType === 'editProperty') {
              const editResponse = await updatePropertyMutation(payload);
              if (editResponse.statusCode === 200) {
                resetForm();
                onClose();
              }
            }
          } else {
            setTouched({});
            setSubmitting(false);
            if (currentStep === 0 && ownBuilding) setCurrentStep(currentStep + 2);
            else setCurrentStep(currentStep + 1);
          }
        }}
      >
        {({ dirty }) => (
          <StyledDialog
            fullScreen={isMobile()}
            open={isDialogOpen()}
            onClose={handleCloseDialog}
            isEdited={dirty}
            width="1064px"
            height="725px"
          >
            <DialogContent>
              {isLoading ? (
                <div className="grid place-content-center h-full">
                  <CircularProgress color="error" />
                </div>
              ) : (
                <div className="flex justify-center h-full">
                  {currentStep !== 4 && (
                    <Steps steps={propertyDialogSteps} currentStep={currentStep} />
                  )}

                  <Form
                    className={`${
                      currentStep === 4 ? 'w-full' : 'w-full sm:w-[75%]'
                    } flex flex-col gap-4 justify-between h-full p-2`}
                  >
                    <div className="m-auto w-full overflow-auto">
                      <LocusContextProvider
                        dropdownValues={data?.data}
                        apiFunctions={apiFunctions}
                        selectedSurfaceSymbol={selectedSurfaceSymbol}
                        selectedCurrencySymbol={selectedCurrencySymbol}
                        APIResponse={APIResponse}
                        dialogType={openDialog?.dialogType}
                      >
                        {currentStep === 0 && (
                          <PropertyType
                            owBuilding={ownBuilding}
                            setOwnBuilding={(bool) => setOwnBuilding(bool)}
                          />
                        )}
                        {currentStep === 1 && <Address />}
                        {currentStep === 2 && <SQMUnit />}
                        {currentStep === 3 && <Comments />}
                        {currentStep === 4 && <SuccessStep propertyData={propertyData} />}
                      </LocusContextProvider>
                    </div>

                    <div className="self-end flex gap-4">
                      {currentStep !== 0 && currentStep !== 4 && (
                        <div
                          onClick={() => handleSetNewStep('decrease')}
                          className="flex justify-center gap-4 bg-[#F6F4F4] cursor-pointer text-[#8E8E8E] rounded-2xl font-bold p-3 w-32 text-center"
                        >
                          <img alt="" src={backArrow} />
                          {t('back')}
                        </div>
                      )}

                      {currentStep !== 4 && (
                        <StyledButton type="submit">
                          {addPropertyLoading || updatePropertyLoading ? (
                            <div className="flex justify-center items-center">
                              <CircularProgress color="inherit" size={20} />
                            </div>
                          ) : isLastStep ? (
                            t('submit')
                          ) : (
                            t('next')
                          )}
                        </StyledButton>
                      )}

                      {currentStep === 4 && (
                        <StyledButton
                          type="button"
                          onClick={() => {
                            onClose();
                            navigate(`/property-overview/${propertyData?.propertyId}/details`);
                          }}
                        >
                          {t('go_to_details')}
                        </StyledButton>
                      )}
                    </div>
                  </Form>
                </div>
              )}
            </DialogContent>
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const StyledButton = styled.button`
  background: #ea3323;
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  min-width: 120px;
  padding: 10px;
  font-weight: bold;
`;
