import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

/*
 * |-----------------------------------------------------------------------
 * | Listings API
 * | getlistings - getListing - addListing - updateListing - deleteListing
 * |-----------------------------------------------------------------------
 */

export const getlistings = () => {
  return axios.get(`/api/v1/listing-app/listings/${getUserGroupId()}`);
};

export const getListing = (listingId) => {
  return axios.get(`/api/v1/listing-app/listing/show/${listingId}/${getUserGroupId()}`);
};

export const getListingOffers = (listingId) => {
  return axios.get(`/api/v2/listing-app/listings/offers/${listingId}/${getUserGroupId()}`);
};

export const addListing = (payload) => {
  return axios.post(`/api/v1/listing-app/listing/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateListing = (payload) => {
  return axios.post(`/api/v1/listing-app/listing/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteListing = (listingsIds) => {
  return axios.post(`/api/v1/listing-app/listing/destroy`, {
    listing_ids: listingsIds,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |----------------------
 * | Listings Related API
 * | getListingRelated
 * |----------------------
 */

export const getListingRelated = (listingId) =>
  axios.get(`/api/v1/listing-app/listing/related/${listingId}/${getUserGroupId()}`);

/*
 * |---------------------------------------------------------------------------------------------------
 * | Listings Attachments API
 * | getListingAttachments - addListingAttachment - updateListingAttachment - deleteListingAttachment
 * |---------------------------------------------------------------------------------------------------
 */

export const getListingAttachments = (listingId, page) => {
  return axios.get(
    `/api/v1/listing-app/listing/attachments/${listingId}/${getUserGroupId()}?page=${page}`,
  );
};

export const addListingAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachment).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });
  formData.append('listing_id', payload.listing_id);
  formData.append('user_group_id', getUserGroupId());
  return axios.post(`/api/v2/listing-app/listings/attachments/store`, formData);
};

export const updateListingAttachment = (payload) => {
  return axios.post(`/api/v1/listing-app/listing/attachments/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteListingAttachment = (listingAttachmentId) => {
  return axios.post(`/api/v1/listing-app/listing/attachments/destroy`, {
    listing_attachment_ids: listingAttachmentId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |----------------------------
 * | Listings Public Images API
 * | getListingPublicImages
 * |----------------------------
 */

export const getListingPublicImages = (listingId) => {
  return axios.get(`/api/v1/listing-app/listing/public-images/${listingId}/${getUserGroupId()}`);
};

export const getListingDropdownValues = () =>
  axios.get(`/api/v1/listing-app/listing/dropdown-values/${getUserGroupId()}`);

export const exportListings = (payload) => {
  return axios.post(`/api/v2/listing-app/listing/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    listing_ids: payload.ids,
  });
};

export const getListingSharedAttachments = (listingId) => {
  return axios.get(
    `/api/v2/listing-app/listing/shared-attachments/${listingId}/${getUserGroupId()}`,
  );
};
