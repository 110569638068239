import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryList, useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import {isMobile, TabName} from 'utils/utils';
import { closingsTableColumns } from 'utils/columns';

import {
  AddButton,
  CardButton,
  DeleteButton,
  DeleteDialog,
  EditButton,
  EmptyList,
  ExportButton,
  ListButton,
  StyledDataGrid,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { EditClosingDialog } from './editClosingDialog';
import { ExportClosingsDialog } from './exportClosingsDialog';
import { ClosingDialog } from './closingDialog';

import { CircularProgress, Checkbox, Alert } from '@mui/material';
import searchIcon from 'assets/search.svg';
import locationIcon from 'assets/LocationIcon.svg';
import homeIcon from 'assets/homeIcon.svg';

const Closings = ({ apiFunctions }) => {
  TabName('Closings');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');
  const surfaceSymbol = localStorage.getItem('SelectedSurfaceSymbol');

  const [globalError, setGlobalError] = React.useState(null)
  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedClosings, setSelectedClosings] = React.useState([]);
  const [allClosingsSelected, setAllClosingsSelected] = React.useState(false);
  const [closingsList, setClosingsList] = React.useState([]);

  const { data, isLoading, error } = useQueryList(
    apiFunctions.getAll.func,
    apiFunctions.getAll.key,
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const { data: closingDropdown, isLoading: dropdownLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    null,
  );

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedClosings(data?.data.map((item) => item.closingId));
      setAllClosingsSelected(true);
    } else {
      setSelectedClosings([]);
      setAllClosingsSelected(false);
    }
  };

  const handleDeleteClosing = async () => {
    const response = await deleteMutation(selectedClosings);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setClosingsList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.propertyName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.propertyAddress.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.propertyType.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.contactName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.closingTypeName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.contractStart.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.contractEnd.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setClosingsList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setClosingsList(data?.data);
    }
  }, [data?.data]);

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if(globalErrorMsg){
      setGlobalError({statusCode: 500, message: globalErrorMsg});
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div>
      {error && (
        <Alert sx={{ borderRadius: '16px' }} severity="error">
          There was an error while getting the data
        </Alert>
      )}

      <Notifier data={mutationResponse} />
      <Notifier data={globalError} />

      <div className="bg-white rounded-2xl mt-11 pt-2">
        <div className="flex justify-between">
          <div className="hidden lg:flex items-center pl-5">
            <div className="flex h-[40px] items-center justify-center border border-[#C9C9C9] rounded-lg">
              <Checkbox
                icon={
                  <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
                }
                checkedIcon={
                  <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
                }
                checked={allClosingsSelected}
                onChange={handleSelectAll}
              />
              <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
            </div>
          </div>

          <div className="flex flex-col-reverse sm:flex-row justify-between w-full lg:w-auto gap-4 p-3">
            <div className="flex rounded-lg bg-[#F6F4F4]">
              <div className="flex items-center">
                <img className="pl-2" src={searchIcon} />
              </div>
              <input
                className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
                placeholder={t('search_by_property')}
                value={searchValue}
                onChange={handleFilterChange}
              />
            </div>

            <div className="flex gap-4">
              <AddButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'addNewClosing' })}
                disabled={isLoading}
              />

              <EditButton
                onClick={() => setOpenDialog({ data: selectedClosings, dialogType: 'editClosing' })}
                disabled={selectedClosings.length > 1 || !selectedClosings.length}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={!selectedClosings.length}
              />

              <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
            </div>

            <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
              <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
              <ListButton
                isActivated={activeTab === 'table'}
                onClick={() => setActiveTab('table')}
              />
            </div>
          </div>
        </div>

        {!isLoading && !data?.data?.length ? (
          <EmptyList listName="closings" />
        ) : activeTab === 'table' ? (
          <StyledDataGrid
            checkboxSelection
            rows={closingsList || []}
            columns={closingsTableColumns(t)}
            getRowId={(row) => row.closingId}
            onSelectionModelChange={(newSelectedClosing) => setSelectedClosings(newSelectedClosing)}
            selectionModel={selectedClosings}
            rowsPerPageOptions={[]}
            loading={isLoading}
            pageSize={25}
            rowHeight={70}
            onRowClick={(params) => navigate(`/closing-overview/${params.row.closingId}/details`)}
            components={{
              LoadingOverlay: () => (
                <div className="mt-8 flex justify-center">
                  <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
                </div>
              ),
            }}
          />
        ) : isLoading ? (
          <div className="flex flex-col gap-4 p-3">
            {[1, 2, 3, 4].map((_) => (
              <SkeletonCardLoader />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 overflow-y-auto p-5">
            {data?.data?.map((closing) => (
              <div
                className="flex h-[160px] border border-[#ECECEC] rounded-lg hover:cursor-pointer hover:shadow-xl hover:scale-[1.015]"
                onClick={() => navigate(`/closing-overview/${closing.closingId}/details`)}
              >
                <img
                  className="w-[34%] rounded-l-lg flex-shrink-0 object-cover"
                  src={closing.avatar}
                />

                <div className="flex flex-col w-full justify-between p-2">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="text-[10px] text-[#8E8E8E] font-light">
                        {closing.propertyType?.toUpperCase()}
                      </div>
                      <div className="font-bold">{closing.propertyName}</div>
                      <div className="text-xs font-light">{closing.contactName}</div>
                    </div>
                    <div
                      className={`flex justify-center items-center w-[30%] h-fit text-[10px] rounded-[4px] py-1.5 px-2 ${
                        closing.closingTypeName === 'For Rent'
                          ? 'bg-[#F4FFE7] text-[#008E0E]'
                          : 'bg-[#FFF2F0] text-[#EA3323]'
                      }`}
                    >
                      {closing.closingTypeName}
                    </div>
                  </div>

                  <div className="flex items-center gap-1">
                    <div className="bg-[#ECECEC] rounded-full p-1.5">
                      <img className="w-4" src={locationIcon} />
                    </div>
                    <div>
                      <div className="text-[10px] font-medium">{closing.propertyAddress}</div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex items-center gap-1">
                      <div className="bg-[#ECECEC] rounded-full p-1.5">
                        <img className="w-4" src={homeIcon} />
                      </div>
                      <div>
                        <div className="text-[10px] font-medium">
                          {closing.grossSqm + ' ' + surfaceSymbol}
                        </div>
                      </div>
                    </div>
                    <div className="font-bold text-[#5A5A5A] whitespace-nowrap">
                      {currencySymbol + ' ' + closing.listingAmount}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <ClosingDialog
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
          apiFunctions={apiFunctions}
        />

        <DeleteDialog
          openDialog={openDialog}
          name="closing"
          onClose={() => setOpenDialog(null)}
          onDelete={handleDeleteClosing}
          isLoading={deleteLoading}
        />

        <EditClosingDialog
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
          data={closingDropdown}
          isLoading={dropdownLoading}
        />

        <ExportClosingsDialog
          openDialog={openDialog}
          onClose={() => setOpenDialog(null)}
          selectedClosings={selectedClosings}
          data={closingDropdown}
          isLoading={dropdownLoading}
        />
      </div>
    </div>
  );
};

export default Closings;
