import { isMobile } from 'utils/utils';
import { SkeletonTableLoading } from 'components';
import Skeleton from '@mui/material/Skeleton';

export const InvoiceOverviewLoading = () => {
  return (
    <div className="flex flex-col gap-5 p-3 bg-white rounded-b-2xl">
      <div className="flex flex-col sm:flex-row justify-between gap-4 border border-[#C9C9C9] rounded-[20px] p-[30px]">
        <div className="w-full sm:w-[55%]">
          <Skeleton variant="rectangular" width={100} height={60} sx={{ borderRadius: '16px' }} />
          <Skeleton variant="text" width={200} height={88} />
          <div className="flex gap-2">
            <Skeleton variant="text" width={100} height={44} />
            <Skeleton variant="text" width={100} height={44} />
          </div>
        </div>
        <Skeleton
          variant="rectangular"
          width={isMobile() ? '40%' : '60%'}
          height={`200px`}
          sx={{ borderRadius: '16px' }}
        />
      </div>

      <div>
        <div className="flex w-full justify-between">
          <Skeleton variant="text" width={120} height={44} />
          <Skeleton variant="text" width={80} height={44} />
        </div>
        <SkeletonTableLoading />
      </div>
    </div>
  );
};
