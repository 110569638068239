import React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { verify2FA } from 'apis/authentication/authentication';

import { CircularProgress } from '@mui/material';
import bannerImage from 'assets/testLoginbg.png';

const verify2FASchema = yup.object().shape({
  code: yup.string().typeError('Code field is required').required('Code field is required'),
});

const Otp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { redirectionMessage, sessionId } = state || {};
  const [invalidOTP, setInvalidOTP] = React.useState('');
  const { mutate, isLoading } = useMutation((payload) => verify2FA(payload));

  const setUserAuthData = (data) => {
    const {
      selectedUserGroupId,
      selectedUserGroupName,
      token,
      contact,
      SelectedSurfaceSymbol,
      selectedCurrencySymbol,
      isAdmin,
    } = data.data;
    localStorage.setItem('access-token', token.accessToken);
    localStorage.setItem('userGroupId', selectedUserGroupId);
    localStorage.setItem('userGroupName', selectedUserGroupName);
    localStorage.setItem('contactName', contact.fullName);
    localStorage.setItem('SelectedSurfaceSymbol', SelectedSurfaceSymbol);
    localStorage.setItem('selectedCurrencySymbol', selectedCurrencySymbol);
    localStorage.setItem('isAdmin', isAdmin);
  };

  return (
    <Formik
      initialValues={{
        sessionId: sessionId,
        userType: 'landlord',
        code: '',
      }}
      validationSchema={verify2FASchema}
      onSubmit={(values) => {
        mutate(
          {
            session_id: values.sessionId,
            user_type: 'landlord',
            code: values.code,
          },
          {
            onSuccess: (data) => {
              if (data.statusCode === 200) {
                setUserAuthData(data);
                navigate('/');
              } else if (data.statusCode === 201) {
                setInvalidOTP(data.message);
              }
            },
          },
        );
      }}
    >
      {({ values, errors, touched, handleChange }) => (
        <Form>
          <div className="flex h-screen">
            <div className="flex-none w-[500px] hidden lg:flex relative">
              <img src={bannerImage} className="absolute inset-0 w-full h-full object-cover" />
            </div>

            <div className="flex flex-col w-full bg-[url('assets/login-background.svg')] bg-cover bg-center p-4">
              <div className="flex items-center self-end mt-4 justify-center text-lg bg-black w-40 h-12 text-white rounded-tr-3xl rounded-bl-3xl">
                <Link to="/">Back to home</Link>
              </div>

              <div className="p-4 mt-12 bg-gray-50 w-full sm:w-[70%] rounded-lg">
                <span className="font-medium">
                  We just sent you an email with an authentication code. Enter the code to verify
                  your identity.
                </span>
              </div>

              {!!invalidOTP.length && (
                <div
                  className="p-4 text-sm text-red-700 w-full sm:w-1/2 bg-red-100 rounded-lg"
                  role="alert"
                >
                  <span className="font-medium">{invalidOTP}</span>
                </div>
              )}

              <div className="grid content-start w-full sm:w-6/12 h-full flex flex-col mt-12">
                <p className="font-semibold text-3xl">2FA Verification</p>
                <Field
                  name="code"
                  value={values.code}
                  placeholder="Enter OTP"
                  onChange={handleChange}
                  className={`pl-4 mt-4 focus:outline-none border border-solid ${
                    touched.code && errors.code ? 'border-[#EA3323]' : 'border-[#00000033]'
                  } rounded-lg w-full sm:w-80 h-14`}
                />
                <ErrorMessage
                  name="code"
                  render={(msg) => <div className="mt-2 text-[#EA3323]">{msg}</div>}
                />
                <button
                  type="submit"
                  className="w-32 h-12 mt-8 bg-[#FF0000] text-white font-medium align-middle text-center rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer"
                >
                  {isLoading ? <CircularProgress color="inherit" size={25} /> : 'CONFIRM'}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Otp;
