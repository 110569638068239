//  assets for app sidebar
import dashboardLogo from 'assets/sidebar-icons-new/dashboard-sidebar-new.svg';
import contactSectionLogo from 'assets/sidebar-icons-new/contact-sidebar-new.svg';
import propertiesSectionLogo from 'assets/sidebar-icons-new/properties-sidebar-new.svg';
import repairsSectionLogo from 'assets/sidebar-icons-new/repairs-sidebar-new.svg';
import listingsSectionLogo from 'assets/sidebar-icons-new/listing-sidebar-new.svg';
import leadSectionLogo from 'assets/sidebar-icons-new/lead.svg';
import reportsSectionLogo from 'assets/sidebar-icons-new/offers.svg';
import closingsSectionLogo from 'assets/sidebar-icons-new/closing-sidebar-new.svg';
import invoicesSectionLogo from 'assets/sidebar-icons-new/invoice-sidebar-new.svg';
import dashboardLogoActive from 'assets/sidebar-icons-new/dashboard-sidebar-new-active.svg';
import contactLogoActive from 'assets/sidebar-icons-new/contact-sidebar-new-active.svg';
import leadsLogoActive from 'assets/sidebar-icons-new/leads-sidebar-new-active.svg';
import propertiesLogoActive from 'assets/sidebar-icons-new/properties-sidebar-new-active.svg';
import repairsLogoActive from 'assets/sidebar-icons-new/repairs-sidebar-new-active.svg';
import reportsLogoActive from 'assets/sidebar-icons-new/offer-active-logo.svg';
import invoicesLogoActive from 'assets/sidebar-icons-new/invoices-sidebar-new-active.svg';
import listingsLogoActive from 'assets/sidebar-icons-new/listings-sidebar-new-active.svg';
import closingLogoActive from 'assets/sidebar-icons-new/closing-sidebar-new-active.svg';

//  assets for contact dialog
import contactTypeIcon from 'assets/userIconn.svg';
import generalInfoIcon from 'assets/generalInfo.svg';
import commentIcon from 'assets/contactInfo.svg';
import billingAddressIcon from 'assets/billingAddress.svg';

//  assets for property dialog
import sqmIcon from 'assets/sqmIcon.svg';
import financialIcon from 'assets/financialIcon.svg';

export const sidebarItems = [
  { name: 'dashboard', routePath: '/', icon: dashboardLogo, activeIcon: dashboardLogoActive },
  {
    name: 'contacts',
    routePath: '/contacts',
    icon: contactSectionLogo,
    activeIcon: contactLogoActive,
  },
  {
    name: 'properties',
    routePath: '/properties',
    icon: propertiesSectionLogo,
    activeIcon: propertiesLogoActive,
  },
  {
    name: 'repairs',
    routePath: '/repairs',
    icon: repairsSectionLogo,
    activeIcon: repairsLogoActive,
  },
  {
    name: 'listings',
    routePath: '/listings',
    icon: listingsSectionLogo,
    activeIcon: listingsLogoActive,
  },
  { name: 'Leads', routePath: '/leads', icon: leadSectionLogo, activeIcon: leadsLogoActive },
  {
    name: 'reports',
    routePath: '/reports',
    icon: reportsSectionLogo,
    activeIcon: reportsLogoActive,
  },
  {
    name: 'closings',
    routePath: '/closings',
    icon: closingsSectionLogo,
    activeIcon: closingLogoActive,
  },
  {
    name: 'transactions',
    routePath: '/transactions',
    icon: invoicesSectionLogo,
    activeIcon: invoicesLogoActive,
  },
];

export const settingsNavItems = [
  { name: 'Profile', route: 'profile' },
  { name: 'user_groups', route: 'user-group' },
  { name: 'bank_integration', route: 'bank-integration' },
  { name: 'accounting', route: 'acounting' },
];

export const contactOverviewNavItems = [
  { name: 'details', route: 'details' },
  { name: 'related', route: 'related' },
  { name: 'financials', route: 'financials' },
  { name: 'attachments', route: 'attachments' },
];

export const propertyOvervoewNavItems = [
  { name: 'details', route: 'details' },
  { name: 'related', route: 'related' },
  { name: 'communication', route: 'communication' },
  { name: 'gl_account', route: 'gl-account' },
  { name: 'attachments', route: 'attachments' },
  { name: 'public_images', route: 'public-images' },
];

export const repairOverviewNavItems = [
  { name: 'details', route: 'details' },
  { name: 'attachments', route: 'attachments' },
];

export const listingOverviewNavItems = [
  { name: 'details', route: 'details' },
  { name: 'offers', route: 'offers' },
  { name: 'related', route: 'related' },
  { name: 'attachments', route: 'attachments' },
  { name: 'public_images', route: 'public-images' },
];

export const closingOverviewNavItems = [
  { name: 'details', route: 'details' },
  { name: 'related', route: 'related' },
  { name: 'attachments', route: 'attachments' },
];

export const transactionOverviewNavitems = [
  { name: 'details', route: 'details' },
  { name: 'payments', route: 'payments' },
  { name: 'attachments', route: 'attachments' },
];

export const contactDialogSteps = [
  {
    stepName: 'contact_type',
    stepIdentifier: 'contactType',
    stepIndex: 0,
    icon: contactTypeIcon,
  },
  {
    stepName: 'general_info',
    stepIdentifier: 'generalInfo',
    stepIndex: 1,
    icon: generalInfoIcon,
  },
  {
    stepName: 'contact_address',
    stepIdentifier: 'contactAddress',
    stepIndex: 2,
    icon: billingAddressIcon,
  },
  {
    stepName: 'billing_address',
    stepIdentifier: 'billingAddress',
    stepIndex: 3,
    icon: billingAddressIcon,
  },
  { stepName: 'comments', stepIdentifier: 'comments', stepIndex: 4, icon: commentIcon },
];

export const userGroupSteps = [
  { stepName: 'Legal Info', stepIdentifier: 'legalInfo', stepIndex: 0, icon: contactTypeIcon },
  {
    stepName: 'Billing Address',
    stepIdentifier: 'billingAddress',
    stepIndex: 1,
    icon: generalInfoIcon,
  },
  {
    stepName: 'Preferences',
    stepIdentifier: 'preferences',
    stepIndex: 2,
    icon: billingAddressIcon,
  },
  {
    stepName: 'Subscriptions',
    stepIdentifier: 'subscriptions',
    stepIndex: 3,
    icon: billingAddressIcon,
  },
];

export const propertyDialogSteps = [
  {
    stepName: 'property_type',
    stepIdentifier: 'propertyType',
    stepIndex: 0,
    icon: generalInfoIcon,
  },
  {
    stepName: 'building_details',
    stepIdentifier: 'buildingDetails',
    stepIndex: 1,
    icon: generalInfoIcon,
  },
  { stepName: 'units', stepIdentifier: 'units', stepIndex: 2, icon: billingAddressIcon },
  { stepName: 'Comments', stepIdentifier: 'comments', stepIndex: 3, icon: commentIcon },
];

export const closingDialogSteps = [
  {
    stepName: 'Closing Details',
    stepIdentifier: 'closingDetails',
    stepIndex: 0,
    icon: contactTypeIcon,
  },
  {
    stepName: 'Payment Ranges',
    stepIdentifier: 'paymentRanges',
    stepIndex: 1,
    icon: billingAddressIcon,
  },
];

export const paymentDialogSteps = [
  { stepName: 'Invoice', stepIdentifier: 'invoice', stepIndex: 0, icon: generalInfoIcon },
  { stepName: 'Payment', stepIdentifier: 'payment', stepIndex: 1, icon: generalInfoIcon },
  { stepName: 'Transaction', stepIdentifier: 'transaction', stepIndex: 2, icon: generalInfoIcon },
  { stepName: 'Comment', stepIdentifier: 'comment', stepIndex: 3, icon: generalInfoIcon },
];

export const updateProfileDialogSteps = [
  {
    stepName: 'Profile Details',
    stepIdentifier: 'profileDetails',
    stepIndex: 0,
    icon: contactTypeIcon,
  },
  {
    stepName: 'Residency Address',
    stepIdentifier: 'residencyAddress',
    stepIndex: 1,
    icon: billingAddressIcon,
  },
  {
    stepName: 'Billing Address',
    stepIdentifier: 'billingAddress',
    stepIndex: 2,
    icon: billingAddressIcon,
  },
];

export const newTransactionDialogSteps = [
  {
    stepName: 'Transaction Details',
    stepIdentifier: 'transactionDetails',
    stepIndex: 0,
    icon: contactTypeIcon,
  },
  {
    stepName: 'Transaction Lines',
    stepIdentifier: 'transactionLines',
    stepIndex: 1,
    icon: billingAddressIcon,
  },
];
