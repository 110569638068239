import * as yup from 'yup';

export const ownershipSchema = yup.object().shape({
  retriveContact: yup.string().required('Retrive Contact field is required'),
  shares: yup
    .number('')
    .max(100, 'Shares must be less than 100')
    .required('Shares field is required')
    .typeError('you must specify a number'),
  ownershipType: yup.string().required('Ownership Type field is required'),
  role: yup.string().required('Role field is required'),
});
