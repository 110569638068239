export const userGroupInitialState = (data = null) => {
  const { billingAddress } = data?.preferences || {};

  return {
    userGroupName: data?.userGroupName || '',
    IBAN: data?.preferences.iban || '',
    vatApplicable: data?.preferences.vatId || '',
    vatNumber: data?.preferences.vatNumber || '',
    vatPercentage: data?.preferences.vatPercentage?.toString() || '',
    BICNumber: data?.preferences.bic || '',
    chamberOfCommerceNumber: data?.preferences.chamberCommerceNumber || '',
    registrationNumber: data?.preferences.registrationNumber || '',
    legalEntityName: data?.preferences.legalEntityName || '',
    plan: data?.plan || '',
    paymentFrequency: data?.paymentFrequency || '',
    units: data?.units || '',
    users: data?.users || '',

    placeId: billingAddress?.placeId || '',
    description: billingAddress?.description || '',
    building: billingAddress?.building || '',
    floor: billingAddress?.floor || '',
    zipCode: billingAddress?.zipCode || '',
    apartmentNumber: billingAddress?.apartmentNumber || '',

    plan: '',
    paymentFrequency: '',
    users: '',
    units: '',

    currency: data?.preferences.currencyId || '',
    surfaceUnit: data?.preferences.sqmUnitId || '',
    color: data?.invoiceColor || '',
    attachments: [],
  };
};
