import React from 'react';
import styled from 'styled-components';

import { isMobile } from 'utils/utils';
import { ConfirmDialog } from 'components';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const StyledDialog = (props) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
    props.onClose();
  };

  const onCloseButtonClick = () => {
    if (props.isEdited) return setIsConfirmDialogOpen(true);
    return props.onClose();
  };

  return (
    <CustomDialog
      fullScreen={props.fullScreen}
      open={props.open}
      width={!isMobile() && props.width}
      height={!isMobile() && props.height}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && props.isEdited) return setIsConfirmDialogOpen(true);
        if (reason === 'escapeKeyDown' && props.isEdited) return setIsConfirmDialogOpen(true);
        return props.onClose();
      }}
    >
      <div className="self-end rounded-lg p-2 bg-[#F6F4F4]">
        <CloseIcon onClick={onCloseButtonClick} sx={{ color: '#5A5A5A', cursor: 'pointer' }} />
      </div>

      {props.children}

      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        onCancel={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleConfirmDialogClose}
      />
    </CustomDialog>
  );
};

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 100%;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: 16px;
    padding: ${(props) => (props.padding ? props.padding : '8px 8px 20px')};
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
  }
`;
