import React from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useIsMutating, useMutation, useQuery } from 'react-query';
import {
  accountingIntegrationDropdown,
  storeAccountingIntegration,
  storeAccountingUserGroupIntegration,
} from 'apis/accounting/accounting';

import { CircularProgress, DialogActions, Snackbar, SnackbarContent } from '@mui/material';
import alertIcon from 'assets/redAlert.svg';
import alertCross from 'assets/alertCross.svg';
import { StyledDialog, StyledSelect } from 'components';
import removeIcon from 'assets/removeIcon.svg';
import styled from 'styled-components';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

const AccountingCallBack = ({ openDialog, onClose }) => {
  const navigate = useNavigate();
  const [APIResponse, setAPIResponse] = React.useState({ message: '', code: '' });
  const { data: dropdownData, isLoading: dropdownDataLoading } = useQuery(
    'accountingDropdown',
    accountingIntegrationDropdown,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [account, setAccount] = React.useState('');
  const [userGroup, setUserGroup] = React.useState('');

  const clientId = localStorage.getItem('accounting-clientId');
  const clientSecret = localStorage.getItem('accounting-clientSecret');
  const country = localStorage.getItem('accounting-country');
  const integrationId = localStorage.getItem('accounting-integrationId');

  const { mutate, isLoading } = useMutation((payload) => storeAccountingIntegration(payload));
  const { mutate: mutateUserGroup, isLoading: userGroupIntegrationLoading } = useMutation(
    (payload) => storeAccountingUserGroupIntegration(payload),
  );
  const [userGroupIntegrationArray, setUserGroupIntegrationArray] = React.useState([]);
  const [userGroupIntegrationSuccess, setUserGroupIntegrationSuccess] = React.useState({
    message: '',
    success: false,
  });
  const [data, setData] = React.useState(null);

  const columns = [
    { field: 'division_id', headerName: 'Account Name' },
    { field: 'user_group_id', headerName: 'User Group' },
    { field: 'remove', headerName: 'Actions' },
  ];

  const handleAddIntegration = (value) => {
    const newUserGroupIntegrationArray = [...userGroupIntegrationArray, value];
    setUserGroupIntegrationArray(newUserGroupIntegrationArray);
    setAccount('');
    setUserGroup('');
  };

  const handleRemoveUserGroupIntegration = (index) => {
    const newUserGroupIntegrationArray = userGroupIntegrationArray.filter((item, i) => i !== index);
    setUserGroupIntegrationArray(newUserGroupIntegrationArray);
  };

  React.useEffect(() => {
    let payload = {
      code: searchParams.get('code'),
      clientId: clientId,
      clientSecret: clientSecret,
      country: country,
      integrationId: integrationId,
    };
    mutate(payload, {
      onSuccess: (data) => {
        if (data?.statusCode === 200) {
          setData(data);
          localStorage.removeItem('accounting-clientId');
          localStorage.removeItem('accounting-clientSecret');
          localStorage.removeItem('accounting-integrationId');
          localStorage.removeItem('accounting-country');
        } else {
          setAPIResponse({ message: data?.data?.error_description, code: data?.data?.statusCode });
        }
      },
    });
  }, [searchParams.get('code')]);

  const handleStoreUserGroupIntegration = () => {
    let payload = {
      mapped_accounts: userGroupIntegrationArray,
      integration_detail_id: data.data?.integrationDetailId,
    };
    mutateUserGroup(payload, {
      onSuccess: (data) => {
        if (data?.statusCode === 200) {
          setUserGroupIntegrationSuccess({ message: data?.message, success: true });
          navigate('/settings/acounting');
        } else if (data?.statusCode === 403) {
          setAPIResponse({ message: data?.message, code: data?.statusCode });
        } else {
          setAPIResponse({ message: 'Server Error', code: data?.statusCode });
        }
      },
    });
  };

  return (
    <StyledDialog
      fullWidth
      width="1064px"
      height="725px"
      padding="30px"
      disableClose={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
          navigate('/settings/acounting');
        }
      }}
      open={openDialog?.dialogType === 'integrationCallback'}
    >
      <div className="flex flex-col gap-3 bg-white rounded-b-2xl">
        {APIResponse.message && (
          <div className="flex justify-between items-center bg-[#FFF2F0] text-[#EA3323] p-3 rounded-lg">
            <div className="flex gap-2">
              <img src={alertIcon} />
              <span>{APIResponse.message}</span>
            </div>
            <img
              src={alertCross}
              className="hover:cursor-pointer"
              onClick={() => setAPIResponse({ message: '', code: '' })}
            />
          </div>
        )}

        {userGroupIntegrationSuccess.success && (
          <div className="flex justify-between items-center bg-[#edf7f0] text-[#018e0c] p-3 rounded-lg">
            {userGroupIntegrationSuccess.message}
            <img
              src={alertCross}
              className="hover:cursor-pointer"
              onClick={() => setUserGroupIntegrationSuccess({ message: '', success: false })}
            />
          </div>
        )}

        <div className="flex w-full flex-col gap-2 h-fit justify-center items-center">
          <div className="flex w-[60%] h-full border border-[#dfdfe87a] rounded-2xl justify-between items-center p-3">
            <div className="flex gap-2 items-center font-semibold">
              <div className="flex w-[48px] h-[48px] bg-[#EA3323] rounded-full items-center justify-center">
                <img src={dialogTitleIcon} />
              </div>
              Accounting integration Data
            </div>
          </div>
        </div>

        {isLoading || dropdownDataLoading ? (
          <div className="flex justify-center items-center w-full">
            <CircularProgress sx={{ color: '#EA3323' }} />
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="flex flex-col w-[60%] gap-4">
              <div className="flex h-full justify-start items-end gap-4">
                <div className="flex flex-col w-[45%] items-center gap-4">
                  <div className="flex flex-col gap-2 w-full h-full">
                    <div className="text-sm text-black">Account</div>
                    <StyledSelect
                      name="account"
                      placeholder="Select Account"
                      value={account}
                      onChange={(e) => setAccount(e.target.value)}
                      itemsList={data?.data?.accounts}
                    />
                  </div>
                </div>

                {dropdownData && (
                  <div className="flex flex-col w-[45%] items-end gap-4">
                    <div className="flex flex-col gap-2 w-full h-full">
                      <div className="text-sm text-black">User Group</div>
                      <StyledSelect
                        name="userGroup"
                        placeholder="Select User Group"
                        value={userGroup}
                        onChange={(e) => setUserGroup(e.target.value)}
                        itemsList={dropdownData?.data?.userGroups}
                      />
                    </div>
                  </div>
                )}

                <button
                  key="add_btn"
                  className="w-[140px] h-[40px] bg-[#EA3323] text-[11px] rounded-lg text-white font-bold disabled:bg-[#FF857A]"
                  onClick={() =>
                    handleAddIntegration({
                      division_id: account,
                      user_group_id: userGroup,
                    })
                  }
                  disabled={!account || !userGroup}
                >
                  Add Integration
                </button>
              </div>

              <div className="flex flex-col gap-4 border border-[#C9C9C9] rounded-[20px] p-6 mt-4 mb-4">
                {userGroupIntegrationArray?.length ? (
                  <div className="flex flex-col gap-5">
                    <TableHeader>
                      {columns?.map((col) => (
                        <div key={col.headerName}>{col.headerName}</div>
                      ))}
                    </TableHeader>

                    <div className="flex max-h-[100px] overflow-y-auto flex-col gap-4">
                      {userGroupIntegrationArray?.map((row, index) => (
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center rounded-[20px] text-sm">
                            {columns?.map((col) =>
                              col.field === 'remove' ? (
                                <div className="flex flex-1 items-center gap-4 w-[20%]">
                                  <img
                                    className="hover:cursor-pointer"
                                    src={removeIcon}
                                    onClick={() => handleRemoveUserGroupIntegration(index)}
                                  />
                                </div>
                              ) : col.field === 'user_group_id' ? (
                                <TableData type={row[col.field]}>
                                  {dropdownData?.data?.userGroups.find(
                                    (item) => item.id === row[col.field],
                                  )?.name || ''}
                                </TableData>
                              ) : (
                                <TableData type={row[col.field]}>
                                  {data?.data?.accounts.find((item) => item.id === row[col.field])
                                    ?.name || ''}
                                </TableData>
                              ),
                            )}
                          </div>
                          {index !== userGroupIntegrationArray?.length - 1 && (
                            <div className="w-full h-[1px] bg-[#F6F4F4] mt-1"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center font-bold text-[#535353] text-sm">
                    {`You don't have any integrations right now, add your first one!`}
                  </div>
                )}
              </div>

              <DialogActions>
                <button
                  key="submit_btn"
                  className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold disabled:bg-[#FF857A]"
                  onClick={handleStoreUserGroupIntegration}
                  disabled={!userGroupIntegrationArray.length}
                >
                  {userGroupIntegrationLoading ? (
                    <div className="flex items-center justify-center">
                      <CircularProgress sx={{ color: 'white' }} />
                    </div>
                  ) : (
                    'Submit'
                  )}
                </button>
              </DialogActions>
            </div>
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  color: #535353;
  font-weight: 700;
  font-size: 12px;
  div {
    flex: 1;
  }
`;

const TableData = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${({ type }) => {
    switch (type) {
      case 'For Rent':
        return '#008E0E';
      case 'For Sale':
        return '#2B5DDC';
    }
  }};
`;

export default AccountingCallBack;
