export const mapToAddPropertyRequestBody = (payload, userGroupId) => {
  if(payload.masterPropertyId) {
    return {
      user_group_id: userGroupId,
      master_property_id: payload.masterPropertyId,
      units: payload.units,
    };
  }
  else {
    return {
      user_group_id: userGroupId,
      // * Property attributes
      property_name: payload.propertyName, // * title
      record_type_id: '2',
      type_id: payload.typeId,
      place_id: payload.placeId,
      acquisition_amount: payload.acquisitionPrice,
      description: payload.description,
      gross_sqm: payload.grossSQM,
      loan_level: payload.loanLevel,
      loan_amount: payload.buildingLoanAmount,
      units: payload.units,
      longitude: payload.longitude,
      latitude: payload.latitude,
      // * Comments
      property_description: payload.propertyDescription,
    };
  }
};

export const mapToEditPropertyRequestBody = (payload, userGroupId) => {
  if(payload.recordTypeId === '1') {
    return {
      user_group_id: userGroupId,
      property_id: payload.propertyId,
      // * Property attributes
      record_type_id: payload.recordTypeId,
      property_name: payload.title, // * title
      type_id: payload.typeId,
      acquisition_amount: payload.acquisitionAmount,
      gross_sqm: payload.grossSQM,
      number_of_bedrooms: payload.numberOfBedrooms,
      number_of_bathrooms: payload.numberOfBathrooms,
      loan_amount: payload.loanAmount,
    };
  }
  else {
    return {
      user_group_id: userGroupId,
      property_id: payload.propertyId,
      // * Property attributes
      property_name: payload.propertyName, // * title
      record_type_id: '2',
      type_id: payload.typeId,
      place_id: payload.placeId,
      acquisition_amount: payload.acquisitionPrice,
      description: payload.description,
      gross_sqm: payload.grossSQM,
      longitude: payload.longitude,
      latitude: payload.latitude,
      units: payload.units,
      // * Comments
      property_description: payload.propertyDescription,
    };
  }
};
