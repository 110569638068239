import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryList } from 'queries';
import { useCustomMutation } from 'mutations';
import { leadsTableColumns } from 'utils/columns';
import {isMobile, TabName} from 'utils/utils';

import {
  AddButton,
  CardButton,
  DeleteButton,
  EditButton,
  EmptyList,
  ExportButton,
  ListButton,
  StyledDataGrid,
  SkeletonCardLoader,
  Notifier,
} from 'components';
import { LeadDialog } from './leadDialog';
import { ExportDialog, DeleteDialog } from 'components/dialogs';
import { LeadCard } from './leadCard';

import { CircularProgress, Checkbox } from '@mui/material';
import searchIcon from 'assets/search.svg';

const Leads = ({ apiFunctions }) => {
  TabName('Leads');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [globalError, setGlobalError] = React.useState(null)
  const [activeTab, setActiveTab] = React.useState(isMobile() ? 'card' : 'table');
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedLeads, setSelectedLeads] = React.useState([]);
  const [allLeadsSelected, setAllLeadsSelected] = React.useState(false);
  const [leadsList, setLeadsList] = React.useState([]);

  const { data, isLoading } = useQueryList(apiFunctions.getAll.func, apiFunctions.getAll.key);

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedLeads(data.data.map((item) => item.leadId));
      setAllLeadsSelected(true);
    } else {
      setSelectedLeads([]);
      setAllLeadsSelected(false);
    }
  };

  const handleLeadDelete = async () => {
    const response = await deleteMutation(selectedLeads);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleFilterChange = (e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue);

    if (currentSearchValue === '' || !currentSearchValue.trim()) {
      setLeadsList(data?.data);
    } else {
      const filteredRows = data?.data.filter(
        (row) =>
          row.contactName.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.contactType.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.listingType.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.propertyUsage.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.country.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          row.city.toLowerCase().includes(currentSearchValue.toLowerCase()),
      );
      setLeadsList(filteredRows);
    }
  };

  React.useEffect(() => {
    if (data?.data) {
      setLeadsList(data?.data);
    }
  }, [data?.data]);

  React.useEffect(() => {
    let globalErrorMsg = localStorage.getItem('error-message');
    if(globalErrorMsg){
      setGlobalError({statusCode: 500, message: globalErrorMsg});
      localStorage.removeItem('error-message');
    }
  }, []);

  return (
    <div className="bg-white rounded-2xl mt-11 pt-2">
      <Notifier data={mutationResponse} />
        <Notifier data={globalError} />

      <div className="flex justify-between">
        <div className="hidden lg:flex items-center pl-5">
          <div className="flex h-[40px] items-center justify-center border border-[#C9C9C9] rounded-lg">
            <Checkbox
              icon={
                <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
              }
              checkedIcon={
                <div className="w-[18px] h-[18px] bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border-2 border-[#2B5DDC] rounded"></div>
              }
              checked={allLeadsSelected}
              onChange={handleSelectAll}
            />
            <label className="text-sm font-medium text-[#5A5A5A] pr-2">{t('select_all')}</label>
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row justify-between gap-4 p-3.5">
          <div className="flex rounded-lg bg-[#F6F4F4]">
            <div className="flex items-center">
              <img alt="" className="pl-2" src={searchIcon} />
            </div>

            <input
              className="w-80 rounded-lg p-2 focus:outline-none bg-[#F6F4F4]"
              placeholder={t('search_by_lead')}
              value={searchValue}
              onChange={handleFilterChange}
            />
          </div>

          <div className="flex gap-4">
            <AddButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'addNewLead' })}
              disabled={isLoading}
            />

            <EditButton
              onClick={() => setOpenDialog({ data: selectedLeads, dialogType: 'editLead' })}
              disabled={selectedLeads.length > 1 || !selectedLeads.length}
            />

            <DeleteButton
              onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
              disabled={!selectedLeads.length}
            />

            <ExportButton onClick={() => setOpenDialog({ data: null, dialogType: 'export' })} />
          </div>

          <div className="hidden lg:flex rounded-lg p-1 gap-1 justify-center items-center bg-[#F6F4F4]">
            <CardButton isActivated={activeTab === 'card'} onClick={() => setActiveTab('card')} />
            <ListButton isActivated={activeTab === 'table'} onClick={() => setActiveTab('table')} />
          </div>
        </div>
      </div>

      {!isLoading && !data?.data?.length ? (
        <EmptyList listName="leads" />
      ) : activeTab === 'table' ? (
        <StyledDataGrid
          checkboxSelection
          rows={leadsList || []}
          columns={leadsTableColumns(t)}
          getRowId={(row) => row.leadId}
          onSelectionModelChange={(newSelectedLead) => setSelectedLeads(newSelectedLead)}
          selectionModel={selectedLeads}
          rowsPerPageOptions={[]}
          loading={isLoading}
          pageSize={25}
          rowHeight={70}
          onRowClick={(params) => navigate(`/lead-overview/${params.row.leadId}/details`)}
          components={{
            LoadingOverlay: () => (
              <div className="mt-8 flex justify-center">
                <CircularProgress color="error" />
              </div>
            ),
          }}
        />
      ) : isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-3">
          {[1, 2, 3, 4].map((_) => (
            <SkeletonCardLoader />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 p-5">
          {data?.data.map((lead) => (
            <LeadCard lead={lead} />
          ))}
        </div>
      )}

      <LeadDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <DeleteDialog
        openDialog={openDialog}
        name="lead"
        onClose={() => setOpenDialog(null)}
        onDelete={handleLeadDelete}
        isLoading={deleteLoading}
      />

      <ExportDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        selectedElements={selectedLeads}
        apiFunctions={apiFunctions}
      />
    </div>
  );
};

export default Leads;
