import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';

import { useCustomMutation } from 'mutations';
import { useCustomQuery } from 'queries';
import { repairsScheme } from 'validationSchemas';
import { isMobile } from 'utils/utils';

import { StyledSelect, StyledInput, StyledDialog, DatePicker, Notifier } from 'components';
import { DialogActions, DialogContent, CircularProgress } from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

export const RepairDialog = ({ openDialog, onClose, propertyId, apiFunctions }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addRepair') return true;
    else if (openDialog?.dialogType === 'editRepair') return true;
    return false;
  };

  const { data, isLoading } = useCustomQuery(
    apiFunctions.getDropdowns.func,
    apiFunctions.getDropdowns.key,
    null,
    { enabled: isDialogOpen() },
  );

  const { data: repairDetails, isLoading: repairDetailsLoading } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    null,
    { enabled: isDialogOpen() === 'editRepair' },
  );

  const {
    mutateAsync: addRepairMutation,
    data: addMutationReponse,
    isLoading: addLoading,
  } = useCustomMutation(apiFunctions.add.func, apiFunctions.add.key);

  const {
    mutateAsync: updateRepairMutation,
    data: editMutationResponse,
    isLoading: editLoading,
  } = useCustomMutation(apiFunctions.edit.func, apiFunctions.edit.key);

  const { summary, generalInfo, repairId } = openDialog?.data || {};

  return (
    <>
      <Notifier data={addMutationReponse} />
      <Notifier data={editMutationResponse} />

      <Formik
        enableReinitialize
        initialValues={{
          property: summary?.propertyId || '',
          category: summary?.categoryId || '',
          subCategory: summary?.subCategoryId || '',
          title: generalInfo?.title || '',
          requestedBy: summary?.requestedById || null,
          status: generalInfo?.statusId || null,
          requestedDate: generalInfo?.requestedDate || '',
        }}
        validationSchema={repairsScheme}
        onSubmit={async (values, { resetForm }) => {
          if (openDialog?.dialogType === 'addRepair') {
            const response = await addRepairMutation(values);
            if (response.statusCode === 200) {
              navigate(`/repair-overview/${response.data.repairId}/details`);
              resetForm();
              onClose();
            }
          } else {
            let payload = {
              repairId: repairId,
              propertyId: summary.propertyId,
              ...values,
            };

            const response = await updateRepairMutation(payload);
            if (response.statusCode === 200) onClose();
          }
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue, dirty, resetForm }) => (
          <StyledDialog
            fullWidth
            width="1064px"
            open={isDialogOpen()}
            fullScreen={isMobile()}
            isEdited={dirty}
            onClose={() => {
              resetForm();
              onClose();
            }}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex items-center w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
                    <img alt="" src={dialogTitleIcon} />
                  </div>
                  <span className="font-semibold">
                    {openDialog?.dialogType === 'addRepair'
                      ? t('add_new_repair')
                      : t('edit_repair')}
                  </span>
                </div>
              </div>
            </div>

            {isLoading || repairDetailsLoading ? (
              <div className="flex h-full justify-center items-center">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="flex h-full justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] md:w-[60%] items-center gap-4">
                      <div>
                        <div className="text-sm pb-1">
                          Property {errors['property'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledSelect
                          name="property"
                          placeholder="Property"
                          value={values.property}
                          onChange={handleChange}
                          error={touched.property && errors.property}
                          itemsList={data?.data.properties}
                          disabled={!!propertyId || summary?.editPropertyId}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="property" />
                      </div>

                      <div>
                        <div className="text-sm pb-1">
                          Category {errors['category'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledSelect
                          name="category"
                          placeholder="Category"
                          value={values.category}
                          onChange={handleChange}
                          itemsList={data?.data.categories}
                          error={touched.category && errors.category}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="category" />
                      </div>

                      <div>
                        <div className="text-sm pb-1">
                          Subcategory{' '}
                          {errors['subCategory'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledSelect
                          name="subCategory"
                          placeholder="Subcategory"
                          value={values.subCategory}
                          onChange={handleChange}
                          itemsList={data?.data.subCategories}
                          error={touched.subCategory && errors.subCategory}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="subCategory" />
                      </div>

                      <div>
                        <div className="text-sm pb-1">
                          Title {errors['title'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledInput
                          name="title"
                          placeholder="Title"
                          value={values.title}
                          onChange={handleChange}
                          error={touched.title && errors.title}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="title" />
                      </div>

                      <div>
                        <div className="text-sm pb-1">
                          Requested By{' '}
                          {errors['title'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledSelect
                          name="requestedBy"
                          placeholder="Requested By"
                          value={values.requestedBy}
                          onChange={handleChange}
                          itemsList={data?.data.contacts}
                          error={touched.requestedBy && errors.requestedBy}
                          disabled={summary?.requestedById}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="requestedBy" />
                      </div>

                      <div>
                        <div className="text-sm pb-1">
                          Status {errors['status'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledSelect
                          name="status"
                          placeholder="Status"
                          value={values.status}
                          onChange={handleChange}
                          itemsList={data?.data.status}
                          error={touched.status && errors.status}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="status" />
                      </div>

                      <div>
                        <div className="text-sm pb-1">
                          Requested Date{' '}
                          {errors['requestedDate'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <DatePicker
                          name="requestedDate"
                          value={values.requestedDate}
                          onChange={(value) => setFieldValue('requestedDate', value)}
                          error={touched.requestedDate && errors.requestedDate}
                          disabled={generalInfo?.requestedDate}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="requestedDate" />
                      </div>
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <div className="flex justify-center gap-4 w-full pt-4">
                    <div className="flex justify-end w-[60%]">
                      <button
                        className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                        type="submit"
                      >
                        {addLoading || editLoading ? (
                          <div className="flex justify-center items-center">
                            <CircularProgress color="inherit" size={25} />
                          </div>
                        ) : openDialog?.dialogType === 'addRepair' ? (
                          t('add')
                        ) : (
                          t('edit')
                        )}
                      </button>
                    </div>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
