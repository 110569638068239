import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const TableRowCount = ({ name, numOfRows }) => {
  const { t } = useTranslation();

  return (
    <BadgeContainer>
      <span className="text-xl">{t(name)}</span>
      <Badge>{numOfRows}</Badge>
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
  background-color: #ea3323;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
`;
