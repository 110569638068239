import { Chip, Box } from '@mui/material';

export const TagsRenderer = ({ tags }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        width: '100%',
      }}
    >
      {tags.map((value) => (
        <Chip
          sx={{
            fontSize: '10px',
            borderRadius: '8px',
          }}
          key={value}
          label={value}
        />
      ))}
    </Box>
  );
};
