import { Skeleton, Stack } from '@mui/material';

export const SkeletonCardLoader = () => {
  return (
    <div className="p-[10px] border border-[1px] border-[#C9C9C9] rounded-2xl">
      <Stack spacing={1}>
        <div className="flex gap-2">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
        </div>
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60} />
      </Stack>
    </div>
  );
};
