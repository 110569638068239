import React from 'react';
import styled from 'styled-components';
import { useIsMutating } from 'react-query';
import { Formik, Form, ErrorMessage } from 'formik';

import { ReminderSchema } from 'validationSchemas';
import { DatePicker, StyledDialog, StyledInput } from 'components';
import { CircularProgress, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export const ReminderDialog = ({ openDialog, onClose, storeReminder }) => {
  const isMutating = useIsMutating();
  const { dialogType } = openDialog || {};
  const { fileName } = openDialog?.data?.attachment || {};
  const { description, title, dueDate } = openDialog?.data?.file?.reminder || {};

  const isDialogOpen = () => {
    if (dialogType === 'storeReminder') return true;
    else if (dialogType === 'viewReminder') return true;
    return false;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: title || '',
        description: description || '',
        dueDate: dueDate || null,
      }}
      validationSchema={ReminderSchema}
      onSubmit={(values, { resetForm }) => {
        const payload = {
          title: values.title,
          description: values.description,
          dueDate: values.dueDate,
        };
        storeReminder(payload);
        resetForm();
      }}
    >
      {({ handleChange, values, errors, touched, setFieldValue }) => (
        <StyledDialog fullWidth width="550px" paddig="8px" open={isDialogOpen()} onClose={onClose}>
          <DialogTitle>
            <div className="text-2xl font-bold">
              {dialogType === 'viewReminder' ? 'Reminder' : 'Add Reminder'}
            </div>
          </DialogTitle>

          <Form>
            <DialogContent>
              <div className="flex flex-col justify-between gap-6">
                <div className="flex gap-2">
                  <div className="flex flex-col gap-2 w-1/2">
                    <div className="flex flex-col gap-1">
                      <div className="text-sm text-black">File Name</div>
                      <StyledInput
                        name="fileName"
                        placeholder="File Name"
                        value={fileName}
                        onChange={handleChange}
                        error={touched.fileName && errors.fileName}
                        disabled
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="text-sm text-black">Title</div>
                      <StyledInput
                        name="title"
                        placeholder="Title"
                        value={values.title}
                        onChange={handleChange}
                        error={touched.title && errors.title}
                        disabled={dialogType === 'viewReminder'}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="title" />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 w-1/2">
                    <div className="flex flex-col gap-1">
                      <div className="text-sm text-black">Description</div>
                      <StyledInput
                        name="description"
                        placeholder="Description"
                        value={values.description}
                        onChange={handleChange}
                        error={touched.description && errors.description}
                        disabled={dialogType === 'viewReminder'}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="description" />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="text-sm text-black">Due Date</div>
                      <DatePicker
                        name="dueDate"
                        value={values.dueDate}
                        onChange={(value) => setFieldValue('dueDate', value)}
                        error={touched.dueDate && errors.dueDate}
                        disabled={dialogType === 'viewReminder'}
                      />
                      <ErrorMessage component={CustomErrorMessage} name="dueDate" />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <div className="grid justify-items-center m-auto w-[90%] sm:w-[60%]">
                <button
                  className="w-[146px] h-[52px] bg-[#EA3323] rounded-lg text-white font-semibold"
                  type="submit"
                >
                  {!!isMutating ? (
                    <div className="flex items-center justify-center">
                      <CircularProgress color="inherit" size={25} />
                    </div>
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </DialogActions>
          </Form>
        </StyledDialog>
      )}
    </Formik>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;
