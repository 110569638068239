import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';
import moment from 'moment/moment';

export const storeReminders = (payload) => {
  return axios.post(`api/v1/todos-app/reminders/store`, {
    title: payload.title,
    description: payload.description,
    todo_type: payload.todoType,
    app: payload.app,
    parent_id: payload.parentId,
    due_date: moment(payload.dueDate).format('YYYY-MM-DD'),
    related_id: payload.relatedId,
    user_group_id: getUserGroupId(),
  });
};
