import React, { useState } from 'react';
import styled from 'styled-components';
import { Calendar as RequestCalendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const Calendar = () => {
  const [value, onChange] = useState(new Date());
  return (
    <div>
      <CustomCalendar onChange={onChange} value={value} />
    </div>
  );
};

const CustomCalendar = styled(RequestCalendar)`
  &.react-calendar {
    border: none;
    width: 100%;
    & .react-calendar__tile {
      &:hover {
        border-radius: 18px;
      }
    }
    & .react-calendar__tile--now {
      border-radius: 18px;
      background: #f1f1f1;
    }
    & .react-calendar__month-view__weekdays__weekday {
      abbr {
        text-decoration: none;
        color: rgba(60, 60, 67, 0.3);
      }
    }
    & .react-calendar__tile--active {
      border-radius: 18px;
      background: #ff0000;
    }
  }
`;
