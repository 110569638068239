import React from 'react';
import { truncate } from 'utils/utils';
import { TagsRenderer } from 'components';
import { Select, MenuItem, CircularProgress } from '@mui/material';

export const StyledSelect = (props) => {
  return (
    <Select
      sx={{
        borderRadius: props.borderRadius ? props.borderRadius : '16px',
        boxShadow: props.error && '0px 0px 0px 3px rgb(234 51 35 / 20%)',
        fontSize: 14,
        width: props.width ? props.width : '100%',
        height: props.height ? props.height : '56px',
      }}
      variant="outlined"
      multiple={props.multiple}
      disabled={props.disabled}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
      error={props.error}
      onChange={props.onChange}
      disableUnderline
      displayEmpty
      renderValue={(value) => {
        const isValuePresent = props.itemsList?.find((item) => String(item.id) === value);

        if (props.multiple) {
          let selectedTagsValues = [];
          for (let i = 0; i < props.itemsList.length; i++)
            if (value.includes(props.itemsList[i].id))
              selectedTagsValues.push(props.itemsList[i].name);

          return !value.length ? props.placeholder : <TagsRenderer tags={selectedTagsValues} />;
        }

        if (isValuePresent) return truncate(isValuePresent.name);
        if (!value) return props.placeholder;

        return props.placeholder.concat(': Select a valid option');
      }}
    >
      {props?.isLoading && (
        <div className="flex items-center justify-center">
          <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
        </div>
      )}

      {props?.itemsList?.map((list) => (
        <MenuItem disabled={list.disabled} key={list.id} value={list.id}>
          {list.name}
        </MenuItem>
      ))}
    </Select>
  );
};
