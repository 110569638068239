import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { logout } from 'apis/authentication/authentication';
import { getNotificationsCount } from 'apis/notifications/notifications';
import { getSectionName } from 'utils/utils';

import { UserGroupSelector, GlobalSearch } from 'components';
import { NotificationDialog } from './notificationDialog';

import { Skeleton, Tooltip, Menu, MenuItem } from '@mui/material';

import notification from 'assets/notification.svg';
import userIcon from 'assets/usericon.svg';
import question from 'assets/questionMark.svg';
import sidebarIcon from 'assets/sidebar-menu.svg';

export const MainHeader = ({ onMenuToggle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const contactName = localStorage.getItem('contactName');

  const [userActions, setUserActions] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data: notificationCount, isLoading: notificationLoading } = useQuery(
    'notificationsCount',
    getNotificationsCount,
  );
  const { mutate: logoutMutation } = useMutation(() => logout());

  const handleNavigationToSettings = () => {
    setUserActions(null);
    setTimeout(() => {
      navigate('/settings/profile');
    }, 300); // navigate is asynchronous (wait until menu has)
  };

  const handleLogout = () => {
    logoutMutation(null, {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          localStorage.clear();
          setUserActions(null);
          navigate('/login');
        }
      },
    });
  };

  return (
    <div className="flex flex-col gap-2 mt-2 relative">
      <div className="flex justify-between items-center gap-4 lg:gap-0">
        {location.pathname === '/' ? (
          <div className="text-3xl font-bold text-[#8E8E8E] truncate">
            <span>{t('hello')}, </span>
            <span className="text-[#111] font-bold">{contactName}</span>
          </div>
        ) : (
          <span className="text-[#111] text-4xl font-bold ml-1">
            {t(getSectionName(location.pathname).toLocaleLowerCase())}
          </span>
        )}

        <img onClick={onMenuToggle} src={sidebarIcon} className="block lg:hidden" />

        <div className="hidden lg:flex flex-col sm:flex-row gap-4 mt-2 sm:mt-0">
          <Tooltip title="Change User Group">
            <UserGroupSelector />
          </Tooltip>

          <GlobalSearch />

          <div className="flex gap-4">
            <Tooltip title="Notifications">
              <div
                className="flex relative items-center justify-center cursor-pointer bg-white rounded-lg w-10 p-2 z-0"
                onClick={() => setOpenDialog({ data: null, dialogType: 'notification' })}
              >
                {notificationLoading ? (
                  <Skeleton
                    className="z-10 absolute top-[-10%] right-[-20%]"
                    variant="circular"
                    width={20}
                    height={20}
                  />
                ) : (
                  !!notificationCount.data.count && (
                    <div className="z-10 flex justify-center items-center text-xs font-bold text-white w-6 h-6 absolute top-[-10%] right-[-20%] rounded-full bg-[#EA3323]">
                      {notificationCount.data.count > 50 ? '50+' : notificationCount.data.count}
                    </div>
                  )
                )}
                <img src={notification} className="w-10" />
              </div>
            </Tooltip>

            <Tooltip title="support">
              <img className="cursor-pointer bg-white rounded-lg w-10 p-2" src={question} />
            </Tooltip>

            <Tooltip title="settings">
              <img
                className="cursor-pointer bg-white rounded-lg w-10 p-2"
                src={userIcon}
                onClick={(e) => setUserActions(e.currentTarget)}
              />
            </Tooltip>

            <Menu
              anchorEl={userActions}
              open={Boolean(userActions)}
              onClose={() => setUserActions(null)}
            >
              <MenuItem onClick={handleNavigationToSettings}>{t('settings')}</MenuItem>
              <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
            </Menu>
          </div>
        </div>

        <NotificationDialog openDialog={openDialog} onClose={() => setOpenDialog(null)} />
      </div>
    </div>
  );
};
