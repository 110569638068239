import axios from '../axiosInstance';
import moment from 'moment';
import { getUserGroupId } from 'utils/utils';

/*
 * |--------------------------------------------------------------------------
 * | Invoices API
 * | getInvoices - getInvoice - addInvoice - updateInvoice - deleteInvoice
 * |--------------------------------------------------------------------------
 */

export const getInvoices = () => {
  return axios.get(`/api/v2/accounting-app-new/accounting/transactions/${getUserGroupId()}`);
};

export const getTransaction = (transactionId) =>
  axios.get(
    `/api/v2/accounting-app-new/accounting/transactions/show/${transactionId}/${getUserGroupId()}`,
  );

export const addTransaction = (payload) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/transactions/store`, {
    gl_account_id: payload.glAccountId,
    description: payload.description,
    transaction_date: moment(payload.transactionDate).format('YYYY-MM-DD'),
    due_date: moment(payload.dueDate).format('YYYY-MM-DD'),
    contact_id: payload.contactId,
    invoice_id: payload.invoiceId,
    transaction_status_id: payload.transactionStatusId,
    accounting_reference: payload.accountingReference,
    apply_vat: payload.applyVat,
    transaction_lines: payload.transactionLines, //array of Transaction Lines
    user_group_id: getUserGroupId(),
  });
};

export const updateTransaction = (payload) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/transactions/update`, {
    transaction_id: payload.transactionId,
    transaction_date: moment(payload.transactionDate).format('YYYY-MM-DD'),
    description: payload.description,
    due_date: moment(payload.dueDate).format('YYYY-MM-DD'),
    user_group_id: getUserGroupId(),
  });
};

export const deleteTransaction = (transactionId) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/transactions/destroy`, {
    transaction_id: transactionId,
    user_group_id: getUserGroupId(),
  });
};

// * Show Transaction Invoice
export const transactionInvoiceShow = (transactionId) =>
  axios.get(
    `/api/v2/accounting-app-new/accounting/transactions/invoices/show/${transactionId}/${getUserGroupId()}`,
  );

// * Invoice Related API
export const getTransactionPayments = (transactionId) =>
  axios.get(
    `/api/v2/accounting-app-new/accounting/transactions/payments/${transactionId}/${getUserGroupId()}`,
  );

/*
 * |----------------------------------------
 * | Invoices API
 * | addRequest - editRequest - deleteRequest
 * |----------------------------------------
 */

export const showTransactionInvoice = (transactionId) =>
  axios.get(
    `/api/v2/accounting-app-new/accounting/transactions/invoices/show/${transactionId}/${getUserGroupId()}`,
  );

export const addTransactionLine = (payload) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/transactions/transaction-lines/store`, {
    transaction_id: payload.transactionId,
    description: payload.transactionLinesDescription,
    amount: payload.amountDc,
    transaction_line_type_id: payload.transactionLineTypeId,
    user_group_id: getUserGroupId(),
  });
};

export const editTransactionLine = (payload) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/transactions/transaction-lines/update`, {
    transaction_line_id: payload.transactionLineId,
    transaction_id: payload.transactionId,
    description: payload.transactionLinesDescription,
    amount: payload.amountDc,
    transaction_line_type_id: payload.transactionLineTypeId,
    user_group_id: getUserGroupId(),
  });
};

// * Invoice Dropdown API
export const getTransactionDropdownData = () =>
  axios.get(`/api/v2/accounting-app-new/accounting/transactions/dropdown/${getUserGroupId()}`);

/*
 * |------------------------------------------------------------------------------------------------
 * | Invoice Attahcment API
 * | getClosingAttachment - addClosingAttachment - updateClosingAttachment - deleteClosingAttachment
 * |------------------------------------------------------------------------------------------------
 */

export const getAttachments = (parentId, type, page) => {
  return axios.get(
    `api/v2/accounting-app-new/accounting/attachments/${parentId}/${getUserGroupId()}?page=${page}&type=${type}`,
  );
};

export const addAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachment).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });
  formData.append('parent_id', payload.parentId);
  formData.append('share_with_tenant', payload.shareWithTenant);
  formData.append('type', payload.type);
  formData.append('user_group_id', getUserGroupId());

  return axios.post(`api/v2/accounting-app-new/accounting/attachments/store`, formData);
};

export const updateAttachment = (payload) => {
  return axios.post(`api/v2/accounting-app-new/accounting/attachments/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteAttachment = (payload) => {
  return axios.post(`api/v2/accounting-app-new/accounting/attachments/destroy`, {
    accounting_attachment_ids: [payload.attachmentId],
    type: payload.type,
    user_group_id: getUserGroupId(),
  });
};

// * export transactions
export const exportInvoices = (payload) => {
  return axios.post(`api/v2/invoice-app/invoices/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    invoice_ids: payload.invoiceIds,
  });
};

// * approve transactions
export const approveInvoices = (invoiceId) => {
  return axios.post(`api/v2/invoice-app/invoice/approve`, {
    user_group_id: getUserGroupId(),
    invoice_id: invoiceId,
  });
};

// * disapprove transactions
export const disApproveInvoices = (invoiceId) => {
  return axios.post(`api/v2/invoice-app/invoice/un-approve`, {
    user_group_id: getUserGroupId(),
    invoice_id: invoiceId,
  });
};

// * invoice generate pdf
export const generateInvoicePdf = (transactionId) => {
  return axios.post(`api/v2/accounting-app-new/accounting/transactions/invoices/generate`, {
    user_group_id: getUserGroupId(),
    transaction_id: transactionId,
  });
};

export const deleteTransactionLine = (transactionLineId) => {
  return axios.post(`api/v2/accounting-app-new/accounting/transactions/transaction-lines/destroy`, {
    transaction_line_id: transactionLineId,
    user_group_id: getUserGroupId(),
  });
};

export const sendInvoice = (invoiceId) => {
  return axios.post(`/api/v2/invoice-app/invoices/pdf/notify`, {
    invoice_id: invoiceId,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |----------------------------------------------------------
 * | Payments API
 * | addPayment - updatePayment - deletePayment - getDropdowns
 * |----------------------------------------------------------
 */

export const addPayment = (payload) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/payments/store`, {
    transaction_id: payload.transactionId,
    payment_date: moment(payload.paymentDate).format('YYYY-MM-DD'),
    contact_id: payload.contactId,
    amount: payload.amountFc,
    reference_id: payload.referenceId,
    payment_method_id: payload.paymentMethodId,
    user_group_id: getUserGroupId(),
  });
};

export const updatePayment = (payload) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/payments/update`, {
    payment_id: payload.paymentId,
    transaction_id: payload.transactionId,
    payment_date: moment(payload.paymentDate).format('YYYY-MM-DD'),
    contact_id: payload.contactId,
    amount: payload.amountFc,
    payment_method_id: payload.paymentMethodId,
    user_group_id: getUserGroupId(),
  });
};

export const deletePayment = (paymentId) => {
  return axios.post(`/api/v2/accounting-app-new/accounting/payments/destroy`, {
    payment_id: {
      paymentId,
    },
    user_group_id: getUserGroupId(),
  });
};
export const getPaymentDropdownData = () =>
  axios.get(`/api/v2/accounting-app-new/accounting/payments/dropdown/${getUserGroupId()}`);
