import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { LocusContext } from 'hooks/useLocusContext';

import { StyledAutoComplete, StyledInput, StyledSelect } from 'components';

export const IndividualGeneralInfo = () => {
  const { t } = useTranslation();

  const { dropdownValues, apiFunctions, dialogType } = React.useContext(LocusContext);
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  const { data: crmAccounts } = useCustomQuery(
    apiFunctions.getCrmAccounts.func,
    apiFunctions.getCrmAccounts.key,
    {
      enabled: dropdownValues?.activeAccountingIntegration,
    },
  );

  const isError = (value) => {
    if (errors[value]) return <span className="text-[#EA3323]">*</span>;
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="text-3xl font-bold">{t('general_info')}</div>

      <div className="flex flex-col sm:flex-row gap-5 p-1">
        <div className="flex flex-col gap-4 w-full">
          <div>
            <div className="text-sm pb-1">
              {t('salutation')} {isError('salutation')}
            </div>

            <StyledSelect
              name="salutation"
              placeholder="salutation"
              value={values.salutation}
              onChange={handleChange}
              itemsList={dropdownValues.salutations}
              error={touched.salutation && errors.salutation}
            />
            <ErrorMessage name="salutation" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('first_name')} {isError('firstName')}
            </div>

            <StyledInput
              name="firstName"
              placeholder={t('first_name')}
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && errors.firstName}
            />
            <ErrorMessage name="firstName" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('email')} {isError('email')}
            </div>

            <StyledInput
              name="email"
              placeholder={t('email')}
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
              disabled={dialogType === 'editContact'}
            />
            <ErrorMessage name="email" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('invoice_email')} {isError('invoiceEmail')}
            </div>

            <StyledInput
              name="invoiceEmail"
              placeholder={t('invoice_email')}
              value={values.invoiceEmail}
              onChange={handleChange}
              error={touched.invoiceEmail && errors.invoiceEmail}
            />
            <ErrorMessage name="invoiceEmail" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">{t('iban')}</div>

            <StyledInput
              name="iban"
              value={values.iban}
              onChange={handleChange}
              placeholder="IBan"
            />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('source')} {isError('source')}
            </div>

            <StyledSelect
              name="source"
              placeholder="source"
              value={values.source}
              onChange={handleChange}
              itemsList={dropdownValues.sources}
              error={touched.source && errors.source}
            />

            <ErrorMessage name="source" component={ValidationError} />
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full">
          <div>
            <div className="text-sm pb-1">
              {t('last_name')} {isError('lastName')}
            </div>

            <StyledInput
              name="lastName"
              placeholder="Last name"
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && errors.lastName}
            />

            <ErrorMessage name="lastName" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('phone')} {isError('phone')}
            </div>

            <StyledInput
              name="phone"
              placeholder="(+90)"
              value={values.phone}
              onChange={handleChange}
              error={touched.phone && errors.phone}
            />

            <ErrorMessage name="phone" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">Type {isError('type')}</div>

            <StyledSelect
              name="type"
              value={values.type}
              onChange={handleChange}
              placeholder="Type"
              itemsList={dropdownValues.contactTypes}
              error={touched.type && errors.type}
            />

            <ErrorMessage name="type" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">Passport {isError('passport')}</div>

            <StyledInput
              name="passport"
              placeholder="Passport"
              value={values.passport}
              onChange={handleChange}
              error={touched.passport && errors.passport}
            />

            <ErrorMessage name="passport" component={ValidationError} />
          </div>

          <div>
            <div className="text-sm pb-1">
              {t('nationality')} {isError('nationality')}
            </div>

            <StyledSelect
              name="nationality"
              placeholder="Nationality"
              value={values.nationality}
              onChange={handleChange}
              itemsList={dropdownValues.nationalities}
              error={touched.nationality && errors.nationality}
            />

            <ErrorMessage name="nationality" component={ValidationError} />
          </div>

          {dropdownValues?.activeAccountingIntegration && crmAccounts && (
            <div>
              <div className="text-sm pb-1">{t('crm Account')}</div>

              <StyledAutoComplete
                name="crmAccount"
                placeholder="Crm Account"
                value={values.crmAccount}
                onChange={(event, newValue) => setFieldValue('crmAccount', newValue.id)}
                itemsList={crmAccounts?.data}
              />

              <ErrorMessage component={ValidationError} name="crmAccount" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
