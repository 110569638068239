import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useCustomMutation } from 'mutations';
import { useTranslation } from 'react-i18next';

import { usePaginatedQuery, useQueryList } from 'queries';
import {
  EditAttachmentDialog,
  DeleteAttachmentDialog,
  AttachmentProgressBar,
  SectionContainer,
  SkeletonTableLoading,
  AttachmentsList,
  FileUploader,
  Notifier,
} from 'components';

import { CircularProgress } from '@mui/material';
import attachmentIcon from 'assets/attachmentBrowseIcon.svg';
import saveCircleIcon from 'assets/saveAttachmentIcon.svg';
import closeCircleIcon from 'assets/close-circle.svg';

export const PaymentAttachments = ({ apiFunctions, paymentId }) => {
  const { t } = useTranslation();

  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();

  /**
   * * data: includes contact attachments data
   * * paymentDropdown: dropdowns requried in the edit attachment dialog (tags)
   */
  const {
    data: attachmentsData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedQuery(
    apiFunctions.getAttachments.func,
    apiFunctions.getAttachments.key,
    paymentId,
    'PAYMENT',
  );

  const { data: paymentDropdown } = useQueryList(
    apiFunctions.getPaymentDropdowns.func,
    apiFunctions.getPaymentDropdowns.key,
  );

  const { tags, shareWithTenant } = paymentDropdown?.data || {};

  const {
    mutateAsync: storeAttachmentMutation,
    data: addAttachmentResponse,
    isLoading: storeAttachmentLoading,
  } = useCustomMutation(apiFunctions.addAttachment.func, apiFunctions.addAttachment.key);

  const { mutateAsync: deleteAttachmentMutation, data: deleteAttachmentResponse } =
    useCustomMutation(apiFunctions.deleteAttachment.func, apiFunctions.deleteAttachment.key);

  const { mutateAsync: editAttachmentMutation, data: editAttachmentResponse } = useCustomMutation(
    apiFunctions.editAttachment.func,
    apiFunctions.editAttachment.key,
  );

  React.useEffect(() => {
    if (acceptedFiles.length) setUploadedFiles(acceptedFiles);
  }, [acceptedFiles]);

  const handleFilesUpload = async () => {
    let payload = {
      attachment: uploadedFiles,
      parentId: paymentId,
      shareWithTenant: 1,
      type: 'PAYMENT',
    };
    const response = await storeAttachmentMutation(payload);
    if (response.statusCode === 200) setUploadedFiles([]);
  };

  const handleAttachmentEdit = async (attachmentData) => {
    const { attachmentId, accountingAttachmentId, attachment } = openDialog?.data || {};

    let payload = {
      attachment_id: attachmentId,
      parent_id: paymentId,
      accounting_attachment_id: accountingAttachmentId,
      file_url: attachment?.fileUrl,
      type: 'PAYMENT',
      ...attachmentData,
    };
    const response = await editAttachmentMutation(payload);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleAttachmentDelete = async () => {
    let payload = {
      attachmentId: openDialog?.data.accountingAttachmentId,
      type: 'PAYMENT',
    };

    const response = await deleteAttachmentMutation(payload);
    if (response.statusCode === 200) setOpenDialog(null);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 100
    ) {
      if (!isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="flex flex-col gap-5 p-3 rounded-b-2xl">
      <Notifier data={addAttachmentResponse} />
      <Notifier data={editAttachmentResponse} />
      <Notifier data={deleteAttachmentResponse} />

      <div className="flex flex-col gap-2 w-full">
        <FileUploader
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
        />

        {!!uploadedFiles.length && (
          <div className="flex flex-wrap flex-col sm:flex-row gap-2">
            {acceptedFiles.map((file) => (
              <div
                className={`flex justify-between gap-5 bg-[#FCFCFD] border border-[#DFDFE6] rounded-xl p-2`}
                key={file.name}
              >
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center bg-[#EBEFF7] p-3 rounded-full">
                    <img alt="" className="w-5" src={attachmentIcon} />
                  </div>

                  <div className="flex flex-col text-xs text-[#87898E]">
                    <div className="truncate">{file.name}</div>
                    {storeAttachmentLoading ? (
                      <AttachmentProgressBar />
                    ) : (
                      <div>{file.size / 1000} Kb</div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between gap-1">
                  <img
                    alt=""
                    className="hover:cursor-pointer"
                    src={saveCircleIcon}
                    onClick={handleFilesUpload}
                  />
                  <img
                    alt=""
                    className="hover:cursor-pointer"
                    src={closeCircleIcon}
                    onClick={() => setUploadedFiles([])}
                  />
                </div>
              </div>
            ))}

            {acceptedFiles.length > 1 && (
              <div className="flex justify-center sm:justify-start gap-2 border border-[#DFDFE6] rounded-xl p-3">
                <img
                  alt=""
                  className="hover:cursor-pointer w-6"
                  src={saveCircleIcon}
                  onClick={handleFilesUpload}
                />
                <img
                  alt=""
                  className="hover:cursor-pointer w-6"
                  src={closeCircleIcon}
                  onClick={() => setUploadedFiles([])}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-3">
          <div className="font-bold">{t('files')}</div>
        </div>

        <SectionContainer>
          {isLoading ? (
            <SkeletonTableLoading />
          ) : !attachmentsData?.pages.length ? (
            <div className="flex text-center items-center justify-center font-bold text-[#535353] text-sm">
              {t('no_attachments')}
            </div>
          ) : (
            <AttachmentsList
              attachments={attachmentsData}
              pagination={attachmentsData.pagination}
              onEdit={(data) =>
                setOpenDialog({
                  data: data,
                  dialogType: 'editAttachment',
                })
              }
              onDelete={(data) =>
                setOpenDialog({
                  data: data,
                  dialogType: 'deleteAttachment',
                })
              }
            >
              {isFetchingNextPage && (
                <div className="flex items-center justify-center">
                  <CircularProgress color="error" size={25} />
                </div>
              )}
            </AttachmentsList>
          )}
        </SectionContainer>
      </div>

      <DeleteAttachmentDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        onDelete={handleAttachmentDelete}
        type="Attachment"
      />

      <EditAttachmentDialog
        tagsDropdown={tags}
        shareWithTenantDropdown={shareWithTenant}
        enableShareWithTenant={true}
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        onEdit={handleAttachmentEdit}
      />
    </div>
  );
};
