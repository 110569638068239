import React from 'react';
import { NavLink, useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from 'queries';
import { useCustomMutation } from 'mutations';
import { contactOverviewNavItems } from 'utils/constants';
import { TabName } from 'utils/utils';

import { DeleteButton, EditButton, Notifier } from 'components';
import { ContactDialog, DeleteDialog } from 'components/dialogs';

import { Skeleton } from '@mui/material';
import phoneIcon from 'assets/phoneContactIcon.svg';
import greaterIcon from 'assets/greaterIcon.svg';

const ContactOverview = ({ apiFunctions }) => {
  TabName('ContactOverview');

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { contactId } = useParams();

  const [openDialog, setOpenDialog] = React.useState({ data: null, dialogType: '' });

  const { data, isLoading } = useCustomQuery(
    apiFunctions.get.func,
    apiFunctions.get.key,
    contactId,
    {
      onSuccess: (data) => {
        if (location.state && location.state.directEdit)
          setOpenDialog({ data: data.data, dialogType: 'editContact' });
      },
    },
  );

  const {
    mutateAsync: deleteContactMutation,
    isLoading: deleteLoading,
    data: mutationResponse,
  } = useCustomMutation(apiFunctions.delete.func, apiFunctions.delete.key);

  const handleContactDelete = async () => {
    const response = await deleteContactMutation([contactId]);

    if (response.statusCode === 200) {
      setOpenDialog(null);
      navigate('/contacts');
    }
  };

  const { summary } = data?.data || {};

  const contactContext = {
    contactDetails: data?.data,
    isLoading: isLoading,
  };

  return (
    <div className="mt-5">
      <Notifier data={mutationResponse} />

      <div className="flex gap-2 text-[#EA3323] font-medium sm:text-md text-sm">
        <NavLink
          className="cursor-pointer"
          to="/contacts"
          key="contactTitle"
          activeClassName="active"
        >
          {t('contact')}
        </NavLink>

        <img alt="" src={greaterIcon} />
        <div>{summary?.name}</div>
      </div>

      {isLoading ? (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            {[1, 2, 3, 4, 5].map((_) => (
              <Skeleton variant="text" width={120} height={44} />
            ))}
          </div>
        </div>
      ) : (
        <div className="h-full bg-[#FFF2F0] rounded-t-2xl text-[10px] lg:text-[12px] mt-6 p-3">
          <div className="flex gap-3 w-[85vw] sm:w-auto overflow-x-auto whitespace-nowrap">
            <div className="bg-white p-3 font-semibold rounded-3xl">{summary.name}</div>
            <div className="bg-white p-3 rounded-3xl text-[#8E8E8E]">{summary.email || 'N/A'}</div>

            <div className="flex gap-1 items-center bg-white p-3 font-semibold rounded-3xl">
              <img alt="" src={phoneIcon} />
              <div>{summary.phone || 'N/A'}</div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-3">
        <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center justify-between">
          <div className="flex sm:gap-3 gap-2 text-sm sm:text-md">
            {contactOverviewNavItems.map((item) => (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'bg-[#EA3323] text-white p-2 rounded-lg'
                    : 'border border-[#C9C9C9] p-2 rounded-lg'
                }
                to={`/contact-overview/${contactId}/${item.route}`}
                key={item.name}
                activeClassName="active"
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          {window.location.pathname.includes('/details') && (
            <div className="flex gap-3">
              <EditButton
                onClick={() => setOpenDialog({ data: data?.data, dialogType: 'editContact' })}
                disabled={isLoading}
              />

              <DeleteButton
                onClick={() => setOpenDialog({ data: null, dialogType: 'delete' })}
                disabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>

      <ContactDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog(null)}
        apiFunctions={apiFunctions}
      />

      <DeleteDialog
        openDialog={openDialog}
        name="contact"
        onClose={() => setOpenDialog(null)}
        onDelete={handleContactDelete}
        isLoading={deleteLoading}
      />

      <Outlet context={contactContext} />
    </div>
  );
};

export default ContactOverview;
