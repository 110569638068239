import React from 'react';
import { useQuery } from 'react-query';

import { isMobile } from 'utils/utils';
import { getNotifications } from 'apis/notifications/notifications';

import { NotificationDetails, StyledDialog } from 'components';
import { CircularProgress, DialogContent } from '@mui/material';
import notification from 'assets/notificationWhite.svg';

export const NotificationDialog = ({ openDialog, onClose }) => {
  const { data, isLoading } = useQuery('notifications', getNotifications, {
    enabled: openDialog?.dialogType === 'notification',
  });

  return (
    <StyledDialog
      fullWidth
      width="900px"
      height="725px"
      padding="30px"
      fullScreen={isMobile()}
      open={openDialog?.dialogType === 'notification'}
      onClose={onClose}
    >
      <div className="flex w-full h-fit justify-center items-center">
        <div className="w-[90%] sm:w-[60%] border border-[#dfdfe87a] rounded-2xl p-2 sm:p-3 mt-4">
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center w-[48px] h-[48px] bg-[#EA3323] rounded-full">
              <img src={notification} />
            </div>
            <span className="font-semibold">Notifications</span>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex h-full justify-center items-center">
          <CircularProgress color="primary" sx={{ color: '#EA3323' }} />
        </div>
      ) : (
        <DialogContent className={`${data?.data.length !== 0 ? '' : 'flex justify-center'}`}>
          <div className={`flex flex-col gap-4 items-center h-fit `}>
            {data?.data.length !== 0 ? (
              data?.data.map((notification) => (
                <NotificationDetails
                  notification={notification}
                  notificationId={notification.notificationId}
                />
              ))
            ) : (
              <div className="flex items-center justify-center font-bold text-[#535353] text-sm">
                You don't have any notification right now!
              </div>
            )}
          </div>
        </DialogContent>
      )}
    </StyledDialog>
  );
};
