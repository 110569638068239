import React from 'react';

export const Context = React.createContext({});

export const Provider = (props) => {
  const locusContext = { ...props };
  return <Context.Provider value={locusContext}>{props.children}</Context.Provider>;
};

export const { Consumer } = Context;
