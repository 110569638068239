import { useTranslation } from 'react-i18next';

export const Steps = ({ steps, currentStep }) => {
  const { t } = useTranslation();
  return (
    <div className="hidden sm:flex items-center gap-8 w-[25%]">
      <div className="relative flex flex-col justify-between h-5/6">
        <div className="absolute right-6 h-full border border-[#F6F4F4] z-10"></div>
        {steps.map((step) => (
          <div key={step.stepIndex} className="flex justify-center items-center gap-5 z-50">
            <div
              className={`w-full flex justify-end text-[16px] text-[#8E8E8E] ${
                currentStep === step.stepIndex && 'font-semibold text-black'
              }`}
            >
              {t(step.stepName)}
            </div>
            <div
              className={`transition ease-in-out delay-150 p-4 ${
                currentStep === step.stepIndex ? 'bg-[#EA3323]' : 'bg-[#8E8E8E]'
              } rounded-full`}
            >
              <img src={step.icon} />
            </div>
          </div>
        ))}
      </div>

      <div className="relative flex flex-col justify-between h-5/6">
        <div className="absolute h-full right-[7px] z-10 border border-[#F6F4F4]"></div>
        {steps.map((step) => (
          <div key={step.stepIndex} className="p-1 rounded-full flex bg-[#F6F4F4]">
            <span
              className={`${
                currentStep === step.stepIndex ? 'bg-[#EA3323]' : 'bg-[#8E8E8E]'
              } w-2 h-2 z-50 rounded-full transition ease-in-out delay-150`}
            ></span>
          </div>
        ))}
      </div>
    </div>
  );
};
