import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { ErrorMessage, useFormikContext } from 'formik';

import { addressAutoComplete } from 'apis/address/address';
import { LocusContext } from 'hooks/useLocusContext';
import { useDebounce } from 'hooks/useDebounce';

import { StyledInput } from 'components';
import { CircularProgress, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ContactAddress = ({ onCheckBoxToggle, isChcked }) => {
  const { dialogType } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  const [searchValue, setSearchValue] = React.useState('');
  const [valueChosen, setValueChosen] = React.useState({ value: '', isChosen: false });
  const debounceSearch = useDebounce(searchValue, 750);

  const {
    data,
    isLoading: searchLoading,
    error,
  } = useQuery(['addressAutoComplete', debounceSearch], () => addressAutoComplete(debounceSearch), {
    enabled: !!searchValue && !valueChosen.isChosen,
  });

  const isError = (value) => {
    if (errors.contactAddress?.[value]) return <span className="text-[#EA3323]">*</span>;
  };

  React.useEffect(() => {
    if (dialogType === 'editContact') {
      setSearchValue(values.contactAddress.description);
      setValueChosen({ value: values.contactAddress.description, isChosen: true });
    }
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-3xl font-bold">{t('contact_billing_address')}</div>
      <div className="text-2xl font-medium">{t('contact_address')}</div>

      <div className="mt-3 p-1">
        <div className="text-sm pb-1">
          {t('location')} {isError('placeId')}
        </div>
        <StyledInput
          name="contactAddress.placeId"
          placeholder="Location"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            //checks if the value is being deleted
            if (valueChosen.value.length > e.target.value.length) {
              setValueChosen({ value: '', isChosen: false });
              setSearchValue(e.target.value);
            }
          }}
          error={touched.contactAddress?.placeId && errors.contactAddress?.placeId}
        />

        <ErrorMessage name="contactAddress.placeId" component={ValidationError} />

        {searchValue && !valueChosen.isChosen && (
          <div className="bg-white flex flex-col gap-2 max-h-[200px] shadow-lg overflow-y-auto rounded-lg p-1 w-full z-10">
            {searchLoading ? (
              <div className="flex justify-center items-center p-2">
                <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
              </div>
            ) : (
              data?.data?.map((searchItem) => (
                <div
                  className="flex flex-col gap-1 justify-center rounded-lg py-3 px-4 hover:bg-[#EA3323] hover:text-white hover:cursor-pointer"
                  onClick={() => {
                    setFieldValue('contactAddress.placeId', searchItem.id);
                    setFieldValue('contactAddress.description', searchItem.name);
                    setSearchValue(searchItem.name);
                    setValueChosen({ value: searchItem.name, isChosen: true });
                  }}
                >
                  <div className="text-sm font-semibold">{searchItem.name}</div>
                </div>
              ))
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="w-full">
          <div className="text-sm pb-1">
            {t('zip_code')} {isError('zip_code')}
          </div>
          <StyledInput
            name="contactAddress.zipCode"
            value={values.contactAddress.zipCode}
            onChange={handleChange}
            placeholder="Zip Code"
            error={touched.contactAddress?.zipCode && errors.contactAddress?.zipCode}
          />
          <ErrorMessage name="contactAddress.zipCode" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">
            {t('apartment_number')} {isError('apartmentNumber')}
          </div>
          <StyledInput
            name="contactAddress.apartmentNumber"
            value={values.contactAddress.apartmentNumber}
            onChange={handleChange}
            placeholder="Apartment Number"
            error={
              touched.contactAddress?.apartmentNumber && errors.contactAddress?.apartmentNumber
            }
          />
          <ErrorMessage name="contactAddress.apartmentNumber" component={ValidationError} />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="w-full">
          <div className="text-sm pb-1">
            {t('building')} {isError('building')}
          </div>
          <StyledInput
            name="contactAddress.building"
            value={values.contactAddress.building}
            onChange={handleChange}
            placeholder="Building"
            error={touched.contactAddress?.building && errors.contactAddress?.building}
          />
          <ErrorMessage name="contactAddress.building" component={ValidationError} />
        </div>

        <div className="w-full">
          <div className="text-sm pb-1">
            {t('floor')} {isError('floor')}
          </div>
          <StyledInput
            name="contactAddress.floor"
            value={values.contactAddress.floor}
            onChange={handleChange}
            placeholder="Floor"
            error={touched.contactAddress?.floor && errors.contactAddress?.floor}
          />
          <ErrorMessage name="contactAddress.floor" component={ValidationError} />
        </div>
      </div>

      <div className="flex items-center">
        <Checkbox
          icon={
            <div className="w-[18px] h-[18px] bg-white border-2 border-[#8E8E8E] rounded"></div>
          }
          checked={isChcked}
          onChange={onCheckBoxToggle}
        />
        <div className="text-base">{t('same_address')}</div>
      </div>
    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;
