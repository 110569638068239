export const mapToAddContactReqBody = (payload) => {
  // * common contact date
  const {
    type,
    invoiceEmail,
    phone,
    email,
    notes,
    comments,
    crmAccount,
    billingAddress,
    contactAddress,
  } = payload || {};

  // * individual contact data
  const { salutation, firstName, lastName, nationality, passport, source, vatNumber } =
    payload || {};

  // * corporate contact data
  const { name, contactName, same_address, chamberCommerceNumber } = payload || {};

  return {
    // * contact type individual
    salutation_id: salutation,
    first_name: firstName,
    last_name: lastName,
    nationality_id: nationality,
    passport: passport,
    source_id: source,
    // * contact type corporate
    name: name,
    country: nationality,
    reference_person_name: contactName,
    chamber_of_commerce_number: chamberCommerceNumber,

    // * common data
    currency_id: '1', //  todo: to be removed
    record_type_id: payload.record_type_id,
    contact_type_id: type,
    phone: phone,
    email: email,
    invoice_email: invoiceEmail,
    vat_number: vatNumber,
    notes: notes,
    iban: payload.iban,
    accounting_reference: crmAccount,
    comments: comments,
    same_address: same_address,
    billing_address: {
      building: billingAddress.building,
      floor: billingAddress.floor,
      zip_code: billingAddress.zipCode,
      place_id: billingAddress?.placeId || '',
      description: billingAddress?.description || '',
      apartment_number: billingAddress.apartmentNumber,
    },
    contact_address: {
      building: contactAddress.building,
      floor: contactAddress.floor,
      zip_code: contactAddress.zipCode,
      place_id: contactAddress?.placeId || '',
      description: contactAddress?.description || '',
      apartment_number: contactAddress.apartmentNumber,
    },
    attachments: payload.attachments,
  };
};

export const mapToEditContactReqBody = (payload) => {
  // * individual contact data
  const { salutation, firstName, lastName, nationality, passport, source, occupation, crmAccount } =
    payload || {};

  // * corporate contact data
  const { name, contactName, chamberCommerceNumber } = payload || {};

  // * common contact date
  const {
    type,
    invoiceEmail,
    phone,
    email,
    same_address,
    legalType,
    notes,
    comments,
    billingAddress,
    contactAddress,
    vatNumber,
  } = payload || {};

  return {
    // * contact type individual
    salutation_id: salutation,
    first_name: firstName,
    last_name: lastName,
    nationality_id: nationality,
    passport: passport,
    source_id: source,
    occupation_id: occupation,

    // * contact type corporate
    name: name,
    country: nationality,
    reference_person_name: contactName,
    chamber_of_commerce_number: chamberCommerceNumber,

    // * common data
    contact_id: payload.contact_id,
    record_type_id: payload.record_type_id,
    contact_type_id: type,
    phone: phone,
    email: email,
    invoice_email: invoiceEmail,
    vat_number: vatNumber,
    legal_type_id: legalType,
    iban: payload.iban,
    accounting_reference: crmAccount,
    notes: notes,
    comments: comments,
    same_address: same_address,
    billing_address: {
      address_id: payload.billingAddressId,
      building: billingAddress.building,
      floor: billingAddress.floor,
      zip_code: billingAddress.zipCode,
      place_id: billingAddress.placeId || '',
      description: billingAddress.description || '',
      apartment_number: billingAddress.apartmentNumber,
    },
    contact_address: {
      address_id: payload.contactAddressId,
      building: contactAddress.building,
      floor: contactAddress.floor,
      zip_code: contactAddress.zipCode,
      place_id: contactAddress.placeId || '',
      description: contactAddress.description || '',
      apartment_number: contactAddress.apartmentNumber,
    },
  };
};
