import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';

export const getCustomerSubscriptions = () => {
  return axios.get(`/api/v2/setting-app/subscriptions/customers/${getUserGroupId()}`);
};

export const getUserGroupSubscriptionDetails = (userGroup) => {
  return axios.get(`/api/v2/setting-app/subscriptions/user-groups/${userGroup}`);
};

export const getCustomerDetails = () => {
  return axios.get(`/api/v2/setting-app/subscriptions/details/customers/${getUserGroupId()}`);
};

export const getUserGroupDropdowns = () => {
  return axios.get(`/api/v2/setting-app/subscriptions/dropdown-values/${getUserGroupId()}`);
};

export const updateSubscription = (payload) => {
  return axios.post(`/api/v2/setting-app/subscriptions/update`, {
    user_group_plan_id: payload.userGroupPlanId,
    user_group_id: payload.userGroupId,
    payment_frequency: payload.paymentFrequency,
    units: parseInt(payload.units),
    users: parseInt(payload.users),
    plan_id: payload.plan,
  });
};

export const updatePaymentMethod = (payload) => {
  return axios.post(`/api/v2/setting-app/subscriptions/payment-methods/update`, {
    user_group_id: getUserGroupId(),
    payment_role: payload.method,
    payment_source_id: payload.paymentSourceId,
  });
};
