import { useNavigate } from 'react-router-dom';

import locationIcon from 'assets/LocationIcon.svg';
import { Checkbox } from '@mui/material';

export const PropertyCard = ({ property, selectedProperties, onSelect, onUnselect }) => {
  const navigate = useNavigate();

  return (
    <div
      key={property.propertyName}
      className={`cursor-pointer border border-[1px] border-[#C9C9C9] rounded-2xl p-4 ease-in duration-300 hover:shadow-xl hover:scale-[1.015] ${
        selectedProperties.includes(property.propertyId) && 'bg-[#F5F5F5]'
      }`}
      onClick={(e) => {
        if (e.target.type === 'checkbox') e.stopPropagation();
        else navigate(`/property-overview/${property.propertyId}/details`);
      }}
    >
      <div className="flex justify-between items-center">
        <img
          className="w-[34%] rounded-lg flex-shrink-0 object-cover"
          src={property.propertyAvatar}
        />

        <Checkbox
          icon={<div className="w-4 h-4 bg-[#F6F4F4] border border-[#C9C9C9] rounded"></div>}
          checkedIcon={
            <div className="w-4 h-4 bg-[url('assets/blueCheckbox.svg')] bg-[length:10px] bg-no-repeat bg-center border border-[#C9C9C9] rounded"></div>
          }
          checked={!!selectedProperties.includes(property.propertyId)}
          onChange={(e) => {
            if (e.target.checked) onSelect();
            else onUnselect();
          }}
        />
      </div>

      <div className="flex flex-col justify-between p-2 w-full">
        <div className="flex flex-col gap-1">
          <div className="text-[10px] text-[#8E8E8E] font-light">
            {property.usageType?.toUpperCase()}
          </div>
          <div>{property.propertyName}</div>
          <div className="text-xs font-light">
            {property.propertyType + ' / ' + property.recordType}
          </div>
          <div className="text-[10px] text-[#8E8E8E]">{property.description}</div>
        </div>

        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-1">
            <div className="bg-[#ECECEC] rounded-full p-1.5">
              <img className="w-4" src={locationIcon} />
            </div>

            <div>
              <div className="text-[10px] font-medium">{property.city}</div>
              <p className="text-[10px] font-light text-[#8E8E8E]">{property.address}</p>
            </div>
          </div>

          <div className="text-[10px] rounded p-2 bg-[#EEF6F0] text-[#008E0E] truncate">
            {property.status}
          </div>
        </div>
      </div>
    </div>
  );
};
