import * as yup from 'yup';
import React from 'react';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage, Field } from 'formik';

import { useCustomMutation } from 'mutations';
import { useCustomQuery } from 'queries';
import { StyledInput, StyledDialog, Notifier } from 'components';

import {
  CircularProgress,
  Chip,
  Box,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
} from '@mui/material';
import dialogTitleIcon from 'assets/dialogTitleIcon.svg';

const notifyTenantSchema = yup.object().shape({
  title: yup.string().required('Title field is required'),
  description: yup.string().required('Description field is required'),
  properties: yup.array().required('Property field is required'),
});

export const NotifyTenantDialog = ({ openDialog, onClose, apiFunctions }) => {
  const { propertyId } = openDialog?.data || {};

  const { data, isLoading: dropDownLoading } = useCustomQuery(
    apiFunctions.tenantDropdown.func,
    apiFunctions.tenantDropdown.key,
    propertyId,
    { enabled: openDialog?.dialogType === 'notifyTenant' },
  );

  const {
    mutateAsync,
    isLoading: addLoading,
    data: addResponse,
  } = useCustomMutation(apiFunctions.sendNotification.func, apiFunctions.sendNotification.key);

  return (
    <>
      <Notifier data={addResponse} />

      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          description: '',
          properties: [],
        }}
        validationSchema={notifyTenantSchema}
        onSubmit={async (values, { resetForm }) => {
          let payload = {
            property_id: values.properties,
            title: values.title,
            description: values.description,
          };

          const res = await mutateAsync(payload);
          if (res.statusCode === 200) {
            resetForm();
            onClose();
          }
        }}
      >
        {({ handleChange, values, errors, touched, dirty, resetForm }) => (
          <StyledDialog
            fullWidth
            width="1064px"
            isEdited={dirty}
            open={openDialog?.dialogType === 'notifyTenant'}
            onClose={() => {
              resetForm();
              onClose();
            }}
          >
            <div className="flex flex-col gap-2 w-full h-fit justify-center items-center">
              <div className="flex w-[60%] h-full border border-[#dfdfe87a] rounded-2xl justify-between items-center p-3">
                <div className="flex gap-2 items-center font-semibold">
                  <div className="flex w-[48px] h-[48px] bg-[#EA3323] rounded-full items-center justify-center">
                    <img src={dialogTitleIcon} />
                  </div>
                  Send Notification
                </div>
              </div>
            </div>

            {dropDownLoading ? (
              <div className="flex h-full justify-center items-center">
                <CircularProgress sx={{ color: '#e93424' }} />
              </div>
            ) : (
              <Form>
                <DialogContent>
                  <div className="flex h-full justify-center items-end pt-2">
                    <div className="grid grid-cols-1 w-[60%] items-center gap-4">
                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">
                          Properties{' '}
                          {errors['properties'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <Select
                          sx={{
                            borderRadius: '16px',
                            fontSize: 14,
                          }}
                          name="properties"
                          placeholder="Properties"
                          value={values.properties}
                          onChange={handleChange}
                          multiple
                          disableUnderline
                          displayEmpty
                          error={touched.properties && errors.properties}
                          renderValue={(selected) => {
                            let selectedPropertiesValues = []; // * will include tags names retrieved by thier ids
                            for (let i = 0; i < data?.data?.length; i++)
                              if (selected?.includes(data?.data[i].id))
                                selectedPropertiesValues.push(data?.data[i].name);

                            return !values.properties?.length ? (
                              'Properties'
                            ) : (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selectedPropertiesValues.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            );
                          }}
                        >
                          {data?.data?.map((property) => (
                            <MenuItem key={property.id} value={property.id}>
                              {property.name}
                            </MenuItem>
                          ))}
                        </Select>

                        <ErrorMessage component={CustomErrorMessage} name="properties" />
                      </div>

                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">
                          Title {errors['title'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <StyledInput
                          name="title"
                          placeholder="Title"
                          value={values.title}
                          onChange={handleChange}
                          error={touched.title && errors.title}
                        />

                        <ErrorMessage component={CustomErrorMessage} name="title" />
                      </div>

                      <div className="flex flex-col gap-2 w-full h-full">
                        <div className="text-sm text-black">
                          Description{' '}
                          {errors['description'] && <span className="text-[#EA3323]">*</span>}
                        </div>

                        <Field
                          as={StyledTextArea}
                          name="description"
                          placeholder="Description"
                          rows="6"
                          cols="50"
                        />

                        <ErrorMessage component={CustomErrorMessage} name="description" />
                      </div>
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <div className="flex justify-center gap-4 w-full pt-2">
                    <div className="flex justify-end w-[60%]">
                      <button
                        className="w-[120px] h-[50px] bg-[#EA3323] rounded-lg text-white font-bold"
                        type="submit"
                      >
                        {addLoading ? (
                          <div className="flex justify-center items-center">
                            <CircularProgress color="inherit" size={25} />
                          </div>
                        ) : (
                          'Send'
                        )}
                      </button>
                    </div>
                  </div>
                </DialogActions>
              </Form>
            )}
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};

const CustomErrorMessage = styled.div`
  color: #ff3b00;
  font-size: 14px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 16px;
  padding: 8px;
  box-shadow: ${({ error }) => error && '0px 0px 0px 3px rgba(234,51,35,0.2)'};
  border: ${({ error }) => (error ? '1px solid #EA3323' : '1px solid #C9C9C9')};
  transition: 0.5s;
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(43, 93, 220, 0.2);
    border: 1px solid #2b5ddc;
    outline: none;
  }
`;
