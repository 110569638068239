import {
  getListedProperties,
  getMonthlyPerformance,
  getDashboardMap,
  getTodos,
  getRepairs,
  getChartGrid,
  getYearlyCashFlow,
  getDashboardDropdown,
  getMasterProperties,
  globalSearch,
} from 'apis/dashboard/dashboard';

export const DashboardApiFunctions = {
  getListedProperties: { func: getListedProperties, key: 'dashboard' },
  getMonthlyPerformance: { func: getMonthlyPerformance, key: 'dashboard' },
  getDashboardMap: { func: getDashboardMap, key: 'dashboard' },
  getTodos: { func: getTodos, key: 'dashboard' },
  getRepairs: { func: getRepairs, key: 'dashboard' },
  getChartGrid: { func: getChartGrid, key: 'dashboard' },
  getYearlyCashFlow: { func: getYearlyCashFlow, key: 'dashboard' },
  getDashboardDropdown: { func: getDashboardDropdown, key: 'dashboard' },
  getMasterProperties: { func: getMasterProperties, key: 'dashboard' },
  globalSearch: { func: globalSearch, key: 'dashboard' },
};
