import * as yup from 'yup';

export const roomSchema = yup.object().shape({
  title: yup.string().required('title field is required'),
  surface: yup
    .number('surface should be a number')
    .positive()
    .typeError('input must be a number')
    .required('surface field is required'),
  status: yup.string().required('status field is required'),
});
