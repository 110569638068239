export const leadInitialState = (data) => {
  const { generalInfo, summary } = data || {};

  return {
    contactRecordType: summary?.contactRecordTypeId || data?.generalInfo.recordTypeId || '',
    contact: summary?.contactId || data?.contactId || '',
    leadSource: summary?.leadSourceId || '',
    creationDate: generalInfo?.creationDate || '',
    contactMethod: generalInfo?.contactMethodId || '',
    listingType: summary?.listingTypeId || data?.listingTypeId || '',
    city: generalInfo?.description || '',
    propertyUsage: summary?.propertyUsageId || '',
    sqmRange: generalInfo?.sqmRangeId || '',
    budget: generalInfo?.budget || '',
    comments: generalInfo?.comments || '',
    placeId: generalInfo?.placeId || '',
    description: generalInfo?.description || '',
  };
};
