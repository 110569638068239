export const paymentInitialState = (data) => {
  return {
    paymentId: data?.paymentId || '',

    paymentDate: data?.paymentDate || null,
    transactionId: data?.transactionId || '',
    amountFc: data?.amountFc || '',
    referenceId: data?.referenceId || '',
    contactId: data?.contactId || '',
    paymentMethodId: data?.paymentMethodId || '',
  };
};
