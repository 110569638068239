import React, {useEffect, useState} from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { registerSchema } from 'validationSchemas';
import { register } from 'apis/authentication/authentication';

import 'react-phone-input-2/lib/material.css';

import { CircularProgress } from '@mui/material';

import bannerImage from 'assets/testLoginbg.png';

import { TabName } from 'utils/utils';
import {RegisterSteps} from "components/registerSteps";
import {AccountInfo} from "./registerComponents/accountInfo";
import {BillingInfo} from "./registerComponents/billingInfo";
import {CreditCardInfo} from "./registerComponents/creditCardInfo";
import {propertyDialogSteps} from "../../utils/constants";
import {useTranslation} from "react-i18next";

export const Register = ({ apiFunctions }) => {
  TabName('Register');

  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, error: e, mutate, isLoading } = useMutation((payload) => register(payload));
  const isLastStep = currentStep === propertyDialogSteps.length - 1;

  const handleSetNewStep = (direction) => {
    if (direction === 'increase') setCurrentStep(currentStep + 1);
    else  {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    window.Chargebee.init({
      site: "locusplatform-test",
      domain: "https://locusplatform-test.chargebee.com/",
      publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          mobile: null,
          companyName: '',
          vatNumber: '',
          placeId: '',
          description: '',
          floor: '',
          building: '',
          zipCode: '',
          countryCode: '',
          apartmentNumber: '',
          token: null,
        }}
        validationSchema={registerSchema[currentStep - 1]}
        onSubmit={(values) => {
          if(isLastStep && values.token) {
            mutate(values, {
              onSuccess: (data) => {
                if (data.statusCode === 201) {
                  localStorage.setItem('username', values.email);
                  navigate('/confirm-account', {state: {username: values.email}});
                }
                else if (data.statusCode === 202) {
                  //navigate to the confirmation of account through otp
                  navigate('/confirm-account', {
                    state: { redirectionMessage: data.message, username: values.email },
                  });
                }
              },
            });
          } else {
            setCurrentStep(prev => prev + 1)
          }
        }}
      >
        {({ values}) => (
          <Form>
            <div className="flex h-screen">
              <div className="flex-none w-[35%] hidden lg:block relative">
                <img src={bannerImage} className="absolute inset-0 w-full h-full object-cover" />
              </div>

              <div className="flex flex-col items-center gap-4 w-full bg-[url('assets/login-background.svg')] bg-cover bg-center p-6">
                <div className="flex items-center self-end mt-4 justify-center text-lg bg-black w-40 h-12 text-white rounded-tr-3xl rounded-bl-3xl">
                  <a href="">Back to home</a>
                </div>

                <RegisterSteps currentStep={currentStep} />
                {currentStep === 1 && <AccountInfo data={data?.message}/>}
                {currentStep === 2 && <BillingInfo apiFunctions={apiFunctions}/>}
                {currentStep === 3 && <CreditCardInfo />}

                <div className="flex gap-3 justify-start">
                  {
                    currentStep !== 1 &&
                    <button
                      type="button"
                      onClick={() => handleSetNewStep('decrease')}
                      className="mt-6 w-24 h-12 bg-black text-white rounded-tr-3xl rounded-bl-3xl font-medium align-middle text-center hover:cursor-pointer"
                    >
                      {isLoading ? <CircularProgress color="inherit" size={25} /> : t('back')}
                    </button>
                  }

                  {
                    isLastStep ?
                      <button
                        disabled={!values.token}
                        type="submit"
                        className="mt-6 w-24 h-12 bg-[#FF0000] text-white font-medium align-middle text-center rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer"
                      >
                        {isLoading ? <CircularProgress color="inherit" size={25} /> : t('sign_up') }
                      </button>
                      :
                      <button
                        type="submit"
                        className="mt-6 w-24 h-12 bg-[#FF0000] text-white font-medium align-middle text-center rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer"
                      >
                        {t('next')}
                      </button>
                  }


                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
