import { isMobile } from 'utils/utils';
import Skeleton from '@mui/material/Skeleton';

export const GenericLoadingIndicator = () => {
  return (
    <div className="mt-4 flex sm:flex-row flex-col justify-between gap-4 border border-[#C9C9C9] rounded-[20px] p-[10px] sm:p-[30px]">
      <div>
        <Skeleton variant="rectangular" width={100} height={60} sx={{ borderRadius: '16px' }} />
        <Skeleton variant="text" width={200} height={88} />
        <div className="flex gap-2">
          <Skeleton variant="text" width={100} height={44} />
          <Skeleton variant="text" width={100} height={44} />
        </div>
      </div>

      <Skeleton
        variant="rectangular"
        width={isMobile() ? '100%' : `40%`}
        height={`200px`}
        sx={{ borderRadius: '16px' }}
      />
    </div>
  );
};
